var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['tw-flex tw-flex-nowrap tw-items-center tw-py-4 h-56 top-nav tw-border-b', { 'top-nav--bg' : !_vm.bgTransparent } ]},[_c('div',{ref:"topnav-left-action"},[_vm._t("left-action",function(){return [(_vm.isBack && (!_vm.isDesktopView || _vm.showBackButton))?_c('BackButton',{staticClass:"top-nav__action tw-mr-4 pl-body",attrs:{"prev-route":_vm.prevRoute}}):(_vm.type === 'menu')?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDesktopView),expression:"!isDesktopView"}],staticClass:"top-nav__action tw-mr-4 pl-body",on:{"click":function($event){return _vm.clickLeftAction()}}},[_vm._t("left-action-icon",function(){return [_c('svg',{staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M68.7,13.1H3.3c-2,0-3.3-1.3-3.3-3.3s1.3-3.3,3.3-3.3h65.5c2,0,3.3,1.3,3.3,3.3S70.7,13.1,68.7,13.1z M72,36\n              c0-2-1.3-3.3-3.3-3.3H3.3C1.3,32.7,0,34,0,36s1.3,3.3,3.3,3.3h65.5C70.7,39.3,72,38,72,36z M72,62.2c0-2-1.3-3.3-3.3-3.3H3.3\n              c-2,0-3.3,1.3-3.3,3.3c0,2,1.3,3.3,3.3,3.3h65.5C70.7,65.5,72,64.1,72,62.2z"}})])]})],2):_vm._e()]})],2),_c('p',{ref:"topnav-title",class:[
      'tw-font-thin leading-24 tw-text-base tw-text-black tw-uppercase tw-truncate',
      _vm.titleClass,
      {
        'tw-mx-auto tw-text-center tw-font-normal tw-absolute tw-left-1/2': (_vm.centerTitle && _vm.isMobileView) || _vm.alwaysCenter
      },
      {
        'tw-flex tw-items-center': !_vm.logo
      },
    ],style:(`
      transform: translateX(${(_vm.centerTitle && _vm.isMobileView) || _vm.alwaysCenter ? '-50%': '0'});
      top: ${(_vm.centerTitle && _vm.isMobileView) || _vm.alwaysCenter ? '16px': 'auto'};
      `)},[(_vm.logo)?_c('img',{staticClass:"tw-h-14 tw-block",attrs:{"src":_vm.appLogoUrl,"alt":"eMentored"}}):[_vm._t("title-left"),_vm._v(" "+_vm._s(_vm.transformedTitle)+" "),_vm._t("title-right")]],2),_c('div',{ref:"topnav-right-action",staticClass:"tw-ml-auto tw-flex tw-items-center"},[_vm._t("right-actions"),_c('NotificationIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNotificationIcon),expression:"showNotificationIcon"}],class:[
        'tw-h-6 tw-w-6',
        { 'tw-mr-6': _vm.showUserPhoto },
        { 'mr-body': !_vm.showUserPhoto },
      ]}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUserPhoto),expression:"showUserPhoto"}],staticClass:"mr-body tw-cursor-pointer tw-rounded-full tw-flex tw-flex-grow-0",attrs:{"to":{ name: 'profile' },"tag":"button"}},[_c('UserPhoto',{attrs:{"photo-size":"tw-h-6 tw-w-6","self":""}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }