<template>
  <AppView padd-bottom>
    <TopNav
      slot="top-app-view"
      title="Survey"
      title-class="lg:ml-body lg-app:tw-font-bold"
      center-title
      type="menu"
      @input="openSideNav()"
      show-notification-icon
    />
    <div class="mx-body tw-mb-8 tw-h-full">
      <div v-if="error">
        <p class="tw-text-center tw-text-base opacity-78 tw-my-20" v-html="error"></p>
      </div>
      <div v-else-if="loading">
        <p class="tw-text-center tw-text-xs opacity-54 tw-my-20">Loading...</p>
      </div>
      <div v-else>
        <div class="tw-max-w-3xl tw-mx-auto tw-mt-10">
          <template v-for="(question, index) in surveyQuestions">
            <div class="tw-pb-4 tw-mb-4 tw-border-b" :key="index">
              <div class="tw-my-2">
                <p class="tw-text-lg">
                  {{question.criteria}}
                </p>
              </div>
              <div class="tw-p-2 tw-bg-gray-100 tw-rounded-10" v-if="question.ratings.length">
                <div class="tw-flex sm:tw-flex-nowrap tw-flex-wrap tw-items-end">
                  <template v-for="(rating, ratingindex) in question.ratings">
                    <div :key="`rating-${ratingindex}`" class="tw-p-5 tw-text-center">
                      <p class="tw-text-center tw-text-sm" style="max-width: 150px">
                        {{rating.rates}}
                      </p>
                      <input type="radio" :value="rating.id" v-model="form[question.id].rating" />
                    </div>
                  </template>
                </div>
              </div>
              <div v-else>
                <!-- just a textarea -->
                <BaseTextarea
                  rows="4"
                  class="bg-black-11 placeholder:tw-text-gray-900"
                  v-model="form[question.id].rating"
                  placeholder="Type your answer here"
                  max-height="45vh"
                />
              </div>
            </div>
          </template>

          <BaseButton
            :text="btnText"
            class="tw-block tw-w-full tw-py-3"
            :disabled="!canSendSurvey || disableBtn"
            @click="sendSurveyResponses"
          />
        </div>
      </div>


    </div>
  </AppView>
</template>

<script>
export default {
  name: 'MatchEndedSurvey',
  data() {
    return {
      loading: true,
      error: undefined,
      surveyQuestions: [],
      form: {},
      btnText: 'Send',
      disableBtn: false,
      surveyResponse: {},
    }
  },
  computed: {
    canSendSurvey() {
      let canSend = true;
      Object.entries(this.form).forEach(([id, formItem]) => {
        if (!formItem.rating) {
          canSend = false;
        }
      })

      return canSend;
    }
  },
  methods: {
    async getSurveyQuestions() {
      const survey = await this.$store.dispatch('getMatchEndedSurvey', true)
    
      if(!Array.isArray(survey.questions)) {
        this.error = 'Could not get the survey questions. Please try again later.'
        return false;
      }

      // console.log(survey);

      // map to form
      survey.questions.forEach((question) => {
        const questionForm = {
          questionId: question.id,
          rating: undefined,
        }

        this.form = {
          ...this.form,
          [question.id]: questionForm,
        }
      });

      this.surveyQuestions = survey.questions;

      return true;
    },
    async getSurveyUserResponse() {
      const response = await this.$store.dispatch('getMatchEndedSurveyUserResponse');

      if (!response) {
        this.error = 'Error while fetching your response';
        return false;
      }

      this.surveyResponse = response;

      return true;
    },
    async sendSurveyResponses() {
      if (!this.canSendSurvey) return;

      this.disableBtn = true;
      this.btnText = 'sending...';
      this.$toasted.info('sending...', { duration: 3000 });

      // map to be an array
      const mappedForm = Object.entries(this.form).map(([index, formItem]) => {
        return formItem;
      });

      await this.$nextTick();

      // console.log(mappedForm);

      const response = await this.$store.dispatch('saveUserSurveyResponse', mappedForm);

      await this.$nextTick();

      if (response) {
        this.$toasted.success('success!', { duration: 4000 });

        this.$router.push('/app');

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Error in saving your survey response.'
        });
      }

      this.btnText = 'Send';
      this.disableBtn = false;
    },
    async assertUser() {
      const can = await this.$store.dispatch('userCanTakeSurvey');

      console.log(can);

      return !!can;
    },
  },
  async created() {
    this.loading = true;
    this.error = undefined;

    const userCanTakeSurvey = await this.assertUser();

    if (!userCanTakeSurvey) {
      // ? todo: how to make sure this works
      // this.$router.push('/app');
      // return;
    }

    await Promise.all([
      this.getSurveyQuestions(),
      this.getSurveyUserResponse(),
    ])

    await this.$nextTick();

    this.loading = false;
  }
};
</script>

<style>

</style>
