import { maxFileSize } from '../modules/misc';
import form from '../modules/formHelper';

export default {
  name: 'profileMixin',
  data() {
    return {
      loadingPhoto: true,
      minBioLength: 50,
      maxBioLength: 500,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.photo': function (val = '') {
      if (val.length > 0) {
        this.loadingPhoto = false;
      }
    },
  },
  computed: {
    data() {
      return this.dataObjName ? this[this.dataObjName] : this.user;
    },
    bioLength() {
      const match = this.form.bio.match(/[a-z]/gi);
      return match ? match.length : 0;
    },
    isValidBio() {
      return this.data.bio.length > 0;
    },
    isValidPhoto() {
      return this.data.photo.length > 0 || this.form.photo.length > 0;
    },
    canSaveBio() {
      return this.bioLength >= this.minBioLength && this.bioLength <= this.maxBioLength;
    },
    canSavePhoneNumber() {
      return form.isValidNumber(this.form.phoneNumber, false);
    },
    canSaveEmail() {
      return form.isValidKenGenEmail(this.form.email);
    },
    canSaveName() {
      return this.form.name?.length > 0 && this.form.last_name?.length > 0;
    },
  },
  methods: {
    addUserPhoto() {
      [this.form.photoFile] = this.$refs['photo-input'].files;

      if (this.form.photoFile.size > maxFileSize) {
        this.form.photoFile = undefined;

        this.$toasted.info('Please upload a photo 8MB maximum', {
          duration: 4000,
          position: 'top-right',
        });
      }
    },
    promptUpdatePhoto() {
      this.$refs['photo-input'].click();
    },
  },
};
