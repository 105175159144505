<template>
  <AppView padd-bottom class="transition-fast">
    <TopNav
      slot="top-app-view"
      type="back"
      show-notification-icon
      show-user-photo
      show-back-button
    >
      <BaseButton
        slot="right-actions"
        text="download"
        icon
        @click="downloadResource(resourceUrl)"
        class="tw-mr-6"
      >
        <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
            M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
            c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
            C62.8,33.4,62.8,31.7,61.6,30.5z"/>
        </svg>
      </BaseButton>
    </TopNav>

    <div class="mx-body">
      <div v-if="loading">
        <p class="tw-text-center tw-my-12 tw-text-xs opacity-54 tw-italic">loading...</p>
      </div>
      <div v-else class="tw-relative tw-w-full tw-p-2">
        <!-- file -->
        <p class="tw-text-center tw-mb-6 tw-mt-2 tw-font-bold">{{title}}</p>

        <div v-if="docType === 'word' || docType === 'pdf' || docType === 'excel' || docType === 'html'">
          <!-- word/pdf/excel-->
          <div v-show="iframeLoading">
            <p class="tw-text-center tw-my-12 tw-text-xs opacity-54 tw-italic">loading...</p>
          </div>
          <div v-show="!iframeLoading">
            <p class="opacity-75 tw-text-center tw-mb-4">Please refresh the page if the document does not appear here within 10 seconds</p>
            <iframe
              :src="docType === 'html' ? resourceUrl : `https://docs.google.com/gview?url=${resourceUrl}&embedded=true`"
              width="100%"
              style="min-height: 90vh;"
              frameborder="0"
              ref="doc-iframe"
              loading="eager"
              @load="iframeLoading = false"
            ></iframe>
          </div>
        </div>
        <div v-else-if="docType === 'jpg' || docType === 'png' || docType === 'svg'">
          <!-- image -->
          <img
            :src="resourceUrl"
            class="tw-w-full tw-h-auto tw-object-contain"
            :alt="title"
          >
        </div>
        <div v-else class="tw-text-center">
          <p class="tw-text-center tw-my-12">unreadable file type</p>
          <BaseButton
            text="download resource"
            icon
            @click="downloadResource(resourceUrl)"
          >
            <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                C62.8,33.4,62.8,31.7,61.6,30.5z"/>
            </svg>
          </BaseButton>
        </div>
      </div>
    </div>

  </AppView>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  name: 'ResourceView',
  data() {
    return {
      loading: true,
      iframeLoading: true,
      doc: this.$route.query.doc,
      title: this.$route.query.title || ' ',
    };
  },
  computed: {
    resourceUrl() {
      // not sure what to do here
      return decodeURI(this.doc);
    },
    docType() {
      return this.resourceType(this.doc, true);
    },
  },
  methods: {
    downloadResource(resource) {
      window.open(resource, '_blank');
    },
    consoleIt(text) {
      console.log(text);
    }
  },
  async mounted() {
    //
    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;

    const iframe = this.$refs['doc-iframe'];

    // console.log(iframe);

    if (iframe) {
      iframe.contentWindow.onunload = function() {
        this.iframeLoading = true;
      }
    } else {
      this.iframeLoading = false;
    }

  },
};
</script>

<style>

</style>
