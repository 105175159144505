import Vue from 'vue';

import Toasted from 'vue-toasted/dist/vue-toasted';
import vMoment from 'vue-moment';
import PortalVue from 'portal-vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';

import router from './router';
import store from './store';
import uiMixins from './mixins/uiMixins';
import licenseMixin from './mixins/license';
import vSelect from './modules/vSelect';
import BaseButton from './components/BaseButton.vue';
import BaseTextarea from './components/BaseTextarea.vue';
import BaseCard from './components/BaseCard.vue';
import UserPhoto from './components/UserPhoto.vue';
import ViewMore from './components/ViewMore.vue';
import AppView from './views/_partials/AppView.vue';
import TopNav from './views/_partials/TopNav.vue';
import {
  calculatePercentage,
  capitalizeText,
  toJsDate,
  pluralize,
  firstLetter,
  genderize,
  notificationActionText,
  toBoolean,
} from './modules/dataHelper';
import logLastSeen from './modules/api/logLastSeen';

import App from './App.vue';
import './registerServiceWorker';
import VueRouter from 'vue-router';

// #region vue register

Vue.filter('percent', calculatePercentage);
Vue.filter('toJsDate', toJsDate);
Vue.filter('capitalize', capitalizeText);
Vue.filter('pluralize', pluralize);
Vue.filter('genderize', genderize);
Vue.filter('firstLetter', firstLetter);
Vue.filter('notificationActionText', notificationActionText);
Vue.mixin(licenseMixin);
Vue.mixin(uiMixins);
Vue.use(PortalVue);
Vue.use(VueAwesomeSwiper);
Vue.use(Toasted);
Vue.use(vMoment);
Vue.component('AppView', AppView);
Vue.component('v-select', vSelect);
Vue.component('BaseButton', BaseButton);
Vue.component('UserPhoto', UserPhoto);
Vue.component('BaseCard', BaseCard);
Vue.component('BaseTextarea', BaseTextarea);
Vue.component('TopNav', TopNav);
Vue.component('ViewMore', ViewMore);

Vue.toasted.register('appError',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.errorMessage) {
      return 'Oops.. Something Went Wrong';
    }

    // if there is a message show it with the message
    return `Oops... ${payload.errorMessage}`;
  },
  {
    position: 'top-right',
    fullWidth: false,
    type: 'error',
    duration: 4000,
  });

// #endregion vue register

Vue.config.productionTip = process.env.NODE_ENV !== 'production';
Vue.config.devtools = process.env.NODE_ENV === 'development';

const removeLoadingState = () => {
  // * remove static loading element
  let $preApp = document.getElementById('pre-app--loading');
  if ($preApp) {
    $preApp.remove();
    $preApp = undefined;
  }
}

(async () => {
  if (toBoolean(process.env.VUE_APP_KILLED)) {
    new Vue({
      router: new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: [{
          path: '*',
          name: 'landingpageKilled',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import( /* webpackChunkName: "killed" */ './views/CompanyHomeKilled.vue'),
        }]
      }),
      store,
      render: h => h(App),
    }).$mount('#app');

    removeLoadingState();
    return;
  }

  const $router = await router();
  const $app = new Vue({
    router: $router,
    store,
    render: h => h(App),
  }).$mount('#app');

  removeLoadingState();

  // eslint-disable-next-line no-multi-assign
  document.onvisibilitychange = window.onunload = logLastSeen.bind(this, $app);

  if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = $app.constructor;
  }
})();
