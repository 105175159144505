import _sortBy from 'lodash/sortBy';
import { apiPost, generateFormData } from '../modules/apiHelper';
import { getRandomArrayItem, now, toBoolean } from '../modules/dataHelper';

const botNames = [
  'Ebby',
  'Elly',
];

export default {
  state: () => ({
    botName: window.localStorage.getItem('ema-app-bot-name') || getRandomArrayItem(botNames),
    messages: [],
  }),
  getters: {
    getBotName(state) {
      return state.botName;
    },
    userSupportMessages(state) {
      return _sortBy(state.messages, 'tym');
    },
  },
  mutations: {
    addSupportMessage(state, text) {
      state.messages.push({
        text,
        tym: now(),
      });
    },
  },
  actions: {
    async sendSupportMessage(context, { text }) {
      const userId = context.rootState.User.id;
      const formData = generateFormData({
        senderid: userId,
        clientid: userId,
        text,
        // supportid: ???
      });

      const response = await apiPost('support', formData, 11)
        .then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          context.commit('addSupportMessage', text);

          // todo: save support messages to apiStore
          return true;
        });

      return !!response;
    },
  },
};
