<template>
  <div
    :class="['tw-flex tw-flex-nowrap tw-items-center tw-py-4 h-56 top-nav tw-border-b', { 'top-nav--bg' : !bgTransparent } ]"
  >
    <div ref="topnav-left-action">
      <slot name="left-action">
        <BackButton v-if="isBack && (!isDesktopView || showBackButton)" class="top-nav__action tw-mr-4 pl-body" :prev-route="prevRoute"/>
        <button
          v-else-if="type === 'menu'"
          class="top-nav__action tw-mr-4 pl-body"
          v-show="!isDesktopView"
          @click="clickLeftAction()"
        >
          <slot name="left-action-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M68.7,13.1H3.3c-2,0-3.3-1.3-3.3-3.3s1.3-3.3,3.3-3.3h65.5c2,0,3.3,1.3,3.3,3.3S70.7,13.1,68.7,13.1z M72,36
                c0-2-1.3-3.3-3.3-3.3H3.3C1.3,32.7,0,34,0,36s1.3,3.3,3.3,3.3h65.5C70.7,39.3,72,38,72,36z M72,62.2c0-2-1.3-3.3-3.3-3.3H3.3
                c-2,0-3.3,1.3-3.3,3.3c0,2,1.3,3.3,3.3,3.3h65.5C70.7,65.5,72,64.1,72,62.2z"/>
            </svg>
          </slot>
        </button>
      </slot>
    </div>

    <p
      ref="topnav-title"
      :style="`
        transform: translateX(${(centerTitle && isMobileView) || alwaysCenter ? '-50%': '0'});
        top: ${(centerTitle && isMobileView) || alwaysCenter ? '16px': 'auto'};
        `"
      :class="[
        'tw-font-thin leading-24 tw-text-base tw-text-black tw-uppercase tw-truncate',
        titleClass,
        {
          'tw-mx-auto tw-text-center tw-font-normal tw-absolute tw-left-1/2': (centerTitle && isMobileView) || alwaysCenter
        },
        {
          'tw-flex tw-items-center': !logo
        },
      ]"
    >
      <img v-if="logo" :src="appLogoUrl" alt="eMentored" class="tw-h-14 tw-block">

      <template v-else>
        <slot name="title-left" />
        {{ transformedTitle }}
        <slot name="title-right" />
      </template>
    </p>

    <div
      ref="topnav-right-action"
      class="tw-ml-auto tw-flex tw-items-center"
    >
      <slot name="right-actions" />
      <NotificationIcon
        v-show="showNotificationIcon"
        :class="[
          'tw-h-6 tw-w-6',
          { 'tw-mr-6': showUserPhoto },
          { 'mr-body': !showUserPhoto },
        ]"
      />
      <router-link
        v-show="showUserPhoto"
        :to="{ name: 'profile' }"
        tag="button"
        class="mr-body tw-cursor-pointer tw-rounded-full tw-flex tw-flex-grow-0"
      >
        <UserPhoto photo-size="tw-h-6 tw-w-6" self />
      </router-link>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import BackButton from '@/components/BackButton.vue';
import NotificationIcon from '@/components/NotificationIcon.vue';

export default {
  name: 'TopNav',
  components: {
    BackButton,
    NotificationIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    bgTransparent: {
      type: Boolean,
      default: false,
    },
    alwaysCenter: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: [String, Object, Array],
      default: '',
    },
    shrinkTitle: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'menu',
    },
    showNotificationIcon: {
      type: Boolean,
      default: true,
    },
    showUserPhoto: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    prevRoute: {
      type: [String, Object],
      default: undefined,
    },
  },
  watch: {
    title(val, oldVal) {
      this.titleSize = val.length;
      this.calculateTitleSize();
      if (!oldVal.length) {
        this.addOnResizeFn(this.calculateTitleSize);
      }
    },
  },
  data() {
    return {
      titleSize: this.title.length,
    };
  },
  computed: {
    isBack() {
      return this.type === 'back' && window.history.length > 1;
    },
    transformedTitle() {
      if (this.isMobileView && this.shrinkTitle) {
        return `${this.title.substring(0, ((this.titleSize > 4) ? this.titleSize : 4))}${(this.titleSize > 0 && this.title.length > this.titleSize) ? '...' : ''}`;
      }
      return this.title;
    },
  },
  methods: {
    clickLeftAction() {
      this.$emit('input', 'left-action');
    },
    /**
     * Truncates the title text by the space available for the title
     */
    // eslint-disable-next-line func-names
    calculateTitleSize: _debounce(async function () {
      const minTitleWidth = 40; // assumes profile photo width 24px + a margin of 16px

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();

      if (!this.$refs['topnav-title']) return;

      const $parentElWidth = this.$refs['topnav-title'].parentElement.clientWidth;
      const $titleElWidth = this.$refs['topnav-title'].clientWidth;
      const $siblingsWidth = (this.$refs['topnav-left-action'].clientWidth || 0) + (this.$refs['topnav-right-action'].clientWidth || 0) + 4; // small padding
      const spaceLeft = $parentElWidth - ($siblingsWidth + $titleElWidth); // 24 as the margin between siblings
      const letterWidth = Math.ceil(($titleElWidth - minTitleWidth) / this.transformedTitle.length);
      // console.log($parentElWidth, $titleElWidth, this.$refs['topnav-left-action'].clientWidth, this.$refs['topnav-right-action'].clientWidth);

      // resize the title length
      if (spaceLeft <= 0) {
        // reduce the title length
        const removedLettersCount = Math.abs(spaceLeft) / letterWidth;
        // console.log(Math.ceil(removedLettersCount), spaceLeft, letterWidth, this.titleSize, this.titleSize - Math.ceil(removedLettersCount));

        this.titleSize -= Math.ceil(removedLettersCount);
      } else if (spaceLeft > 10) {
        // grow the title length to the maximum size possible
        const addLettersCount = Math.ceil(Math.abs(spaceLeft) / letterWidth);
        this.titleSize = addLettersCount > this.title.length ? this.title.length : addLettersCount;
      }
    }, 1000),
  },
  // eslint-disable-next-line func-names
  mounted: _debounce(async function () {
    this.titleSize = this.title.length || 0;

    await this.$nextTick();
    await this.$nextTick();


    if (this.title.length > 0) {
      // console.log('resizer handler...');
      this.calculateTitleSize();
      this.addOnResizeFn(this.calculateTitleSize);
    }
  }, 500),
};
</script>

<style>
</style>
