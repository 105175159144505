<template>
  <div class="tw-max-w-md tw-mx-auto px-body tw-flex tw-flex-col tw-w-full tw-h-full">
    <h1 class="font-title tw-font-extrabold tw-text-4xl tw-mb-8 tw-text-app-orange tw-relative">
      <span class="tw-z-10 tw-relative">
        Welcome to eMentored
      </span>
      <img src="/img/asset-10.png" width="48px" class="tw-absolute tw-z-0 tw-bottom-0 tw--ml-6 opacity-87">
    </h1>
    <h4 class="tw-text-base opacity-78 leading-24 tw-font-thin" v-if="isMentor">
      We are so excited to have you as a Mentor on our eMentored Platform. Thank You for your commitment of time, expertise and energy to journey with the assigned Mentee(s) under the platform. We cannot wait for your success story!
    </h4>
    <h4 class="tw-text-base opacity-78 leading-24 tw-font-thin" v-else-if="isMentee">
      Thank you for joining the eMentored Platform as a mentee. As you get ready for the mentorship journey, get ready to unlearn, learn and have fun!
      <br>
      <br>
      We cannot wait for your success story!
    </h4>
  </div>
</template>

<script>
export default {
  name: 'WelcomeMessage',
};
</script>

<style>

</style>
