<template>
  <div class="mx-body tw-mt-8 faq">
    <ol>
      <li><strong>What is KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <p>The KenGen Pass the Baton Mentoring Platform is an in-house mentoring space that connects mentors and mentees across KenGen to share knowledge, skills, motivation, and guidance for personal and career growth. The platform is hosted by eMentoring Africa (under the name <strong>eMentored</strong>) during the pilot period and is funded by USAID.</p>
      <ol start="2">
      <li><strong>Who can use t</strong><strong>he KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <p>KenGen Pass the Baton platform is accessible to all KenGen staff i.e. interns, new employees and other employees.</p>
      <ol start="3">
      <li><strong>How does the platform work?</strong></li>
      </ol>
      <p>The KenGen Pass the Baton Mentoring Platform is accessible online via the URL - <a href="https://kengen.ementored.com/">https://kengen.ementored.com/</a>. Mentors and mentees on the platform are able to chat, share resources and track progress via the platform&rsquo;s features.</p>
      <p><strong>Mentee</strong></p>
      <p>The KenGen Pass The Baton Mentoring Platform is accessible online via the URL - <a href="https://kengen.ementored.com/">https://kengen.ementored.com/</a>. As a mentee, you will be mentored based on the goals you selected on <strong>Signup</strong> and the <strong>Expertise</strong> tab when you log in. You are required to request to be mentored by a mentor of your choice by clicking the <strong>Find A Mentor</strong> button. After the Mentor accepts your request, you will communicate with each other (Break the ice) via a chat and the Mentor will create <strong>Tasks</strong> for you to complete and share <strong>Resources</strong> to guide you in completing the tasks. When you complete all the tasks in the goal, the Mentor will end the match and you are required to complete a survey to provide feedback on the match.</p>
      <ol start="4">
      <li><strong>How do I sign up for the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <ul>
      <li>To sign up as a <strong>MENTEE</strong> on the platform, <a href="https://kengen.ementored.com/mentee-signup"><strong>Click Here</strong></a>. Follow the on-screen instructions by filling in the form with your details. Remember to get the <strong>Secret Key</strong> from your Admin assistant.</li>
      <li>Once signed up, ensure to <strong>VERIFY</strong> your account via email or SMS sent to you by <strong>eMentored</strong>. Follow the link in the verification email or SMS and <strong>LOG IN</strong> with your <strong>username</strong> and <strong>password</strong> created during the signup.</li>
      </ul>
      <h1><strong>Getting Started</strong></h1>
      <ol>
      <li><strong>How do I create a profile on the platform?</strong></li>
      </ol>
      <p>After logging in, you will be required to create your profile. You can do this by following the Step-by-Step process as outlined below:</p>
      <ul>
      <li>Write your <strong>BIO </strong>and upload a good <strong>Profile Photo</strong> of yourself.</li>
      <li>Choose the <strong>PILLARS</strong> &amp; <strong>GOALS</strong> you would like to be mentored on.</li>
      </ul>
      <p><strong><em>Note</em></strong><em> &ndash; Do not forget to read and sign the <strong>Code of Conduct</strong> before starting the mentorship.</em></p>
      <ol start="2">
      <li><strong>How do I find a mentor on the platform?</strong></li>
      </ol>
      <p>To find a mentor, click on <strong>FIND A MENTOR</strong> in the left menu bar where a list of mentors in the pillars and goals you selected will be displayed. <strong>SEND REQUEST</strong> to specific mentor(s) to request a match. You will receive an email notification once the selected mentor has Accepted the match request.</p>
      <p><strong><em>Note</em></strong><em> &ndash; mentor and mentee matches are only formed between mentors and mentees within the same pillars and goals. </em></p>
      <ol start="3">
      <li><strong>How do I communicate with my mentor?</strong></li>
      </ol>
      <p>To communicate with your mentor, go to <strong>My Mentors</strong> tab and click on one of your mentors. A conversation log will appear and you can chat easily.</p>
      <ol start="4">
      <li><strong>Group Chat</strong></li>
      </ol>
      <p>All Mentees can chat among themselves via the <strong>GROUP CHAT</strong> tab on the left menu bar.<br /> </p>
      <ol start="5">
      <li><strong>Where can I access my Resources?</strong></li>
      </ol>
      <p>Under the <strong>Resource</strong> tab, you can access resources shared by your mentor or admin to use as a guide in your mentorship journey. Resources are goal-specific and provide detailed information about the goal.</p>
      <ol start="6">
      <li><strong>How do I upload resources for my mentor to review?</strong></li>
      </ol>
      <p>To upload resources, go to the <strong>Resources</strong> tab and click <strong>Upload</strong>. You can upload documents in PDF or MS Office Word format and links.</p>
      <p>&nbsp;</p>
      <ol start="7">
      <li><strong>How do I access My Tasks?</strong></li>
      </ol>
      <ul>
      <li>Go to <strong>MY TASKS</strong> on the left menu tab to view the current and upcoming tasks created for you by mentors in specific pillars/goals.</li>
      <li>Your Mentor(s) will create Tasks for you to complete under a specific goal, which will include the Task Description and a Timeline.</li>
      <li>To <strong>Submit a Task Report</strong>, click on the specific task to view it in full. You can either <strong>WRITE</strong> in your task report or <strong>Upload</strong> a <strong>resource</strong> and click <strong>SUBMIT</strong>. Once completed, click on <strong>MARK COMPLETED</strong> and the status of the task will be displayed. Your Mentor will proceed to review the task. You will receive a Notification once your Mentor sends feedback. Your task can either be <strong>Accepted</strong> or <strong>Reverted</strong> by the mentor. When Reverted, ensure to revise the task and consult your mentor for guidance on the same.</li>
      </ul>
      <h1><strong>Account and Profile Management</strong></h1>
      <ol>
      <li><strong>How do I manage my account information on the platform?</strong></li>
      </ol>
      <p>You can review your personal details and edit them when necessary.</p>
      <ol start="2">
      <li><strong>How do I change my password on the platform?</strong></li>
      </ol>
      <p>To change your password, click on <strong>Forgot Password </strong>on the Log In page. A link will be sent to your email for your to reset your password and log back in.</p>
      <p><strong><em>Note</em></strong><em> &ndash; Do not share your password with anyone to avoid compromising your account. </em></p>
      <h1><strong>Technical Issues</strong></h1>
      <ol>
      <li><strong>What do I do if I can't log in to the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <p>If you have trouble logging into the platform, try resetting your password via the <strong>Forgot Password</strong> button on the login page.</p>
      <p>If the issue persists, contact <strong>Support</strong> via <strong>Jivo Chat</strong> on the screen. An admin will assist in less than 24hrs.</p>
      <ol start="2">
      <li><strong>What do I do if I'm having trouble accessing the platform?</strong></li>
      </ol>
      <p>Contact <strong>Support</strong> via <strong>Jivo Chat</strong> on the screen to report the issue and you will receive assistance.</p>
      <ol start="3">
      <li><strong>What do I do if I can't upload files on the platform?</strong></li>
      </ol>
      <ul>
      <li>First, ensure you are uploading the correct <strong>formats</strong> recommended i.e., PDF, Word doc, or Link.</li>
      <li>If the issue has nothing to do with the format of the files, try refreshing your page and attempt to upload files again.</li>
      <li>Still nothing? Contact support via <strong>ELLY</strong> or E<strong>BBY </strong></li>
      </ul>
      <h1><strong>Code of Conduct</strong></h1>
      <ol>
      <li><strong>What is the Code of Conduct for the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <p>The Code of Conduct outlines the rules and regulations that a mentor should follow during the mentorship.</p>
      <ol start="2">
      <li><strong>What behaviour is expected of mentors and mentees on the platform?</strong></li>
      </ol>
      <ul>
      <li>Mentees are required to be professional in their communication with mentors and to undertake all mentorship sessions in a respectful and sober manner that is free of all forms of discrimination and bias.</li>
      <li>Mentees are also advised to create a conducive and friendly environment and be open to instruction and guidance by their mentor(s) to achieve the goals of the mentorship efficiently.</li>
      <li>Ensure to carefully read the <strong>Code of Conduct</strong> form in the platform and sign it before proceeding with the mentorship.</li>
      </ul>
      <ol start="3">
      <li><strong>How do I report issues or concerns on the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <ul>
      <li>To report issues and concerns regarding the mentor, contact <strong>Support</strong> under <strong>EBBY</strong> or <strong>ELLY</strong> or approach the KenGen mentorship administrator for help.</li>
      </ul>
      <ol start="4">
      <li><strong>How do I give feedback on the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
      </ol>
      <p>To give any feedback, contact <strong>Support</strong>.</p>
      <ol start="5">
      <li><strong>How do I end a mentoring relationship on the platform?</strong></li>
      </ol>
      <p>If you would like to <strong>End a Match</strong>, contact <strong>Support</strong> with your request and justification.</p>
      <p>&nbsp;</p>
      <p>Thank you for using the KenGen Pass The Baton Mentoring Platform.</p>
      <p>If you have any additional questions or concerns, please reach out to our support team at <a href="mailto:mentor@ementoringafrica.or.ke"><strong>mentor@ementoringafrica.or.ke</strong></a><strong>.</strong></p>
  </div>
</template>

<script>
export default {
  name: 'FAQMenteePartial',
};
</script>

<style lang="scss" scoped>
.faq {

  * {
    font-size: 20px;
  }

  h2, h1 {
    font-size: 50px !important;
  }

  p {
    margin-bottom: 20px;
  }

  ol {
    line-height: 1.5;
    list-style-type: decimal;
    color: var(--primary-orange);
  }

  ul {
    list-style-type: disc;
    padding-left: 40px;
  }

  a {
    color: var(--primary-blue);
  }
}
</style>
