import { toJsDate } from '../modules/dataHelper';

export default {
  name: 'licenseMixin',
  computed: {
    menteePackageIsExpired() {
      if (!this.isMentee) {
        return undefined;
      }

      if (!this.licenseExpiry) {
        return true;
      }

      try {
        const licenseExpiry = this.$moment(toJsDate(this.licenseExpiry)).add(1, 'd');
        const dateNow = this.$moment();

        return dateNow.isAfter(licenseExpiry);
      } catch (error) {
        return true;
      }
    },
    menteePackageIsValid() {
      return this.isMentee ? !!(this.$store.state.User.mentee.license.package || {}).id : undefined;
    },
    licenseStartDate() {
      return this.isMentee ? this.$store.state.User.mentee.license.licenseStartDate : undefined;
    },
    licenseExpiry() {
      return this.isMentee ? this.$store.state.User.mentee.license.licenseExpiry : undefined;
    },
  },
};
