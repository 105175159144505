import { apiStore } from '../modules/apiHelper';

export default {
  state: {
    userViewsStates: {},
    userViewsData: {},
    authError: undefined,
    xhrError: undefined,
    viewWidth: window.innerWidth,
    isFlatSideNavView: false,
    isFullAppView: false,
    showMenuNav: true,
    rightSideNavTitle: '',
    rightSideNavLoading: true,
    openRightSideNav: false,
    openPopOver: [false, 0, 0],
    openModal: false,
    selfCloseModal: true,
    modalType: 'modal',
    showNotifications: false,
    showTaskDetails: false,
    showGoalBadgeDetails: false,
    currentTaskDetails: {},
    currentGoalBadgeDetails: {},
    refreshMyPhoto: 0,
  },
  getters: {
    findViewState: state => stateId => state.userViewsStates[stateId],
    findViewData: state => dataId => state.userViewsData[dataId],
    isMobileView(state) {
      return state.viewWidth <= 1024;
    },
    isDesktopView(state) {
      return state.viewWidth > 1024;
    },
    isDesktopXxlView(state) {
      return state.viewWidth >= 1920;
    },
    isFlatSideNavView(state) {
      return state.isFlatSideNavView && state.viewWidth > 1024;
    },
    viewWidthIsLargerThan: state => width => state.viewWidth > width,
    popOverIsOpen(state) {
      return !!state.openPopOver[0];
    },
  },
  mutations: {
    updateUserViewStates(state, data) {
      state.userViewsStates = Object.assign({}, state.userViewsStates, data);
    },
    updateUserViewData(state, data) {
      state.userViewsData = Object.assign({}, state.userViewsData, data);
    },
    deleteViewData(state, refId) {
      const data = state.userViewsData[refId];

      if (data) {
        const viewsData = Object.assign({}, state.userViewsData);
        delete viewsData[refId];

        state.userViewsData = Object.assign({}, viewsData);
      }
    },
    screenSizeChange(state, newSize) {
      state.viewWidth = newSize;
    },
    isFlatView(state, isFlat) {
      state.isFlatSideNavView = isFlat;
    },
    openPopOver(state, [open = true, mouseX = 0, mouseY = 0]) {
      state.openPopOver = [open, mouseX, mouseY];
    },
    // side-nav states
    openRightSideNav(state, sideNavTitle = '') {
      state.rightSideNavTitle = sideNavTitle;
      state.openRightSideNav = true;
    },
    closeRightSideNav(state) {
      state.openRightSideNav = false;
      state.showTaskDetails = false;
      state.showGoalBadgeDetails = false;
      state.showNotifications = false;
    },
    showNotifications(state, show = true) {
      state.showNotifications = show;
    },
    showMenuNav(state, show = true) {
      state.showMenuNav = show;
    },
    isFullAppView(state, isFullView = false) {
      state.isFullAppView = isFullView;
    },
    openModal(state, [open = true, type = 'modal', selfClose = true]) {
      state.modalType = type;
      state.openModal = open;
      state.selfCloseModal = selfClose;
    },
    showTaskDetails(state, task) {
      if (typeof task === 'object') {
        state.showTaskDetails = true;
        state.currentTaskDetails = task;
        return;
      }
      state.showTaskDetails = false;
    },
    showGoalBadgeDetails(state, goalBadge) {
      if (typeof goalBadge === 'object') {
        state.showGoalBadgeDetails = true;
        state.currentGoalBadgeDetails = goalBadge;
        return;
      }
      state.showGoalBadgeDetails = false;
    },
    refreshMyPhoto(state) {
      state.refreshMyPhoto += 1;
    },
    xhrError(state, message = undefined) {
      state.xhrError = message;
    },
  },
  actions: {
    async saveTaskRef(context, [refId, task]) {
      const taskData = {
        [`refTask/${refId}`]: task,
      };
      context.commit('updateUserViewData', taskData);
      await apiStore.setItem(`refTask/${refId}`, task);
      return true;
    },
    async saveResourceRef(context, [refId, resource]) {
      const resourceData = {
        [`refResource/${refId}`]: resource,
      };
      context.commit('updateUserViewData', resourceData);
      await apiStore.setItem(`refResource/${refId}`, resource);
      return true;
    },
    /**
     *
     * @param {String} refId 'refTask/:id' or 'refResource/:id'
     */
    async getChatReferencedItem(context, refId) {
      if (!refId) return false;
      const data = context.getters.findViewData(refId) || false;

      if (data) return data;

      const dbData = await apiStore.getItem(refId) || false;
      return dbData;
    },
    /**
     *
     * @param {String} refId 'refTask/:id' or 'refResource/:id' or {any}/:id
     */
    async clearReferencedItem(context, refId) {
      if (!refId) return false;
      context.commit('deleteViewData', refId);
      await apiStore.removeItem(refId);

      return true;
    },
  },
};
