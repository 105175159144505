import { mapGetters, mapState } from 'vuex';
import _differenceBy from 'lodash/differenceBy';
import _debounce from 'lodash/debounce';
import { asyncForEach } from '../modules/misc';
import { notificationActionText, cleanUrl } from '../modules/dataHelper';

/**
 * Notifies the user in any view
 *  - mediums -
 *  1. Toast
 *  2. menu nav
 */
export default {
  name: 'notificationMixin',
  // watch: {
  //   // eslint-disable-next-line func-names
  //   matchNotifications: _debounce(function (newVal = [], oldVal = []) {
  //     if (newVal.length > oldVal.length) {
  //       const newNotifications = _differenceBy(newVal, oldVal, 'id');
  //       this.notifyUser(newNotifications);

  //       // get mentee requests again
  //       this.$store.dispatch('getMenteesRequests');
  //     }
  //   }, 300, { leading: true }),
  //   // eslint-disable-next-line func-names
  //   taskNotifications: _debounce(function (newVal = [], oldVal = []) {
  //     if (newVal.length > oldVal.length) {
  //       const newNotifications = _differenceBy(newVal, oldVal, 'id');
  //       this.notifyUser(newNotifications);
  //     }
  //   }, 300, { leading: true }),
  //   // eslint-disable-next-line prefer-arrow-callback, func-names
  //   totalNewMessages: _debounce(function (val) {
  //     if (val !== 0) {
  //       this.notifyNewMessage(val);
  //     }
  //   }, 300, { leading: true }),
  // },
  // computed: {
  //   ...mapGetters([
  //     'matchNotifications',
  //     'taskNotifications',
  //   ]),
  //   ...mapState({
  //     totalNewMessages: state => state.chat.totalNewMessages,
  //   }),
  // },
  // methods: {
  //   async notifyUser(notifications = []) {
  //     let delayTime = 2000;

  //     if (notifications.length > 2) {
  //       this.$toasted.info(`You have ${notifications.length} new important notifications`, {
  //         duration: 5000,
  //         position: 'bottom-center',
  //         action: {
  //           text: 'View Notifications',
  //           onClick: (e, toastObj) => {
  //             this.showUserNotifications();
  //             toastObj.goAway(300);
  //           },
  //         },
  //       });

  //       return true;
  //     }

  //     await asyncForEach(notifications, async (notification) => {
  //       delayTime += 500;
  //       await setTimeout(async () => {
  //         const action = this.notificationActions(notification);

  //         this.$toasted.info(notification.text_message, {
  //           position: 'bottom-center',
  //           duration: 5000,
  //           action,
  //           className: 'tw-shadow-app-lg tw-px-6 tw-py-8 tw-mb-4 tw-bg-app-deep-blue transition mx-body',
  //         });

  //         // console.log(notification.id);
  //       }, delayTime);
  //     });

  //     return true;
  //   },
  //   notificationActions({
  //     action1 = '', action2 = '', action3 = '', type,
  //   }) {
  //     const actions = [];

  //     if (!type) return actions;

  //     ['', action1, action2, action3].forEach((action, index) => {
  //       if (action.length > 1) {
  //         actions.push({
  //           text: notificationActionText(type, index),
  //           href: cleanUrl(action),
  //           class: ((index === 3) ? '' : 'hover:tw-bg-app-deep-blue hover:tw-shadow'),
  //         });
  //       }
  //     });


  //     return actions;
  //   },
  //   notifyNewMessage(total = 0) {
  //     if (total === 0) return false;
  //     // don't toast if in chat page,
  //     // [TODO] wait till navigated away
  //     const currRoute = this.$route.name;
  //     if (
  //       currRoute === 'chatView'
  //       || currRoute === 'mentorChat'
  //       || currRoute === 'mentorMenteeChat'
  //       || currRoute === 'menteeMentorsView'
  //       || currRoute === 'mentorMenteesView'
  //     ) return false;


  //     const url = this.isMentor ? '/app/my-mentees' : '/app/my-mentors';

  //     this.$toasted.info(`You have ${total} new message${total !== 1 ? 's' : ''}`, {
  //       duration: 6000,
  //       position: 'bottom-center',
  //       action: {
  //         text: 'view',
  //         href: cleanUrl(url),
  //       },
  //     });

  //     return true;
  //   },
  // },
};
