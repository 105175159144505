<template>
  <svg
    class="tw-absolute tw-top-0 tw-left-0 tw-w-2 -tw-ml-2 transform-scale-0"
    style="height: 10px;enable-background:new 0 0 8 10.2;"
    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 8 10.2" xml:space="preserve">
    <path fill="var(--primary-blue)" d="M8,10.2c-1.1-1-6.8-6.1-7.4-6.9C-0.1,2.6-0.6,0,1.6,0c1,0,3.7,0,6.4,0L8,10.2z"/>
  </svg>
</template>

<script>
export default {
  name: 'LeftTip',
};
</script>

<style>

</style>
