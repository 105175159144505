<template>
  <AppView>
    <TopNav
      title="Submit Task Report"
      title-class="font-title lg-app:ml-body"
      center-title
      type="back"
      slot="top-app-view"
    />
    <div class="mx-body tw-mb-8 tw-h-full">
      <div v-if="error">
        <p class="tw-text-center tw-text-base opacity-78 tw-my-20" v-html="error"></p>
      </div>
      <div v-else-if="loading">
        <p class="tw-text-center tw-text-xs opacity-54 tw-my-20">Loading...</p>
      </div>
      <form
        method="POST"
        enctype="multipart/form-data"
        ref="create-task-form"
        class="tw-py-16 tw-max-w-md tw-mx-auto tw-h-full"
        v-else
      >
        <p class="tw-text-xs leading-17 opacity-54 tw-mb-2">Task</p>
        <h4 class=" tw-text-xl leading-24 tw-mb-8">{{ task.task_name }}</h4>
        <div v-if="taskIsOnReview(task.status)" class="tw-text-center">
          <p class="tw-py-10 tw-text-green-500 tw-text-base tw-text-center">Task is being reviewed!</p>
          <BaseButton
            class="tw-text-center"
            text="My Group Tasks"
            :to="{ name: 'mentorshipGroupTasks', params: { id: task.groupid } }"
          />
          <!-- GOODIES -->
        </div>
        <div v-else-if="taskIsCompleted(task.status)" class="tw-text-center">
          <p class="tw-py-10 tw-text-green-500 tw-text-base">You completed the task!</p>
          <BaseButton
            class="tw-text-center"
            text="My Group Tasks"
            :to="{ name: 'mentorshipGroupTasks', params: { id: task.groupid } }"
          />
          <!-- GOODIES -->
        </div>
        <div v-else>
          <p class="tw-text-app-primary-orange tw-text-sm tw-mb-8">
            To submit a task report, you can either write your report below, or upload a resource, or both
          </p>

          <!-- report text -->
          <p class="tw-text-xs leading-17 opacity-54">Write your Task Report</p>
          <QuillTextareaGroup
            label=""
            type="text"
            placeholder=""
            input-classes="focus:tw-bg-gray-100 tw-w-full tw-mb-4"
            v-model="reportText"
            :rows="4"
          />

          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <p class="tw-text-xs leading-17 opacity-54">Submit Resources</p>
            <BaseButton
              text="Clear Resources"
              v-if="hasAttachedResources"
              @click="taskResources = []"
            />
          </div>

          <div v-if="hasAttachedResources">
            <div class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4">
              <div
                class="info-card tw-shadow-app tw-relative tw-cursor-pointer"
                v-for="resource in taskResources"
                :key="resource.id"
              >
                <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.data }}</h4>
                <div class="tw-flex tw-items-end">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    {{ resourceType((resource.file_type || resource.type || resource.name), (!resource.file_type && resource.type.length === 0) ) }}
                  </p>
                </div>
              </div>
            </div>
            <BaseInput
              type="file"
              name="file1"
              multiple
              accepts="*"
              class="tw-py-3 tw-w-full tw-my-6"
              v-model="taskResources"
            />
          </div>

          <div v-else class="tw-py-10 tw-text-center tw-bg-gray-200">
            <p class="tw-text-center tw-text-base opacity-78 tw-pb-4">Upload some resources to submit for review</p>

            <BaseInput
              type="file"
              name="file1"
              :multiple="true"
              accepts="*"
              class="tw-py-3 tw-w-full tw-my-6"
              v-model="taskResources"
            />
          </div>

          <BaseButton
            :text="saveButtonText"
            :disabled="!canSave || saveDisabled"
            style="margin-top: 80px;"
            class="tw-shadow-app tw-block tw-w-full tw-bg-black tw-border-transparent tw-text-white tw-py-3"
            @click="onSubmit"
          />
        </div>
      </form>
    </div>
  </AppView>
</template>

<script>
import { totalSumOfArray } from '@/modules/dataHelper';
import BaseInput from '@/components/BaseInput.vue';
import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';

export default {
  name: 'GroupTaskReport',
  props: ['id'],
  components: {
    BaseInput,
    QuillTextareaGroup,
  },
  data() {
    return {
      loading: true,
      error: undefined,
      task: {},
      reportText: '',
      taskResources: [],
      mentee: {},
      saveButtonText: 'Submit',
      saveDisabled: false,
    };
  },
  computed: {
    hasAttachedResources() {
      return this.taskResources.length > 0;
    },
    canSave() {
      return this.taskResources.length > 0 || this.reportText.length > 0;
    },
  },
  methods: {
    async attachResources() {
      // await this.$store.dispatch('saveSubmitTaskDraft', [this.id, [this.taskName, this.taskDescription, this.taskGoalId]]);
      await this.$nextTick();

      this.$router.push({
        name: 'addResourcesToTaskSubmit',
        params: {
          id: this.id,
        },
      });
    },
    async removeResource(resourceId) {
      const index = this.taskResources.findIndex(r => r.id === resourceId);
      if (index >= 0) {
        this.taskResources.splice(index, 1);
      }
    },
    // [TODO] Reset the form
    // Using a fallback of reloading the page
    async resetForm() {
      this.taskResources = [];
    },
    async onSubmit() {
      if (!this.canSave) {
        this.$toasted.info('Error. Can\'t submit task',
          {
            duration: 4000,
            position: 'bottom-right',
          });
        return false;
      }

      this.saveButtonText = 'working... (0%)';
      this.saveDisabled = true;

      const response = await this.$store.dispatch('markGroupTaskComplete', [this.id, this.task.groupid, this.reportText, this.taskResources.length > 0]);
      await this.$nextTick();

      if (response && this.taskResources.length > 0) {
        const [result, progress] = await this.$store.dispatch('uploadGroupTaskReportResource', [this.id, this.task.groupid, this.taskResources]);
        const $this = this;
        await this.$nextTick();

        // eslint-disable-next-line prefer-arrow-callback
        setTimeout(function again() {
          const totalPercentage = Math.round((totalSumOfArray(progress) / progress.length) || 0);
          $this.saveButtonText = `working... (${totalPercentage}%)`;
          if (totalPercentage < 100) {
            setTimeout(again, 100);
          }
        }, 500);

        await result;
      }

      await this.$nextTick();
      this.saveButtonText = '100% please wait...';
      await this.$nextTick();

      const task = await this.$store.dispatch('getTask', [this.id, true]);
      this.task = Object.assign({}, task);

      this.saveButtonText = 'Submit';
      this.saveDisabled = false;

      await this.$nextTick();

      // get prompt for user
      this.$store.dispatch('getCurrentUserJourneyAction');

      return response;
    },
  },
  async created() {
    this.$store.commit('isFlatView', true);
    this.$store.commit('closeRightSideNav');
    this.$store.commit('showNotifications', false);
    this.$store.commit('showTaskDetails', false);
    this.error = undefined;
    this.loading = true;

    const isPermitted = await this.assertTask(this.id);
    if (!isPermitted || !this.isMentee) return true;

    return await this.$store.dispatch('getTask', [this.id, true])
      .catch(() => {
        this.error = 'Error in getting task';
        this.$toasted.global.appError({ errorMessage: this.error });
        return false;
      })
      .then((task) => {
        if (!task) {
          this.error = 'Could not get task';
          return false;
        }

        this.task = Object.assign({}, task);

        this.error = undefined;
        this.loading = false;

        return task;
      });
  },
};
</script>

<style type="css" lang="postcss">
</style>
