<template>
  <div>
    <div class="camera-button">
      <button type="button" @click="toggleCamera" class="tw-inline-flex tw-items-center tw-bg-gray-300 tw-text-sm tw-border tw-px-2 tw-py-1 tw-rounded-10 tw-border-gray-500">
        <svg version="1.1" class="tw-h-6 tw-w-6 tw-mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M36,59.4c-10.6,0-19.1-8.6-19.1-19.1S25.4,21.1,36,21.1s19.1,8.6,19.1,19.1S46.6,59.4,36,59.4z M36,25.8
            c-8,0-14.4,6.5-14.4,14.4S28,54.6,36,54.6s14.4-6.5,14.4-14.4S44,25.8,36,25.8z M60.2,69.7H11.8C5.3,69.7,0,64.4,0,57.9V22.5
            C0,16,5.3,10.7,11.8,10.7h9.9L22,9.1c0.6-3.9,4-6.8,7.9-6.8h12.2c4,0,7.3,2.9,7.9,6.8l0.3,1.6h9.9C66.7,10.7,72,16,72,22.5v35.4
            C72,64.4,66.7,69.7,60.2,69.7L60.2,69.7z M11.8,15.4c-3.9,0-7.1,3.2-7.1,7.1v35.4c0,3.9,3.2,7.1,7.1,7.1h48.4c3.9,0,7.1-3.2,7.1-7.1
            V22.5c0-3.9-3.2-7.1-7.1-7.1H48.3c-1.2,0-2.2-0.8-2.3-2l-0.6-3.6C45.1,8.2,43.8,7,42.1,7H29.9c-1.6,0-3,1.2-3.3,2.8l-0.6,3.6
            c-0.2,1.2-1.2,2-2.3,2H11.8z M58,20.6c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2C61.1,22,59.7,20.6,58,20.6z"/>
        </svg>
        <span v-if="!isCameraOpen">or take a photo (Open Camera)</span>
        <span v-else>Close Camera</span>
      </button>
    </div>
    
    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  
    <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
      
      <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
        
      <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
      
      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
    </div>
    
    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
      <button type="button" @click="takePhoto" class="tw-inline-flex tw-items-center tw-bg-gray-300 tw-text-sm tw-border tw-px-2 tw-py-1 tw-rounded-10 tw-border-gray-500 tw-mt-3 tw-mr-3">
        <svg version="1.1" class="tw-h-6 tw-w-6 tw-mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M36,59.4c-10.6,0-19.1-8.6-19.1-19.1S25.4,21.1,36,21.1s19.1,8.6,19.1,19.1S46.6,59.4,36,59.4z M36,25.8
            c-8,0-14.4,6.5-14.4,14.4S28,54.6,36,54.6s14.4-6.5,14.4-14.4S44,25.8,36,25.8z M60.2,69.7H11.8C5.3,69.7,0,64.4,0,57.9V22.5
            C0,16,5.3,10.7,11.8,10.7h9.9L22,9.1c0.6-3.9,4-6.8,7.9-6.8h12.2c4,0,7.3,2.9,7.9,6.8l0.3,1.6h9.9C66.7,10.7,72,16,72,22.5v35.4
            C72,64.4,66.7,69.7,60.2,69.7L60.2,69.7z M11.8,15.4c-3.9,0-7.1,3.2-7.1,7.1v35.4c0,3.9,3.2,7.1,7.1,7.1h48.4c3.9,0,7.1-3.2,7.1-7.1
            V22.5c0-3.9-3.2-7.1-7.1-7.1H48.3c-1.2,0-2.2-0.8-2.3-2l-0.6-3.6C45.1,8.2,43.8,7,42.1,7H29.9c-1.6,0-3,1.2-3.3,2.8l-0.6,3.6
            c-0.2,1.2-1.2,2-2.3,2H11.8z M58,20.6c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2C61.1,22,59.7,20.6,58,20.6z"/>
        </svg>
        Capture Photo
      </button>

      <button v-if="isCameraOpen" type="button" @click="toggleCamera" class="tw-inline-flex tw-items-center tw-bg-gray-300 tw-text-sm tw-border tw-px-2 tw-py-1 tw-rounded-10 tw-border-gray-500">
        <svg version="1.1" class="tw-h-6 tw-w-6 tw-mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M36,59.4c-10.6,0-19.1-8.6-19.1-19.1S25.4,21.1,36,21.1s19.1,8.6,19.1,19.1S46.6,59.4,36,59.4z M36,25.8
            c-8,0-14.4,6.5-14.4,14.4S28,54.6,36,54.6s14.4-6.5,14.4-14.4S44,25.8,36,25.8z M60.2,69.7H11.8C5.3,69.7,0,64.4,0,57.9V22.5
            C0,16,5.3,10.7,11.8,10.7h9.9L22,9.1c0.6-3.9,4-6.8,7.9-6.8h12.2c4,0,7.3,2.9,7.9,6.8l0.3,1.6h9.9C66.7,10.7,72,16,72,22.5v35.4
            C72,64.4,66.7,69.7,60.2,69.7L60.2,69.7z M11.8,15.4c-3.9,0-7.1,3.2-7.1,7.1v35.4c0,3.9,3.2,7.1,7.1,7.1h48.4c3.9,0,7.1-3.2,7.1-7.1
            V22.5c0-3.9-3.2-7.1-7.1-7.1H48.3c-1.2,0-2.2-0.8-2.3-2l-0.6-3.6C45.1,8.2,43.8,7,42.1,7H29.9c-1.6,0-3,1.2-3.3,2.8l-0.6,3.6
            c-0.2,1.2-1.2,2-2.3,2H11.8z M58,20.6c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2C61.1,22,59.7,20.6,58,20.6z"/>
        </svg>
        <span>Close Camera</span>
      </button>
    </div>
    
    <!-- <div v-if="isPhotoTaken && isCameraOpen" class="camera-download tw-mt-5">
      <a id="downloadPhoto" download="my-photo.jpg" class="tw-mt-3 tw-bg-black tw-text-white tw-py-2 tw-px-3" role="button" @click="downloadImage">
        Download
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'CameraPhoto',
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#'
    }
  },
  
  methods: {
    toggleCamera() {
      if(this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    
    createCameraElement() {
      this.isLoading = true;
      
      const constraints = (window.constraints = {
				audio: false,
				video: true
			});


			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch(error => {
          this.isLoading = false;
					alert("May the browser didn't support or there is some errors.");
				});
    },
    
    stopCameraStream() {
      if (this.$refs.camera) {
        let tracks = this.$refs.camera.srcObject.getTracks();
  
        tracks.forEach(track => {
          track.stop();
        });
      }
    },
    
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      
      this.isPhotoTaken = !this.isPhotoTaken;
      
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);

      const canvas = document.getElementById("photoTaken").toDataURL("image/png")

      this.$emit('input', canvas)

      this.stopCameraStream()
    },
    
    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      //
      download.setAttribute("href", canvas);
    }
  }
}
</script>

<style>

</style>