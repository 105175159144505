import { mapGetters } from 'vuex';
import { toBoolean } from '../modules/dataHelper';

export default {
  name: 'MatchedListMixin',
  computed: {
    ...mapGetters([
      'userLastMessage',
    ]),
  },
  methods: {
    hasUserLastMessage(userId) {
      if (this.userLastMessage(userId)) {
        // eslint-disable-next-line camelcase
        const { chat_message, resourceid } = this.userLastMessage(userId);
        return chat_message.length > 0 || (resourceid.length > 0 && resourceid !== '0');
      }
      return false;
    },
    userLastMessageText(userId) {
      if (this.userLastMessage(userId)) {
        if (this.userLastMessageIsResource(userId)) {
          return '[resource]';
        }
        return this.userLastMessage(userId).chat_message;
      }
      return '';
    },
    userLastMessageIsResource(userId) {
      if (this.userLastMessage(userId)) {
        return (this.userLastMessage(userId).resourceid.length > 0
          && this.userLastMessage(userId).resourceid !== '0');
      }
      return false;
    },
    userLastMessageIsSelf(userId) {
      if (this.userLastMessage(userId)) {
        return (this.userLastMessage(userId).senderid === this.userId
          || Number(this.userLastMessage(userId).senderid) === this.userId);
      }
      return false;
    },
    userLastMessageIsRead(userId) {
      if (this.userLastMessage(userId)) {
        return toBoolean(this.userLastMessage(userId).status);
      }
      return false;
    },
  },
};
