<template>
  <AppView
    :key="id"
    padd-bottom
  >
    <TopNav
      title="Review task report"
      title-class="font-title lg-app:ml-body"
      center-title
      type="back"
      slot="top-app-view"
    />
    <div class="tw-mb-8 tw-mt-1">
      <transition name="fade" mode="out-in" appear>
        <div v-if="loading">
          <p class="mx-body tw-text-center tw-text-base opacity-78 tw-my-20">Loading...</p>
        </div>
        <div v-else-if="taskIsOnReview(task.status)" class="lg-grid lg:tw-flex lg:tw-flex-row">
          <!-- TASK REPORT -->
          <div class="lg-grid__col-2/3 tw-mb-14 tw-overflow-visible">
            <div class="bg-black-11 tw-overflow-visible tw-rounded-r-55 mr-body lg:tw-mr-0 tw-py-6 lg:tw-py-8 transition-fast">
              <div class="tw-flex tw-flex-col pl-body">
                <!-- <p class="tw-text-green-500 tw-text-sm leading-17">Submitted {{ task.dateOfPost | moment('from', 'now') }}</p> -->
                <p class="tw-text-black opacity-54 tw-text-sm leading-17 tw-mt-1">{{ mentee.name }} has finished a task you gave {{ mentee.gender.id | genderize(2) }} </p>
              </div>
              <!-- Task Report Text -->
              <transition name="fade" mode="out-in">
                <div v-if="taskReportTexts.length" class="pl-body tw-mt-10 tw-pr-4">
                  <h6 class="tw-text-sm opacity-54 tw-font-bold leading-17">Task Report</h6>

                  <p
                    v-for="reportText in taskReportTexts"
                    :key="reportText.id"
                    class="tw-text-base tw-mt-4 tw-border-b tw-border-gray-300"
                  >
                    <span v-html="reportText.review"></span>
                    <!-- <br /> -->
                    <span class="tw-text-xs tw-pb-2 opacity-31">
                      {{ reportText.dateOfPost | toJsDate(true) | moment('ddd Do hh:mm A') }}
                    </span>
                  </p>
                </div>
              </transition>
              <!-- Task Report Resources -->
              <transition name="fade" mode="out-in" appear>
                <div class="tw-mt-8 tw-pt-10 tw-overflow-visible" v-if="taskResources.length > 0">
                  <div class="tw-flex tw-justify-between tw-items-center pl-body">
                    <h6 class="tw-text-sm opacity-54 tw-font-bold leading-17">Resources submitted</h6>
                    <div class="tw-flex tw-justify-end tw-items-center">
                      <h6 class="tw-text-sm opacity-87 tw-font-bold leading-17 tw-mr-6">{{ taskResources.length || 0 }}</h6>
                      <!-- <button
                        class="leading-14 opacity-78 mr-body lg-app:tw-mr-10 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                        @click="downloadAllResources(taskResources)"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                            M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                            c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                            C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                        </svg>
                      </button> -->
                    </div>
                  </div>
                  <ViewMore
                    v-if="isDesktopView"
                    class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-3 tw-mb-8 pl-body mr-body lg-app:tw-mr-8"
                    :limit="8"
                    :list="taskResources"
                    v-slot="{ item: resource }"
                  >
                    <div class="info-card tw-shadow-app tw-relative">
                      <h4 class="leading-19 tw-text-base tw-p-2 tw-mb-2 tw-break-words">{{ resource.name || resource.data }}</h4>
                      <div class="tw-flex tw-items-end">
                        <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">{{ resourceType(resource.file_type) }}</p>

                        <a
                          v-if="resource.title && !resource.file_type"
                          title="view/read"
                          class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                          :href="resource.resource"
                          target="_blank"
                        >
                          <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                            <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                              c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                              c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                              c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                              S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                              C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                          </svg>
                        </a>

                        <div class="tw-ml-auto tw-inline-flex tw-items-center" v-else>
                          <router-link
                            tag="a"
                            :to="{
                              name: 'ResourceView',
                              query: {
                                doc: `${apiUrl}/${resource.data}`,
                                title: resource.name,
                              },
                            }"
                            title="view/read"
                            class="tw-mr-2 tw-mb-2"
                          >
                            <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                              <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                                c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                                c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                                c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                                S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                                C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                            </svg>
                          </router-link>
                          <button
                            class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                            @click.stop="downloadResource(resource.data, resource.name)"
                          >
                            <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                              <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                                M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                                c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                                C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ViewMore>
                  <swiper
                    v-else
                    ref="task-report-resources"
                    :options="swiperOptions"
                    class="tw-overflow-visible"
                    style="overflow: visible;"
                  >
                    <swiper-slide
                      v-for="(resource, index) in taskResources"
                      :key="`${resource.id}-${index}`"
                      style="max-width: calc(75% - var(--body));"
                      :class="[
                        'tw-w-auto tw-mb-8 tw-mt-3',
                        {
                          'ml-body': index === 0,
                        }
                      ]"
                    >
                      <div class="info-card tw-shadow-app tw-relative tw-inline-block">
                      <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words tw-mb-2">{{ resource.name || resource.data }}</h4>
                      <div class="tw-flex tw-items-end">
                        <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-mr-2.5 tw-uppercase info-card__action-info info-card__action-info--left">{{ resourceType(resource.file_type) }}</p>

                        <a
                          v-if="resource.title && !resource.file_type"
                          title="view/read"
                          class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                          :href="resource.resource"
                          target="_blank"
                        >
                          <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                            <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                              c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                              c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                              c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                              S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                              C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                          </svg>
                        </a>

                        <div class="tw-ml-auto tw-inline-flex tw-items-center" v-else>
                          <router-link
                            tag="a"
                            :to="{
                              name: 'ResourceView',
                              query: {
                                doc: `${apiUrl}/${resource.data}`,
                                title: resource.name,
                              },
                            }"
                            title="view/read"
                            class="tw-mr-2 tw-mb-2"
                          >
                            <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                              <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                                c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                                c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                                c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                                S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                                C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                            </svg>
                          </router-link>
                          <button
                            class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                            @click.stop="downloadResource(resource.data, resource.name)"
                          >
                            <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                              <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                                M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                                c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                                C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    </swiper-slide>
                  </swiper>
                </div>
                <div v-else class="tw-h-14"></div>
              </transition>
            </div>
          </div>
          <!-- TASK CARD AND ACTIONS -->
          <div class="lg-grid__col-1/3 px-body lg:tw-px-0 lg:mr-body tw-pt-4">
            <p class="tw-text-sm leading-17 opacity-54 tw-uppercase tw-mb-2">The Task</p>
            <TaskCard
              :task="task"
              :task-user-id="mentee.id"
              full-width-on-large
              :show-due="false"
              :show-task-details-on-click="false"
            />
            <div class="tw-mt-16 tw-hidden lg-app:tw-flex">
              <BaseButton
                :text="acceptButtonText"
                class="tw-flex-1 tw-py-3 tw-bg-app-deep-blue tw-border-0 tw-text-white tw-block tw-mr-6 lg-app:tw-ml-4 transition-fast hover:tw-shadow-app"
                :disabled="actionButtonsDisabled"
                @click="approveReport()"
              />
              <!-- <BaseButton
                :text="declineButtonText"
                class="tw-flex-1 tw-py-3 tw-block lg-app:tw-w-1/2"
                :disabled="actionButtonsDisabled"
                @click="rejectReport()"
              /> -->
              <BaseButton
                :text="declineButtonText"
                class="tw-flex-1 tw-py-3 tw-block lg-app:tw-w-1/2"
                :to="{name: 'RevertTask', params: { id }}"
              />
            </div>
          </div>
        </div>
        <!-- IF TASK IS ALREADY REVIEWED -->
        <div v-else-if="taskIsCompleted(task.status)" class="lg-grid mx-body">
          <transition name="new" mode="out-in" appear>
            <div class="lg-grid__col-2/3">
              <div>
                <div class="tw-max-w-md tw-mx-auto tw-text-center">
                  <h1 class="tw-text-xl leading-28 tw-pt-10 font-title tw-text-app-deep-blue">Task approved!</h1>
                  <h4 class="tw-text-xs leading-19 tw-py-4 font-title opacity-87">You marked the task report as complete</h4>
                  <!-- GOODIES -->
                  <div class="tw-text-center">
                    <BaseButton
                      text="Create another task"
                      class="tw-mt-4 tw-mx-4"
                      :to="{ name: 'mentorMenteeCreateTask', params: { id: mentee.id } }"
                    />
                    <BaseButton
                      text="Mentee's Tasks"
                      class="tw-mt-4 tw-mx-4"
                      :to="{ name: 'mentorMenteeTasks', params: { id: mentee.id } }"
                    />
                  </div>
                </div>

                <!-- OTHER TASKS TO APPROVE -->
                <transition name="new" mode="out-in" appear>
                  <div class="tw-mt-16" v-if="!loadingMoreTasksToReview && tasksToReview.length > 0">
                    <p class="opacity-54 tw-text-xs leading-17 tw-mb-4">There are pending tasks for you to review</p>
                    <TasksOnReview :tasks="tasksToReview" />
                  </div>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <div
      slot="bottom-app-view"
      class="mx-body tw-py-4 lg-app:tw-mx-0 lg-app:tw-hidden tw-flex"
      v-if="!taskIsApproved"
    >
      <BaseButton
        :text="acceptButtonText"
        class="tw-flex-grow tw-py-3 tw-bg-app-deep-blue tw-border-0 tw-text-white tw-block tw-mr-6 lg-app:tw-ml-4 transition-fast hover:tw-shadow-app"
        :disabled="actionButtonsDisabled"
        @click="approveReport()"
      />
      <BaseButton
        :text="declineButtonText"
        class="tw-shrink-0 tw-py-3 tw-block lg-app:tw-w-1/2"
        :to="{name: 'RevertTask', params: { id }}"
      />
    </div>
  </AppView>
</template>

<script>
import _sortBy from 'lodash/sortBy';
import TaskCard from '@/components/TaskCard.vue';
import TasksOnReview from '@/views/_partials/TasksOnReview.vue';
import { apiUrl } from '@/modules/apiHelper';

export default {
  name: 'ReviewTask',
  components: {
    TaskCard,
    TasksOnReview,
  },
  props: ['id'],
  data() {
    return {
      error: undefined,
      loading: true,
      loadingMoreTasksToReview: true,
      tasksToReview: [],
      task: {},
      taskResources: [],
      taskReportTexts: [],
      mentee: {},
      acceptButtonText: 'Approve',
      declineButtonText: 'Revert',
      actionButtonsDisabled: false,
      taskIsApproved: false,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: false,
        grabCursor: true,
      },
      apiUrl,
    };
  },
  watch: {
    task(val = {}) {
      if (this.taskIsCompleted(val.status) && !this.taskIsOnReview(val.status)) {
        this.loadMoreTasksToReview();
        this.taskIsApproved = true;
      } else {
        this.loadingMoreTasksToReview = true;
      }
    },
    id() {
      this.init();
    },
  },
  methods: {
    async approveReport() {
      this.acceptButtonText = 'working...';
      this.actionButtonsDisabled = true;
      const response = await this.$store.dispatch('approveTaskReport', this.id);
      await this.$nextTick();

      if (!response) {
        this.$toasted.global.appError({ errorMessage: 'Error in approving the task report. Please try again later' });
      } else {
        this.$toasted.success('Task approved', {
          duration: 4000,
          position: 'top-right',
        });

        const newTask = await this.$store.dispatch('getTask', [this.id, true]);
        this.task = Object.assign({}, newTask);

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      }
      this.acceptButtonText = 'Accept';
      this.actionButtonsDisabled = false;
    },
    // async rejectReport() {
    //   this.declineButtonText = 'working...';
    //   this.actionButtonsDisabled = true;
    //   const response = await this.$store.dispatch('rejectTaskReport', this.id);
    //   await this.$nextTick();

    //   if (!response) {
    //     this.$toasted.global.appError({ errorMessage: 'Error in rejecting the task report. Please try again later' });
    //     this.actionButtonsDisabled = false;
    //   } else {
    //     this.$toasted.success('Task rejected', {
    //       duration: 3000,
    //       position: 'top-right',
    //     });
    //     this.$toasted.info(`Please chat with ${this.mentee.name} to explain your reasons.`, {
    //       duration: 10000,
    //       position: 'top-right',
    //     });
    //     this.loading = true;
    //     const newTask = await this.$store.dispatch('getTask', [this.id, true]);
    //     this.task = Object.assign({}, newTask);

    //     setTimeout(() => {
    //       this.refTaskInChat(this.mentee.id, this.task);
    //       this.actionButtonsDisabled = false;
    //     }, 3000);
    //   }
    //   this.declineButtonText = 'Revert';
    // },
    async loadMoreTasksToReview() {
      const tasks = await this.$store.dispatch('tasksList', [this.$store.state.User.id, this.mentee.id]);
      this.tasksToReview = _sortBy(
        tasks.filter(task => (Number(task.status) === 2)),
        'dateOfPost',
      );

      this.loadingMoreTasksToReview = false;
    },
    async init() {
      this.$store.commit('isFlatView', false);
      this.$store.commit('closeRightSideNav');
      this.$store.commit('showNotifications', false);
      this.$store.commit('showTaskDetails', false);
      this.error = undefined;
      this.loading = true;
      this.loadingMoreTasksToReview = true;
      this.actionButtonsDisabled = true;

      const isPermitted = await this.assertTask(this.id);
      if (!isPermitted || !this.isMentor) return true;

      const response = await this.$store.dispatch('getTask', [this.id, true])
        .catch(() => {
          this.error = 'Could not get the task';
          this.$toasted.global.appError({ errorMessage: this.error });
          return false;
        })
        .then(async (task) => {
          if (!task.id) {
            this.error = 'Could not get the task';
            return false;
          }

          this.error = undefined;
          this.task = Object.assign({}, task);
          return true;
        });

      if (!response) return false;


      // return to mentee's page if task has not been submitted for review
      if (!this.taskIsOnReview(this.task.status)) {
        await this.$nextTick();
        this.$toasted.info('Task has not been submitted for review yet', {
          duration: 5000,
          position: 'top-right',
        });
        await this.$nextTick();
        this.$router.push({ name: 'app' });
      }

      const user = await this.$store.dispatch('getUserData', this.task.userid);
      this.mentee = Object.assign({}, user);

      if (this.taskIsCompleted(this.task.status) && !this.taskIsOnReview(this.task.status)) {
        this.loadMoreTasksToReview();
        this.taskIsApproved = true;
      } else {
        // get task report resources
        this.taskIsApproved = false;
        this.actionButtonsDisabled = false;

        this.$store.dispatch('taskReportResources', this.id)
          .catch(() => {
            this.$toasted.global.appError({ errorMessage: 'Error in getting task report resources' });
          })
          .then((resources) => {
            this.taskResources = resources || [];
          });
        // 

        this.$store.dispatch('taskReportTexts', this.id)
          .catch(() => {
            this.$toasted.global.appError({ errorMessage: 'Error in getting task report' });
          })
          .then((texts) => {
            this.taskReportTexts = texts;
          })
      }

      await this.$nextTick();
      this.loading = false;
      return this.task;
    },
  },
  async created() {
    await this.init();
  },
};
</script>

<style>

</style>
