<template>
  
</template>

<script>
const scriptId = "jivo-script";

export default {
  name: 'JivoChat',
  mounted() {
    if (!document.getElementById(scriptId)) {
      const jivoScript = document.createElement('script')
      jivoScript.id = scriptId;
      jivoScript.async = true;
      jivoScript.setAttribute('src', '//code.jivosite.com/widget/HotgWRPZSM')
      document.head.appendChild(jivoScript)
    }
  },
  beforeDestroy() {
    if (typeof window.jivo_destroy === 'function') {
      console.log('destroyed');
      window.jivo_destroy()
    }

    const jivoScript = document.getElementById(scriptId);

    if (jivoScript) {
      jivoScript.remove();

      const jivoCss = document.head.getElementsByClassName('jv-css')[0];
      
      if(jivoCss) {
        jivoCss.remove();
      }
    }
  }
}
</script>

<style>

</style>