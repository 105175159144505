<template>
  <div>
    <template v-for="meeting in meetings">
      <VideoMeetingCard
        :meeting="meeting"
        :participants="meeting.participants"
        :key="meeting.id"
        class="tw-mb-4"
        @click="onClick"
      />
    </template>
  </div>
</template>

<script>
import VideoMeetingCard from './VideoMeetingCard.vue';
export default {
	components: { VideoMeetingCard },
  name: 'VideoMeetingsList',
  props: {
    meetings: {
      type: Array,
      default() {
        return [];
      }
    },
    onCardClick: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  methods: {
    onClick() {
      // ? navigate to meeting?

      this.onCardClick(meeting);
    }
  }
};
</script>

<style>

</style>
