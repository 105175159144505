<template>
  <div v-if="error" class="tw-h-screen tw-w-screen px-body tw-flex tw-flex-col tw-justify-center">
    <h3 class="tw-text-xl opacity-87 tw-text-red-500">
      Error
    </h3>
    <h3 class="tw-text-xl opacity-87">
      {{ error }}
    </h3>
    <div>
      <router-link to="/login" class="tw-py-3 tw-px-5 tw-bg-gray-300 tw-inline-block tw-mt-4">Back to Login</router-link>
    </div>
  </div>
  <div v-else-if="email" class="bg-pattern tw-bg-green-100 tw-h-screen tw-w-screen px-body tw-flex tw-flex-col tw-justify-center">
    <h2 class="tw-font-bold font-title tw-text-4xl">
      Verify account
    </h2>
    <h3 class="tw-text-xl">
      {{ message }}
    </h3>

  </div>
  <div v-else-if="token" class="tw-h-screen tw-w-screen px-body tw-flex tw-flex-col tw-justify-center">
    <h3 class="tw-text-xl opacity-87">
      verifying account...
    </h3>
  </div>
  <div v-else class="tw-h-screen tw-w-screen px-body tw-flex tw-flex-col tw-justify-center">
    <template v-if="statusVerify">
      <h2 class="tw-font-bold font-title tw-text-4xl" v-if="statusVerify">
        Verify account
      </h2>
      <h3 class="tw-text-xl">
        Check your email to verify your account
      </h3>
    </template>
    <template v-else>
      <h2 class="tw-font-bold font-title tw-text-4xl">
        Have you signed up?
      </h2>
      <h3 class="tw-text-xl">
        If so, check your email to verify your account
      </h3>
      <br>
      <router-link class="tw-text-blue-500 tw-mt-2 tw-uppercase tw-inline-block tw-flex-grow-0 tw-flex-shrink tw-cursor-pointer" tag="div" to="/mentor-signup">Sign up as a mentor</router-link>
      <router-link class="tw-text-blue-500 tw-mt-2 tw-uppercase tw-inline-block tw-flex-grow-0 tw-flex-shrink tw-cursor-pointer" tag="div" to="/mentee-signup">Sign up as a mentee</router-link>
    </template>
  </div>
</template>

<script>
import { apiGet } from '@/modules/apiHelper';
import authMixin from '@/mixins/auth';

export default {
  mixins: [authMixin],
  data() {
    return {
      token: this.$route.query.t || undefined,
      email: undefined,
      name: undefined,
      message: undefined || 'Registration success! \nWe\'ve sent you an email to verify your account',
      error: undefined,
    };
  },
  computed: {
    statusVerify() {
      return this.$route.query.s === 'ev';
    },
  },
  async mounted() {
    if (this.$store.state.auth) {
      this.email = this.$store.state.auth.email;
      this.name = this.$store.state.auth.name;
      this.message = this.$store.state.auth.status;
    } else {
      this.registerAuthStoreModule();
      await this.$store.dispatch('updateAuthData');
    }

    if (!this.token) {
      return false;
    }

    return apiGet('verify', 3, {
      t: this.token,
    }, true, false)
      .catch(() => {
        this.$toasted.global.appError({ duration: 10000 });
        this.error = 'Sorry, We encountered some error...';
        // console.error(err);
      })
      .then(async (res) => {
        if (!res) {
          this.$toasted.global.appError({ duration: 10000 });
          this.error = 'Sorry, We encountered some error...';
          return false;
        }
        // console.log(res.data);
        if (res.data.Status !== 'Success') {
          this.error = res.data.message;
          return false;
        }
        this.error = undefined;

        this.$toasted.show(res.data.message, {
          type: 'success',
          duration: 1200,
        });

        await this.$store.dispatch('clearUserSession');
        this.$store.commit('clearAuthData');

        return setTimeout(() => {
          this.$router.push({
            name: 'app',
          });
        }, 1100);
      });
  },
};
</script>

<style>

</style>
