<template>
  <AppView
    padd-bottom
    :body-class="{ 'tw-mx-auto': !canNavigate }"
  >
    <TopNav
      slot="top-app-view"
      title="eMentored"
      logo
      :title-class="['lg-app:tw-hidden', {'ml-body sm:tw-ml-0': !canNavigate}]"
      :type="canNavigate ? 'menu': 'undefined'"
      @input="openSideNav()"
      :show-notification-icon="canNavigate"
      :show-user-photo="canNavigate"
    />
    <div class="mx-body tw-mb-10 tw-text-center">
      <img
        :src="appLogoUrl"
        :data-src="appLogoUrl"
        class="tw-inline-block tw-py-8 tw-w-3/5 sm:tw-w-1/6 tw-object-contain tw-text-center"
        height="150px"
        style="max-height: 150px"
      >
      <h3 class="tw-text-center opacity-54 tw-mt-2 tw-text-base leading-19 tw-font-bold">
        Hello {{ userName || '' }}
      </h3>
      <p
        class="tw-text-center opacity-54 tw-mt-2 tw-text-base leading-19"
        v-if="menteePackageIsExpired"
      >
        We believe your license has expired. If this is a mistake, kindly
        <a href="https://wa.me/254708271537?text=Hi,%20there%20might%20be%20an%20error%20on%20my%20license." target="_blank" class="tw-underline tw-text-blue-600">contact us</a>.
      </p>
    </div>
    <div class="mx-body">
      <LicenseCard
        v-if="license"
        :license="license"
        :license-status="!menteePackageIsExpired"
        :show-amount="false"
        :class="[
          'tw-mb-8 --tw-inline-block tw-rounded-t-32 tw-rounded-b-32 tw-mx-auto tw-text-center',
          {'tw-bg-white': !menteePackageIsExpired},
          {'tw-bg-red-100': menteePackageIsExpired},
        ]"
      >
        <template slot="details">
          <p
            v-if="licenseStartDate && licenseExpiry"
            :class="['tw-text-sm', {'tw-text-red-600': menteePackageIsExpired}]"
          >
            <span>Started on {{licenseStartDate | toJsDate | moment('Do MMMM YYYY')}}<br></span>
            <span>|<br></span>
            <span>Ends on {{licenseExpiry | toJsDate | moment('Do MMMM YYYY')}}<br></span>
          </p>
        </template>
      </LicenseCard>

      <template v-if="menteePackageIsExpired">
        <h3 class="opacity-54 tw-mt-2 tw-mb-4 tw-text-base leading-19">
          Buy a package
        </h3>
        <LicensePackagesPartial
          @input-success="hasPaid()"
        />
      </template>
    </div>
  </AppView>
</template>

<script>
import { mapState } from 'vuex';
import LicensePackagesPartial from '@/views/_partials/LicensePackages.vue';
import LicenseCard from '@/components/LicenseCard.vue';

export default {
  name: 'LicensePackages',
  components: {
    LicensePackagesPartial,
    LicenseCard,
  },
  watch: {
    canNavigate(val) {
      if (val) {
        this.$store.commit('showMenuNav', true);
        this.$store.commit('isFullAppView', false);
      }
    },
  },
  computed: {
    ...mapState({
      userName: state => state.User.name || '',
    }),
    license() {
      return this.$store.state.User.mentee.license.package;
    },
    canNavigate() {
      return this.menteePackageIsValid && !this.menteePackageIsExpired;
    },
  },
  methods: {
    hasPaid() {
      // navigate to home
      // this.$router.push({
      //   name: 'home',
      // });
    },
  },
  created() {
    if (!this.canNavigate) {
      this.$store.commit('showMenuNav', false);
      this.$store.commit('isFullAppView', true);
    }
  },
  beforeDestroy() {
    this.$store.commit('showMenuNav', true);
    this.$store.commit('isFullAppView', false);
  },
};
</script>

<style>

</style>
