<template>
  <div
    v-if="totalTasksOnReview > 0"
    class="tw-overflow-hidden tw-border tw-border-app-deep-blue tw-rounded-lg tw-pt-6 tw-mb-8"
  >
    <h6 class="opacity-87 tw-capitalize tw-text-app-deep-blue px-body lg:tw-text-center tw-text-sm tw-font-bold">{{ totalTasksOnReview }} Tasks submitted for review</h6>
    <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
        <!-- date-class="tw-text-white opacity-78" -->
      <!-- LIST GRID -->
      <swiper
        ref="tasks-to-review-slider"
        :options="swiperOptions"
        :key="totalTasksOnReview"
      >
        <swiper-slide
          v-for="(task, index) in tasksOnReview"
          :key="`${task.id}/${totalTasksOnReview}`"
          :class="[
            'swiper-slider--timeline tw-w-auto swiper-slider--selected tw-mb-6',
          ]"
        >
          <div :class="['tw-inline-block', { 'ml-body': index === 0}]">
            <div :data-swiper-parallax="-20" class="tw-mb-3 tw-ml-5">
              <p class="tw-mb-2 tw-text-sm leading-15 tw-font-bold opacity-31">
                {{ task.dateOfPost | moment('from', 'now') }}
              </p>
              <div class="tw-flex tw-items-center">
                <div class="tw-rounded-full tw-bg-black tw-w-3 tw-h-3 tw-shadow-app tw-mr-6 tw-flex-shrink-0"></div>
                <div
                  class="tw-flex-shrink-0 opacity-11 tw-border tw-border-black transition-fast slide-swiper__date-timeline-divider"
                ></div>
              </div>
            </div>
            <BaseCard
              class="task-card task-card--swiper transition-fast tw-flex tw-flex-col tw-justify-between tw-bg-white"
            >
              <div>
                <h4 class="task-card__name transition-fast">
                  {{ task.task_name }}
                </h4>
                <!-- RECENT TASK ACTIVITY! -->
              </div>

              <div class="tw-mb-1">
                <h5 class="tw-text-xs leading-15 tw-mx-2 tw-mt-2 task-card__goal">
                  <span class="tw-font-bold">Goal</span><br>
                  {{ task?.goal?.goal || '' }}
                  <!-- {{ taskGoal(task.subid) }} -->
                </h5>
                <transition name="new" mode="in-out" appear>
                  <div class="tw-mx-2 tw-mt-4 ">
                    <BaseButton
                      text="Review"
                      class="tw-text-white tw-w-full tw-bg-app-deep-blue tw-block tw-py-2 tw-border-none box-border tw-shadow-app focus:tw-shadow-app-lg focus:tw-bg-gray-900"
                      :to="{ name: 'ReviewTask', params: { id: task.id } }"
                    />
                  </div>
                </transition>
              </div>

            </BaseCard>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import TasksListMixin from '@/mixins/taskslist';

export default {
  name: 'TasksOnReview',
  mixins: [TasksListMixin],
  props: {
    tasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        parallax: true,
        freeMode: false,
        grabCursor: true,
        // on: {
        //   beforeDestroy() {
        //     console.log(this);
        //   },
        // },
      },
    };
  },
  watch: {
    totalTasksOnReview() {
      console.log('change');
      if (this.$refs['tasks-to-review-slider']) {
        console.log('rendering again');
        this.$refs['tasks-to-review-slider'].swiper.destroy(false, false);
        this.$refs['tasks-to-review-slider'].swiper.init();
      }
    },
  },
  // This should override the mixin computed prop TASKS
  computed: {
    TASKS() {
      return this.tasks;
    },
  },
  async mounted() {
    if (this.totalTasksOnReview > 0) {
      await this.$nextTick();
      this.$refs['tasks-to-review-slider'].swiper.destroy(false, false);
      this.$refs['tasks-to-review-slider'].swiper.init();
    }
  },
};
</script>

<style>

</style>
