var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'app-view',
    { 'app-view--full': _vm.isFullAppView }
  ]},[_c('div',{ref:"top-app-view",staticClass:"tw-fixed tw-top-0 tw-z-10 tw-w-full tw-bg-app-light-blue tw-mx-auto"},[_vm._t("top-app-view"),_c('portal-target',{attrs:{"name":"top-app-view"}})],2),_c('div',{class:['tw-z-0 tw-relative tw-min-h-screen', _vm.bodyClass],style:({
      paddingTop: `${_vm.topViewHeight}px`,
      paddingBottom: _vm.paddBottom ? `${_vm.bottomViewHeight}px` : 'auto',
    })},[_vm._t("default",null,{"recalculate":_vm.calculatePaddings})],2),_c('div',{ref:"bottom-app-view",staticClass:"tw-fixed tw-bottom-0 tw-z-10 tw-w-full bg-bottom-app transition-fast box-border",style:(`transform: translateY(${_vm.showBottomBar ? '0%' : '100%'}) ${_vm.isDesktopXxlView ? 'translateX(-50%)' : ''}`)},[_vm._t("bottom-app-view",null,{"recalculate":_vm.calculatePaddings}),_c('div',{class:{ 'tw-absolute tw-bottom-0 tw-w-full': _vm.floatBottomAppView }},[_c('transition',{attrs:{"name":"new","mode":"out-in","appear":""}},[_c('portal-target',{attrs:{"name":"bottom-app-view"}})],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }