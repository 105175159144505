import defaultWelcomeMessage from './_helpers/defaultWelcomeMessage';
import defaultTheme from './_helpers/themes';
import resolve from '../modules/api/resolve';
import updateProp from './_helpers/updateProp';


import { apiStore, apiPost, generateFormData } from '../modules/apiHelper';
import { isStaleData, now, objectIsValid } from '../modules/dataHelper';

const defaultAppLogo = '/img/app-logo-kengen.png';

export default {
  state: {
    welcomeMessage: defaultWelcomeMessage,
    logo: defaultAppLogo,
    theme: defaultTheme.default,
    landingpage: {
      text: '',
      bgImage: undefined,
    },
  },
  mutations: {
    updateProp,
  },
  actions: {
    /**
     * TODO: split method to smaller chunks
     * async getLandingpageSettings() {},
     * async getLogoSettings() {},
     * async getUserWelcomeMessage() {},
     * async getAppTheme() {},
     */
    async getUiSettings(context, refresh = false) {
      await context.dispatch('setScope');
      // console.log(company);

      const { scopeId } = context.rootState.scope;
      const company = await context.dispatch('getCompany', [scopeId, refresh]);

      if (!company) return false;

      context.commit('updateProp', ['logo', (company.logo || defaultAppLogo)]);

      const settings = await apiStore.getItem(`companysettings/${company.id}`) || [0, {}];

      if (refresh || isStaleData(settings[0]) || !objectIsValid(settings[1])) {
        const result = await resolve(apiPost('UI_lists', generateFormData({ companyid: company.id }), 12))
          .then((res) => {
            if (!res) return false;
            const uisettings = res.UI_lists || [];

            if (uisettings.length) {
              // save to store the latest ui settings
              apiStore.setItem(`companysettings/${company.id}`, [now(), uisettings[uisettings.length - 1]]);

              // eslint-disable-next-line no-underscore-dangle
              const __settings = JSON.parse((uisettings[uisettings.length - 1]).settings);

              return {
                ...__settings,
                ...{
                  landingpageBg: uisettings[uisettings.length - 1].background_url || '',
                },
              };
            }

            return false;
          });
        //

        if (result) {
          context.commit('updateProp', ['landingpage', result.landingpageText, 'text']);
          context.commit('updateProp', ['landingpage', result.landingpageBg, 'bgImage']);
          // context.commit('updateProp', ['uiSettings', result]);
          // context.commit('updateProp', ['uid', result.id]); // TODO: update uid
        }
      }

      if (!context.getters.isLoggedIn) return false;

      const userId = context.rootState.User.id;
      const userType = context.rootState.User.type;

      // ! get message and theme
      const messages = await apiStore.getItem(`welcomemessage/${userId}/${company.id}`) || [0, ''];

      if (!refresh && !isStaleData(messages[0]) && messages[1].length) return messages[1];

      const result2 = resolve(apiPost('active_messages', generateFormData({ companyid: company.id }), 7))
        .then((res) => {
          if (!res) return;
          const mssgs = res.company_messages || [];
          // console.log(mssgs);
          const message = mssgs.find(m => (Number(m.level) === userType || Number(m.level) === 0));

          if (message) {
            context.commit('updateProp', ['welcomeMessage', (message.message || defaultWelcomeMessage)]);
            // store to store
            apiStore.setItem(`welcomemessage/${userId}/${company.id}`, [now(), (message.message || defaultWelcomeMessage)]);
          }
        });
      //

      return result2;
    },

    getMentorshipSettings() {},
  },
};
