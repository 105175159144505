import vSelect from 'vue-select';

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', {
      domProps: {
        innerHTML: `
          <svg version="1.1"  width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
              L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
              s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
          </svg>
        `,
      },
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('span', {
      domProps: {
        innerHTML: `
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_21" data-name="Rectangle 21" width="24" height="24" fill="none"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_23" data-name="Mask Group 23" transform="translate(24) rotate(90)" clip-path="url(#clip-path)">
              <path id="noun_right_1701594" d="M0,16.95a1,1,0,0,1,.3-.725l7.25-7.25L.3,1.725A1,1,0,0,1,.3.3,1,1,0,0,1,1.725.3L9.7,8.275A1,1,0,0,1,9.7,9.7l-7.975,8A1,1,0,0,1,.3,17.7,1.059,1.059,0,0,1,0,16.95Z" transform="translate(7 3)"/>
            </g>
          </svg>
        `,
      },
    }),
  },
});

export default vSelect;
