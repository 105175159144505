import _sortBy from 'lodash/sortBy';
import _debounce from 'lodash/debounce';
import { apiGet, apiPost, generateFormData } from '../../modules/apiHelper';
import { toBoolean } from '../../modules/dataHelper';

const INTERVAL_SLOW = 20000;
const INTERVAL_NORMAL = 10000;
const INTERVAL_FAST = 3000; // ? make this slower for none-chromium browsers
const INTERVAL_DEFAULT = INTERVAL_NORMAL;

export default {
  state: {
    mentorshipGroupChatMessages: {},
    fetchingMentorshipGroupMessages: {},
    fetchingMentorshipGroupChatInterval: {
      default: INTERVAL_NORMAL,
    }, // INTERVAL_FAST for fastFetching, // INTERVAL_FAST for fastFetching
    fetchingMentorshipGroupChatError: false,
    totalNewMentorshipGroupChatMessages: 0,
  },
  getters: {
    mentorshipGroupChatMessages: state => id => _sortBy(state.mentorshipGroupChatMessages[`group${id}`], 'tym_unix'),
    mentorshipGroupChatMessage: state => (id, messageId) => state.mentorshipGroupChatMessages[`group${id}`].find((message) => message.id == messageId),
    // todo: I need a way to mark as read group messages
    // totalNewUserMessages: (state, getters, rootState) => (id) => {
    //   const messages = state.message || [];
    //   return messages.filter(t => !toBoolean(t.status) && Number(t.senderid) !== Number(rootState.User.id)).length;
    // },
    // mentorshipGroupLastMessage: state => (id) => {
    //   const messages = _sortBy(state.mentorshipGroupChatMessages, 'tym_unix') || [];
    //   return messages[messages.length - 1];
    // },
    isMentorshipGroupChatFetching: state => id => !!state.fetchingMentorshipGroupMessages[id] || false,
    fetchingMentorshipGroupChatError: state => !!state.fetchingMentorshipGroupChatError,
  },
  mutations: {
    addMentorshipGroupChatMessages(state, [id, messages]) {
      // console.log('MESSAGES...');
      state.mentorshipGroupChatMessages = { ...state.mentorshipGroupChatMessages, [`group${id}`]: [...messages]};
      // console.log(state.messages);
    },
    addMentorshipGroupChatMessage(state, [id, message]) {
      if (!state.mentorshipGroupChatMessages[`group${id}`]) {
        // console.log('setting');
        state.mentorshipGroupChatMessages[`group${id}`] = [...message]; // ! why am I destructuring? [todo]
      } else {
        // console.log('pushing.....');
        state.mentorshipGroupChatMessages[`group${id}`].push(message);
      }
    },
    updateFetchingMentorshipGroupChats(state, [groupId, isFetching]) {
      state.fetchingMentorshipGroupMessages = { ...state.fetchingMentorshipGroupMessages, [groupId]: isFetching };;
      state.fetchingMentorshipGroupChatInterval = { ...state.fetchingMentorshipGroupChatInterval, [groupId]: isFetching ? INTERVAL_NORMAL : INTERVAL_SLOW };
    },
    isOnMentorshipGroupChat(state, groupId) {
      state.fetchingMentorshipGroupChatInterval = { ...state.fetchingMentorshipGroupChatInterval, [groupId]: INTERVAL_FAST };
    
      // slow the fetch speed for other groups
      Object.entries(state.fetchingMentorshipGroupChatInterval).forEach(([id]) => {
        if (id !== 'default' && id !== groupId) {
          state.fetchingMentorshipGroupChatInterval = {
            ...state.fetchingMentorshipGroupChatInterval,
            [id]: INTERVAL_SLOW
          };
        }
      });
    },
    resetMentorshipGroupChatFetchSpeed(state) {
      Object.entries(state.fetchingMentorshipGroupChatInterval).forEach(([id]) => {
        if (id !== 'default') {
          state.fetchingMentorshipGroupChatInterval[id] = INTERVAL_NORMAL;
        }
      });
    },
    updateFetchingMentorshipGroupChatError(state, error) {
      state.fetchingMentorshipGroupChatError = error;
    },
    updateTotalNewMentorshipGroupChatMessages(state, total) {
      state.totalNewMentorshipGroupChatMessages = total;
    },
  },
  actions: {
    async getMentorshipGroupChatMessages(context, groupId) {
      // if (process.env.NODE_ENV === 'development') return false;
      await context.dispatch('fetchMentorshipGroupChatMessages', groupId);

      if (context.getters.isMentorshipGroupChatFetching(groupId)) return true;
      // This is set only once when this action is dispatched
      context.commit('updateFetchingMentorshipGroupChats', [groupId, true]);

      try {
        setTimeout(async function fetch() {
          // const n = now() + context.state.fetchingMentorshipGroupChatIntervals;
          if (context.getters.isMentorshipGroupChatFetching(groupId)) {
            await context.dispatch('fetchMentorshipGroupChatMessages', groupId)
              .catch((err) => {
                context.commit('updatefetchingMentorshipGroupChatError', true);
                throw err;
              });

            setTimeout(fetch, (context.state.fetchingMentorshipGroupChatInterval[groupId] || context.state.fetchingMentorshipGroupChatInterval.default));
          }
        }, (context.state.fetchingMentorshipGroupChatInterval[groupId] || context.state.fetchingMentorshipGroupChatInterval.default));
      } catch (err) {
        console.error('ERROR IN FETCHING MENTORSHIP GROUP MESSAGES! (mentorsship-groups/chats.js::fetchMentorshipGroupChatMessages)\n\n', err);
        context.commit('updatefetchingMentorshipGroupChatError', true);
      }
    },
    async fetchMentorshipGroupChatMessages(context, groupId) {
      const formData = new FormData();
      formData.set('groupid', groupId);

      await apiPost('chat_list', formData, 54)
        .then(async (res) => {
          if (!res) return false;
          const messages = context.state.mentorshipGroupChatMessages[`group${groupId}`] || [];
          // add new messages
          // await context.commit('addGroupMessages', [groupId, res.data.chat_lists]);
          // if (messages.length !== res.data.chat_lists.length) {
          if (messages.length > 0) {
            // eslint-disable-next-line no-async-promise-executor
            await new Promise(async (resolve) => {
              await res.data.chat_lists.forEach(async (message) => {
                if (!messages.find((m) => m.id === message.id)) {
                  await context.commit('addMentorshipGroupChatMessage', [groupId, message]);
                }
                // update the chat message status // * no need for this for admin
                // const storeMessage = context.state.messages[`group${groupId}`].find((m) => m.id === message.id);
                // if (storeMessage.status !== message.status) {
                //   context.commit('updateGroupMessage', [message.id, groupId, 'status', message.status]);
                // }
              });

              resolve();
            });
          } else {
            await context.commit('addMentorshipGroupChatMessages', [groupId, res.data.chat_lists]);
          }

          return true;
        });
    },
    // called when logging out
    stopFetchingMentorshipGroupChatMessages(context, groupId) {
      if (!groupId) { // stop all if groupId was not given
        // eslint-disable-next-line no-unused-vars
        Object.entries(context.state.fetchingMentorshipGroupMessages).forEach(([id, isFetching]) => {
          context.commit('updateFetchingMentorshipGroupChats', [id, false]);
        });
      } else {
        context.commit('updateFetchingMentorshipGroupChats', [groupId, false]);
      }
    },
    async sendMentorshipGroupChatMessage(context, {
      groupId,
      text,
      attachmentId = undefined,
      attachmentType = undefined,
      chatReplyId = undefined,
      resourceType = 3, // resource
    }) {
      const formData = generateFormData({
        text,
        groupid: groupId,
        senderid: context.rootState.User.id,
        userid: context.rootState.User.id,
      });

      if (attachmentType && attachmentId) {
        formData.set(attachmentType, attachmentId);
        formData.set('resource_type', resourceType);
      }

      if (chatReplyId) {
        formData.set('chatrefid', chatReplyId);
      }

      const response = await apiPost('chat', formData, 54)
        .then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;
          return true;
        });
      //

      return !!response;
    },
    markMentorshipGroupMessagesAsRead(context, linkid) {
      const formData = new FormData();
      formData.set('linkid', linkid);

      apiPost('chat_seen', formData, 4);
    },
  },
};
