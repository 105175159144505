import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _groupBy from 'lodash/groupBy';
import { apiPost } from '../modules/apiHelper';
import { toBoolean } from '../modules/dataHelper';

export default {
  state: {
    notifications: [],
    unread: 0,
  },
  getters: {
    totalNewNotifications(state) {
      return Number(state.unread) || state.unread;
    },
    notifications(state) {
      const notifications = state.notifications.filter(n => !toBoolean(n.status));

      // group mentorship group notifications
      // const groupedMentorshipGroupNotifications = _groupBy(notifications, []);
      const notificationsWithNoMentorshipGroupNotifications = notifications.filter((notif) => !/(\S+(group_chat))/.test(notif.type))

      return _reverse(_sortBy([
        ...notificationsWithNoMentorshipGroupNotifications,
        // ...groupedMentorshipGroupNotifications,
      ], 'time'));
    },
    userNotifications(state, getters) {
      const notifications = getters.notifications.filter(notif => !(/(\S+(task|match))/.test(notif.type)));
      return _reverse(_sortBy(notifications, 'time'));
    },
    matchNotifications(state, getters) {
      const notifications = getters.notifications.filter(notif => /(\S+(match))/.test(notif.type));
      return _reverse(_sortBy(notifications, 'time'));
    },
    taskNotifications(state, getters) {
      const notifications = getters.notifications.filter(notif => /(\S+(task))/.test(notif.type));
      return _reverse(_sortBy(notifications, 'time'));
    },
    mentorshipGroupChatsNotifications(state, getters) {
      const notifications = getters.notifications.filter(notif => /(\S+(group_chat))/.test(notif.type));
      return _reverse(_sortBy(notifications, 'time'));
    },
  },
  mutations: {
    addNotification(state, data) {
      if (!state.notifications.find(notif => notif.id === data.id)) {
        state.notifications.push(data);
      }
    },
    addNotifications(state, notifs = []) {
      state.notifications = [...notifs];
    },
    removeNotification(state, data) {
      const index = state.notifications.findIndex(n => n.id === data.id);
      if (index >= 0) {
        state.notifications.splice(index, 1);
      }
    },
    updateNotification(state, {
      id, property, data,
    }) {
      const index = state.notifications.findIndex(n => n.id === id);
      if (index >= 0) {
        state.notifications[index][property] = data;
      }
    },
    updateTotalUnreadNotifications(state, total) {
      state.unread = total;
    },
    updateFetchingNotifs(state, isFetching) {
      state.isFetching = isFetching;
    },
  },
  actions: {
    /**
     * Loops get requests
     * [TODO] Use Websocket instead of long-polling
     */
    // eslint-disable-next-line consistent-return
    async getNotifications(context) {
      // if (process.env.NODE_ENV === 'development') return false;
      await context.dispatch('fetchNotifications');

      if (context.state.isFetching) return true;
      // This is set only once when thi action is dispatched
      context.commit('updateFetchingNotifs', true);

      try {
        setTimeout(async function fetch() {
          if (context.state.isFetching) {
            await context.dispatch('fetchNotifications');

            setTimeout(fetch, 30000);
          }
        }, 30000);
      } catch (err) {
        console.warn('ERROR IN FETCHING NOTIFICATIONS!! > ', err);
      }
    },
    /**
     * Get user notifications from server and replace all current store notifications
     */
    async fetchNotifications(context) {
      const formData = new FormData();
      formData.set('userid', context.rootState.User.id);

      await apiPost('notifications', formData, 0)
        .then((res) => {
          if (!res) return false;

          if (res.data.notifications.length !== context.state.notifications.length) {
            context.commit('addNotifications', res.data.notifications);
          }
          if (res.data.unread !== context.state.unread) {
            context.commit('updateTotalUnreadNotifications', res.data.unread);
          }
          return true;
        });
    },
    stopFetchingNotifications(context) {
      context.commit('updateFetchingNotifs', false);
    },
    async markNotificationRead(context, notificationid) {
      // xhr POST
      // mutate updateNotification
      const formData = new FormData();
      formData.set('notificationid', notificationid);

      const index = context.getters.notifications.findIndex(n => n.id === notificationid);
      if (index < 0) return false;

      const response = await apiPost('notifications_read', formData, 1)
        .catch((err) => {
          console.warn('markNotificationRead err: ', err);
          return false;
        })
        .then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          context.dispatch('fetchNotifications');

          context.commit('updateNotification', {
            id: notificationid,
            property: 'status',
            data: '1',
          });

          return true;
        });

      return response;
    },
  },
};
