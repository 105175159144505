<template>
  <AppView :show-bottom-bar="!error && !loading" padd-bottom>
    <TopNav
      type="back"
      :show-notification-icon="false"
      :show-back-button="showBackButton"
      slot="top-app-view"
    />
    <div class="px-body">
      <div v-if="error" class="tw-text-center">
        <p class="tw-text-xs opacity-54 tw-py-18 tw-text-center" v-html="error"></p>
      </div>
      <div v-else-if="loading">
        <p class="tw-text-xs opacity-54 tw-py-18 tw-text-center">Loading...</p>
      </div>
      <div v-else>
        <transition name="new" mode="out-in" appear>
          <div class="tw-text-center tw-max-w-lg tw-mx-auto">
            <UserPhoto
              :photo="user.photo"
              :user-name="user"
              photo-size="tw-w-20 tw-h-20 tw-inline-block"
            />
            <h4 class="font-title tw-text-xl leading-24 tw-mt-3">{{ userFullName }}</h4>
            <p v-if="user.profession.profession" class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 tw-mr-2 leading-19 tw-px-2 tw-inline-block">
              Profession: <b>{{ user.profession.profession }}</b>
            </p>
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
              Department: <b>{{ user.department.departments }}</b>
            </p>
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
              Region: <b>{{ user.regional_area.region }}</b>
            </p>
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
              Sub-area: <b>{{ user.sub_area.sub_area }}</b>
            </p>
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black leading-19 tw-pt-2 tw-mb-1">
              availability
            </p>
            <p
              class="tw-text-xs tw-mr-2 tw-text-center opacity-54 tw-text-white leading-19 tw-bg-gray-700 tw-rounded-full tw-px-2 tw-inline-block tw-mb-1"
              v-for="(availability, index) in user.availabilities"
              :key="index"
            >
              {{ availability.availability }}
            </p>
            <p class="tw-text-base leading-19 opacity-54 tw-mt-6">{{ user.bio }}</p>
          </div>
        </transition>
      </div>
    </div>
    <div
      slot="bottom-app-view"
      class="px-body tw-py-4"
      v-if="isMentee"
    >
      <BaseButton
        :class="[
          'tw-py-3 tw-bg-black tw-text-white tw-block lg:tw-mx-auto lg:tw-max-w-lg tw-border-0 tw-w-full tw-shadow-xl',
          {'tw-bg-green-400': requestSuccess}
        ]"
        :text="btnText"
        :disabled="loading || !canSendRequest || isMyMentor"
        @click="sendMentorRequest"
      />
    </div>
    <!-- [TODO] ACCEPT/REJECT MENTEE REQUEST FROM HERE -->
    <div
      slot="bottom-app-view"
      class="px-body tw-py-4 tw-flex"
      v-if="isMentor && isMenteeRequest"
    >
      <BaseButton
        :class="[
          'tw-py-3 tw-bg-app-deep-blue tw-text-white tw-block lg:tw-max-w-lg tw-border-0 tw-flex-grow tw-mr-4 tw-shadow-xl',
          {'tw-bg-green-400': requestSuccess}
        ]"
        :text="acceptBtnText"
        :disabled="loading || !canSendRequest || isMyMentor"
        @click="acceptMenteeRequest"
      />
      <BaseButton
        :class="[
          'tw-py-3 tw-bg-red-200 tw-text-red-600 tw-block lg:tw-max-w-lg tw-border-0 tw-flex-grow tw-shadow-xl',
          {'tw-bg-green-400': requestSuccess}
        ]"
        :text="declineBtnText"
        :disabled="loading || !canSendRequest || isMyMentor"
        @click="declineMenteeRequest"
      />
    </div>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserBio',
  props: ['id', 'slug', 'showBackButton'],
  data() {
    return {
      user: undefined,
      loading: true,
      error: undefined,
      canSendRequest: true,
      requestSuccess: false,
      goalid: undefined,
      buttonText: 'Send Request',
      acceptBtnText: 'Accept Request',
      declineBtnText: 'I am unavailable',
    };
  },
  computed: {
    ...mapGetters([
      'newMentees',
    ]),
    isMenteeRequest() {
      if (this.isMentee) return false;
      return this.newMentees.find(menteeRequest => menteeRequest.menteeid === this.id);
    },
    userFullName() {
      return `${this.user.name} ${this.user.last_name}`;
    },
    isMyMentor() {
      if (this.isMentee) {
        return !!this.$store.getters.getMentor(this.id);
      }
      return false;
    },
    btnText() {
      if (this.isMyMentor) {
        return `${this.userFullName} is your mentor`;
      }

      return this.buttonText;
    },
  },
  methods: {
    sendMentorRequest() {
      if (!this.id || !this.isMentee || !this.goalid) return false;
      this.canSendRequest = false;
      this.buttonText = 'Sending...';

      const data = {
        mentorid: this.id,
        goalid: this.goalid,
      };

      this.$store.dispatch('sendMentorRequest', data)
        .catch((err) => {
          const errorMessage = 'Error in sending your request. Try again later';
          console.warning(errorMessage, err);
          this.$toasted.global.appError({ errorMessage });
          this.error = errorMessage;
        })
        .then(([res, errorMessage]) => {
          if (!res) {
            this.$toasted.global.appError({ errorMessage });
            this.buttonText = 'Send Request';
            this.canSendRequest = true;
          } else {
            // Tell the mentee that he waits?
            this.$toasted.success(`Request sent to ${this.userFullName}!`,
              {
                duration: 5000,
                position: 'top-right',
              });
            this.requestSuccess = true;
            this.buttonText = 'Request sent!';
            this.canSendRequest = false;

            // ? redirect back?

            // get prompt for user
            this.$store.dispatch('getCurrentUserJourneyAction');
          }
        });

      return true;
    },
    async acceptMenteeRequest() {
      if (!this.canSendRequest) {
        this.$toasted.success('action not allowed at the moment. Please wait...', {
          duration: 3000,
          position: 'top',
        });
        return false;
      }

      this.acceptBtnText = 'Working...';
      this.canSendRequest = false;

      const [result, message] = await this.$store.dispatch('AcceptMenteeRequest', this.id);
      if (!result) {
        this.$toasted.global.appError({ errorMessage: message });
        this.acceptBtnText = 'Accept Request';
        this.canSendRequest = true;
        return false;
      }

      this.$toasted.success(message, {
        duration: 3000,
        position: 'top-right',
      });
      this.acceptBtnText = 'Accepted!';
      this.requestSuccess = true;

      // get prompt for user
      this.$store.dispatch('getCurrentUserJourneyAction');

      return true;
    },
    async declineMenteeRequest() {
      if (!this.canSendRequest) {
        this.$toasted.success('action not allowed at the moment. Please wait...', {
          duration: 3000,
          position: 'top',
        });
        return false;
      }

      this.declineBtnText = 'Working...';
      this.canSendRequest = false;

      const [result, message] = await this.$store.dispatch('DeclineMenteeRequest', this.id);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: message });
        this.canSendRequest = true;
        this.declineBtnText = 'Decline Request';
        return false;
      }

      this.$toasted.success(message, {
        duration: 3000,
        position: 'top',
      });
      this.declineBtnText = 'Declined!';
      this.requestSuccess = true;

      // get prompt for user
      this.$store.dispatch('getCurrentUserJourneyAction');

      return true;
    },
  },
  async mounted() {
    this.$store.commit('isFlatView', false);

    // (mentor) redirect to app if mentee request not found
    if (this.$route.name === 'menteeRequest' && this.isMentor) {
      if (!this.isMenteeRequest) {
        // confirm with server
        await this.$store.dispatch('getMenteesRequests');
        await this.$nextTick();
        await this.$nextTick();

        if (!this.isMenteeRequest) {
          this.$toasted.global.appError({ errorMessage: 'You have no such request. Redirecting you back...' });
          await this.$nextTick();
          this.$router.push({ name: 'app' });
          return false;
        }
      }
    }

    this.goalid = this.$route.query.goalid;

    const errorMessage = `Error in getting ${this.isMentor ? 'mentee\'s' : 'mentor\'s'} information`;
    return this.$store.dispatch('getUserData', this.id)
      .catch((err) => {
        console.error(errorMessage, err);
        this.$toasted.global.appError({ errorMessage });
        this.error = errorMessage;
      })
      .then(async (user) => {
        if (!user) return false;
        this.user = user;
        this.loading = false;
        this.error = undefined;

        if (this.slug === 'accept' && this.isMentor) {
          // accept then go to my-mentees(app)
          const result = await this.acceptMenteeRequest();

          if (result) {
            this.$router.push({ name: 'app' });
          }
        }
        if ((this.slug === 'reject' || this.slug === 'decline') && this.isMentor) {
          // decline then go to app
          const result = await this.declineMenteeRequest();

          if (result) {
            this.$router.push({ name: 'app' });
          }
        }
        return user;
      });
  },
};
</script>

<style>

</style>
