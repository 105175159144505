export default {
  isLoggedIn(state) {
    return !!state.id;
  },
  userId(state) {
    return Number(state.id);
  },
  userTypeName(state) {
    if (state.type === 3) {
      return 'mentor';
    }
    if (state.type === 4) {
      return 'mentee';
    }
    return undefined;
  },
  userCompanyId(state) {
    return Number(state.companyid) || (process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEFAULT_COMPANY_ID : process.env.VUE_APP_DEFAULT_COMPANY_ID_PRODUCTION);
  },
};
