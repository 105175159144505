import { apiPost, generateFormData, apiStore } from '../../../modules/apiHelper';
import {
  toBoolean, isStaleData, now, urlFriendly, objectIsValid,
} from '../../../modules/dataHelper';

const RESOURCE_TYPES = {
  pillarresource: 1,
  pillarlink: 2,
  resource: 3,
  link: 4,
}

export default {
  async getTask(context, [taskid, refresh = false]) {
    const localDb = await apiStore.getItem(`task/${taskid}`) || [0, undefined];

    if (localDb[1] && !isStaleData(localDb[0]) && !refresh) return localDb[1];

    const goals = await context.dispatch('getGoals', []);

    const formData = new FormData();
    formData.set('taskid', taskid);

    const task = await apiPost('task_list', formData, 1)
      .then((res) => {
        // console.log(res);
        if (!res) return {};
        if (toBoolean(res.data.error)) return {};

        const tsk = res.data.task_list[0];

        if (tsk) {
          // get tasks' goals
          const taskGoal = goals.find((goal) => goal.id == tsk.subgoalid)

          tsk.goal = taskGoal || {}

          apiStore.setItem(`task/${taskid}`, [now(), tsk]);
        }

        return tsk || {};
      });

    //
    return task;
  },

  // How to cache the tasks...
  async tasksList(context, [mentorid, menteeid]) {
    const formData = generateFormData({
      mentorid,
      menteeid,
    });

    const goals = await context.dispatch('getGoals', []);

    const response = await apiPost('task_list_match', formData, 1)
      .catch((err) => {
        console.warn('tasksList failed! ', err);
      })
      .then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        const tasks = res.data.task_list_match;

        const mappedTasks = tasks.map((task) => {
          const taskGoal = goals.find((goal) => goal.id == task.subgoalid)

          return {
            ...task,
            goal: taskGoal || {},
          }
        });

        return mappedTasks;
      });

    return response;
  },

  async uploadTaskResource(context, [taskid, resources = [], linkResources = []]) {
    const requests = [];
    const percentagesComplete = [];
    const apiAction = context.getters.userTypeName === 'mentor'
      ? 'add_task_resource'
      : 'add_task_report_resource';
    //

    const combinedResources = [...Array.from(resources), ...linkResources];

    combinedResources.forEach((resource, index) => {
      // console.log(resource);
      const formData = new FormData();
      formData.set('taskid', taskid);
      formData.set('senderid', context.getters.userId);

      if (resource.id) {
        formData.set('resourceid', resource.id);
        formData.set('resourcetype', resource.title ? 2 : 1); // 1 = resourceid, 2 = link resourceid
      } else {
        formData.set('name', resource.name);
        formData.append('file1', resource, urlFriendly(resource.name));
        formData.set('resourcetype', 0); // 0 = file
      }

      requests.push(apiPost(apiAction, formData, 3,
        {
          'Content-Type': 'multipart/form-data',
        },
        (progressEvent) => {
          percentagesComplete[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }));
    });

    const response = Promise.all(requests)
      .catch((err) => {
        console.warn('uploadTaskResource failed!', err);
      })
      .then((res) => {
        if (!res) return false;
        const positive = res.every(r => !!r);
        if (!positive) return false;
        const haveErrors = res.every(r => toBoolean(r.data.error));
        if (haveErrors) return false;

        return true;
      });

    return [response, percentagesComplete];
  },

  async taskResources(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('task_share_resources', formData, 1)
      .catch((err) => {
        console.warn('taskResources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];
        const resources = [];
        const resourcesProm = [];

        (res.data.task_share_resources || []).forEach(({ resourceid, resource_type }) => {
          const resourceTypePillar = resource_type == 2 ? RESOURCE_TYPES.pillarlink : RESOURCE_TYPES.pillarresource;

          const r = context.dispatch('getResource', [resourceid, resourceTypePillar]);

          resourcesProm.push(r);

          r.then((resource) => {
            // console.log(resource);
            if (objectIsValid(resource)) {
              resources.push(resource);
            }
          });
        });

        await Promise.all(resourcesProm);
        return resources;
      });

    return response;
  },

  async taskReportResources(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('task_report_resources', formData, 1)
      .catch((err) => {
        console.warn('taskReportResources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];
        const resources = [];
        const resourcesProm = [];

        (res.data.task_report_resources || []).forEach(({ resourceid }) => {
          const r = context.dispatch('getResource', [resourceid]);
          resourcesProm.push(r);
          r.then((resource) => {
            if (objectIsValid(resource)) {
              resources.push(resource);
            }
          });
        });

        await Promise.all(resourcesProm);
        return resources;
      });

    return response;
  },

  async taskReportTexts(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('task_review_log', formData, 46)
      .catch((err) => {
        console.warn('taskReportText failed: ', err);
        return '';
      })
      .then(async (res) => {
        if (!res) return '';
        if (toBoolean(res.data.error)) return '';

        return res.data.task_review_log.filter((log) => log.taskid == taskid);
      });
    //

    return response;
  },

  async revertedTaskResources(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('reject_task_resource', formData, 42)
      .catch((err) => {
        console.warn('revertedTaskResources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        return res.data.reject_task_resource || [];
      });
    //

    return response;
  },

  async revertedTaskTexts(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('task_reject_log', formData, 46)
      .catch((err) => {
        console.warn('revertedTaskTexts failed: ', err);
        return '';
      })
      .then(async (res) => {
        if (!res) return '';
        if (toBoolean(res.data.error)) return '';

        return res.data.task_reject_log.filter((log) => log.taskid == taskid);
      });
    //

    return response;
  },
};
