import _sortBy from 'lodash/sortBy';
import _debounce from 'lodash/debounce';
import { apiPost, generateFormData } from '../modules/apiHelper';
import { toBoolean } from '../modules/dataHelper';

const INTERVAL_SLOW = 20000;
const INTERVAL_NORMAL = 10000;
const INTERVAL_FAST = 3000; // ? make this slower for none-chromium browsers

export default {
  state: {
    messages: {},
    fetchingUserMessages: {},
    fetchingIntervals: {
      default: INTERVAL_NORMAL,
    }, // INTERVAL_FAST for fastFetching
    fetchingChatError: false,
    totalNewMessages: 0,
  },
  getters: {
    userMessages: state => id => _sortBy(state.messages[`user${id}`], 'tym_unix'),
    totalNewUserMessages: (state, getters, rootState) => (id) => {
      const messages = state.messages[`user${id}`] || [];
      return messages.filter(t => !toBoolean(t.status) && Number(t.senderid) !== Number(rootState.User.id)).length;
    },
    userLastMessage: state => (id) => {
      const messages = _sortBy(state.messages[`user${id}`], 'tym_unix') || [];
      return messages[messages.length - 1];
    },
    isFetching: state => id => !!state.fetchingUserMessages[`user${id}`] || false,
    fetchingError: state => !!state.fetchingChatError,
  },
  mutations: {
    addUserMessages(state, [id, messages]) {
      // console.log('MESSAGES...');
      state.messages = Object.assign({}, state.messages, { [`user${id}`]: [...messages] });
      // console.log(state.messages);
    },
    addUserMessage(state, [id, message]) {
      if (!state.messages[`user${id}`]) {
        // console.log('setting');
        state.messages[`user${id}`] = [...message]; // ! why am I destructuring? [todo]
      } else {
        // console.log('pushing.....');
        state.messages[`user${id}`].push(message);
      }
    },
    updateUserMessage(state, [id, userId, prop, value]) {
      if (!state.messages[`user${userId}`]) return false;
      const messageIndex = state.messages[`user${userId}`].findIndex(m => m.id == id);
      if (messageIndex < 0) return false;

      state.messages[`user${userId}`][messageIndex][prop] = value;
      return true;
    },
    updateFetchingChats(state, [userId, isFetching]) {
      state.fetchingUserMessages = Object.assign({}, state.fetchingUserMessages, { [`user${userId}`]: isFetching });
      state.fetchingIntervals = Object.assign({}, state.fetchingIntervals, { [userId]: isFetching ? INTERVAL_NORMAL : INTERVAL_SLOW });
    },
    isOnChatWith(state, userId) {
      state.fetchingIntervals = Object.assign({}, state.fetchingIntervals, { [userId]: INTERVAL_FAST });
      // state.fetchingIntervals[userId] = INTERVAL_FAST;

      // slow the fetch speed for other users
      Object.entries(state.fetchingIntervals).forEach(([id]) => {
        if (id !== 'default' && id !== userId) {
          state.fetchingIntervals = Object.assign({}, state.fetchingIntervals, { [id]: INTERVAL_SLOW });
          // state.fetchingIntervals[id] = INTERVAL_SLOW;
        }
      });
    },
    resetFetchSpeeds(state) {
      Object.entries(state.fetchingIntervals).forEach(([id]) => {
        if (id !== 'default') {
          state.fetchingIntervals[id] = INTERVAL_NORMAL;
        }
      });
    },
    updateFetchingChatError(state, error) {
      state.fetchingChatError = error;
    },
    updateTotalNewMessages(state, total) {
      state.totalNewMessages = total;
    },
  },
  actions: {
    totalNewMessages: _debounce(async (context) => {
      if (!context.rootState.User[context.getters.userTypeName]) return;
      let totalNewMessages = 0;
      const userState = context.getters.userTypeName === 'mentor' ? 'mentees' : 'mentors';

      context.rootState.User[context.getters.userTypeName][userState].forEach(({ user }) => {
        totalNewMessages += context.getters.totalNewUserMessages(user.id);
      });

      context.commit('updateTotalNewMessages', totalNewMessages);
    }, 700),
    // eslint-disable-next-line consistent-return
    async getUserMessages(context, [userId, mentorid, menteeid]) {
      // if (process.env.NODE_ENV === 'development') return false;
      await context.dispatch('fetchMessages', [userId, mentorid, menteeid]);

      if (context.getters.isFetching(userId)) return true;
      // This is set only once when this action is dispatched
      context.commit('updateFetchingChats', [userId, true]);

      try {
        setTimeout(async function fetch() {
          // const n = now() + context.state.fetchingIntervals;
          if (context.getters.isFetching(userId)) {
            await context.dispatch('fetchMessages', [userId, mentorid, menteeid])
              .catch((err) => {
                context.commit('updateFetchingChatError', true);
                throw err;
              });

            await context.dispatch('totalNewMessages');

            setTimeout(fetch, (context.state.fetchingIntervals[userId] || context.state.fetchingIntervals.default));
          }
        }, (context.state.fetchingIntervals[userId] || context.state.fetchingIntervals.default));
      } catch (err) {
        console.error('ERROR IN FETCHING MESSAGES! (chat.js::fetchMessages)\n\n', err);
        context.commit('updateFetchingChatError', true);
      }
    },
    async fetchMessages(context, [userId, mentorid, menteeid]) {
      const formData = new FormData();
      formData.set('mentorid', mentorid);
      formData.set('menteeid', menteeid);

      await apiPost('chat_list', formData, 4)
        .then(async (res) => {
          if (!res) return false;
          const messages = context.state.messages[`user${userId}`] || [];
          // add new messages
          // await context.commit('addUserMessages', [userId, res.data.chat_lists]);
          // if (messages.length !== res.data.chat_lists.length) {
          if (messages.length > 0) {
            await new Promise(async (resolve) => {
              await res.data.chat_lists.forEach(async (message) => {
                if (!messages.find(m => m.id === message.id)) {
                  await context.commit('addUserMessage', [userId, message]);
                }
                // update the chat message status
                const storeMessage = context.state.messages[`user${userId}`].find(m => m.id === message.id);
                if (storeMessage.status !== message.status) {
                  context.commit('updateUserMessage', [message.id, userId, 'status', message.status]);
                }
              });

              resolve();
            });
          } else {
            await context.commit('addUserMessages', [userId, res.data.chat_lists]);
          }
          // }


          // [TODO] users' online status
          // console.log(res.data.mentee_online_status, res.data.mentor_online_status);

          return true;
        });
    },
    // called when logging out
    stopFetchingUserMessages(context, userId = undefined) {
      if (!userId) { // stop all if userId was not given
        // eslint-disable-next-line no-unused-vars
        Object.entries(context.state.fetchingUserMessages).forEach(([id, isFetching]) => {
          context.commit('updateFetchingChats', [id.split('user').join(''), false]);
        });
      } else {
        context.commit('updateFetchingChats', [userId, false]);
      }
    },
    async sendMessage(context, {
      userId, text, attachmentId = undefined, attachmentType = undefined, resourceType = 3 // resource
    }) {
      // console.log('sending...', text);

      // send message
      const menteeid = context.getters.userTypeName === 'mentee' ? context.rootState.User.id : userId;
      const mentorid = context.getters.userTypeName === 'mentor' ? context.rootState.User.id : userId;
      if (!menteeid || !mentorid) return false;

      const formData = generateFormData({
        text,
        menteeid,
        mentorid,
        senderid: context.rootState.User.id,
      });

      if (attachmentType && attachmentId) {
        formData.set(attachmentType, attachmentId);
        formData.set('resource_type', resourceType);
      }

      const response = await apiPost('chat', formData, 4)
        .then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;
          return true;
        });

      return !!response;
    },
    markMessagesAsRead(context, linkid) {
      const formData = new FormData();
      formData.set('linkid', linkid);

      apiPost('chat_seen', formData, 4);
    },
  },
};
