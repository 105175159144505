<template>
  <div>
    <form
      ref="create-meeting-form"
      id="create-meeting-form"
      method="post"
      action="/create_meeting"
      class="tw-pb-16 md:tw-max-w-md"
      @submit.prevent="onSubmit"
    >
      <div class="tw-mb-4">
        <InputGroup
          label="Meeting Title"
          name="title"
          type="text"
          placeholder="meeting title"
          v-model="meetingTitle"
          :error="errors.meetingTitle"
        />
      </div>
      <div class="tw-mb-4">
        
      </div>
      <div class="tw-mb-4">
        <SelectGroup
          label="Meeting Duration"
          name="duration"
          nameKey="name"
          placeholder="duration"
          instruct="How long will the meeting take"
          input-classes="tw-font-sans tw-bg-gray-200"
          v-model="meetingDuration"
          :reduce="duration => duration.value"
          :options="durations"
          :selected="meetingDuration"
          :clearable="false"
          :error="errors.meetingDuration"
        />
      </div>
      <div class="tw-mb-4">
        <p class="tw-text-sm tw-mb-2 opacity-78">Meeting Date</p>
        <p class="tw-text-sm tw-mb-2 tw-text-red-500" v-if="errors.meetingDate" v-html="errors.meetingDate"></p>
        <FunctionalCalendar
          placeholder="meeting date"
          ref="meeting-date-ref"
          v-model="meetingDate"
          :sunday-start="true"
          :date-format="'mm/dd/yyyy'"
          :is-date-range="false"
          :is-date-picker="true"
          :change-month-function="false"
          :change-year-function="false"
          :disabled-dates="['beforeToday']"
          :day-names="['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']"
        />
      </div>

      <div class="tw-mb-4">
        <InputGroup
          label="Meeting Time"
          name="time"
          type="time"
          placeholder="meeting time"
          v-model="meetingTime"
          :error="errors.meetingTitle"
        />
      </div>

      <div>
        <BaseButton
          :text="saveButtonText"
          type="submit"
          style="margin-top: 80px;"
          class="tw-shadow-app tw-block tw-w-full tw-bg-black tw-border-transparent tw-text-white tw-py-3"
          :disabled="!canSubmit || saveButtonDisabled"
          @click="onSubmit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { FunctionalCalendar } from 'vue-functional-calendar';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import form from '@/modules/formHelper';
import { now, toJsDate } from '@/modules/dataHelper';

const durations = [
  {
    value: '15',
    name: '15 minutes'
  },
  {
    value: '30',
    name: '30 minutes'
  },
  {
    value: '45',
    name: '45 minutes'
  },
  {
    value: '60',
    name: '1 hour'
  },
  {
    value: '120',
    name: '2 hours'
  }];
//

const defaultButtonText = 'SAVE MEETING DETAILS';

export default {
  name: 'CreateMeeting',
  components: {
    InputGroup,
    SelectGroup,
    FunctionalCalendar,
  },
  props: {
    meeting: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      loading: true,
      meetingTitle: '',
      meetingDate: {},
      meetingTime: '',
      meetingDuration: '',
      durations,
      errors: {
        meetingTitle: undefined,
        meetingDate: undefined,
        meetingTime: undefined,
        meetingDuration: undefined,
      },
      saveButtonText: defaultButtonText,
      saveButtonDisabled: false,
    };
  },
  watch: {
    'meeting.id': {
      handler(value) {
        if (value) {
          this.setFormData();
        }
      },
      immediate: true,
    },
    'meetingDate.selectedDate'(value) {
      if (value) {
        if (!this.meetingDateIsValid()) {
          this.errors.meetingDate = 'Invalid date selected. Make sure you select a future date.';
        } else {
          this.errors.meetingDate = undefined;
        }
      }
    }
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.meetingTitle)
        && this.meetingDateIsValid() // if selected date is today or the future
        && form.isValidTextInput(this.meetingTime) // is valid time
        && form.isValidNumber(this.meetingDuration, false);
    },
  },
  methods: {
    meetingDateIsValid() {
      if (!this.meetingDate?.selectedDate) return false;

      const meetingDate = this.$moment(toJsDate(this.meetingDate.selectedDate));

      return meetingDate.isAfter(this.$moment()) || meetingDate.isSame(this.$moment(), 'day');
    },
    yesterday() {
      return this.$moment(new Date()).subtract(1, 'days').format('DD/MM/YYYY');
    },
    threeMonthsLater() {
      return this.$moment(new Date()).add(3, 'months').format('DD/MM/YYYY');
    },
    async onSubmit() {
      this.saveButtonDisabled = true;
      this.saveButtonText = 'SAVING...';

      const selectedDate = this.$moment(this.meetingDate.selectedDate).format('MM/DD/YYYY');

      const formData = {
        title: this.meetingTitle,
        start: selectedDate,
        end: selectedDate, // this is not necessary, but some APIs require it
        duration: this.meetingDuration,
        time: this.meetingTime,
      };

      if ((this.meeting || {})?.id) {
        // update meeting
        const ok = await this.$store.dispatch('updateMeeting', [this.meeting.id, formData]);

        if (ok) {
          this.$toasted.success('Meeting Saved.', { duration: 4000 });
          await this.$nextTick();
        }

        this.saveButtonText = defaultButtonText;
        this.saveButtonDisabled = false;
        return;
      }

      const meeting = await this.$store.dispatch('createMeeting', formData);

      if ((meeting || {})?.linkid) {
        this.$toasted.success('Meeting created. Please add participants', { duration: 4000 });
        await this.$nextTick();

        await this.$emit('on-success', meeting.linkid);
      }

      this.saveButtonText = defaultButtonText;
      this.saveButtonDisabled = false;
    },
    async setFormData() {
      if (!this.meeting?.id) return;

      this.meetingTitle = this.meeting.title;
      this.meetingTime = this.meeting.time;
      this.meetingDuration = this.meeting.duration;
      
      await this.$nextTick();

      if (this.$refs['meeting-date-ref']) {
        this.$refs['meeting-date-ref'].ChooseDate(this.meeting.start);
        this.meetingDate = { ...this.meetingDate, selectedDate: this.meeting.start };
      } else {
        this.meetingDate = {
          selectedDate: this.meeting.start
        };
      }
    }
  },
};
</script>

<style>

</style>
