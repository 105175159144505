import { createPopper } from '@popperjs/core';

export default {
  name: 'vSelectMixin',
  methods: {
    dropdownPosition(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [{
          name: 'flip',
          enabled: true,
          options: {
            rootBoundary: 'viewport',
          },
        }],
      });

      return () => popper.destroy();
    },
  },
};
