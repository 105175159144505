import resolve from "../modules/api/resolve";
import { apiPost, apiStore, generateFormData } from "../modules/apiHelper";
import { isStaleData, now, toBoolean } from "../modules/dataHelper";

async function addParticipant(data) {
  const formData = generateFormData(data);

  const response = await apiPost('add_prt', formData, 47).catch((err) => {
    console.error('error adding participants::', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
};

async function removeParticipant(data) {
  const formData = generateFormData(data);

  const response = await apiPost('del_prt', formData, 49).catch((err) => {
    console.error('error removing participant');
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
};

async function updateMeetingTitle(data) {
  const formData = generateFormData(data);

  const response = await apiPost('change_meet_name', formData, 49).catch((err) => {
    console.error('error updating meeting title');
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

async function rescheduleMeeting(data) {
  const formData = generateFormData(data);

  const response = await apiPost('rsc_meet', formData, 47).catch((err) => {
    console.error('error rescheduling meeting');
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

export default {
  state: {},
  actions: {
    async createMeeting(context, meeting) {
      meeting.userid = context.rootState.User.id;
      meeting.companyid = context.rootState.User.companyid;
      meeting.dash = 0;
      meeting.description = meeting.title; // ? do we need description?

      const formData = generateFormData(meeting);

      const response = await apiPost('create_meet', formData, 47).catch((err) => {
        console.error('error creating meeting::', err);
        return false;
      }).then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return res.data.linkid;
      });

      if ((response || '').length) {
        // get the new meeting
        const meeting = await context.dispatch('getMeetingByLinkId', [response]);

        return meeting;
      }

      return response;
    },
    async updateMeeting(context, [meetingId, data]) {
      const ok = await context.dispatch('assertMeetingOwnerById', meetingId);

      if (!ok) return false;

      const currentMeetingData = await context.dispatch('getMeetingById', [meetingId, true]);

      // compare values and run update on updated values
      const promises = [];

      if (data.title !== currentMeetingData.title) {
        const res = updateMeetingTitle({
          meetingid: meetingId,
          title: data.title
        });

        promises.push(res);
      }

      if (
        (new Date(data.start).getTime() !== new Date(currentMeetingData.start).getTime())
        || data.duration !== currentMeetingData.duration
        || data.time !== currentMeetingData.time
      ) {
        const res = rescheduleMeeting({
          meetingid: meetingId,
          ...data,
          reason: '[convinience]',
        });

        promises.push(res);
      }

      const result = await Promise.all(promises);

      // if success on all
      if (result.every((res) => !!res)) {
        // get meeting data afresh
        await context.dispatch('getMeetingById', [meetingId, true]);

        return true;
      }

      return false;
    },
    async cancelMeeting(context, data) {
      const formData = generateFormData(data);
      
      const response = await apiPost('dev_meet', formData, 47).catch((err) => {
        console.error('error cancelling meeting::', err);
        return false;
      }).then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`meeting/${data.linkId}`);

        return true;
      });

      return response;
    },
    async getMeetingByLinkId(context, [linkId, refresh = false]) {
      const meeting = await apiStore.getItem(`meeting/${linkId}`) || [0, {}];

      if (!refresh && !isStaleData(meeting[0]) && (meeting[1] || {})?.id) return meeting[1];

      const formData = generateFormData({
        linkid: linkId
      });

      const result = await resolve(apiPost('meetings_linkid', formData, 48)).then((data) => {
        if (!data) return false;

        const meetingData = data.meetings_linkid[0] || false;

        if (meetingData) {
          apiStore.setItem(`meeting/${linkId}`, [now(), meetingData]);
        }

        return meetingData;
      });
      
      return result;
    },
    async getMeetingById(context, [meetingId, refresh = false]) {
      const meeting = await apiStore.getItem(`meeting/${meetingId}`) || [0, {}];

      if (!refresh && !isStaleData(meeting[0]) && (meeting[1] || {})?.id) return meeting[1];

      const formData = generateFormData({
        meetingid: meetingId
      });

      const result = await resolve(apiPost('meetings', formData, 48)).then((data) => {
        if (!data) return false;

        const meetingData = data.meetings[0] || false;

        if (meetingData) {
          apiStore.setItem(`meeting/${meetingId}`, [now(), meetingData]);
        }

        return meetingData;
      });

      return result;
    },
    async getMyActiveMeetings(context) {
      const formData = generateFormData({
        userid: context.rootState.User.id
      });

      const result = await resolve(apiPost('my_active_meetings', formData, 50)).then(async (data) => {
        if (!data) return false;

        // const meetings = data.my_active_meetings;
        const activeMeetings = data.my_active_meetings;
        const meetings = [];
        const promises = [];

        activeMeetings.forEach((meeting) => {
          // console.log(meeting);
          // get meeting and meeting participants
          const prom1 = context.dispatch('getMeetingById', [meeting.meetingid]);
          const prom2 = context.dispatch('getParticipants', meeting.meetingid);

          Promise.all([
            prom1,
            prom2,
          ]).then(([m, p]) => {
            if (m && p) {
              meetings.push({
                ...m,
                participants: p,
              });
            }
          });

          promises.push(prom1, prom2);
        });

        await Promise.all(promises);

        return meetings;
      });

      return result;
    },
    async getParticipants(context, meetingId) {
      const formData = generateFormData({
        companyid: context.rootState.User.companyid,
        meetingid: meetingId
      });

      const result = await resolve(apiPost('company_participants', formData, 48)).then(async (data) => {
        if (!data) return false;

        /** @type {Array} */
        const participants = data.company_participants || [];

        // get users' data
        const promises = [];

        participants.forEach((participant) => {
          const prom = context.dispatch('getUserData', participant.userid);

          promises.push(prom);
        });

        const users = await Promise.all(promises);
        // console.log('participants::', users);

        return users;
      });

      return result;
    },
    async saveParticipants(context, { meetingId, participants }) {
      const ok = await context.dispatch('assertMeetingOwnerByLinkId', meetingId);

      if (!ok) return false;

      // get current participants
      /** @type {Array} */
      const currentParticipants = await context.dispatch('getParticipants', ok.id);

      // console.log(currentParticipants, participants);

      participants.forEach((participant) => {
        const foundInCurrent = currentParticipants.find((part) => Number(part.id) === Number(participant));

        // if already exist, skip
        if (foundInCurrent) return;

        // new, so add
        addParticipant({
          meetingid: ok.id,
          userid: participant
        });
      });

      currentParticipants.forEach((currentParticipant) => {
        const foundInNew = participants.find((part) => Number(part) === Number(currentParticipant.id));

        // if found in new list, skip
        if (foundInNew) return;

        // if not exist in new list, remove
        removeParticipant({
          meetingid: ok.id,
          userid: currentParticipant.id
        });
      });
    },
    async startMeeting(context, meetingId) {
      // todo
    },
    async endMeeting(context, meetingId) {
      const formData = generateFormData({
        meetingid: meetingId,
        userid: context.rootState.User.id,
      });

      const result = await apiPost('end_meet', formData, 50).catch((err) =>{
        console.warn('error ending meeting');
        return false;
      }).then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return true;
      });

      return result;
    },
    async assertMeetingOwnerByLinkId(context, meetingId) {
      const meeting = await context.dispatch('getMeetingByLinkId', [meetingId]);

      if (!meeting) return false;
      
      if (Number(meeting.admin) !== Number(context.rootState.User.id)) return false;
      
      return meeting;
    },
    async assertMeetingOwnerById(context, meetingId) {
      const meeting = await context.dispatch('getMeetingById', [meetingId]);

      if (!meeting) return false;
      
      if (Number(meeting.admin) !== Number(context.rootState.User.id)) return false;
      
      return meeting;
    },
    async assertMeetingParticipant(context, meetingId) {
      const meetingParticipants = await context.dispatch('getParticipants', meetingId);
  
      if (!meetingParticipants) return false;

      return !!meetingParticipants.find((participant) => Number(participant.id) === Number(context.rootState.User.id));
    }
  },
};
