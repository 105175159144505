<template>
  <div class="tw-mt-8 lg:px-body lg-grid lg:tw-flex box-border">
    <div class="lg-grid__col-2/3 lg:tw-pr-8 tw-relative">
      <div class="tw-relative tw-block">
        <template v-if="!isDesktopView">
          <!-- show when there's active upcoming meetings -->
          <!-- <div class="tw-fixed tw-top-[94px] tw-left-0 tw-p-5 tw-text-center tw-z-40">
            <p href="#!" class="tw-text-sm tw-uppercase tw-bg-app-primary-orange tw-inline-block tw-px-4 tw-py-2.5 tw-rounded-full tw-cursor-pointer" @click="showMeetings">
              Upcoming Video Meetings (3)
            </p>
          </div> -->
        </template>

        <!-- <portal to="modal-body">
          <div class="tw-p-5">
            <h4 class="font-title tw-my-5">Upcoming Video Meetings</h4>
            <VideoMeetingsList :meetings="meetings" />
          </div>
        </portal> -->
      </div>

      <div class="tw-z-0">
        <Chat :lg-grid-on-input="true" :id="mentee.id" />
      </div>
    </div>

    <div class="lg-grid__col-1/3">
      <!-- <template v-if="isDesktopView">
        <div class="tw-relative tw-block">
          <div class="tw-fixed tw-rounded-32 tw-bg-gray-200 tw-p-4 tw-max-h-[66vh] tw-overflow-auto nice-scrollbar">
            <h4 class="font-title tw-mb-5">Upcoming Video Meetings</h4>
            <VideoMeetingsList :meetings="meetings" />
          </div>
        </div>
      </template> -->
    </div>
  </div>
</template>

<script>
import Chat from '@/views/_partials/Chat.vue';
import VideoMeetingsList from '@/components/VideoMeetingsList.vue';

export default {
	components: { Chat, VideoMeetingsList },
  name: 'MenteeChat',
  props: ['mentee'],
  data() {
    return {
      meetings: []
    };
  },
  methods: {
    // showMeetings() {
    //   this.$store.commit('openModal', [true]);
    // },
    // async getMyMeetings() {
    //   const meetings = await this.$store.dispatch('getMyActiveMeetings');

    //   console.log(meetings);

    //   // list only meetings that the user in chat is participating in
    //   this.meetings = meetings.filter((meeting) => {
    //     if (meeting.participants.find(p => Number(p.id) === Number(this.mentee.id))) {
    //       return meeting;
    //     } 
    //   });
    // }
  },
  async mounted() {
    // await this.getMyMeetings();
  }
};
</script>

<style>

</style>
