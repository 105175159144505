import { toBoolean, toJsDate } from '../../modules/dataHelper';

export default {
  name: 'taskUiMixin',
  data() {
    return {
      isMarkingComplete: false,
    };
  },
  methods: {
    async refTaskInChat(chatUserId, task) {
      // save task to be used in the chat view
      await this.$store.dispatch('saveTaskRef', [chatUserId, task]);
      // redirect to chat
      const routeName = this.isMentee ? 'mentorChat' : 'mentorMenteeChat';

      if (this.$route.name !== routeName) {
        this.$router.push({
          name: routeName,
          params: {
            id: chatUserId,
          },
        });
      }

      this.$store.commit('closeRightSideNav');
    },
    async viewTaskDetails(task) {
      await this.$nextTick();
      if (this.isMarkingComplete) return false;
      await this.$nextTick();

      if (!task) {
        this.$toasted.error('Unable to show task', {
          duration: 200,
          position: 'top-right',
        });
        return false;
      }
      // console.log('showing task details...', task);
      this.$store.commit('openRightSideNav', 'Task');

      this.isMarkingTaskComplete = false;
      return this.$store.commit('showTaskDetails', task);
    },
    taskTimeIsValid(startTym, endTym) {
      const taskEndTym = this.$moment(toJsDate(endTym));
      const taskStartTym = this.$moment(toJsDate(startTym));

      return taskStartTym.isBefore(taskEndTym);
    },
    taskIsCompleted(taskStatus) {
      return Number(taskStatus) === 1;
    },
    taskIsOnReview(taskStatus) {
      return Number(taskStatus) === 2;
    },
    markTaskCompleted(taskId, isGroupTask = false) {
      // console.log('marking Completed...', taskId);
      this.isMarkingTaskComplete = true;
      this.$store.commit('closeRightSideNav');
      const routeName = this.$route.name;

      if (routeName !== 'TaskReport' || routeName !== 'GroupTaskReport') {
        this.$router.push({
          name: isGroupTask ? 'GroupTaskReport' : 'TaskReport',
          params: {
            id: taskId,
          },
        });
      }
      this.isMarkingTaskComplete = false;
    },
    // if taskId is relevent to the current logged In User
    async assertTask(taskId) {
      const task = await this.$store.dispatch('getTask', [taskId]);
      const userId = this.$store.getters.userId.toString();

      if (userId !== task.mentorid && userId !== task.userid) {
        this.$toasted.global.appError({
          errorMessage: 'Access denied!',
        });
        await this.$nextTick();
        this.$router.push({
          name: 'app',
        });
        await this.$nextTick();
        return false;
      }

      return true;
    },
    // if taskId is relevent to the current logged In User
    async assertGroupTask(taskId) {
      const task = await this.$store.dispatch('getTask', [taskId]);
      const assert = await this.$store.dispatch('assertParticipant', task.groupid);

      if (!assert) {
        this.$toasted.global.appError({
          errorMessage: 'Access denied!',
        });
        await this.$nextTick();
        this.$router.push({
          name: 'app',
        });
        await this.$nextTick();
        return false;
      }

      return true;
    },
  },
};
