<template>
  <div>
    <ContentLoader
      :height="154"
      :width="830"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      class="transform-scale-0"
    >
      <rect x="0" y="4" rx="5" ry="5" width="195" height="142" />
      <rect x="205" y="4" rx="5" ry="5" width="195" height="142" />
      <rect x="410" y="6" rx="5" ry="5" width="195" height="142" />
      <rect x="615" y="6" rx="5" ry="5" width="195" height="142" />
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoadingTwoGrid',
  components: {
    ContentLoader,
  },
};
</script>

<style>

</style>
