<template>
  <transition name="new" mode="out-in" appear>
    <BaseCard class="tw-p-6 tw-pb-8 tw-items-center">
      <div class="tw-text-center">
        <h5 class="tw-text-xs leading-15 tw-font-bold tw-py-2 tw-text-center tw-text-white--">Click the circle below to add Profile Photo</h5>
        <div
          :class="[
            'tw-inline-block tw-w-20 tw-h-20 tw-rounded-full tw-relative tw-cursor-pointer tw-flex-shrink-0 tw-border-2 tw-border-app-primary-blue',
            {'bg-white-11-- bg-black-11' : loadingPhoto},
          ]"
          @click="promptUpdatePhoto"
        >
          <!-- PUT PROFILE PIC HERE -->
          <img v-if="isValidPhoto && !loadingPhoto" :src="form.photo" class="tw-w-full tw-h-full tw-object-cover tw-rounded-full" />
          <input
            class="tw-hidden"
            type="file"
            ref="photo-input"
            accept="image/*"
            @change="addUserPhoto"
          />
        </div>
        <br />
        <CameraPhoto v-model="form.photoFileFromCamera" v-if="!isMobileView" />
        <!-- <vCamera output="blob" /> -->
      </div>
      <!-- BIO -->
      <div class="tw-mt-8 tw-max-w-xl tw-w-full tw-mx-auto">
        <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
          <h5 class="tw-text-xs leading-15 tw-font-bold tw-py-2 tw-text-center tw-text-white--">Bio</h5>
          <label class="tw-text-xs tw-text-white-- opacity-78">
            <span :class="{
              'tw-text-red-500': !canSaveBio,
              'tw-text-blue-500': canSaveBio
            }">{{ bioLength }}</span>
             / {{ maxBioLength }}
          </label>
        </div>
        <BaseTextarea
          rows="4"
          class="tw-border-b bg-black-11 bg-white-11-- focus:bg-white-11-- hover:bg-white-11-- tw-text-white--"
          v-model="form.bio"
          max-height="45vh"
        />
      </div>
    </BaseCard>
  </transition>
</template>

<script>
import profileMixin from '@/mixins/profile';
import { getPhoto } from '@/modules/dataHelper';
import CameraPhoto from '@/components/CameraPhoto.vue';
import { dataURItoBlob } from '../../modules/dataHelper';

export default {
  name: 'NewUserProfile',
  components: {
    CameraPhoto,
  },
  mixins: [profileMixin],
  props: {
    bio: {
      type: String,
      default: '',
    },
    photo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      minBioLength: 50,
      maxBioLength: 500,
      form: {
        photo: '',
        photoFile: undefined,
        photoFileFromCamera: undefined,
        photoBlobFromCamera: undefined,
        bio: '',
      },
      dataObjName: 'form', // for the profileMixin this.data
    };
  },
  watch: {
    'form.photoFileFromCamera': function (val) {
      if (val) {
        this.form.photo = val;

        // console.log(dataURItoBlob(val));

        const blobFile = dataURItoBlob(val);

        blobFile.lastModifiedDate = new Date();
        blobFile.name = 'a name.png'
        
        this.form.photoBlobFromCamera = blobFile;

        this.$emit('update', this.form.photoBlobFromCamera, this.form.bio);
      }
    },
    // eslint-disable-next-line func-names
    'form.photoFile': function (val) {
      // console.log(val);
      if (val) {
        this.loadingPhoto = true;

        getPhoto(this.form.photoFile, (event) => {
          this.form.photo = event.target.result;
          this.$emit('update', this.form.photoFile, this.form.bio);
        });

        return '';
      }
      return true;
    },
    // eslint-disable-next-line func-names
    'form.bio': function (val) {
      this.$emit('update', this.form.photoFile, val);
    },
  },
  methods: {
    getImage(data) {
      console.log('data::', data);
    }
  },
  created() {
    this.form.bio = this.bio;
    this.form.photo = this.photo.length > 0 ? this.userPhoto(this.photo) : this.photo;
  },
};
</script>

<style>

</style>
