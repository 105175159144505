<template>
  <AppView
    body-class="tw-flex tw-flex-col"
    padd-bottom
  >
    <TopNav
      slot="top-app-view"
      :class="{ 'tw-shadow-app': isMobileView }"
      :title="`${ellyOrEbby} (Admin)`"
      center-title
      title-class="lg:ml-body tw-tracking-wide font-title"
      type="menu"
      :show-user-photo="false"
      @input="openSideNav()"
    >
      <!-- BOT ICON -->
    </TopNav>
    <div class="tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-grow lg-grid">
    <div class="lg-grid__col-2/3"></div>
    <div
      class="tw-flex-grow tw-overflow-auto tw-w-full tw-h-full tw-pt-8 tw-flex tw-justify-end tw-flex-col transition-fast lg-grid__col-2/3"
      ref="chat-body"
    >
      <div v-if="loading">
        <p class="tw-text-xs tw-text-center tw-py-10 opacity-54">Loading. Please wait..</p>
      </div>
      <template v-else>
        <template v-for="(message, index) in userSupportMessages">
          <div class="tw-relative tw-w-full tw-py-1" :key="index">
            <div
              :class="[
                'chat-bubble tw-overflow-visible tw-fill-current chat-bubble--right mr-body lg:tw-mr-2'
              ]"
            >
              <div class="tw-overflow-auto tw-relative">
                <p class="tw-text-sm opacity-87 leading-17 tw-mt-2 tw-mx-2 tw-break-words tw-whitespace-pre-wrap">
                  {{ message.text }}
                </p>
                <div class="tw-flex tw-items-center">
                  <span class="tw-mx-2 tw-my-2 tw-text-xs leading-14 tw-font-light tw-block tw-flex-grow tw-text-right opacity-31">
                    {{ message.tym | toJsDate | moment('ddd Do hh:mm A') }}
                  </span>
                  <!-- READ/UNREAD TICKS -->
                  <span class="tw-fill-current tw-my-1 tw-flex-shrink-0 tw-pr-2 tw-text-green-400">
                    <svg class="tw-h-3 tw-w-3 transform-scale-0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <g>
                        <path d="M34.6,58.7c-0.8,0-1.6-0.4-2.2-1l-16.7-18c-1.2-1.3-1.1-3.4,0-4.7c1.2-1.3,3.1-1.2,4.3,0l14.5,15.6l32.1-36.4
                          c1.1-1.3,3.1-1.4,4.3-0.2c1.2,1.2,1.3,3.3,0.2,4.7L36.9,57.7C36.3,58.3,35.5,58.7,34.6,58.7L34.6,58.7z"/>
                        <path d="M35.2,40.9l19.1-21.7c1-1.1,1.2-2.9,0.4-4.2c-1.1-1.8-3.4-1.9-4.7-0.4L30.5,36.7c-1.1,1.2-1.1,3.1,0.2,4.3l0,0
                          C31.9,42.3,34,42.2,35.2,40.9z"/>
                        <path d="M22.1,52.8L5.8,35.1c-1-1.1-2.4-1.4-3.8-1c-2.1,0.8-2.6,3.3-1.2,4.8L17.4,57c1.2,1.4,3.4,1.4,4.6,0l0,0
                          C23.2,55.9,23.2,54,22.1,52.8z"/>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <!--  -->
              <svg
                class="tw-absolute tw-top-0 tw-right-0 tw-w-2 tw--mr-2 transform-scale-0"
                style="height: 10px;enable-background:new 0 0 8 10.2;"
                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 8 10.2" xml:space="preserve">
                <path fill="#000" fill-opacity="0.08" d="M0,0c2.7,0,5.4,0,6.4,0c2.2,0,1.7,2.6,1,3.4C6.8,4.1,1.1,9.2,0,10.2L0,0z"/>
              </svg>
            </div>
          </div>
        </template>
        <transition name="new" mode="out-in" appear>
          <template v-if="userSupportMessages.length > 0">
            <div class="tw-relative tw-w-full tw-py-1">
              <div
                :class="[
                  'chat-bubble tw-overflow-visible tw-fill-current chat-bubble--left ml-body'
                ]"
              >
                <div class="tw-overflow-auto tw-relative">
                  <p class="tw-text-sm opacity-87 leading-17 tw-my-3 tw-mx-3 tw-break-words tw-whitespace-pre-wrap">
                    Our support team will respond shortly, typically within 3 hours.
                  </p>
                </div>
                <!--  -->
                <svg
                  class="tw-absolute tw-top-0 tw-left-0 tw-w-2 tw--ml-2 transform-scale-0"
                  style="height: 10px;enable-background:new 0 0 8 10.2;"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 8 10.2" xml:space="preserve">
                  <path fill="#000" fill-opacity="0.08" d="M8,10.2c-1.1-1-6.8-6.1-7.4-6.9C-0.1,2.6-0.6,0,1.6,0c1,0,3.7,0,6.4,0L8,10.2z"/>
                </svg>
              </div>
            </div>
          </template>
        </transition>
      </template>
    </div>

    <portal to="bottom-app-view" slim>
      <div class="lg-grid">
        <div class="lg-grid__col-2/3">
          <!-- #00bfa5 WHATSAPP OPTION -->
        </div>
        <div
          class="tw-flex-grow-0 tw-flex-shrink-0 tw-w-full lg-grid__col-2/3 tw-bg-app-light-blue"
          ref="chat-bottom-bar"
        >
          <!-- <template v-if="!isMobileView"> -->
          <template>
            <div>
              <!-- still show whatsapp button here -->
            </div>
            <!-- admin message -->
            <div class="tw-flex bg-black-11 tw-px-8 tw-pt-6 lg:tw-ml-8 tw-rounded-t-10 tw-pb-6">
              <img class="tw-w-8 tw-h-8 tw-mr-2 tw-inline-block tw-rounded-full tw-flex-shrink-0" :src="`/img/support_${ellyOrEbby.toLowerCase()}.png`">
              <div>
                <p class="tw-mb-3 font-title tw-text-base tw-leading-none tw-mt-2">{{ ellyOrEbby }}</p>
                <p class="tw-font-bold tw-text-base">Hello! I'm {{ ellyOrEbby }} (admin) and I'm here to help with any issue.</p>
              </div>
            </div>

            <div class="tw-pb-6 tw-flex tw-flex-nowrap tw-items-end transition-fast tw-px-6 lg:tw-ml-8 bg-black-11">

              <div
                class="tw-flex tw-flex-wrap tw-shadow-app tw-p-1 tw-bg-white tw-flex-1 tw-items-end tw-mr-4 tw-relative"
                style="border-radius: 24px;"
              >
                <!-- textarea -->
                <textarea
                  ref="text-input-el"
                  rows="1"
                  class="leading-24 tw-p-2 tw-text-xl tw-flex-1 tw-bg-transparent tw-w-full tw-resize-none transition-fast"
                  :style="'height: ' + textareaScrollHeight + '; max-height: 240px;'"
                  placeholder="Type here..."
                  v-model="form.text"
                  @focus.prevent.stop="focusMode = true"
                  @blur.prevent.stop="focusMode = false"
                ></textarea>
              </div>
              <!-- send button -->
              <template>
                <BaseButton
                  text=""
                  icon
                  style="line-height: 0; font-size: 0;"
                  class="tw-shadow-app tw-bg-black tw-rounded-full tw-text-white tw-py-3 tw-border-0 focus:tw-bg-gray-600 transition-fast"
                  :disabled="disabled || sending"
                  @click="sendMessage()"
                >
                  <svg slot="icon" class="tw-w-6 tw-h-6 tw-mx-3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M70.9,0.9c-0.8-0.8-2.2-1-3.3-0.6l-65.8,25c-1.2,0.6-2,1.8-1.8,3c0,1.2,0.8,2.2,2,2.6l28.8,10l10.4,29.1
                      c0.4,1.2,1.6,2,2.8,2l0,0c1.4,0,2.6-1,3-2.2L71.9,4.1C72.2,2.9,71.7,1.7,70.9,0.9z M44.1,66.1l-9.8-27c-0.4-0.8-1-1.6-1.8-1.8
                      L5.7,28.1L67.1,4.9L44.1,66.1z M39.6,32.3c-0.8-0.8-0.8-2,0-2.8l10-10c0.8-0.8,2-0.8,2.8,0s0.8,2,0,2.8l-10,10
                      c-0.4,0.4-1,0.6-1.4,0.6C40.7,33,40.1,32.5,39.6,32.3z"/>
                  </svg>
                </BaseButton>
              </template>
            </div>
          </template>

          <!-- <template v-else>
            <div class="tw-bg-app-whatsapp-green tw-px-8 tw-pt-6 lg:tw-ml-8 tw-rounded-t-10 tw-pb-6">
              <p class="tw-font-bold tw-text-base tw-text-white">Reach out to us via whatsapp for faster response.</p>
            </div>

            <div class="tw-pb-6 tw-px-6 lg:tw-ml-8 tw-bg-app-whatsapp-green">
              button
              <BaseButton
                text="Go to Whatsapp"
                icon
                type="link"
                href="https://wa.me/254708271537?text=Hi"
                class="tw-shadow-app tw-bg-app-whatsapp-green tw-border-white tw-py-6 tw-text-white tw-block tw-text-center tw-w-full"
              >
                <svg slot="icon" class="tw-w-8 tw-h-8 my-btn__icon" style="width: 2.25rem; height: 2.25rem;enable-background:new 0 0 72 72;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" xml:space="preserve">
                  <path d="M57.5,44.1c-0.5-0.6-1.2-1.1-1.9-1.4l-0.5-0.3c-0.1,0-5.8-2.8-6.9-3.2c-0.8-0.3-2.5-0.9-3.8,1c-0.6,0.9-2.3,2.8-3.1,3.7
                    C41.3,44,41.2,44,41.2,44c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.7-0.3c-2.6-1-4.9-2.5-7-4.3c-2.1-1.9-3.9-4.1-5.3-6.5l-0.1-0.1
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.3,0.7-0.8,1.1-1.2l0.5-0.6c0.4-0.5,0.8-1,1-1.6l0.1-0.3c0.5-0.9,0.4-2-0.1-2.9
                    c-0.2-0.3-1.2-2.8-1.9-4.6l-1.1-2.6c-0.9-2.2-2.1-2.5-3.2-2.5h-0.1c-0.6,0-1.3,0-2,0c-1.4,0-2.7,0.6-3.6,1.6l-0.2,0.2
                    c-2.4,2.3-3.7,5.5-3.6,8.9c0,4.7,3,9.2,4.3,10.9l0.1,0.2c2.9,4.1,9,11.4,17.4,15c1.9,0.8,3.8,1.5,5.8,2.1c1.4,0.4,2.8,0.6,4.3,0.6
                    c0.9,0,1.7-0.1,2.6-0.2c2.1-0.3,6.7-2.5,7.8-5.5C57.8,47.9,58.2,45.2,57.5,44.1L57.5,44.1z M54.6,49c-0.6,1.6-4,3.6-5.7,3.8
                    c-1.9,0.3-3.9,0.2-5.7-0.3c-1.9-0.6-3.7-1.3-5.6-2C29.8,47.1,24,40.1,21.3,36.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.9-1.2-3.8-5.3-3.8-9.4
                    c-0.1-2.7,1-5.2,2.9-7.1l0.2-0.2c0.4-0.5,1.1-0.8,1.7-0.8c0.6,0,1.3,0,1.9,0h0.3c0.3,0,0.4,0,0.8,0.9c0.3,0.6,0.7,1.6,1.1,2.6
                    c1.1,2.7,1.8,4.4,2,4.7c0.2,0.3,0.1,0.4,0.1,0.5l-0.1,0.3c-0.2,0.4-0.4,0.8-0.7,1.1L27,29.3c-0.3,0.4-0.6,0.7-0.9,1
                    c-1.1,0.9-1.4,2.4-0.6,3.6c1.6,2.7,3.5,5.1,5.8,7.1c2.3,2,4.9,3.6,7.7,4.8l0.6,0.3c1.2,0.8,2.8,0.5,3.7-0.5c0.5-0.6,2.5-2.9,3.3-4
                    c0.1-0.1,0.1-0.2,0.1-0.2c0.2,0,0.4,0.1,0.6,0.2c0.8,0.3,5.4,2.5,6.7,3.1l0.6,0.3c0.3,0.1,0.6,0.3,0.8,0.4
                    C55.3,46.6,55.1,47.8,54.6,49z"/>
                  <path d="M10.6,11c-11.7,11.3-14,29.3-5.5,43.2L0.4,69.4c-0.1,0.5,0,1,0.3,1.3C1.1,71,1.6,71.2,2,71l15.6-4.7
                    c14.1,8.4,32.1,6.2,43.9-5.4C75.3,47.5,75.5,25.3,62,11.5c-0.2-0.2-0.4-0.4-0.6-0.6C47.3-2.9,24.7-2.9,10.6,11z M59.6,59.2
                    c-11,10.9-28,12.8-41.2,4.7c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.4,0.1-0.6,0.1L3.6,67.8l4.1-13.2c0.2-0.4,0.2-0.9,0-1.3
                    c-8.2-12.9-6.3-29.8,4.7-40.5C25.5,0,46.5,0,59.6,12.8c12.8,12.5,13,33,0.5,45.8C60,58.8,59.8,59,59.6,59.2L59.6,59.2z"/>
                </svg>
              </BaseButton>
            </div>
          </template> -->
        </div>
      </div>
    </portal>
  </div>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import chatMixin from '@/mixins/chat';

export default {
  name: 'EllyEbby',
  mixins: [chatMixin],
  data() {
    return {
      ellyOrEbby: this.$store.getters.getBotName,
    };
  },
  computed: {
    ...mapGetters([
      'userSupportMessages',
    ]),
  },
  methods: {
    async sendMessage() {
      // disable the textarea
      this.sending = true;

      // send message
      const response = await this.$store.dispatch('sendSupportMessage', { ...this.form });

      await this.$nextTick();
      this.sending = false;

      // validate response
      if (!response) {
        this.$toasted.global.appError({
          errorMessage: 'Could not send message. Try again later',
        });
      } else {
        this.form.text = '';
      }
    },
  },
  async created() {
    this.loading = true;

    await this.$nextTick();
    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>
</style>
