<template>
  <div class="tw-w-full">
    <div class="tw-w-full tw-pt-12 tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <h2 class="font-title tw-text-xl tw-mb-1">{{title}}</h2>
    </div>
    <div
      :class="[
        'tw-flex tw-flex-wrap',
        { '--loading-data': pageIsLoading }
      ]"
    >
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label="Staff number"
          name="staff"
          type="number"
          placeholder="Staff number"
          input-classes="tw-p-3"
          input-type=""
          v-model="form.staff"
          :error="errors.staff"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Designation"
          name="designation"
          nameKey="profession"
          placeholder="Designation"
          instruct="Search for your designation"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200"
          v-model="form.designation"
          :options="designationList"
          :reduce="designation => designation.id"
          :selected="form.designation"
          :clearable="false"
          filterable
          searchable
          :error="errors.designation"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Department"
          name="department"
          nameKey="departments"
          placeholder="Department"
          instruct="Search for your department"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200"
          v-model="form.department"
          :options="departmentList"
          :reduce="department => department.id"
          :selected="form.department"
          :clearable="false"
          filterable
          searchable
          :error="errors.department"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Level"
          name="level"
          nameKey="level"
          placeholder="Level"
          instruct="Search for your level"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200"
          v-model="form.dept_level"
          :options="deptLevelList"
          :reduce="deptLevel => deptLevel.id"
          :selected="form.dept_level"
          :clearable="false"
          filterable
          searchable
          :error="errors.dept_level"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Region"
          name="regional"
          nameKey="region"
          placeholder="Region"
          instruct="Search for your region"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200"
          v-model="form.regional"
          :options="regionalList"
          :reduce="region => region.id"
          :selected="form.regional"
          :clearable="false"
          filterable
          searchable
          :error="errors.regional"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Division"
          name="division"
          nameKey="divisions"
          placeholder="Division"
          instruct="Search for your division"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200"
          v-model="form.division"
          :options="divisionList"
          :reduce="division => division.id"
          :selected="form.division"
          :clearable="false"
          filterable
          searchable
          :error="errors.division"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Sub-area"
          name="sub-area"
          nameKey="sub_area"
          placeholder="Sub-area"
          instruct="Search for your sub-area"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200"
          v-model="form.sub_area"
          :options="subAreaList"
          :reduce="subArea => subArea.id"
          :selected="form.sub_area"
          :clearable="false"
          filterable
          searchable
          :error="errors.sub_area"
        />
      </div>
      
    </div>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import { apiPost, generateFormData } from '@/modules/apiHelper';
import form from '@/modules/formHelper';

export default {
  name: 'KengenUserSignUp',
  components: {
    InputGroup,
    SelectGroup,
  },
  props: {
    title: {
      type: String,
      default: 'KenGen details'
    },
    // defaultValues: {
    //   type: Object,
    //   default: {},
    // }
  },
  data() {
    return {
      pageIsLoading: true,
      form: {
        staff: undefined,
        regional: undefined,
        division: undefined,
        department: undefined,
        sub_area: undefined,
        dept_level: undefined,
        designation: undefined,
      },
      errors: {
        staff: undefined,
        regional: undefined,
        division: undefined,
        department: undefined,
        sub_area: undefined,
        dept_level: undefined,
        designation: undefined,
      },
      // staffList: [],
      regionalList: [],
      divisionList: [],
      departmentList: [],
      subAreaList: [],
      deptLevelList: [],
      designationList: [],
    };
  },
  watch: {
    form: {
      async handler(data) {
        await this.$nextTick();
        this.$emit('input', { ...data });
      },
      deep: true,
    },
    'form.staff': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.staff = 'Staff is required';
      } else {
        this.errors.staff = undefined;
      }
    },
    'form.regional': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.regional = 'Regional is required';
      } else {
        this.errors.regional = undefined;
      }
    },
    'form.division': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.division = 'Division is required';
      } else {
        this.errors.division = undefined;
      }
    },
    'form.department': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.department = 'Department is required';
      } else {
        this.errors.department = undefined;
      }
    },
    'form.sub_area': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.sub_area = 'Sub-area is required';
      } else {
        this.errors.sub_area = undefined;
      }
    },
    'form.dept_level': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.dept_level = 'Department level is required';
      } else {
        this.errors.dept_level = undefined;
      }
    },
  },
  async mounted() {
    this.pageIsLoading = true;

    await this.$store.dispatch('evaluateScope');
    await this.$store.dispatch('setScope');
    await this.$nextTick();

    const scopeId = this.$store.getters.scopeId;

    const requests = [
      // {
      //   action: 'staff_no',
      //   endpoint: 34,
      //   dataName: 'staffList',
      // },
      {
        action: 'regions',
        endpoint: 34,
        dataName: 'regionalList',
      },
      {
        action: 'divisions',
        endpoint: 34,
        dataName: 'divisionList',
      },
      {
        action: 'departments',
        endpoint: 34,
        dataName: 'departmentList',
      },
      {
        action: 'sub_area',
        endpoint: 34,
        dataName: 'subAreaList',
      },
      {
        action: 'dept_level',
        endpoint: 34,
        dataName: 'deptLevelList',
      },
      {
        action: 'profession',
        endpoint: 0,
        dataName: 'designationList',
      },
    ]
    let requestsErrorCount = 0;

    const requestsPromises = requests.map((req) => apiPost(
      req.action,
      generateFormData({ companyid: scopeId}),
      req.endpoint,
    ));

    await Promise.all(requestsPromises)
      .catch((err) => {
        console.warn(err);
        this.$toasted.global.appError({ duration: 10000 });
        this.pageIsLoading = true;
      })
      .then((res) => {
        if (!res) return;
        res.forEach(({ data, error }, i) => {
          if (error) {
            console.error('error in fetching... ', res[i]);
            requestsErrorCount += 1;
            return;
          }
          if (!data[requests[i].action]) {
            console.error('data not found for \'', `${requests[i]}'`);
            requestsErrorCount += 1;
            return;
          }

          this[requests[i].dataName] = data[requests[i].action];
        });

        if (requestsErrorCount === 0) {
          this.pageIsLoading = false;

          // this.form = Object.assign({}, this.form, this.defaultValues)
        }
      });
  }
}
</script>

<style>

</style>
