import getters from './getters';
import mutations from './mutations';
import actions from './actions/index';
import modules from './modules';

export default {
  namespaced: false,
  state: {
    authError: undefined,
    // id: 12,
    // url: 'url',
    // name: 'Tina',
    // type: 4,
    // companyid: 1,
    id: undefined, // apiStore.getItem('userid')
    url: undefined, // apiStore.getItem('userurl')
    type: undefined, // Number(await apiStore.getItem('usertype'))
    name: undefined, // apiStore.getItem('username')
    companyid: undefined, // apiStore.getItem('companyid')
  },
  getters,
  mutations,
  actions,
  modules,
};
