import _compact from 'lodash/compact';

export async function asyncForEach(array, callback) {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

export function getRouteNames(routes) {
  const viewRoutes = [];
  // eslint-disable-next-line no-unused-vars
  Object.entries(routes).forEach(([index, {
    name = undefined,
    alias = undefined,
    children = [],
  }]) => {
    const routeName = name || ((alias !== 'default' && alias) ? alias : false);
    viewRoutes.push(routeName);

    if (children.length > 0) {
      viewRoutes.push(...getRouteNames(children));
    }
  });
  return _compact(viewRoutes);
}

export function customObservable(el, config, callback) {
  const observer = new MutationObserver(callback);
  observer.observe(el, config);
  return observer;
}

export const maxFileSize = 8388608;

// eslint-disable-next-line no-useless-escape
const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
// eslint-disable-next-line no-useless-escape
const urlNoProtocolRegex = /(https:\/\/)+(?=[a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/ig;

export function isValidUrl(url, noProtocol = false) {
  let isValid = false;

  if (noProtocol) {
    isValid = !(urlNoProtocolRegex.test(url));
  } else {
    isValid = urlRegex.test(url);
  }

  return !!isValid;
}
