<template>
  <a @click.stop="goBack">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
      <path d="M56,4.2c0,1.2-0.4,2-1.2,2.9l-29,29l29,29c1.6,1.6,1.6,4.1,0,5.7s-4.1,1.6-5.7,0L17.2,39c-1.6-1.6-1.6-4.1,0-5.7l31.9-32
        c1.6-1.6,4.1-1.6,5.7,0C55.6,2.1,56,3,56,4.2z"/>
    </svg>
    <span class="tw-uppercase tw-align-middle">back</span>
  </a>
</template>

<script>
export default {
  name: 'BackButton',
  props: ['prevRoute'],
  data() {
    return {
      prev: 'app',
    };
  },
  methods: {
    goBack() {
      const prevRouteName = sessionStorage.getItem('previousroutename') || 'app';
      const params = JSON.parse(sessionStorage.getItem('previousrouteparams') || '{}');
      const query = JSON.parse(sessionStorage.getItem('previousroutequery') || '{}');

      // vueRouter
      if (this.prevRoute) {
        this.$router.push(this.prevRoute);

      // nested views with no relation between the parent-child view
      } else if (
        this.isMobileView
        && prevRouteName !== 'mentorMenteeTasks'
        && prevRouteName !== 'MentorsSuggestionsList'
      ) {
        // home as the default page is boring
        this.$router.push({ name: this.isMentee ? 'TasksView' : 'mentorMenteesView' });
      } else {
        this.$router.push({ name: prevRouteName, params, query });
      }
    },
  },
};
</script>

<style>

</style>
