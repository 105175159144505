<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import notificationMixin from '@/mixins/notifications';

export default {
  name: 'App',
  mixins: [notificationMixin],
  components: {},
};
</script>

<style src="./assets/tailwind.css">
