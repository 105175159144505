<template>
  <svg
    class="tw-absolute tw-top-0 tw-right-0 tw-w-2 -tw-mr-2 transform-scale-0"
    style="height: 10px;enable-background:new 0 0 8 10.2;"
    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 8 10.2" xml:space="preserve">
    <path fill="#000" fill-opacity="0.08" d="M0,0c2.7,0,5.4,0,6.4,0c2.2,0,1.7,2.6,1,3.4C6.8,4.1,1.1,9.2,0,10.2L0,0z"/>
  </svg>
</template>

<script>
export default {
  name: 'RightTip',
};
</script>

<style>

</style>
