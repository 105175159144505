<template>
  <ContentLoadingTwoGrid v-if="loading" style="margin-left: -36px; margin-right: -36px;"/>

  <div v-else-if="error">
    <p class="tw-text-red-400 tw-py-10" v-html="error"></p>
  </div>

  <div v-else>
    <!--  -->
    <div>
      <h3
        v-if="classesAreSelectable"
        :class="[
          'tw-text-center -font-title tw-font-bold tw-mb-6',
          { 'tw-text-3xl': !isMobileView },
        ]"
      >
        You need to go to through our MIP Masterclass for you to continue.
        <br>
        Please select a cohort to enroll in.
      </h3>
      <div v-else>
        <h3 class="tw-text-center font-title tw-font-bold tw-mb-6">
          Your are signed up for the MIP Session below
        </h3>
      </div>
    </div>
    <template v-for="classData in classes">
      <div
        :class="[
          'tw-rounded-32 tw-border-2 tw-p-8 tw-mb-6 tw-mt-6 transition-slow hover:tw-shadow-app',
          { 'tw-border-app-white-87': classesAreSelectable },
          { 'tw-border-app-deep-blue': !classesAreSelectable },
        ]"
        :key="classData.id"
      >
        <h4 class="tw-text-base tw-uppercase tw-mb-4">{{ classData.class }}</h4>
        <h5 class="tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54">Description</h5>
        <h4 :class="['tw-text-base tw-mb-4', { 'tw-w-3/4': !isMobileView }]">{{ classData.description }}</h4>
        <div class="tw-flex tw-mb-8">
          <div class="tw-inline-block tw-mr-8">
            <h5 class="tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54">Start Date</h5>
            <h4 class="tw-text-base">{{ classData.start | toJsDate | moment('Do, MMM YYYY') }}</h4>
          </div>
          <div class="tw-inline-block">
            <h5 class="tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54">End Date</h5>
            <h4 class="tw-text-base">{{ classData.end | toJsDate | moment('Do, MMM YYYY') }}</h4>
          </div>
        </div>
        <div class="tw-flex tw-items-center" v-if="classesAreSelectable">
          <BaseButton
            text="enroll"
            :title="`enroll to ${classData.class}`"
            :disabled="btnIsDisabled"
            @click="enrollMentor(classData.id)"
            class="tw-mr-2 tw-py-4 tw-px-8 tw-border-app-deep-blue tw-bg-app-deep-blue tw-text-white hover:tw-shadow-app"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _compact from 'lodash/compact';
import ContentLoadingTwoGrid from '@/components/ContentLoadingTwoGrid.vue';
import { apiGet, generateFormData, apiPost } from '@/modules/apiHelper';
import resolve from '@/modules/api/resolve';

export default {
  name: 'MipClasses',
  components: {
    ContentLoadingTwoGrid,
  },
  data() {
    return {
      loading: true,
      error: undefined,
      classes: [],
      classesAreSelectable: true,
      btnIsDisabled: false,
    };
  },
  methods: {
    async loadPage() {
      this.loading = true;

      const classesUserIsIn = await this.getClassesUserIsIn();

      if (!(classesUserIsIn || []).length) {
        this.classes = await this.getUpcomingClasses();
        this.classesAreSelectable = true;

        this.$emit('input', false);
      } else {
        this.classes = [...classesUserIsIn];
        this.classesAreSelectable = false;

        this.$emit('input', false);
      }

      await this.$nextTick();

      this.loading = false;
    },
    async getUpcomingClasses() {
      // get active classes then filter out the classes already started
      const request = await apiGet('list_class', 13, undefined, true, false)
        .catch((err) => {
          console.warn('getUpcomingClasses::list_class err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (res.data.error) return false;

          // console.log(res.data);

          const now = this.$moment();
          const classes = res.data.list_class.map((l) => {
            // remove already started classes
            const classStart = this.$moment(l.start);

            if (now.isBefore(classStart)) {
              return l;
            }

            return undefined;
          });

          // console.log(classes);

          return _compact(classes);
        });
      //

      if (!request) {
        // error
        this.error = 'Error in finding classes list.';
        return [];
      }

      return request;
    },
    async getClassesUserIsIn() {
      // eslint-disable-next-line prefer-destructuring
      const userId = this.$store.getters.userId;
      // get classes the user is in
      const classesUserIsIn = await apiGet('training_list', 13, undefined, true, false)
        .catch((err) => {
          console.warn('getClassesUserIsIn::training_list err', err);
          return false;
        })
        .then((res) => {
          if (!res) return false;
          if (res.data.error) return false;

          const trainingList = res.data.training_list.map((l) => {
            if (Number(l.userid) === Number(userId)) {
              return l;
            }
            return undefined;
          });

          return _compact(trainingList);
        });
      //

      if (!classesUserIsIn) {
        // error
        this.error = 'Error in finding classes list.';
        return [];
      }

      // console.log(classesUserIsIn);

      const request = await apiGet('list_class', 13, undefined, true, false)
        .catch((err) => {
          console.warn('getClassesUserIsIn::list_class err', err);
          return false;
        })
        .then((res) => {
          if (!res) return false;
          if (res.data.error) return false;

          const classes = [];

          classesUserIsIn.forEach((lui) => {
            const theClass = res.data.list_class.find(l => Number(l.id) === Number(lui.classid));
            if (theClass) {
              classes.push(theClass);
            }
          });

          return classes;
        });
      //

      return request;
    },
    async enrollMentor(classId) {
      this.btnIsDisabled = true;

      const formData = generateFormData({
        userid: this.$store.getters.userId,
        classid: classId,
      });

      const result = await resolve(apiPost('schedule', formData, 13));

      if (!result) {
        this.$toasted.global.appError();
        this.$emit('input', false);
        return false;
      }

      await this.loadPage();

      this.$emit('input', true);
      // this.$emit('input-success', true);

      return true;
    },
  },
  async mounted() {
    await this.loadPage();
  },
};
</script>

<style>

</style>
