import Mentee from '../../views/mentor/Mentee.vue';
import MenteeTasks from '../../views/mentor/MenteeTasks.vue';
import MenteeChat from '../../views/mentor/MenteeChat.vue';
import MenteeProfile from '../../views/mentor/MenteeProfile.vue';
import MyMentees from '../../views/mentor/MyMentees.vue';
import ReviewTask from '../../views/mentor/ReviewTask.vue';
import FAQ from '../../views/mentor/FAQ.vue';
import RevertTask from '../../views/mentor/RevertTask.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: 3,
};

export default [
  {
    path: 'faq-mentor',
    name: 'FAQMentorView',
    component: FAQ,
    meta,
  },
  {
    path: 'my-mentees',
    name: 'mentorMenteesView',
    component: MyMentees,
    meta,
  },
  {
    path: 'mentee/:id',
    // name: 'mentorMenteeView', // mentorMenteeView
    component: Mentee,
    props: true,
    meta,
    children: [
      {
        path: 'tasks',
        name: 'mentorMenteeTasks',
        // alias: '',
        component: MenteeTasks,
      },
      {
        path: 'chat',
        name: 'mentorMenteeChat',
        component: MenteeChat,
      },
      {
        path: 'profile',
        name: 'mentorMenteeProfile',
        component: MenteeProfile,
      },
      {
        path: '',
        name: 'mentorMenteeView',
        component: MenteeTasks,
      },
    ],
  },
  {
    path: 'mentee-request/:id',
    redirect: 'mentee-request/:id/bio',
  },
  {
    path: 'mentee/:id/bio',
    name: 'menteeBio',
    component: () => import(/* webpackChunkName: "mentor" */ '../../views/user/UserBio.vue'),
    props: true,
    meta,
  },
  {
    path: 'mentee-request/:id/:slug',
    name: 'menteeRequest',
    component: () => import(/* webpackChunkName: "mentor" */ '../../views/user/UserBio.vue'),
    props: true,
    meta,
  },
  {
    path: 'task/:id/review',
    name: 'ReviewTask',
    component: ReviewTask,
    props: true,
    meta,
  },
  {
    path: 'task/:id/review/revert',
    name: 'RevertTask',
    component: RevertTask,
    props: true,
    meta,
  },
  // #region nested pages with BackButton
  {
    path: 'mentee/:id/tasks/create-task',
    name: 'mentorMenteeCreateTask',
    component: () => import(/* webpackChunkName: "mentort" */ '../../views/mentor/CreateTask.vue'),
    props(route) {
      return {
        id: route.params.id,
        prevRoute: {
          name: 'mentorMenteeTasks',
          params: {
            id: route.params.id,
          },
        },
      };
    },
    meta,
  },
  {
    path: 'mentee/:id/tasks/create-task/resources',
    name: 'addResourcesToTask',
    component: () => import(/* webpackChunkName: "mentort" */ '../../views/mentor/Resources.vue'),
    props(route) {
      return {
        id: route.params.id,
        prevRoute: {
          name: 'mentorMenteeCreateTask',
          params: {
            id: route.params.id,
          },
        },
      };
    },
    meta,
  },
  // #endregion
];
