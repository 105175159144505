<template>
  <div
    v-if="isFileInput"
    :class="[
      'tw-inline-flex tw-truncate tw-items-center tw-text-left tw-px-4 tw-py-3 tw-relative tw-group',
      inputClass,
      ]"
  >
    <input
      type="file"
      :accept="accepts"
      :placeholder="placeholder"
      :name="name"
      :multiple="multiple"
      @change="fileChange"
      ref="file-input"
      class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-opacity-0 tw-z-10 tw-cursor-pointer tw-inline-flex"
    >
    <span class="tw-uppercase tw-text-xs tw-font-bold tw-inline-block tw-flex-grow-0 tw-cursor-pointer tw-bg-gray-300 tw-py-2 tw-px-4 tw-mr-2 tw-rounded-sm group-hover:tw-bg-black group-hover:tw-text-white">Choose</span>
    <span
      v-if="hasSelectedFiles && multiple"
      class="tw-text-white tw-text-xs tw-flex-grow-0 tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-full tw-p-1"
    >
      {{ totalFilesSelected }}
    </span>
    <span class="tw-mx-2 opacity-78 tw-flex-grow">{{ filesNames }}</span>
  </div>
  <input
    v-else
    :class="inputClass"
    :type="type"
    :placeholder="placeholder"
    :name="name"
    v-model="inputValue"
  >
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accepts: {
      type: String,
      default: 'image/*',
    },
    inputClass: {
      type: String,
      default: 'tw-p-1 tw-w-full tw-rounded-sm tw-block tw-bg-gray-200 tw-outline-none transition hover:tw-bg-gray-200 focus:tw-bg-white',
    },
  },
  data() {
    return {
      inputValue: this.value,
      files: [],
    };
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputValue = val;
    },
  },
  computed: {
    filesNames() {
      // console.log(this.$refs['file-input']);
      if (!this.files) return '';
      const names = [];

      if (!this.multiple) return (this.files[0] || {}).name;

      Array.from(this.files).forEach((file) => {
        const fileName = file.name;
        const fileNameLength = fileName.length;
        const truncatedName = `${fileNameLength > 11 ? fileName.substring(0, 4) : fileName}${fileNameLength > 11 ? '...' : ''}${fileNameLength > 11 ? fileName.substring(fileNameLength - 4, fileNameLength) : ''}`;
        names.push(truncatedName);
      });

      return names.join(', ');
    },
    totalFilesSelected() {
      if (!this.files) return 0;
      return this.files.length;
    },
    hasSelectedFiles() {
      return this.totalFilesSelected !== 0;
    },
    isFileInput() {
      return this.type === 'file';
    },
  },
  methods: {
    fileChange(e) {
      this.$emit('input', e.target.files);
      this.files = e.target.files;
    },
  },
};
</script>

<style>

</style>
