import { apiStore, apiPost, generateFormData } from '../modules/apiHelper';
import { isStaleData, now } from '../modules/dataHelper';
import resolve from '../modules/api/resolve';

export default {
  state: {
    // ! this is wrong but I see no other way
    kengenExperienceId: 21,
    technicalPillarId: 22,
    businessEnablersId: 23,
    minimumCompletedKengenExperienceGoals: 2,
  },
  actions: {
    async getPillarGroups(context, [CompanyId, refresh = false]) {
      const companyid = CompanyId || context.rootGetters.userCompanyId;
      if (!companyid) return false;

      const pillars = await apiStore.getItem(`pillars/${companyid}`) || [0, []];

      if (!refresh && !isStaleData(pillars[0]) && pillars[1].length) return pillars[1];

      const result = await resolve(apiPost('company-pillars', generateFormData({
        companyid,
      }), 5)).then((data) => {
        if (!data) return false;
        // console.log(data);

        const companyPillars = data['company-pillars'] || [];

        if (companyPillars.length > 0) {
          apiStore.setItem(`pillars/${companyid}`, [now(), companyPillars]);
        }

        return companyPillars;
      });

      return result;
    },

    async getGoals(context, [CompanyId, refresh = false]) {
      const companyid = CompanyId || context.rootGetters.userCompanyId;
      if (!companyid) return false;

      const goals = await apiStore.getItem(`goals/${companyid}`) || [0, []];

      if (!refresh && !isStaleData(goals[0]) && goals[1].length) return goals[1];

      const result = await resolve(apiPost('company-goals', generateFormData({
        companyid,
      }), 5)).then((data) => {
        if (!data) return false;
        // console.log(data);

        const companyGoals = data['company-goals'] || [];

        if (companyGoals.length > 0) {
          apiStore.setItem(`goals/${companyid}`, [now(), companyGoals]);
        }

        return companyGoals;
      });

      return result;
    },

    // async getSubGoals(context, [CompanyId, refresh = false]) {
    //   const companyid = CompanyId || context.rootGetters.userCompanyId;
    //   if (!companyid) return false;

    //   const subGoals = await apiStore.getItem(`subgoals/${companyid}`) || [0, []];

    //   if (!refresh && !isStaleData(subGoals[0]) && subGoals[1].length) return subGoals[1];

    //   const result = await resolve(apiPost('company-subgoals', generateFormData({
    //     companyid,
    //   }), 5)).then((data) => {
    //     if (!data) return false;
    //     // console.log(data);

    //     const companySubGoals = data['company-subgoals'] || [];

    //     if (companySubGoals.length > 0) {
    //       apiStore.setItem(`subgoals/${companyid}`, [now(), companySubGoals]);
    //     }

    //     return companySubGoals;
    //   });

    //   return result;
    // },
  },
};
