<template>
  <div class="tw-overflow-hidden tw-w-full">
    <transition name="slide-right" mode="out-in" appear>
      <swiper
        ref="ongoing-tasks-slider"
        :options="swiperOptions"
        style="min-height: 414px;"
      >
        <swiper-slide
          v-for="(task, index) in tasks"
          :key="task.id"
          :class="[
            'swiper-slider--timeline tw-w-auto tw-mb-8',
            { 'swiper-slider--selected tw-z-30': taskIsSelected(task.id)}
          ]"
        >
          <div :class="['tw-inline-block', { 'ml-body': index === 0}]">
            <div :data-swiper-parallax="-20" class="tw-mb-3 tw-ml-5">
              <p class="tw-mb-2 tw-text-sm leading-15 tw-font-bold opacity-31">
                Due {{ task.end_tym | toJsDate | moment('Do MMMM') }}
                <br>
                ({{ task.end_tym | toJsDate | moment('from', 'now') }})
              </p>
              <div class="tw-flex tw-items-center">
                <div class="tw-rounded-full tw-bg-black tw-w-3 tw-h-3 tw-shadow-app tw-mr-6 tw-flex-shrink-0"></div>
                <div
                  class="tw-flex-shrink-0 opacity-11 tw-border tw-border-black transition-fast slide-swiper__date-timeline-divider"
                ></div>
              </div>
            </div>
            <BaseCard
              :class="[
                'task-card task-card--swiper transition-fast tw-flex tw-flex-col tw-justify-between tw-bg-white',
                { 'task-card--selected': taskIsSelected(task.id)},
              ]"
              @click.native="selectTask(task.id)"
            >
              <div>
                <h4 class="task-card__name transition-fast">
                  {{ task.task_name }}
                </h4>
                <p v-html="htmlizeLinksInString(task.description)" class="task-card__description tw-text-xs tw-leading-none opacity-78 tw-truncate tw-mx-2 tw-mb-3"></p>
                <!-- RECENT TASK ACTIVITY! -->
              </div>

              <div class="tw-mb-1 tw-px-3">
                <!-- <h5 v-if="subGoals !== false" class="transition-fast tw-text-xs leading-15 tw-mx-2 tw-mt-2 task-card__goal"> -->
                  <!-- <span class="tw-font-bold">Goal</span><br> -->
                  <!-- {{ task.goal }} -->
                  <!-- {{ taskGoal(task.subid) }} -->
                <!-- </h5> -->
                <transition name="new" mode="in-out" appear>
                  <BaseButton
                    v-show="taskIsSelected(task.id)"
                    text="Mark Completed"
                    class="tw-text-white tw-w-full tw-bg-black tw-block tw-px-6 tw-py-3 tw-mt-4 tw-border-none box-content transition focus:tw-shadow-app-lg focus:tw-bg-gray-900"
                    @click="markTaskCompleted(task.id)"
                  />
                </transition>
              </div>

            </BaseCard>

          </div>
        </swiper-slide>
      </swiper>
    </transition>
  </div>
</template>

<script>
import { apiPost, generateFormData } from '@/modules/apiHelper';
import { toBoolean } from '@/modules/dataHelper';
import { htmlizeLinksInString } from '@/modules/dataHelper';

export default {
  name: 'MenteeCurrentTasks',
  props: {
    ongoingTasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTaskId: 9999,
      subGoals: [],
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        parallax: true,
        freeMode: true,
        grabCursor: true,
      },
    };
  },
  computed: {
    tasks() {
      return this.ongoingTasks;
    },
    hasOngoingTasks() {
      return this.ongoingTasks.length > 0;
    },
  },
  methods: {
    taskGoal(goalid) {
      // console.log('getting task\'s goal ', this.ongoingTasks);
      const goal = this.subGoals.find(g => g.id === goalid);
      return goal ? goal.goal : '';
    },
    taskIsSelected(taskId) {
      return this.selectedTaskId === taskId;
    },
    selectTask(taskId) {
      if (this.taskIsSelected(taskId)) {
        // show task details
        const task = this.tasks.find(t => t.id === taskId);
        this.viewTaskDetails(task);
        return true;
      }
      console.log('selecting task');
      this.selectedTaskId = taskId;
      return true;
    },
    htmlizeLinksInString(str) {
      return htmlizeLinksInString(str);
    },
  },
  async created() {
    await this.$nextTick();
    // get sub_goals data
    // TODO: SUB GOALS BY COMPANY ID
    apiPost('company-subgoals', generateFormData({ companyid: this.$store.state.User.companyid }), 5)
      .catch((err) => {
        console.warn('Error in getting sub_goals', err);
      })
      .then((res) => {
        if (!res) return false;

        if (toBoolean(res.error)) {
          this.$toasted.global.appError({ errorMessage: 'Error in finding a mentor' });
          this.subGoals = false;
          return false;
        }

        this.subGoals = res.data['company-subgoals'];
        return true;
      });
  },
  async mounted() {
    // console.log(this.tasks);
    this.$refs['ongoing-tasks-slider'].swiper.destroy(false, true);
    this.$refs['ongoing-tasks-slider'].swiper.init();
    await this.$nextTick();
    if (this.tasks.length > 0) {
      this.selectTask(this.tasks[0].id);
    }
  },
};
</script>

<style>
</style>
