<template>
  <div class="tw-rounded-lg tw-h-auto">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
};
</script>

<style>

</style>
