<template>
  <AppView
    padd-bottom
  >
    <TopNav
      slot="top-app-view"
      title="Frequently Asked Questions For Mentees"
      title-class="lg:ml-body lg-app:tw-font-bold"
      type="menu"
      @input="openSideNav()"
    />

    <FAQMentor />
  </AppView>
</template>

<script>
import FAQMentor from '@/views/_partials/FAQMentee.vue';

export default {
  name: 'FAQMenteeView',
  components: {
    FAQMentor,
  },
};
</script>

<style>

</style>
