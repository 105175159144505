import resolve from '../../../modules/api/resolve';
import { apiPost, generateFormData, apiStore } from '../../../modules/apiHelper';
import { isStaleData, toBoolean } from '../../../modules/dataHelper';

export default {
  // get logged in user goals
  async getUserGoals(context) {
    const formData = generateFormData({
      userid: context.state.id,
    });

    const userType = context.state.type;
    const { userTypeName } = context.getters;

    // ! needs to be one. ERROR!!!
    const apiCall = (userType === 3)
      ? apiPost('goals_check', formData)
      : apiPost('sub_goals_check', formData, 1);

    const goals = await apiCall
      .catch((err) => {
        console.warn('getUserGoals\nCannot get user goals\n', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const userGoals = res.data.goals_check || [];
        const addUserGoalMutationName = userType === 3
          ? 'addMentorGoal'
          : 'addMenteeGoal';
        //

        userGoals.forEach((goal) => {
          context.commit(addUserGoalMutationName, goal.ementid); // ementid is the selected goal's goal id
        });
        await apiStore.setItem(`${userTypeName}goals`, context.state[userTypeName].goals);

        return userGoals;
      });

    return goals;
  },
  async menteeFinishedGoals(context, menteeId) {
    const menteeid = menteeId || context.rootState.User.id;

    if (!menteeid) {
      return;
    }

    const companyid = context.rootState.User.companyid;

    const menteeFinishedGoals = resolve(apiPost('end_goal_log', generateFormData({ companyid }), 38))
      .then((res) => {
        if (!res) return false;

        // filter only mentee's data
        return res.end_goal_log.filter((goal) => goal.menteeid == menteeid);
      });
    //

    return menteeFinishedGoals;
  },
  // async getCompanyGoalsBadges(context, [CompanyId, refresh = false]) {
  //   const companyid = CompanyId || context.rootGetters.userCompanyId;
  //   if (!companyid) return false;

  //   const companyGoalsBadges = await apiStore.getItem(`goalsbadges/${companyid}`) || [0, []];

  //   if (!refresh && !isStaleData(companyGoalsBadges[0]) && companyGoalsBadges[1].length) return goals[1];

  //   const result = await resolve(apiPost('goal_badge_list', generateFormData({ companyid }), 58))
  //     .then((res) => {
  //       if (!res) return false;
  //       const companyGoalsBadges = res.goal_badge_list || [];

  //       if (companyGoalsBadges.length > 0) {
  //         apiStore.setItem(`goalsbadges/${companyid}`, [now(), companyGoalsBadges]);
  //       }

  //       return companyGoalsBadges;
  //     });
  //   //

  //   return result;
  // },
  async getUserGoalsBadges(context, userId) {
    const formData = generateFormData({
      userid: userId || context.state.id,
    });

    const userGoalsBadges = await resolve(apiPost('goal_badge_user', formData, 58));

    if (userGoalsBadges) {
      return userGoalsBadges.goal_badge_user;
    }

    return [];
  },
};
