var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-relative tw-w-full tw-py-1"},[(_vm.isResourceRefOnly)?_c('ResourceChatBubble',{attrs:{"resource":_vm.resource,"message":_vm.message,"text-is-self":_vm.textIsSelf,"read":_vm.read,"is-link-resource-ref":_vm.isLinkResourceRef}}):_c('div',{class:[
      'chat-bubble tw-overflow-visible tw-fill-current',
      { 'chat-bubble--right mr-body lg:tw-mr-2' : _vm.textIsSelf },
      { 'chat-bubble--left ml-body lg:tw-ml-2 tw-bg-app-primary-blue tw-text-app-light-blue' : !_vm.textIsSelf },
      { 'tw-rounded-tl-32' : _vm.isTaskRef && _vm.textIsSelf },
      { 'tw-rounded-tr-32' : _vm.isTaskRef && !_vm.textIsSelf },
    ]},[(_vm.isTaskRef)?_c('TaskChatRef',{attrs:{"resource":_vm.resource,"text-is-self":_vm.textIsSelf}}):_vm._e(),_c('div',{staticClass:"tw-overflow-auto tw-relative"},[_c('p',{staticClass:"tw-text-sm opacity-87 leading-17 tw-mt-2 tw-mx-2 tw-break-words tw-whitespace-pre-wrap",domProps:{"innerHTML":_vm._s(_vm.htmlizeLinksInString(_vm.message.chat_message || '...'))}}),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('span',{class:[
            'tw-mx-2 tw-my-2 tw-text-xs leading-14 tw-font-light tw-block tw-flex-grow',
            { 'tw-text-right opacity-31' : _vm.textIsSelf },
            { 'tw-text-left opacity-54' : !_vm.textIsSelf },
          ]},[_vm._v(_vm._s(_vm._f("moment")(_vm._f("toJsDate")(_vm.message.tym),'ddd Do hh:mm A')))]),(_vm.textIsSelf)?_c('span',{class:[
          'tw-fill-current tw-my-1 tw-flex-shrink-0 tw-pr-2',
          { 'tw-text-green-400': _vm.read},
          { 'tw-text-gray-500': !_vm.read},
          ]},[_c('svg',{staticClass:"tw-h-3 tw-w-3 transform-scale-0",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('g',[_c('path',{attrs:{"d":"M34.6,58.7c-0.8,0-1.6-0.4-2.2-1l-16.7-18c-1.2-1.3-1.1-3.4,0-4.7c1.2-1.3,3.1-1.2,4.3,0l14.5,15.6l32.1-36.4\n                c1.1-1.3,3.1-1.4,4.3-0.2c1.2,1.2,1.3,3.3,0.2,4.7L36.9,57.7C36.3,58.3,35.5,58.7,34.6,58.7L34.6,58.7z"}}),_c('path',{attrs:{"d":"M35.2,40.9l19.1-21.7c1-1.1,1.2-2.9,0.4-4.2c-1.1-1.8-3.4-1.9-4.7-0.4L30.5,36.7c-1.1,1.2-1.1,3.1,0.2,4.3l0,0\n                C31.9,42.3,34,42.2,35.2,40.9z"}}),_c('path',{attrs:{"d":"M22.1,52.8L5.8,35.1c-1-1.1-2.4-1.4-3.8-1c-2.1,0.8-2.6,3.3-1.2,4.8L17.4,57c1.2,1.4,3.4,1.4,4.6,0l0,0\n                C23.2,55.9,23.2,54,22.1,52.8z"}})])])]):_vm._e()])]),(_vm.textIsSelf)?_c('RightTip'):(!_vm.textIsSelf)?_c('LeftTip'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }