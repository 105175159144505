<template>
  <div v-if="!isMobileView">
    <!-- <ContentLoader :class="['tw-mb-4', listClasses[i]]" v-for="(list, i) in Lists" :key="i">

    </ContentLoader> -->

    <ContentLoader
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      :height="200"
      :width="820"
      :speed="2"
      :class="['tw-mb-12 transform-scale-0 pl-body tw-w-full', listClasses[i]]" v-for="(list, i) in lists" :key="i"
    >
      <rect x="76" y="20" rx="3" ry="3" width="150" height="24" />
    <circle cx="30" cy="30" r="30" />
    <rect x="0" y="80" rx="3" ry="3" width="238" height="60" />
    <circle cx="238" cy="110" r="30" />
    <rect x="358" y="112" rx="0" ry="0" width="146" height="16" />
    <rect x="358" y="136" rx="0" ry="0" width="99" height="16" />
    <rect x="514" y="112" rx="0" ry="0" width="146" height="16" />
    <rect x="514" y="136" rx="0" ry="0" width="99" height="16" />
    <rect x="358" y="86" rx="0" ry="0" width="42" height="8" />
    <rect x="6" y="165" rx="0" ry="0" width="86" height="28" />
    </ContentLoader>
  </div>
  <div v-else>
    <ContentLoader
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      :height="200"
      :width="375"
      :speed="2"
      :class="['tw-mb-4 transform-scale-0', listClasses[i]]" v-for="(list, i) in lists" :key="i"
    >
      <rect x="84" y="24" rx="3" ry="3" width="116" height="16" />
      <rect x="32" y="140" rx="3" ry="3" width="100" height="16" />
      <circle cx="48" cy="32" r="16" />
      <rect x="0" y="60" rx="0" ry="0" width="310" height="60" />
      <circle cx="309.29" cy="90" r="30" />
      <rect x="170" y="140" rx="3" ry="3" width="100" height="16" />
      <rect x="32" y="163" rx="3" ry="3" width="44" height="16" />
      <rect x="170" y="163" rx="3" ry="3" width="44" height="16" />
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoadingUserList',
  components: {
    ContentLoader,
  },
  data() {
    return {
      lists: Array(3),
      listClasses: ['', 'opacity-78', 'opacity-31'],
    };
  },
};
</script>

<style>

</style>
