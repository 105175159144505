<template>
  <BaseCard
    :class="[
      'info-card tw-bg-transparent-- tw-shadow-app tw-overflow-auto tw-flex tw-flex-col',
      { 'tw-cursor-pointer': showTaskDetailsOnClick },
      { 'tw-bg-green-100': taskIsOnReview(task.status) },
      ]"
    @click.native="clickTask(task)"
  >
    <p
      v-if="dueDateRef && showDue"
      :class="[
        dueClass,
        'tw-text-sm leading-17 tw-m-2 tw-mb-0',
      ]"
    >
      {{ duePrefix }} {{ task[dueDateRef] | toJsDate | moment('from', 'now') }}
    </p>
    <div class="tw-mt-2">
      <p
        v-if="taskIsOnReview(task.status) && showOnReviewBadge"
        class="tw-text-xs tw-flex-grow-0 tw-flex-shrink tw-fill-current tw-align-middle leading-15 tw-inline-flex tw-items-center tw-rounded-r-full tw-ml-0 tw-pl-4 tw-pr-2 tw-mb-2 tw-mt-0 tw-bg-blue-500 tw-py-1 tw-px-2 tw-text-white"
      >
        <svg version="1.1" class="tw-w-4 tw-h-4 tw-mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M36,72c-9.5,0-18.7-3.8-25.4-10.6c-14-14-14-36.9,0-50.9C17.3,3.8,26.5,0,36,0s18.7,3.8,25.4,10.6c14,14,14,36.9,0,50.9
            C54.7,68.2,45.5,72,36,72z M36,3.6c-8.6,0-16.7,3.4-22.9,9.5C0.5,25.7,0.5,46.3,13.1,59c6.1,5.9,14.2,9.4,22.9,9.4
            s16.7-3.4,22.9-9.5l0,0c12.6-12.6,12.6-33.1,0-45.9C52.7,7,44.6,3.6,36,3.6z M38.7,22.5c-0.7,0.7-1.4,0.9-2.3,0.9
            c-0.9,0-1.8-0.4-2.3-0.9c-0.7-0.7-0.9-1.4-0.9-2.3s0.4-1.8,0.9-2.3c0.7-0.7,1.4-0.9,2.3-0.9c0.9,0,1.6,0.4,2.3,0.9
            c0.7,0.7,0.9,1.4,0.9,2.3S39.4,22,38.7,22.5z M33.8,53.3V29c0-0.4,0.4-0.7,0.7-0.7h3.6c0.4,0,0.7,0.4,0.7,0.7v24.5
            c0,0.4-0.4,0.7-0.7,0.7h-3.6C34.2,54,33.8,53.6,33.8,53.3z"/>
        </svg>
        Review Stage
      </p>
    </div>
    <p :class="['tw-mx-2 tw-mb-2 tw-text-base lg:tw-text-lg leading-19 lg-app:tw-leading-snug', { 'tw-mt-2': !showDue  }]">{{ task.task_name }}</p>
    <p v-if="task?.goal?.id" class="tw-text-sm tw-mx-2 tw-mb-2 opacity-54">Goal Name: <span class="tw-text-app-primary-blue tw-font-bold">{{task?.goal?.goal}}</span></p>
    <p
      v-show="fullWidthOnLarge"
      style="max-height: 4rem;"
      class="tw-mx-2 tw-mb-4 tw-overflow-hidden tw-text-xs lg-app:tw-text-sm leading-14 lg-app:tw-leading-tight opacity-31"
    >
      {{ task.description }}
    </p>
    <div class="tw-flex-grow">
      <!-- Mentor -->
      <UserPhoto
        v-if="showMentor"
        class="tw--mt-5 tw-ml-2"
        photo-size="tw-w-5 tw-h-5"
        :photo="mentor.photo"
        :user-name="mentor"
        :title="`mentor: ${mentor.name} ${mentor.middle}`"
      />
    </div>
    <div class="tw-flex tw-items-end tw-mt-3">
      <div class="tw-flex-1 opacity-54">
        <p v-show="fullWidthOnLarge" class="tw-text-app-deep-blue tw-text-sm leading-17 tw-ml-2 tw-font-bold tw-hidden lg:tw-block">
          {{ task.end_tym | moment('diff', task.start_tym) | duration('humanize') }}
        </p>
        <!-- TASK TIME SPAN -->
        <p v-if="showDate" :class="[dateClass, 'tw-text-sm leading-17 tw-mb-2 tw-ml-2', { 'tw-mb-4': fullWidthOnLarge }]">
          <span>{{ task.start_tym | moment('Do MMMM') }}</span>
          <span v-if="isMobileView || (!isMobileView && !fullWidthOnLarge)"><br>|<br></span>
          <span v-else> - </span>
          <span>{{ task.end_tym | moment('Do MMMM') }}</span>
        </p>
      </div>
      <div class="tw-flex-initial">
        <slot name="action"></slot>
        <button v-if="showCommentButton"
          class="info-card__action-info info-card__action-info--right tw-fill-current tw-px-2 tw-py-1 tw-inline-block transition-fast hover:tw-bg-blue-100"
          @click.prevent="refTaskInChat(isMentee ? task.mentorid : task.userid, task)"
        >
          <svg version="1.1" class="tw-w-6 tw-h-6 opacity-54 tw-inline-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
              c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
              c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
              C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
              c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
              M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
              S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
          </svg>
          <span v-show="isDesktopView && fullWidthOnLarge" class="tw-px-2 opacity-78 tw-inline-block tw-text-sm leading-17 tw-uppercase">COMMENT</span>
        </button>
      </div>
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: 'TaskCard',
  props: {
    task: {
      type: Object,
      required: true,
    },
    taskUserId: {
      type: [Number, String],
      required: false, // made to false because of the error I get when logging out
    },
    dueClass: {
      type: String,
      required: false,
    },
    dateClass: {
      type: String,
      required: false,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    showDue: {
      type: Boolean,
      default: true,
    },
    showCommentButton: {
      type: Boolean,
      default: true,
    },
    showOnReviewBadge: {
      type: Boolean,
      default: true,
    },
    showMentor: {
      type: Boolean,
      default: true,
    },
    duePrefix: {
      type: String,
      required: false,
    },
    dueDateRef: {
      type: String,
      required: false,
    },
    fullWidthOnLarge: {
      type: Boolean,
      default: false,
    },
    showTaskDetailsOnClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mentor: {},
    };
  },
  computed: {
    loadedMentor() {
      return Object.keys(this.mentor).length > 0;
    },
  },
  methods: {
    clickTask(task) {
      if (this.showTaskDetailsOnClick) {
        this.$emit('click-task', task, this.taskUserId);
        this.viewTaskDetails(task);
      }
    },
    // clickComment(task) {
    //   console.log(task);
    //   this.$emit('clickCommentTask', task.userid, task);
    // },
  },
  async created() {
    // get the mentor
    if (this.showMentor) {
      const {
        // eslint-disable-next-line camelcase
        name, middle, photo, last_name,
      } = await this.$store.dispatch('getUserData', this.task.mentorid);

      this.mentor = {
        name, middle, photo, last_name,
      };
    }
  },
};
</script>

<style>
</style>
