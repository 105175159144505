<template>
  <button
    v-if="type === 'submit'"
    :class="[
      'my-btn tw-fill-current',
      { 'tw-px-0': icon },
      { 'tw-pl-4': iconRight },
    ]"
    :type="type"
  >
    <slot v-if="icon" class="my-btn__icon" name="icon"></slot>
    <router-link v-if="to" :to="to">{{ text }}</router-link>
    <span class="my-btn__text tw-align-middle" v-else>{{ text }}</span>
  </button>
  <a
    v-else-if="type === 'link'"
    :class="[
      'my-btn tw-fill-current',
      { 'tw-px-0': icon },
      { 'tw-pl-4': iconRight },
    ]"
  >
    <slot v-if="icon" class="my-btn__icon" name="icon"></slot>
    <span class="my-btn__text tw-align-middle">{{ text }}</span>
  </a>
  <router-link
    tag="button"
    v-else-if="to"
    :class="[
      'my-btn tw-fill-current',
      { 'tw-px-0': icon },
      { 'tw-pl-4': iconRight },
    ]"
    :type="type"
    :to="to"
  >
    <slot v-if="icon && !iconRight" class="my-btn__icon" name="icon"></slot>
    <span class="my-btn__text tw-align-middle">{{ text }}</span>
    <slot v-if="icon && iconRight" class="my-btn__icon" name="icon"></slot>
  </router-link>
  <button
    v-else
    :class="[
      'my-btn tw-fill-current',
      { 'tw-px-0': icon },
      { 'tw-pl-4': iconRight },
    ]"
    :title="text"
    :type="type"
    @click="btnClick()"
  >
    <slot v-if="icon && !iconRight" class="my-btn__icon" name="icon"></slot>
    <span class="my-btn__text tw-align-middle">{{ text }}</span>
    <slot v-if="icon && iconRight" class="my-btn__icon" name="icon"></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    btnClick() {
      this.$emit('click');
    },
  },
};
</script>

<style>

</style>
