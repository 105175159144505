<template>
  <div>
    <ContentLoader
      :height="310"
      :width="375"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      class="transform-scale-0"
    >
      <rect x="36" y="24" rx="5" ry="5" width="146" height="130" />
      <rect x="36" y="164" rx="5" ry="5" width="146" height="130" />
      <rect x="192" y="24" rx="5" ry="5" width="146" height="130" />
      <rect x="192" y="164" rx="5" ry="5" width="146" height="130" />
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoadingTwoGrid',
  components: {
    ContentLoader,
  },
};
</script>

<style>

</style>
