import { buildApiEndpoint } from '../apiHelper';

export default function ($vm) {
  if (!navigator.sendBeacon) {
    $vm.$store.dispatch('postUserLastSeen');
  }

  const url = buildApiEndpoint('last_seen', 1);
  // Create the data to send
  const userId = $vm.$store.state.User.id;

  if (userId) {
    const data = new FormData();
    data.set('userid', userId);

    // Send the beacon
    navigator.sendBeacon(url, data);
    // const status = navigator.sendBeacon(url, data);

    // Log the data and result
    // console.log('sendBeacon: URL = ', url, '; status = ', status);
  }
}
