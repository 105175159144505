<template>
  <BaseButton
    v-if="isMentee && taskIsOnReview(taskStatus)"
    text="Reviewing stage"
    class="tw-text-black tw-bg-gray-300 tw-cursor-default"
    :disabled="true"
  />
  <BaseButton
    v-else-if="isMentor && taskIsOnReview(taskStatus) && !isGroupTask"
    text="Review Task"
    class="tw-text-white tw-bg-black tw-border-none transition focus:tw-shadow-app-lg focus:tw-bg-gray-900"
    :to="{ name: 'ReviewTask', params: { id: taskId } }"
  />
  <BaseButton
    v-else-if="(isMentee || isMentor) && taskIsCompleted(taskStatus)"
    text="Completed"
    class="tw-text-white tw-bg-green-500"
    :disabled="true"
  />
  <BaseButton
    v-else-if="isMentee"
    text="Mark Completed"
    class="tw-text-white tw-bg-black tw-border-none transition focus:tw-shadow-app-lg focus:tw-bg-gray-900"
    @click="markTaskCompleted(taskId, isGroupTask)"
  />
</template>

<script>
export default {
  name: 'TaskButton',
  props: {
    taskStatus: {
      type: [String, Number],
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    isGroupTask: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>
