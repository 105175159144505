export default {
  name: 'matchUiMixin',
  methods: {
    async assertMatch(userId) {
      const isMatched = await this.$store.dispatch('assertMatch', userId);

      if (!isMatched) {
        this.$toasted.global.appError({
          errorMessage: 'Access denied!',
        });
        await this.$nextTick();
        this.$router.push({
          name: 'app',
        });
        await this.$nextTick();
        return false;
      }

      return true;
    },
  },
};
