<template>
  <div class="tw-overflow-auto">
    <div
      :class="[
        'tw-mt-2 tw-py-2 bg-black-11 tw-cursor-pointer',
        { 'tw-rounded-l-32 tw-pl-8 tw-pr-2 tw-ml-2' : textIsSelf },
        { 'tw-rounded-r-32 tw-pr-8 tw-pl-2 tw-mr-2' : !textIsSelf },
      ]"
      @click="viewTaskDetails(resource)"
    >
      <p class="tw-text-xs opacity-31 tw-uppercase">Task</p>
      <h4 class="tw-font-bold tw-text-sm leading-17 tw-mb-2 opacity-54">
        {{ resource.task_name }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskChatRef',
  props: {
    textIsSelf: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
