export default {
  name: 'visitorsMixin',
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.loading = true;

    // get landingpage data
    await this.$store.dispatch('getUiSettings', true);

    await this.$nextTick();

    this.loading = false;
  },
};
