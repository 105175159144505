<template>
  <div class="mx-body tw-mt-8 faq">
    <h1><strong>Introduction</strong></h1>
    <ol>
    <li><strong>What is KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <p>The KenGen Pass the Baton Mentoring Platform is an in-house mentoring space that connects mentors and mentees across KenGen to share knowledge, skills, motivation, and guidance for personal and career growth. The platform is hosted by eMentoring Africa (under the name <strong>eMentored</strong>) during the pilot period and is funded by USAID.</p>
    <ol start="2">
    <li><strong>Who can use t</strong><strong>he KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <p>KenGen Pass the Baton platform is accessible to all KenGen staff i.e. interns, new employees and other employees.</p>
    <ol start="3">
    <li><strong>How does the platform work?</strong></li>
    </ol>
    <p>The KenGen Pass the Baton Mentoring Platform is accessible online via the URL - <a href="https://kengen.ementored.com/">https://kengen.ementored.com/</a>. Mentors and mentees on the platform are able to chat, share resources and track progress via the platform&rsquo;s features.</p>
    <p><strong>Mentor</strong></p>
    <p>The KenGen Pass The Baton Mentoring Platform is accessible online via the URL - <a href="https://kengen.ementored.com/">https://kengen.ementored.com/</a>. As a mentor, you will provide mentorship to mentees through a matching process based on the goals you selected when signing up and also on the Expertise tab. You will receive a match request(s) and upon accepting a mentee, you&rsquo;ll mentor them by creating tasks, sharing resources to guide them in completion of the tasks and communicating with them through a chat functionality on the platform. Upon completion of three tasks, you will end the match and complete a survey as feedback.</p>
    <ol start="4">
    <li><strong>How do I sign up for the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <ul>
    <li>To sign up as a <strong>MENTOR</strong> on the platform, <a href="https://kengen.ementored.com/mentor-signup"><strong>Click Here</strong></a>. Follow the on-screen instructions by filling in the form with your details. Remember to get the <strong>Secret Key</strong> from your Admin assistant.</li>
    <li>Once signed up, ensure to <strong>VERIFY</strong> your account via email or SMS sent to you by <strong>eMentored</strong>. Follow the link in the verification email or SMS and <strong>LOG IN</strong> with your <strong>username</strong> and <strong>password</strong> created during the sign-up.</li>
    </ul>
    <h1><strong>Getting Started</strong></h1>
    <ol>
    <li><strong>How do I create a profile on the platform?</strong></li>
    </ol>
    <p>After logging in, you will be required to create your profile. You can do this by following the Step-by-Step process as outlined below:</p>
    <ul>
    <li>Write your <strong>BIO </strong>and upload a good <strong>Profile Photo</strong> of yourself.</li>
    <li>Choose the <strong>PILLARS</strong> &amp; <strong>GOALS</strong> in your field of competency to mentor.</li>
    </ul>
    <p><strong><em>Note</em></strong><em> &ndash; Do not forget to read and sign the <strong>Code of Conduct</strong> before starting the mentorship.</em></p>
    <ol start="2">
    <li><strong>How do I find a mentee on the platform?</strong></li>
    </ol>
    <p>Mentees will send a <strong>MATCH REQUEST</strong> which you will see in the Notifications. Once you receive a match request, you can either Accept or Reject the request.</p>
    <p><strong><em>Note</em></strong><em> &ndash; mentor and mentee matches are only formed between mentors and mentees within the same pillars and goals. </em></p>
    <p><em>If you choose to Accept the match request, you can view your list of mentees via the <strong>My Mentees</strong> tab. </em></p>
    <ol start="3">
    <li><strong>How do I communicate with my mentee?</strong></li>
    </ol>
    <p>To communicate with your mentee, go to <strong>My Mentees</strong> tab and click on one of your mentees. A conversation log will appear and you can chat easily.</p>
    <ol start="4">
    <li><strong>How do I upload resources for my mentee?</strong></li>
    </ol>
    <p>To upload resources for your Mentee, go to <strong>Resources</strong> tab and click <strong>Upload</strong>. You can upload documents in PDF or MS Office format and links.</p>
    <p>Your Mentee will be able to access these and use them during your mentorship journey.</p>
    <ol start="5">
    <li><strong>Where can I access my Resources?</strong></li>
    </ol>
    <p>Under the <strong>Resource</strong> tab, you can also access resources to use as a <strong>guide</strong> in your mentorship journey. Every resource is goal-specific and provides detailed information about the goal including sample tasks and other resources to share with your mentee that are relevant to the goal.</p>
    <p>&nbsp;</p>
    <ol start="6">
    <li><strong>How do I create Tasks for my mentee?</strong></li>
    </ol>
    <ul>
    <li>Open a <strong>Mentee</strong> <strong>Chat</strong> and click on <strong>TASKS &gt; CREATE TASK.</strong> Following the onscreen instructions, give the task a title and description of what you want the mentee to do.</li>
    <li>You can proceed to attach a <strong>Resource</strong> that will be used by the mentee when undertaking the task.</li>
    <li>Finally, indicate the <strong>Start Time</strong> and <strong>End Time</strong>e., when they should begin and when the task is due, using the calendar provided, and <strong>SAVE</strong>.</li>
    <li>You can track the <strong>progress</strong> of each task by switching between goals to view.</li>
    <li>When a task is <strong>complete</strong>, you are required to <strong>Review</strong>. After careful review of the task, you are required to either <strong>Approve</strong> or <strong>Revert</strong>. Approve the task when the task has been completed correctly and accurately and revert the task when you need the mentee to work on it again. Ensure to give comments when reverting a task.</li>
    </ul>
    <h1><strong>Account and Profile Management</strong></h1>
    <ol>
    <li><strong>How do I manage my account information on the platform?</strong></li>
    </ol>
    <p>You can review your personal details and edit them if you have an error or need to update.</p>
    <ol start="2">
    <li><strong>How do I change my password on the platform?</strong></li>
    </ol>
    <p>To change your password, click on <strong>Forgot Password </strong>on the Log In page. A link will be sent to your email for you to reset your password and log back in.</p>
    <p><strong><em>Note</em></strong><em> &ndash; Do not share your password with anyone to avoid compromising your account. </em></p>
    <h1><strong>Technical Issues</strong></h1>
    <ol>
    <li><strong>What do I do if I can't log in to the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <p>If you have trouble logging into the platform, try resetting your password via the <strong>Forgot Password</strong> button on the login page.</p>
    <p>&nbsp;</p>
    <p>If the issue persists, contact <strong>Support</strong> via <strong>Jivo Chat</strong> on the screen. An admin will assist in less than 24hrs.</p>
    <ol start="2">
    <li><strong>What do I do if I'm having trouble accessing the platform?</strong></li>
    </ol>
    <p>Contact <strong>Support</strong> via <strong>Jivo Chat</strong> on the screen to report the issue and you will receive assistance.</p>
    <ol start="3">
    <li><strong>What do I do if I can't upload files on the platform?</strong></li>
    </ol>
    <ul>
    <li>First, ensure you are uploading the correct <strong>formats</strong> recommended i.e., PDF, Word doc, or Link.</li>
    <li>If the issue has nothing to do with the format of the files, try refreshing your page and attempt to upload files again.</li>
    <li>Still nothing? Contact support via <strong>ELLY</strong> or E<strong>BBY </strong></li>
    </ul>
    <h1><strong>Code of Conduct</strong></h1>
    <ol>
    <li><strong>What is the Code of Conduct for the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <p>The Code of Conduct outlines the rules and regulations that a mentor should follow during the mentorship.</p>
    <ol start="2">
    <li><strong>What behaviour is expected of mentors and mentees on the platform?</strong></li>
    </ol>
    <ul>
    <li>Mentors are required to be professional in their communication with mentees and to conduct all mentorship sessions in a respectful and sober manner that is free of all forms of discrimination and bias.</li>
    <li>Mentors are also advised to create a conducive and friendly environment that will help the mentee to learn from them as much as possible.</li>
    <li>Ensure to carefully read the <strong>Code of Conduct</strong> form in the platform and sign it before proceeding with the mentorship.</li>
    </ul>
    <ol start="3">
    <li><strong>How do I report issues or concerns on the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <ul>
    <li>To report issues and concerns regarding the mentee, contact <strong>Support</strong> under <strong>EBBY</strong> or <strong>ELLY</strong> or approach the KenGen mentorship administrator for help.</li>
    </ul>
    <ol start="4">
    <li><strong>How do I give feedback on the </strong><strong>KenGen Pass the Baton Mentoring Platform?</strong></li>
    </ol>
    <p>To give any feedback, contact <strong>Support</strong>.</p>
    <ol start="5">
    <li><strong>How do I end a mentoring relationship on the platform?</strong></li>
    </ol>
    <p>If you would like to <strong>End a Match</strong>, contact <strong>Support</strong> with your request and justification.</p>
    <p>&nbsp;</p>
    <p>Thank you for using the KenGen Pass The Baton Mentoring Platform.</p>
    <p>If you have any additional questions or concerns, please reach out to our support team at <a href="mailto:mentor@ementoringafrica.or.ke"><strong>mentor@ementoringafrica.or.ke</strong></a><strong>.</strong></p>
  </div>
</template>

<script>
export default {
  name: 'FAQMentorPartial',
};
</script>

<style lang="scss" scoped>
.faq {

  * {
    font-size: 20px;
  }

  h2, h1 {
    font-size: 50px !important;
  }

  p {
    margin-bottom: 20px;
  }

  ol {
    line-height: 1.5;
    list-style-type: decimal;
    color: var(--primary-orange);
  }

  ul {
    list-style-type: disc;
    padding-left: 40px;
  }

  a {
    color: var(--primary-blue);
  }
}
</style>
