import resolve from "../../modules/api/resolve";
import { apiPost, apiStore, generateFormData } from "../../modules/apiHelper";
import { invalideDate, isStaleData, now } from "../../modules/dataHelper";
// import journeyActionsList from './journeyActionsList.json';

export default {
  state: {
    // journeyActionsList,
    currentActionPrompt: {},
    openPrompt: false,
  },
  mutations: {
    setCurrentActionPrompt(state, actionPrompt) {
      state.currentActionPrompt = actionPrompt;
    },
    showPrompt(state, show = true) {
      state.openPrompt = show;
    },
  },
  actions: {
    async getCurrentUserJourneyAction(context) {
      const userid = context.rootState.User.id;

      const action = await resolve(apiPost('curr_usr_act', generateFormData({ userid }), 57))
        .then(async (res) => {
          if (!res) {
            console.warn('getCurrentUserJourneyAction::journeys/index.js Error in getting badges');
            return false;
          }

          const currentActionPrompt = res.curr_usr_act[0];
          
          if (currentActionPrompt) {
            if (Number(currentActionPrompt.badgeid) && Number(currentActionPrompt.badge_type) !== 1) {
              // get badge
              currentActionPrompt.badge = await context.dispatch('getBadge', currentActionPrompt.badgeid);
            }

            context.commit('setCurrentActionPrompt', currentActionPrompt);

            // only show unrevealed prompts
            if (Number(currentActionPrompt.status) == 0) {
              context.commit('showPrompt', true);
            }
          } else {
            context.commit('setCurrentActionPrompt', {});
          }

          return currentActionPrompt;
        });
      //

      return action;
    },
    async markCurrentJourneyActionAsRevealed(context) {
      context.commit('showPrompt', false);

      // const formData = generateFormData({
      //   userid: context.rootState.User.id,
      //   actid: context.state.currentActionPrompt.id,
      // });
      const formData = {
        userid: context.rootState.User.id,
        actid: context.state.currentActionPrompt.id,
      };

      const result = await resolve(apiPost('tsl_ken_jan_log', JSON.stringify(formData), 3, {
        'Content-Type': 'application/json'
      }));

      if (result) {
        context.dispatch('getCurrentUserJourneyAction');
      }
    },
    async getBadge(context, badgeId) {
      const badges = await context.dispatch('getBadges');

      return badges.find((badge) => Number(badge.id) === Number(badgeId));
    },

    async getBadges(context, refresh = false) {
      const companyid = context.rootState.User.companyid;

      const companyBadges = await apiStore.getItem(`companyBadges/${companyid}`) || [invalideDate, []];

      if (!refresh && !isStaleData(companyBadges[0]) && companyBadges[1].length) return companyBadges[1];

      const badges = await resolve(apiPost('badge_list', generateFormData({ companyid }), 57))
        .then((res) => {
          if (!res) {
            console.warn('getBadges::journeys/index.js Error in getting badges');
            return false;
          }

          apiStore.setItem(`companyBadges/${companyid}`, [now(), res.badge_list]);

          return res.badge_list;
        });
      //

      return badges;
    },
  },
};
