import userdata from './userdata';
import auth from './auth';
import match from './match';
import tasks from './tasks';
import goals from './goals';
import resources from './resources';
import companyData from './company-data';

export default {
  ...auth,
  ...goals,
  ...userdata,
  ...match,
  ...tasks,
  ...resources,
  ...companyData,
};
