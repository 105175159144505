<template>
  <div class="tw-relative">
    <div class="tw-inline-flex tw-items-center tw-mx-auto tw-p-1 tw-text-green-700">
      <span>
        <svg class="tw-h-6 tw-w-6 tw-fill-current" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M59.9,23.9C59.9,10.7,49.3,0,36,0S12.1,10.7,12.1,23.9c0,8.5,4.4,15.7,10.9,20v24.8c0,1.3,0.9,2.4,2,3
            c1.1,0.4,2.6,0.2,3.5-0.7l7.6-7l7.6,7c0.7,0.7,1.3,0.9,2.2,0.9c0.4,0,0.9,0,1.3-0.2c1.1-0.4,2-1.7,2-3V43.9
            C55.6,39.6,59.9,32.4,59.9,23.9z M44.7,66.1L38.2,60c-1.3-1.1-3-1.1-4.4,0l-6.5,6.1v-20c2.6,1.1,5.7,1.7,8.7,1.7s6.1-0.7,8.7-1.7
            V66.1z M36,43.5c-10.9,0-19.6-8.7-19.6-19.6S25.1,4.4,36,4.4s19.6,8.7,19.6,19.6S46.9,43.5,36,43.5z"/>
        </svg>
      </span>
      <p>Goal completed</p>
    </div>
    <p class="tw-text-xs tw-w-1/2 tw-text-center tw-mx-auto" v-if="showTip">
      Tip: You can deselect this goal at "my goals" page and select any other of your choice to get mentored on
    </p>
  </div>
</template>

<script>
export default {
  name: 'CompletedGoal',
  props: {
    showTip: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style>

</style>