var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppView',[_c('TopNav',{attrs:{"slot":"top-app-view","title":"My Mentors","title-class":"lg:ml-body lg-app:tw-font-bold","center-title":"","type":"menu","show-notification-icon":""},on:{"input":function($event){return _vm.openSideNav()}},slot:"top-app-view"}),_c('transition',{attrs:{"name":"new","mode":"out-in","appear":""}},[_c('div',{staticClass:"tw-mx-auto px-body"},[_c('div',{staticClass:"tw-bg-gray-100 tw-rounded-10 tw-p-2 tw-mt-8 tw-shadow-lg"},[_c('p',{staticClass:"tw-text-sm opacity-54 tw-mb-2 tw-text-center"},[_vm._v("Your collected Badges")]),_c('UserGoalsBadges',{attrs:{"tiny":""}})],1)])]),_c('transition',{key:"my-meetings-list",attrs:{"mode":"out-in","name":"fade","appear":""}},[(_vm.meetings.length > 0)?_c('div',{staticClass:"px-body tw-py-4 tw-w-full tw-border-b"},[_c('div',[_c('h5',{staticClass:"opacity-54 tw-mb-2"},[_vm._v("Meetings with your mentors / mentorship groups")])]),_c('div',{staticClass:"tw-relative"},[_c('swiper',{key:_vm.meetingsSlideKey,ref:"my-meetings-slider",attrs:{"options":_vm.meetingsSwiperOptions}},[_vm._l((_vm.meetings),function({ participants, ...meeting },key){return _c('swiper-slide',{key:key,class:[
              'tw-shrink-0 tw-w-screen tw-pb-4 tw-h-auto',
            ],staticStyle:{"height":"auto"}},[_c('VideoMeetingCard',{staticClass:"tw-h-full",attrs:{"meeting":meeting,"participants":participants}})],1)}),(_vm.meetings.length > 3)?_c('button',{ref:"meetings-slider-button-prev",staticClass:"tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-ml-2 tw-left-0 tw-right-auto tw-z-20 tw-bg-app-light-blue",attrs:{"slot":"button-prev"},domProps:{"innerHTML":_vm._s(_vm.leftNav)},on:{"click":function($event){$event.stopPropagation();return _vm.meetingPrevSlide()}},slot:"button-prev"}):_vm._e(),(_vm.meetings.length > 3)?_c('button',{ref:"meetings-slider-button-next",staticClass:"tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-mr-2 tw-right-0 tw-left-auto tw-z-20 tw-bg-app-light-blue",attrs:{"slot":"button-next"},domProps:{"innerHTML":_vm._s(_vm.rightNav)},on:{"click":function($event){$event.stopPropagation();return _vm.meetingNextSlide()}},slot:"button-next"}):_vm._e()],2)],1)]):_vm._e()]),_c('transition',{key:"group-mentorships-list",attrs:{"mode":"out-in","name":"fade","appear":""}},[(_vm.groupMentorships.length > 0)?_c('div',{staticClass:"px-body tw-py-4 tw-w-full"},[_vm._l((_vm.groupMentorships),function(groupMentorship){return [_c('MentorshipGroup',{key:groupMentorship.id,attrs:{"mentorship-group":groupMentorship}})]})],2):_vm._e()]),_c('transition',{attrs:{"mode":"out-in","name":"fade","appear":""}},[(_vm.loading)?_c('ContentLoadingUserList',{staticClass:"tw-mt-12"}):(_vm.hasMentors && !_vm.loading)?_c('div',[_c('transition-group',{key:"mentors",staticClass:"lg:mx-body lg:tw-mt-6 tw-flex tw-flex-wrap",attrs:{"tag":"div","name":"new","mode":"out-in","appear":""}},[_vm._l((_vm.myMentorsSort),function({ user },key){return [_c('div',{key:`${user.photo}-${key}`,class:[
              'tw-mt-4 mentor-card',
              {
                'mentor-card--new tw-text-center tw-shadow-app-lg tw-py-8 lg-app:tw-px-6 mx-body lg-app:tw--mx-6 tw-mb-10 tw-rounded-32 tw-border-2' : !_vm.hasUserLastMessage(user.id),
                'my-bg-pattern tw-border-app-primary-blue tw-bg-black tw-text-app-primary-blue lg:tw-w-full' : !_vm.hasUserLastMessage(user.id),
                'lg:tw-w-1/2' : _vm.hasUserLastMessage(user.id),
              },
            ]},[_c('div',{staticClass:"mx-body lg:tw-mx-0 tw-inline-flex tw-items-center"},[_c('UserPhoto',{attrs:{"photo":user.photo,"user-name":user,"photo-size":"tw-w-8 tw-h-8 lg:tw-w-16 lg:tw-h-16"}}),_c('h4',{staticClass:"opacity-87 tw-tracking-wide tw-ml-2 lg:tw-ml-4 tw-text-xl font-title leading-24 lg:leading-tight"},[_vm._v(_vm._s(user.name)+" "+_vm._s(user.last_name))])],1),_c('div'),_c('transition-group',{attrs:{"name":"fade","tag":"div","mode":"out-in","appear":""}},[(_vm.hasUserLastMessage(user.id))?[_c('div',{key:"head",staticClass:"lg:tw-flex lg:tw-flex-row lg:tw-items-start lg-grid"},[_c('div',{staticClass:"lg-grid__col-2/3"},[_c('router-link',{class:[
                        'mr-body lg:tw-mr-0 pl-body lg:tw-pl-0 tw-flex sm:tw-inline-flex tw-items-center bg-black-11  tw-mt-2 tw-cursor-pointer',
                        'tw-py-4 lg:tw-py-2 transition-fast tw-pr-4 tw-rounded-r-full lg:tw-rounded-bl-full lg:tw--ml-4 lg:tw-pl-4 min-h-60 sm:tw-min-w-1/2'
                      ],attrs:{"tag":"div","to":{ name: 'mentorChat', params: { id: user.id } }}},[_c('div',{staticClass:"tw-flex-shrink-0 tw-relative"},[_c('svg',{class:[
                            'tw-h-6 tw-w-6 tw-inline-block opacity-54 tw-fill-current',
                            { 'tw-text-app-blue opacity-87': !_vm.userLastMessageIsRead(user.id) && !_vm.userLastMessageIsSelf(user.id) },
                          ],staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59\n                            c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6\n                            c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2\n                            C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8\n                            c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z\n                            M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4\n                            S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"}})]),(!_vm.userLastMessageIsRead(user.id) && !_vm.userLastMessageIsSelf(user.id))?_c('span',{staticClass:"tw-p-1 tw-rounded-full tw-mt-1 tw-bg-blue-500 tw-absolute tw-top-0 tw-right-0"}):_vm._e()]),_c('p',{class:[
                          'opacity-78 mentor-card__chat tw-text-xs tw-overflow-hidden tw-ml-4',
                          { 'tw-text-app-blue': !_vm.userLastMessageIsRead(user.id) && !_vm.userLastMessageIsSelf(user.id) },
                        ]},[(_vm.userLastMessageIsSelf(user.id))?_c('span',{staticClass:"tw-italic"},[_vm._v("You: ")]):_vm._e(),_c('span',{class:{
                            'tw-italic tw-font-bold': _vm.userLastMessageIsResource(user.id)
                          }},[_vm._v(" "+_vm._s(_vm.userLastMessageText(user.id))+" ")])])])],1),_c('div',{staticClass:"tw-mt-4 pl-body lg:tw-pl-16 lg-grid__col-1/3"})]),_c('div',{key:"divider",staticClass:"divider tw-mt-4 lg:tw-mt-6 tw-border mx-body lg:tw-mx-0 opacity-18--"})]:[(!_vm.hasUserLastMessage(user.id))?_c('p',{key:"ttl",staticClass:"tw-text-sm tw-mt-4 tw-mb-8"},[_vm._v(" NEW MENTOR! ")]):_vm._e(),_c('p',{key:"msg",staticClass:"tw-max-w-md tw-mx-auto tw-text-lg font-nunito tw-mb-8 tw-px-4"},[_vm._v(" You sent "+_vm._s(user.name)+" a request and "+_vm._s(_vm._f("genderize")(user.gender.id,1))+" has accepted to be your mentor. This is your chance to learn as much and grow! ")]),_c('a',{key:"btn",staticClass:"tw-bg-app-primary-blue tw-text-app-light-blue tw-border-none tw-py-3 tw-mb-2 tw-px-8 font-title tw-tracking-widest tw-shadow-app-lg",attrs:{"href":`${_vm.appUrl}/app/mentor/${user.id}/chat`}},[_vm._v(" Break the ice! ")])]],2)],1)]})],2),_c('div',{staticStyle:{"height":"6rem"}})],1):_c('div',{staticClass:"tw-text-center"},[_c('p',{staticClass:"opacity-54 tw-text-center tw-text-gray-700 tw-pt-48 tw-inset-x-1/2"},[_vm._v("Seems you have no mentors")]),_c('BaseButton',{staticClass:"tw-mx-auto tw-my-4 tw-bg-gray-200",attrs:{"text":"Find A Mentor","to":"/app/request-mentor"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }