<template>
  <AppView
    body-class="tw-flex tw-flex-col"
    padd-bottom
  >
    <TopNav
      slot="top-app-view"
      :class="{ 'tw-shadow-app' : isMobileView }"
      :title="`${user.name || ''} ${user.last_name || ''}`"
      center-title
      title-class="lg:ml-body tw-tracking-wide font-title"
      type="back"
      :prev-route="prevRoute"
      :show-back-button="true"
      :show-user-photo="false"
      @input="openSideNav()"
    >
      <UserPhoto
        v-if="user.photo"
        :photo="user.photo"
        photo-size="tw-h-6 tw-w-6"
        slot="title-left"
        class="tw-inline-block tw-mx-2"
      />
    </TopNav>

    <div class="tw-mt-8 lg:px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-2/3 lg:tw-pr-8 tw-relative">
        <div class="tw-relative tw-block">
          <template v-if="!isDesktopView">
            <!-- show when there's active upcoming meetings -->
            <!-- <div class="tw-fixed tw-top-[60px] tw-left-0 tw-w-full tw-p-5 tw-text-center tw-z-40">
              <p @click="showMeetings" class="tw-text-sm tw-uppercase tw-bg-app-primary-orange tw-inline-block tw-px-4 tw-py-2.5 tw-rounded-full tw-cursor-pointer">
                Upcoming Video Meetings (3)
              </p>
            </div> -->
          </template>

          <!-- <portal to="modal-body">
            <div class="tw-p-5">
              <h4 class="font-title tw-my-5">Upcoming Video Meetings <span v-if="meetings.length">({{meetings.length}})</span></h4>
              <VideoMeetingsList :meetings="meetings" />
            </div>
          </portal> -->
        </div>

        <div class="tw-z-0">
          <ChatPartial
            class="tw-pt-6"
            :id="userId"
            :lg-grid-on-input="true"
          />
        </div>
      </div>

      <div class="lg-grid__col-1/3">
        <!-- <template v-if="isDesktopView">
          <div class="tw-relative tw-block">
            <div class="tw-fixed tw-rounded-32 tw-bg-gray-200 tw-p-4 tw-max-h-[66vh] tw-overflow-auto nice-scrollbar">
              <h4 class="font-title tw-mb-5">Upcoming Video Meetings</h4>
              <VideoMeetingsList :meetings="meetings" />
            </div>
          </div>
        </template> -->
      </div>
    </div>
  </AppView>
</template>

<script>
import ChatPartial from '@/views/_partials/Chat.vue';
import VideoMeetingsList from '@/components/VideoMeetingsList.vue';

export default {
  name: 'Chat',
  components: {
    ChatPartial,
    VideoMeetingsList,
  },
  props: ['id', 'prevRoute'],
  data() {
    return {
      loading: true,
      error: undefined,
      user: {},
      userId: this.id || this.$route.params.id,
      meetings: []
    };
  },
  methods: {
    showMeetings() {
      this.$store.commit('openModal', [true]);
    },
    async getMyMeetings() {
      const meetings = await this.$store.dispatch('getMyActiveMeetings');

      // list only meetings that the user in chat is participating in
      this.meetings = meetings.filter((meeting) => {
        if (meeting.participants.find(p => Number(p.id) === Number(this.userId))) {
          return meeting;
        } 
      });
    }
  },
  async created() {
    await this.$nextTick();
    const isMatched = await this.assertMatch(this.userId);
    if (!isMatched) return false;

    return this.$store.dispatch('getUserData', this.userId)
      .catch((err) => {
        const errorMessage = 'Error in getting data. Please try again later.';
        console.error(errorMessage, err);
        this.$toasted.global.appError({ errorMessage });
        this.error = errorMessage;
      })
      .then(async (user) => {
        if (!user) return false;
        this.user = user;

        await this.getMyMeetings();

        this.loading = false;
        this.error = undefined;
        return user;
      });
  },
};
</script>

<style>

</style>
