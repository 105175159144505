export default {
  computed: {
    appLogoUrl() {
      const logoUrl = this.$store.state.settings.logo;

      if (/(resource\/)/.test(logoUrl)) {
        return this.userPhoto(logoUrl);
      }
      return logoUrl;
    },
    welcomeMessage() {
      return this.$store.state.settings.welcomeMessage;
    },
  },
};
