<template>
  <!-- // * NOTE: DON'T USE AppView COMPONENT, UNLESS <portal> -->
  <transition name="fade" mode="out-in">
    <div v-if="error">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center"> {{ error }} </p>
    </div>

    <div v-else-if="loading">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center">Loading...</p>
    </div>

    <div v-else class="tw-mt-8 px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-1/3 lg:tw-order-last">
        <div class="tw-border-app-deep-blue tw-border tw-p-4 tw-rounded-lg">
          <h6 class="tw-text-xs tw-ml-1 tw-mr-2 lg:tw-text-right">Mentorship Group</h6>
          <h2 class="font-title tw-mr-2 lg:tw-text-right tw-mb-2">{{mentorshipGroup.group_name}}</h2>
          <h4 class="leading-14 font-14 tw-text-gray-700 tw-mr-2 tw-mb-1 lg:tw-text-right" v-html="mentorshipGroup.description">
          </h4>

          <!-- TASKS INFO -->
          <!-- <transition name="fade" mode="out-in">
            <div class="tw-mt-4 tw-mb-8 tw-flex tw-flex-wrap">
              <div class="tw-w-1/2">
                <h4 class="leading-14 font-14 tw-text-gray-700 tw-mb-1">{{ totalSelectedGoalTasks }} {{'task' | pluralize(totalSelectedGoalTasks, 'tasks') }}</h4>
                <h4 v-show="!!totalTasksCompleted" class="leading-14 font-14 tw-text-gray-700 tw-mb-1">{{ totalTasksCompleted }} {{'task' | pluralize(totalTasksCompleted, 'tasks') }} completed</h4>
                <h4 v-show="!!totalTasksOverdue" class="leading-14 font-14 tw-text-red-700">{{ totalTasksOverdue }} {{'task' | pluralize(totalTasksOverdue, 'tasks') }} overdue</h4>
              </div>
              <div class="tw-w-1/2 tw-flex tw-flex-col tw-items-end">
                <div>
                  <p class="font-title tw-text-2xl leading-28 tw-text-right tw-font-extrabold">{{ totalTasksCompleted | percent(totalSelectedGoalTasks) }}</p>
                  <p class="font-title tw-text-2xl leading-17 tw-text-right tw-font-medium">complete</p>
                </div>
              </div>
            </div>
          </transition> -->
        </div>
      </div>

      <div class="lg-grid__col-2/3 lg:tw-pr-8">
        <!-- tasks LISTS -->

        <!-- ONGOING GOAL TASK (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksOngoing > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Ongoing Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOngoing }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                <!-- date-class="tw-text-white opacity-78" -->
              <!-- LIST GRID -->
              <ViewMore
                :limit="1"
                :list="ongoingTasks"
                v-slot="{ item: task }"
                more-btn-text="More Ongoing Tasks"
              >
                <GroupTaskCard
                  :task="task"
                  :full-width-on-large="true"
                  :show-task-details-on-click="true"
                  :show-mentor="false"
                  due-class="tw-text-green-500 tw-bg-blue-100 tw-inline-block tw-rounded-full tw-px-2 tw-py-1"
                  due-prefix="Due"
                  due-date-ref="end_tym"
                  date-class="tw-text-white-- opacity-78"
                  class="lg:tw-pt-2 tw-mb-4 tw-border tw-border-app-deep-blue tw-bg-app-deep-blue-- tw-text-white-- tw-shadow-app"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- OVERDUE GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksOverdue > 0" class="tw-overflow-x-visible tw-mt-10">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Overdue Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOverdue }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="overdueTasks"
                v-slot="{ item: task }"
              >
                <GroupTaskCard
                  :task="task"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-red-500"
                  due-prefix="Due"
                  due-date-ref="end_tym"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- UPCOMING GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksUpcoming > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Upcoming Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksUpcoming }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                :limit="2"
                :list="upcomingTasks"
                v-slot="{ item: task }"
              >
                <GroupTaskCard
                  :task="task"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-blue-400"
                  due-prefix="Starting"
                  due-date-ref="start_tym"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- REVIEW GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksOnReview > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Tasks on review</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOnReview }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                :limit="2"
                :list="tasksOnReview"
                v-slot="{ item: task }"
              >
                <GroupTaskCard
                  :task="task"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-blue-400"
                  due-prefix="Starting"
                  due-date-ref="start_tym"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- COMPLETED GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksCompleted > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs">Completed Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksCompleted }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="completedTasks"
                v-slot="{ item: task }"
              >
                <GroupTaskCard
                  :task="task"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-blue-400"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

      </div>
    </div>
  </transition>
</template>

<script>
import GroupTaskCard from '@/components/GroupTaskCard.vue';
import tasksListMixin from '@/mixins/taskslist';
import _uniqBy from 'lodash/uniqBy';

export default {
  name: 'MentorshipGroupTasks',
  components: {
    GroupTaskCard,
  },
  props: ['mentorshipGroup', 'groupId'],
  mixins: [tasksListMixin],
  data() {
    return {
      loading: true,
      error: undefined,
      tasks: [],
    };
  },
  computed: {
    tasksName() {
      return this.isMentor ? 'uniqueTasks' : 'tasks';
    },
    uniqueTasks() {
      return _uniqBy(this.tasks, 'task_name');
    },
  },
  async created() {
    const request = this.isMentor
      ? this.$store.dispatch('getGroupTasks', this.groupId)
      : this.$store.dispatch('getMyGroupTasks', this.groupId);
    //
    const request2 = this.isMentor
      ? this.$store.dispatch('getEndedGroupTasks', this.groupId)
      : this.$store.dispatch('getMyEndedGroupTasks', this.groupId);
    //
    const request3 = this.isMentor
      ? this.$store.dispatch('getGroupTasksOnReview', this.groupId)
      : this.$store.dispatch('getMyGroupTasksOnReview', this.groupId);
    //

    await Promise.all([
      request,
      request2,
      request3,
    ])
      .catch((err) => {
        console.warn('Error in getting group tasks : ', err);
        this.$toasted.global.appError();
        this.error = 'Group tasks could not be found!';
      })
      .then(([res1, res2, res3]) => {
        // console.log(res);
        if (!res1 || !res2 || !res3) return false;

        this.tasks = [...(res1 || []), ...(res2 || []), ...(res3 || [])];

        this.loading = false;
        this.error = undefined;
        return true;
      });

    this.$emit('view-mounted');
  },
};
</script>

<style>

</style>
