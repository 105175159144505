<template>
  <span
    v-if="total > 0"
    :class="[
      'notification-pill new-notification tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-normal tw-shadow tw-leading-none tw-inline-block tw-text-center',
      { 'tw-bg-blue-500 tw-text-white' : !dumb },
      { 'new-notification--pulse' : hasNewNotification && !dumb },
      { 'tw-bg-gray-500' : !hasNewNotification },
   ]"
  >{{total }}</span>
</template>

<script>
export default {
  name: 'NotificationPill',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    dumb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasNewNotification: false,
    };
  },
  watch: {
    total(newVal, oldVal) {
      if (newVal !== 0 && newVal > oldVal) {
        this.triggerNotify();
      }
    },
  },
  methods: {
    triggerNotify() {
      this.hasNewNotification = true;
      setTimeout(() => {
        this.hasNewNotification = false;
      }, 15000);
    },
  },
  created() {
    if (this.total > 0) {
      this.triggerNotify();
    }
  },
};
</script>

<style>

</style>
