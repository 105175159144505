<template>
  <div>
    <!-- Upload form modal -->
    <portal to="modal-body" v-if="!loading">
      <div class="tw-w-full tw-p-4 tw-pt-6">
        <div class="tw-my-4 tw-p-2 tw-bg-red-200 tw-rounded-10" v-if="!selectedPackageId">
          <p class="tw-text-red-600">Please select a package first</p>
        </div>
        <div class="tw-my-2">
          <p class="tw-text-left opacity-87 tw-text-sm">
            Pay for package <span class="tw-text-app-deep-blue">{{selectedPackage.name}}</span>
            <br>
            Amount: <span class="tw-font-bold tw-text-app-deep-blue">ksh.{{paymentBalance}}</span>
          </p>
        </div>
        <div class="tw-flex tw-flex-nowrap tw-overflow-hidden tw-items-start">
          <transition name="slide-right" mode="out-in" appear>
          <div class="tw-w-full transition-slow" v-if="currentStep == 1">
            <div class="tw-mb-1">
              <InputGroup
                label="Phone number (m-pesa number)"
                instruct="must be '2547...'"
                name="phonenumber"
                ref="phonenumber"
                type="number"
                placeholder="2547********"
                v-model="form.phonenumber"
                :error="formError.phonenumber"
                input-classes="tw-text-app-deep-blue"
              />
                <!-- :value="form.phonenumber" -->
            </div>
            <BaseButton
              class="tw-py-3 tw-my-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
              text="proceed to pay"
              :disabled="!canPay"
              @click="addPackage()"
            />
          </div>
          <div class="tw-w-full transition-slow" v-if="currentStep == 2">
            <!-- <BaseButton
              class="tw-py-3 tw-my-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
              :text="payButtonText"
              :disabled="!canPay || isPaying"
              @click="pay()"
            /> -->
            <!-- <p class="tw-text-left tw-my-2 tw-text-sm" v-if="isPaying">check your phone to enter your m-pesa pin</p> -->
            <template>

              <div class="tw-p-2 bg-black-11 tw-mt-2">
                <!-- <p class="tw-text-center tw-mt-2 opacity-78">
                  <span v-if="isPaying">if nothing is happening on your phone, <br></span>
                  use the alternative method:
                </p> -->

                <div class="tw-p-2 tw-shadow-app-lg tw-my-2 tw-bg-green-100">
                  <p class="tw-text-left ">
                    PAYBILL NUMBER: <span class="tw-font-bold">{{paybillNumber}}</span>
                    <br>
                    ACCOUNT NUMBER: <span class="tw-font-bold">{{user.opt}}</span>
                    <br>
                    AMOUNT: <span class="tw-font-bold">ksh.{{paymentBalance}}</span>
                    <!-- // ? what to do if mentee has balance in wallet -->
                  </p>
                  <BaseButton
                    class="tw-py-3 tw-my-2 tw-font-bold tw-border-2"
                    text="I have paid"
                    @click="paidViaPaybill()"
                  />

                </div>

              </div>

            </template>
          </div>
          </transition>
        </div>
      </div>
    </portal>

    <template>
      <transition name="fade" mode="out-in" appear>
        <ContentLoadingFourGrid v-if="loading" style="margin-left: -36px; margin-right: -36px;"/>

        <div v-else-if="error">
          <p class="tw-text-red-400 tw-py-10" v-html="error"></p>
        </div>

        <div v-else>
          <div v-if="hasPartiallyPaid" class="sm:tw-w-1/2 tw-border tw-border-app-deep-blue tw-rounded-10 tw-p-4 tw-mx-auto tw-mb-6">
            <p class="tw-text-base tw-mb-2">
              You have partially paid for <span class="tw-font-bold">{{selectedPackage.name}}</span>
              <br>
              Balance: <span class="tw-text-xl tw-font-bold">ksh.{{paymentBalance}}</span>
            </p>
            <BaseButton
              class="tw-py-3 tw-bg-app-deep-blue tw-border-app-deep-blue tw-text-white transition-fast"
              text="complete payment"
              @click="selectPackage(Number(selectedPackage.id))"
            />
          </div>

          <div class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-four tw-flex-wrap">
            <!-- // TODO: redesign UI to group packages by pillars -->
            <template v-for="aPackage in packages">

              <!--  -->
              <BaseCard
                :key="aPackage.id"
                :class="[
                  'tw-flex tw-flex-col info-card tw-shadow-app tw-bg-white tw-p-3 tw-relative tw-rounded-tr-32',
                  { 'info-card--selected': Number(selectedPackageId) === Number(aPackage.id) },
                ]"
              >
                <p class="tw-text-2xl font-title tw-mb-3">{{aPackage.name || '...'}}</p>
                <p class="tw-text-sm tw-font-bold opacity-31 tw-uppercase">category</p>
                <p class="tw-text tw-font-bold tw-mb-4">{{ getPillarById(aPackage.pillarid).goal_group || '...' }}</p>
                <p class="tw-text-sm tw-font-bold opacity-31 tw-uppercase">Description</p>
                <p class="tw-text tw-mb-4">{{aPackage.description}}</p>
                <div class="tw-flex-grow"></div>
                <p class="tw-text-2xl tw-font-bold font-title tw-text-app-deep-blue tw-mb-3">ksh.{{aPackage.amount}}</p>
                <BaseButton
                  class="tw-block tw-w-full tw-py-3 hover:tw-bg-app-deep-blue hover:tw-border-app-deep-blue hover:tw-text-white transition-fast"
                  text="buy package"
                  @click="selectPackage(Number(aPackage.id))"
                />
              </BaseCard>
            </template>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import ContentLoadingFourGrid from '@/components/ContentLoadingFourGrid.vue';

import resolve from '@/modules/api/resolve';
import { apiPost, generateFormData } from '@/modules/apiHelper';

export default {
  name: 'LicensePackagesPartial',
  components: {
    InputGroup,
    ContentLoadingFourGrid,
  },
  data() {
    return {
      loading: true,
      error: undefined,
      packages: [],
      pillars: [],
      currentStep: 1,
      selectedPackageId: undefined,
      user: undefined,
      payButtonText: 'click to pay using m-pesa pop-up menu',
      paybillNumber: undefined,
      form: {
        phonenumber: undefined,
        amount: undefined,
        accountnumber: undefined,
      },
      formError: {},
      hasPaid: false,
      isPaying: false,
      walletBalance: 0,
    };
  },
  watch: {
    hasPaid(val) {
      if (val) {
        this.$emit('input', this.user.py);
        this.$emit('input-success', true);
      }
    },
  },
  computed: {
    paymentBalance() {
      if (!!Number(Math.abs(this.walletBalance)) && this.hasPartiallyPaid) {
        return Math.abs(this.walletBalance);
      }

      return this.form.amount;
    },
    selectedPackage() {
      return this.packages.find(p => Number(p.id) === Number(this.selectedPackageId)) || {};
    },
    canPay() {
      if (
        /^(2547).+/g.test(this.form.phonenumber)
        && !!this.selectedPackageId
      ) {
        return true;
      }

      return false;
    },
    hasPartiallyPaid() {
      if (this.user) {
        return !!Number(this.user.py) === false
          && !!Number(this.user.bal) === true
          && !!Number(this.user.packageid) === true;
      }

      return false;
    },
  },
  methods: {
    getPillarById(pillarId) {
      return this.pillars.find(pillar => Number(pillar.id) === Number(pillarId)) || {};
    },
    getPackagesByPillarId(pillarId) {
      return this.packages.filter(aPackage => Number(aPackage.pillarid) === Number(pillarId));
    },
    async getUserWallet() {
      const formData = generateFormData({
        userid: this.user.id,
      });

      const result = await resolve(apiPost('user_balance', formData, 15));

      if (!result) {
        this.error = 'Error while trying to get user wallet balance';
        console.warn('could not get user balance::', result);

        return false;
      }

      this.walletBalance = result.wal_balance;


      return true;
    },
    async selectPackage(packageId) {
      this.selectedPackageId = Number(packageId);

      // update form
      this.form.amount = Number(this.selectedPackage.amount);
      this.currentStep = 1;

      // open mpesa modal
      this.$store.commit('openModal', [true]);

      return true;
    },
    async addPackage() {
      const formData = generateFormData({
        packageid: this.selectedPackageId,
        userid: this.user.id,
        phone: this.form.phonenumber,
      });

      const result = await resolve(apiPost('add_package', formData, 14));

      // post swift
      if (!result) {
        this.error = 'Error while trying to pay';
        console.warn('could not add package::', this.selectedPackageId, result);

        return false;
      }

      this.currentStep = 2;

      this.checkIfPaid();

      return true;
    },
    async pay() {
      if (!this.canPay) return false;

      this.payButtonText = 'working. Please wait...';
      this.isPaying = true;

      const formData = generateFormData({
        userid: this.user.id,
        phone: this.form.phonenumber,
      });

      const result = await resolve(apiPost('swift', formData, 3));

      if (Number(result.ResponseCode) !== 0) {
        // error
        // ? close modal?
        this.error = 'Error while trying to pay. Please use the paybill method.';
        console.warn('could not complete swift', result);

        this.isPaying = false;
        this.$store.commit('openModal', [false]);

        return false;
      }

      // start checking if user has paid
      // this.checkIfPaid();

      return true;
    },
    async checkIfPaid() {
      await this.$store.dispatch('deleteUserData', this.user.id);
      await this.$nextTick();
      const user = await this.$store.dispatch('getUserData', this.user.id);
      this.user = user;

      console.log('py = ', user.py, '\n', 'bal = ', user.bal);

      if (Number(user.py) === 1 && Number(user.bal) === 0 && Number(user.packageid) === Number(this.selectedPackageId)) {
        console.log('has paid');
        this.$toasted.success('Success!', { duration: 6000 });

        // close modal
        this.$store.commit('openModal', [false]);

        await this.$nextTick();
        await this.$store.dispatch('getMenteePackage', true);
        await this.$nextTick();

        this.currentStep = 1;

        this.isPaying = false;
        this.hasPaid = true;
        this.payButtonText = 'pay using m-pesa swift';
      } else {
        console.log('not yet. check again');

        setTimeout(() => {
          this.checkIfPaid();
        }, 4000);
      }
    },
    async paidViaPaybill() {
      // just reload the page
      await this.$store.dispatch('deleteUserData', this.user.id);
      await this.$store.dispatch('getMenteePackage', true);
      window.location.reload();
    },
  },
  async created() {
    this.loading = true;

    await this.$nextTick();

    this.formError = { ...this.form };

    await Promise.all([
      this.$store.dispatch('getPackages', true),
      this.$store.dispatch('getUserData', this.$store.getters.userId),
      this.$store.dispatch('getPillarGroups', []),
    ]).then(([packages, user, pillars]) => {
      this.packages = packages;
      this.user = user;
      this.pillars = pillars;
    });

    this.paybillNumber = process.env.VUE_APP_PAYBILL_NUMBER;
    this.form.phonenumber = Number(this.user.working_num);

    await this.$nextTick();

    if (!this.packages) {
      this.$toasted.global.appError({ errorMessage: 'Sorry we found an error while fetching the packages' });
      this.error = 'Sorry we found an error while fetching the packages.<br>Maybe try again later or talk to us';
      return false;
    }

    if (this.hasPartiallyPaid) {
      // get the partially paid package
      this.selectedPackageId = this.user.packageid;
      await this.getUserWallet();
    }

    this.loading = false;
    this.error = undefined;

    return true;
  },
};
</script>

<style>

</style>
