import resolve from "../modules/api/resolve";
import { apiPost, generateFormData } from "../modules/apiHelper";

import _orderBy from 'lodash/orderBy';
import _reverse from 'lodash/reverse';
import { toBoolean } from "../modules/dataHelper";

const matchEndedSurveyType = 1;

export default {
  state: {
    surveyTypes: [
      {
        id: 0,
        type: 'Not set',
      },
      {
        id: 1,
        type: 'When a mentorship session ends',
      },
    ],
  },
  actions: {
    // gets the survey, the survey questions and the questions' ratings
    // todo: improve this to filter by match's goal id
    async getMatchEndedSurvey(context, getSurveyQuestions = false) {
      const companyid = context.rootState.User.companyid;

      const companySurvey = await resolve(apiPost('survey_pillar_log', generateFormData({ companyid }), 37))
        .then((data) => {
          if (!data) return false;

          return (data.survey_pillar_log || []).find((survey) => Number(survey.type) == matchEndedSurveyType);
        });
      // 

      if (!getSurveyQuestions) return companySurvey;
      if (!companySurvey) return false;

      // get survey questions
      const companySurveyQuestions = await resolve(apiPost('survey_log', generateFormData({ companyid }), 39))
        .then((data) => {
          if (!data) return false;

          return (data.survey_log || []).filter((question) => Number(question.pillarid) === Number(companySurvey.id));
        });
      //

      if(!companySurveyQuestions) return false;

      // get questions ratings
      const questionsRatings = await resolve(apiPost('ratings_log', generateFormData({ companyid }), 39))
        .then((data) => {
          if (!data) return false;

          return data.ratings_log;
        });
      //

      if (!questionsRatings) return false;

      // map ratings to their questions
      const mappedQuestions = _orderBy(companySurveyQuestions.map((question) => {
        const questionRatings = questionsRatings.filter((que) => que.suvid == question.id)

        return {
          ...question,
          ratings: _orderBy(questionRatings, (r) => r.dateOfPost),
        }
      }), (q) => q.dateOfPost)

      return {
        ...companySurvey,
        questions: mappedQuestions
      };
    },
    async saveUserSurveyResponse(context, form) {
      const userid = context.rootState.User.id;
      const companyid = context.rootState.User.companyid;

      const promises = [];

      const sendResponse = async (userResponse, isLastResponse = false) => {
        const formData = generateFormData({
          suvid: userResponse.questionId,
          rateid: userResponse.rating,
          companyid,
          userid,
        });

        if (isLastResponse) {
          formData.set('q', 1)
        }

        const result = await resolve(apiPost('submit_survey', formData, 40))

        return result;
      }

      form.forEach((formItem, index) => {
        promises.push(sendResponse(formItem, index == (form.length - 1)));
      });

      const result = await Promise.all(promises)
        .then((prom) => prom.every((p) => !!p));
      //

      return result;
    },
    getMatchEndedSurveyUserResponse(context) {
      const userid = context.rootState.User.id;
      const companyid = context.rootState.User.companyid;



      return {}
    },
    async userCanTakeSurvey(context) {
      const userid = context.rootState.User.id;
      const companyid = context.rootState.User.companyid;

      const eligibleUsers = await resolve(apiPost('survey_user_pending_log', generateFormData({ companyid }), 42));

      if (!eligibleUsers) {
        return false;
      }

      return !!eligibleUsers.survey_user_pending_log.find((user) => user.userid == userid)
    }
  }
}