<template>
  <AppView
    :body-class="['tw-flex tw-flex-col']"
    v-slot="{ recalculate }"
    padd-bottom
  >
    <div
      v-if="dataError"
      class="tw-flex-grow tw-flex tw-flex-row tw-flex-wrap tw-justify-center tw-h-screen tw-w-full tw-items-center px-body"
    >
      <div>
        <h4 class="tw-text-center tw-text-xl tw-text-red-400 tw-mb-4 ">Error!</h4>
        <h4 class="opacity-87 tw-text-base tw-text-center" v-html="dataError"></h4>
        <!--
          ADD ACTIONS TO BE TAKEN ON ERROR
        -->
      </div>
    </div>

    <template v-else>
      <portal to="top-app-view" slim>
        <div class="tw-z-20 tw-shadow-lg lg-app:tw-shadow-none app-bg">
          <TopNav
            :title="mentorshipGroup.group_name"
            title-class="font-title leading-24 lg:ml-body"
            center-title
            type="back"
            :prev-route="isMentor ? '/app/my-mentees' : '/app/my-mentors'"
            :show-user-photo="false"
          >
            <!-- <p slot="title-left"> {{mentorshipGroup.group_name}}</p> -->
          </TopNav>

          <div class="mx-body lg-grid">
            <div class="lg-grid__col-2/3 tw-flex tw-flex-nowrap transition-fast my-tab my-tab--routes">
              <router-link :to="{name: 'mentorshipGroupTasks'}" class="transition-fast tw-w-1/3 tw-text-center tw-uppercase tw-text-xs tw-align-middle tw-inline-block leading-14 tw-py-2 tw-font-bold" >
                <svg class="w-18 h-18 tw-mr-2 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <title>42</title>
                  <g>
                    <path d="M64.4,17.3c0-4.6-3.7-8.2-8.2-8.2h-4.2c-0.7-2.4-3-4.1-5.5-4.1h-3.3C41.5,1,37-1,33,0.6C31,1.4,29.3,3,28.6,5h-3.2
                      c-2.5,0-4.8,1.7-5.5,4.1h-4.2c-4.6,0-8.2,3.7-8.2,8.2v46.5c0,4.6,3.7,8.2,8.2,8.2h40.7c4.6,0,8.2-3.7,8.2-8.2V17.3H64.4z
                      M22.9,10.7c0-1.4,1.1-2.5,2.5-2.5h4.4c0.8,0,1.5-0.5,1.6-1.3c0.5-2.5,2.9-4.1,5.3-3.6c1.8,0.4,3.2,1.8,3.6,3.6
                      c0.2,0.8,0.9,1.3,1.6,1.3h4.5c1.4,0,2.5,1.1,2.5,2.5v1.2c0,1.4-1.1,2.5-2.5,2.5H25.2c-1.4,0-2.5-1.1-2.5-2.5L22.9,10.7z M61.1,63.8
                      c0,2.7-2.2,4.9-4.9,4.9H15.5c-2.7,0-4.9-2.2-4.9-4.9V17.3c0-2.7,2.2-4.9,4.9-4.9h4c0.2,3,2.7,5.4,5.8,5.4h21c3,0,5.6-2.3,5.8-5.4h4
                      c2.7,0,4.9,2.2,4.9,4.9V63.8z"/>
                    <path d="M26.7,28h-7.8c-0.9,0-1.6,0.7-1.6,1.6v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
                      C28.4,28.7,27.6,28,26.7,28z M25.1,35.8h-4.5v-4.5h4.5V35.8z"/>
                    <path d="M51.9,31.8H36c-0.9,0-1.6,0.7-1.6,1.6S35.1,35,36,35h15.9c0.9,0,1.6-0.7,1.6-1.6S52.8,31.8,51.9,31.8z"/>
                    <path d="M26.7,46.9h-7.8c-0.9,0-1.6,0.7-1.6,1.6l0,0v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
                      C28.4,47.8,27.6,46.9,26.7,46.9L26.7,46.9z M25.1,54.9h-4.5v-4.5h4.5V54.9z"/>
                    <path d="M51.9,50.9H36c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h15.9c0.9,0,1.6-0.7,1.6-1.6C53.5,51.6,52.8,50.9,51.9,50.9z"
                      />
                  </g>
                </svg>
                <span class="tw-inline-block tw-align-middle">
                  Tasks
                </span>
              </router-link>
              <router-link
                :to="{name: 'mentorshipGroupChat'}"
                :class="[
                  'transition-fast tw-w-1/3 tw-text-center tw-uppercase tw-text-xs tw-align-middle tw-inline-block leading-14 tw-py-2 tw-font-bold tw-relative tw-fill-current',
                ]"
              >
                  <!-- { 'tw-text-blue-500': totalNewUserMessages(id) > 0 } -->
                <svg class="w-18 h-18 tw-mr-2 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M7.2,72c-0.8,0-1.7-0.2-2.3-0.8c-1-0.8-1.5-2.1-1.3-3.4l2.1-13.2c-3.6-5.9-5.4-12.6-5.4-19.3c0-16.5,11.9-31,28.1-34.6l0,0
                    c10.9-2.3,22,0.4,30.6,7.5c8.4,7.1,13.2,17.6,12.8,28.7C71.1,52.9,59.3,67,43.6,70.3c-7.7,1.7-15.7,0.8-23-2.7L8.4,71.8
                    C8,72,7.6,72,7.2,72z M29.2,4.8C14.9,7.9,4.5,20.7,4.5,35.4c0,6.3,1.7,12.4,5.2,17.6c0.2,0.4,0.4,1,0.4,1.5L8,67.6l12.1-4.2
                    c0.6-0.2,1-0.2,1.7,0.2c6.5,3.4,13.8,4.2,20.9,2.7c13.6-3.1,24.1-15.5,24.5-29.5C67.7,27,63.5,17.8,56,11.5
                    C48.7,5.2,38.8,2.9,29.2,4.8L29.2,4.8z M28.8,2.9L28.8,2.9L28.8,2.9z"/>
                </svg>
                <span class="tw-inline-block tw-align-middle">
                  Chat
                </span>
                <!-- <NotificationPill
                  v-if="totalNewUserMessages(id) > 0"
                  :total="totalNewUserMessages(id)"
                  class="tw-mx-1"
                /> -->
              </router-link>
              <router-link :to="{name: 'mentorshipGroupProfile'}" class="transition-fast tw-w-1/3 tw-text-center tw-uppercase tw-text-xs tw-align-middle tw-inline-block leading-14 tw-py-2 tw-font-bold" >
                <svg class="w-18 h-18 tw-mr-2 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M46.9,39.5C53,35.6,57.1,29,57.1,21.3C57.1,9.5,47.7,0,35.9,0S14.6,9.5,14.6,21.3c0,7.7,4.1,14.3,10.2,18.2
                    C12.6,43.8,3.3,55.3,2.3,69.2c0,0.8,0.3,1.3,0.8,2C3.6,72,4.4,72,4.9,72h62.3c0.8,0,1.3-0.3,1.8-0.8s0.8-1.3,0.8-2
                    C68.7,55.3,59.4,43.8,46.9,39.5z M19.7,21.3c0-9,7.2-16.1,16.1-16.1S52,12.3,52,21.3s-7.2,16.1-16.1,16.1S19.7,30.2,19.7,21.3z
                    M7.7,66.6C10,53,21.8,42.5,35.9,42.5S62,52.8,64.1,66.6H7.7z"/>
                </svg>
                <span class="tw-inline-block tw-align-middle">
                  Profile
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </portal>

      <div v-if="loading" class="tw-flex tw-flex-row tw-flex-wrap tw-justify-center tw-h-screen tw-w-full tw-items-center px-body">
        <div>
          <h4 class="tw-text-center tw-text-xl tw-text-gray-400 tw-mb-4 ">Getting Mentorship Group details...</h4>
          <!--
            ADD PROGRESS BAR ?
          -->
        </div>
      </div>

      <div v-else-if="mentorshipGroup?.id" class="tw-flex-grow tw-flex tw-flex-col">
        <transition :name="transitionName" mode="out-in" appear>
          <router-view
            :mentorship-group="mentorshipGroup"
            :group-id="mentorshipGroup.id"
            @view-mounted="recalculate()"
          ></router-view>
        </transition>
      </div>

    </template>
  </AppView>
</template>

<script>
export default {
  name: 'MentorshipGroup',
  props: ['id'],
  data() {
    return {
      dataError: undefined,
      transitionName: 'fade',
      loading: true,
      mentorshipGroup: {},
    };
  },
  methods: {
    async getMentorshipGroup() {
      this.mentorshipGroup = await this.$store.dispatch('getMentorshipGroup', [this.id]);

      return this.mentorshipGroup;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const viewRoutes = [
      'mentorshipGroupTasks',
      'mentorshipGroupChat',
      'mentorshipGroupProfile',
    ];

    if (viewRoutes.find(r => r === from.name) && viewRoutes.find(r => r === to.name)) {
      // console.log('\n============================');
      // console.log('from: ', from.name);
      // console.log('to: ', to.name);
      if (to.name === from.name) {
        this.transitionName = 'fade';
      } else if (
        // eslint-disable-next-line no-mixed-operators
        to.name === 'mentorshipGroupChat' && from.name === 'mentorshipGroupTasks'
        // eslint-disable-next-line no-mixed-operators
        || to.name === 'mentorshipGroupProfile' && from.name === 'mentorshipGroupChat'
        // eslint-disable-next-line no-mixed-operators
        || to.name === 'mentorshipGroupProfile' && from.name === 'mentorshipGroupTasks'
      ) {
        this.transitionName = 'slide-right';
      } else {
        this.transitionName = 'slide-left';
      }
      // console.log('============================\n');
    }
    // console.log(this.transitionName);
    next();
  },
  async created() {
    this.loading = true;
    this.$store.commit('isFlatView', false);
    await this.$nextTick();

    const isParticipant = await this.$store.dispatch('assertParticipant', this.id);
    if (!isParticipant) {
      this.$router.push({
        name: this.isMentor ? 'mentorMenteesView' : 'menteeMentorsView',
      });

      return;
    };

    return this.getMentorshipGroup()
      .catch((err) => {
        console.warn('Error in getting mentorship group ', this.$attrs, ': ', err);
        this.$toasted.global.appError();
        this.dataError = 'Mentorship group could not be found!';
      })
      .then(async (res) => {
        if (!res) {
          await this.$nextTick();
          this.dataError = 'Mentorship group could not be found!';
          this.loading = false;

          return;
        };

        await this.$nextTick();

        this.dataError = undefined;
        this.loading = false;

        if (this.$route.name == 'mentorshipGroup') {
          this.$router.push({
            name: 'mentorshipGroupTasks',
            params: {
              id: this.id,
            },
          });
        }
        return true;
      });
    //
  },
};
</script>

<style>

</style>
