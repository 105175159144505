<template>
  <AppView
    :show-bottom-bar="resourcesAreSelected"
    padd-bottom
  >
    <TopNav
      title="RESOURCES"
      title-class="lg:ml-body lg-app:tw-font-bold"
      center-title
      type="back"
      :prev-route="prevRoute"
      slot="top-app-view"
    >
    </TopNav>

    <!-- Upload form modal -->
    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6">
        <div class="tw-flex tw-mt-4 tw-mb-2">
          <button
            :class="[
              'tw-w-1/2 tw-text-center tw-text-black tw-leading-6 tw-text-sm tw-block tw-rounded-t-10 tw-border tw-py-4',
              { 'tw-bg-white tw-border-gray-500 tw-border-b-white tw-font-bold': activeTab == 'file' },
              { 'tw-bg-gray-200 tw-border-gray-100 opacity-78': activeTab != 'file' },
            ]"
            @click="activeTab = 'file'"
          >
            Upload File
          </button>
          <button
            :class="[
              'tw-w-1/2 tw-text-center tw-text-black tw-leading-6 tw-text-sm tw-block tw-rounded-t-10 tw-border tw-py-4',
              { 'tw-bg-white tw-border-gray-500 tw-border-b-white tw-font-bold': activeTab == 'link' },
              { 'tw-bg-gray-200 tw-border-gray-100 opacity-78': activeTab != 'link' },
            ]"
            @click="activeTab = 'link'"
          >
            Upload Link
          </button>
          <button></button>
        </div>
        <!-- <div class="tw-m-1">
          <p class="tw-text-center opacity-54 tw-text-xs">
            Upload media
          </p>
        </div> -->
        <div v-show="activeTab == 'file'">
          <div class="tw-mb-1">
            <InputGroup
              label="Resource file"
              name="resource-file"
              ref="resource-file-input"
              instruct="File must be less than 8MBS"
              type="file"
              accepts="*"
              placeholder="Upload a file"
              @input="fileChange"
              :error="uploadErrors.file"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Resource Name"
              name="resource-name"
              ref="resource-name"
              type="text"
              placeholder="Resource name"
              v-model="upload.name"
              :value="upload.name"
              :error="uploadErrors.name"
            />
          </div>
        </div>
        <div v-show="activeTab == 'link'">
          <div class="tw-mb-1">
            <InputGroup
              label="Link Name"
              name="link-name"
              ref="link-name"
              type="text"
              placeholder="Link name"
              v-model="upload.name"
              :value="upload.name"
              :error="uploadErrors.name"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Link URL"
              name="link-url"
              ref="link-url"
              type="text"
              placeholder="URL"
              v-model="upload.link"
              :value="upload.link"
              :error="uploadErrors.link"
            />
          </div>
        </div>
      </div>
    </portal>
    <portal to="modal-footer">
      <div class="tw-w-full tw-border-t tw-p-4">
        <BaseButton
          class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
          :text="uploadButtonText"
          :disabled="!canUploadResource || !uploadResource"
          @click="doUploadResource()"
        />
      </div>
    </portal>

    <!-- popover - List my mentors -->
    <portal to="popover-menu">
      <ul>
        <li>
          <BaseCheckBox
            @change="selectAllUsers"
            :checked="allUsersAreSelected"
            label="All"
            value="all"
          />
        </li>
        <li
          v-for="({ user }, index) in myMentors"
          :key="index"
          :class="[
            'tw-flex tw-items-center popover__item',
            { 'tw-bg-black hover:bg-black-78 tw-text-white hover:tw-text-white': userIsSelected(user.id) }
          ]"
        >
          <BaseCheckBox
            :value="user.id"
            @change="addUserToShare(user.id, !userIsSelected(user.id))"
            :checked="userIsSelected(user.id)"
            class="tw-mr-4"
          />
          <div
          class="tw-flex tw-items-center"
          @click="addUserToShare(user.id, !userIsSelected(user.id))"
          >
            <UserPhoto
              photo-size="tw-h-4 tw-w-4"
              class="tw-flex-shrink-0"
              :photo="user.photo"
              :user-name="user"
            />
            <p class="tw-ml-4 tw-text-sm leading-17 opacity-78">{{ user.name }} {{ user.lname }}</p>
          </div>
        </li>
      </ul>
      <div class="tw-sticky tw-bottom-0 tw-py-2 tw-px-4 tw-text-right">
        <BaseButton
          :text="`Share ${selectedUsers}`"
          :disabled="!usersAreSelected"
          @click="shareResource(selectedResources, $event, selectedMentors, selectedLinkResources)"
        />
      </div>

    </portal>

    <div class="mx-body tw-mb-8">
      <!-- MY RESOURCES -->
      <div class="tw-flex tw-flex-wrap tw-mt-8 tw-items-center tw-relative">
        <h6 class="tw-text-base">My Resources</h6>
        <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center">
          <BaseButton
            text="Upload"
            class="tw-bg-gray-100"
            @click="openUploadModal()"
            icon
          >
            <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
            </svg>
          </BaseButton>
        </div>
      </div>
      <div class="divider tw-border tw-w-full tw-mt-2 opacity-11"></div>
      <transition mode="out-in" name="fade" appear>
        <ContentLoadingTwoGrid v-if="loading.myResources && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
        <ContentLoadingThreeGrid v-else-if="loading.myResources" style="margin-left: -36px; margin-right: -36px;"/>

        <div v-else-if="error.myResources">
          <p class="tw-text-red-400 tw-py-24" v-html="error.myResources"></p>
        </div>

        <div v-else-if="!hasResources('myResources')" class="tw-py-10">
          <p class="tw-text-gray-800 tw-text-center tw-pb-4 opacity-54">
            You have not uploaded any resources yet.
          </p>
        </div>

        <div v-else>
          <div v-for="(resourceCategory, index) in menteeResourcesCategories" :key="index">
            <div class="tw-rounded-10 tw-p-2 tw-border-b tw-mb-2" v-if="resourceCategory.data.length">
              <h6 class="tw-text-xs tw-mb-2">{{resourceCategory.title}}</h6>
              <ViewMore
                class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                :limit="isMobileView ? 2 : 3"
                :list="resourceCategory.data"
                v-slot="{ item: resource }"
              >
                <div :class="[
                    'info-card tw-shadow-app tw-relative tw-cursor-pointer tw-flex tw-flex-col',
                    {
                      'info-card--selected': resourceIsSelected(resource.id)
                    }
                  ]"
                  @click="selectResource(resource)"
                >
                  <div class="tw-flex-grow">
                    <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.data || resource.title }}</h4>
                    <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                      Uploaded {{ resource['createAt'] || resource['dateOfPost'] || resource['dateofPost'] | moment('calendar') }}
                    </h6>
                  </div>
                  <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mt-4 tw-px-2 opacity-78">
                    <template v-if="!resourceIsLink(resource)">
                      <button
                        class="info-card__action tw-mr-2 tw-mb-2"
                        @click.stop="downloadResource(resource.data, resource.name)"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                            M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                            c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                            C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                        </svg>
                      </button>
                      <router-link
                        tag="a"
                        :to="{
                          name: 'ResourceView',
                          query: {
                            doc: `${apiUrl}/${resource.data}`,
                            title: resource.name,
                          },
                        }"
                        title="view/read"
                        class="info-card__action tw-mr-2 tw-mb-2"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                            c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                            c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                            c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                            S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                            C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                        </svg>
                      </router-link>
                      <button
                        class="info-card__action tw-mr-2 tw-mb-2"
                        @click.stop="shareResource([], $event, selectedMentors, resource)"
                      >
                        <span class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"></span>
                        <span class="tw-inline-block tw-uppercase tw-font-bold tw-text-xs tw-align-middle">SHARE</span>
                        <svg class="tw-w-6 tw-h-6 tw-ml-1 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
                            s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
                            l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
                        </svg>
                      </button>
                    </template>

                    <template v-else>
                      <button
                        class="info-card__action tw-mr-2 tw-mb-2"
                        @click.stop="shareResource(resource, $event, selectedMentors)"
                      >
                        <span class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"></span>
                        <span class="tw-inline-block tw-uppercase tw-font-bold tw-text-xs tw-align-middle">SHARE</span>
                        <svg class="tw-w-6 tw-h-6 tw-ml-1 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
                            s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
                            l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
                        </svg>
                      </button>
                      <a
                        title="view/read"
                        class="info-card__action tw-mr-2 tw-mb-2"
                        :href="resource.resource"
                        target="_blank"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                            c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                            c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                            c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                            S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                            C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                        </svg>
                      </a>
                    </template>
                    <div class="tw-ml-auto"></div>
                    <button
                      class="tw-ml-auto info-card__action tw-text-red-500 tw-bg-red-100 tw-mb-2 tw-mr-2 sm:tw-mr-0"
                      @click.stop="deleteResource(resource)"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M63.1,15.2V64c0,4.4-3.6,8-8,8h-38c-4.4,0-8-3.6-8-8V15.2c0-1.1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9V64c0,2.3,1.9,4.2,4.2,4.2
                          h38c2.3,0,4.2-1.9,4.2-4.2V15.2c0-1.1,0.8-1.9,1.9-1.9S63.1,14.2,63.1,15.2z M66.3,4.9H38v-3C38,0.8,37.2,0,36.1,0
                          c-1.1,0-1.9,0.8-1.9,1.9v3H5.9C4.7,4.9,4,5.7,4,6.8s0.8,1.9,1.9,1.9h60.2C67.3,8.7,68,8,68,6.8S67.3,4.9,66.3,4.9z M24.7,58.9V17.1
                          c0-1.1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S24.7,60,24.7,58.9z M38,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                          c-1.1,0-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9C37.2,60.8,38,60,38,58.9z M51.3,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                          s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S51.3,60,51.3,58.9z"/>
                      </svg>
                    </button>
                  </div>
                  <div class="tw-flex tw-items-end">
                    <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left" v-if="resourceIsLink(resource)">
                      LINK
                    </p>
                    <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left" v-else>
                      {{ resourceType(resource.file_type) }}
                    </p>
                  </div>
                </div>
              </ViewMore>
            </div>
          </div>
        </div>

      </transition>

      <!-- RESOURCES SHARED BY MENTORS -->
      <template
        v-for="({ user, mentorid}, index) in myMentors"
      >
        <div :key="`${user.photo}-${index}`" v-if="hasResources('mentorsResources', mentorid)">
          <div class="tw-flex tw-flex-wrap tw-mt-8 tw-items-center tw-relative">
            <UserPhoto
              :photo="user.photo"
              photo-size="tw-w-5 tw-h-5"
              class="tw-mr-2 opacity-87"
            />
            <h6 class="tw-text-base font-title tw-tracking-wider">{{ user.name }}</h6>
            <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center">
              <h6 class="tw-text-xs tw-text-right tw-mx-6 tw-font-bold">
                {{ mentorsResources[mentorid].length }}
              </h6>
            </div>
          </div>
          <div class="divider tw-border tw-w-full tw-mt-2 opacity-11"></div>
          <transition mode="out-in" name="fade" appear>
            <ContentLoadingTwoGrid v-if="loading.mentorsResources && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
            <ContentLoadingThreeGrid v-else-if="loading.mentorsResources" style="margin-left: -36px; margin-right: -36px;"/>

            <div v-else-if="error.mentorsResources[mentorid]">
              <p class="tw-text-red-400 tw-py-24" v-html="error.mentorsResources[mentorid]"></p>
            </div>

            <div v-else-if="!hasResources('mentorsResources', mentorid)" class="tw-py-10">
              <!-- GOODIES -->
              <p class="tw-text-gray-800 tw-text-center tw-pb-4 opacity-54">
                {{ user.name }} has not shared any resources with you.
              </p>
            </div>

            <ViewMore
              v-else
              class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
              :limit="3"
              :list="mentorsResources[mentorid]"
              v-slot="{ item: resource }"
            >
              <!-- <ResourceCard
                @click-delete="deleteResource(resource)"
              /> -->
              <div class="info-card tw-shadow-app tw-relative tw-flex tw-flex-col">
                <div class="tw-flex-grow">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.data }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['Date of Post'] || resource['dateOfPost'] | moment('calendar') }}
                  </h6>
                </div>
                <div class="tw-flex tw-items-end tw-justify-between">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    {{ resourceType(resource.file_type) }}
                  </p>
                  <div class="tw-inline-flex tw-items-center">
                    <router-link
                      tag="a"
                      :to="{
                        name: 'ResourceView',
                        query: {
                          doc: `${apiUrl}/${resource.data}`,
                          title: resource.name || resource.data,
                        },
                      }"
                      title="view/read"
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </router-link>
                    <button
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                      @click.stop="downloadResource(resource.data, resource.name)"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                          M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                          c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                          C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="info-card tw-shadow-app tw-relative tw-cursor-pointer">
                <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.data }}</h4>
                <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                  Uploaded {{ resource['Date of Post'] || resource['dateOfPost'] | moment('calendar') }}
                </h6>
                <div class="tw-flex tw-items-end">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    {{ resourceType(resource.file_type) }}
                  </p>
                  <button
                    class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                    @click.stop="downloadResource(resource.data, resource.name)"
                  >
                    <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                      y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                        M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                        c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                        C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                    </svg>
                  </button>
                </div>
              </div> -->
            </ViewMore>
          </transition>
        </div>
      </template>


      <!-- REFERENCE CENTER -->
      <div class="tw-flex tw-flex-wrap tw-mt-10 tw-items-center tw-relative">
          <h6 class="tw-text-base tw-capitalize">Resources from eMentored</h6>
          <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center">
          </div>
      </div>
      <div class="divider tw-border tw-w-full tw-mt-2 opacity-11"></div>
      <h6 class="tw-text-xs leading-14 opacity-54 tw-mt-2 tw-text-center">
        These are documents we feel might help you in achieving your goals.
      </h6>

      <BaseCard class="tw-flex tw-flex-no-wrap tw-shadow-app tw-py-2 tw-px-4 tw-mb-3 tw-mt-3 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow">
        <div class="tw-inline-flex tw-items-center">
          <!--  -->
          <svg version="1.1" class="tw-w-6 tw-h-6 sm:tw-inline-block tw-hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
              c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
              l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
          </svg>
          <p class="leading-19 sm:tw-ml-2">Search</p>
        </div>
        <BaseInput
          v-model="filterValue"
          type="text"
          placeholder="Search"
          input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
        />
      </BaseCard>

      <transition mode="out-in" name="fade" appear>
        <ContentLoadingTwoGrid v-if="loading.otherResources && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
        <ContentLoadingThreeGrid v-else-if="loading.otherResources" style="margin-left: -36px; margin-right: -36px;"/>

        <div v-else-if="error.otherResources">
          <p class="tw-text-red-400 tw-py-24" v-html="error.otherResources"></p>
        </div>

        <div v-else>
          <div class="tw-border-b tw-mb-2">
            <h6 class="tw-text-xs tw-mb-2">Links</h6>
            <ViewMore
              class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
              :limit="isMobileView ? 2 : 3"
              :list="filteredOtherLinkResources"
              v-slot="{ item: resource }"
            >
              <div class="info-card tw-shadow-app tw-relative tw-flex tw-flex-col">
                <div class="tw-flex-grow">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.title || resource.link }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['createAt'] || resource['dateOfPost'] || resource['dateofPost'] | moment('calendar') }}
                  </h6>
                  <p v-if="resource.pillar?.id" class="tw-text-sm tw-m-2 tw-text-app-primary-blue">Category: {{resource.pillar?.goal_group || 'Not set'}}</p>
                </div>
                <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mt-4 tw-px-2 opacity-78">
                  <template v-if="!resourceIsLink(resource)">
                    <button
                      class="info-card__action tw-mr-2 tw-mb-2"
                      @click.stop="downloadResource(resource.data, resource.name)"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                          M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                          c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                          C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                      </svg>
                    </button>
                    <router-link
                      tag="a"
                      :to="{
                        name: 'ResourceView',
                        query: {
                          doc: `${apiUrl}/${resource.data}`,
                          title: resource.name,
                        },
                      }"
                      title="view/read"
                      class="info-card__action tw-mr-2 tw-mb-2"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </router-link>
                  </template>
                  <template v-else>
                    <a
                      title="view/read"
                      class="info-card__action tw-mr-2 tw-mb-2"
                      :href="resource.link"
                      target="_blank"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </a>
                  </template>
                  <div class="tw-ml-auto"></div>
                </div>
                <div class="tw-flex tw-items-end">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left" v-if="resourceIsLink(resource)">
                    LINK
                  </p>
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left" v-else>
                    {{ resourceType(resource.file_type) }}
                  </p>
                </div>
              </div>
            </ViewMore>
          </div>
          <div class="tw-border-b tw-mb-2">
            <h6 class="tw-text-xs tw-mb-2">Files and media</h6>
            <ViewMore
              class="tw-flex stacked-grid lg:stacked-grid--stack-three  stacked-grid--stack-full stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-6"
              :limit="3"
              :list="filteredOtherResources"
              v-slot="{ item: resource }"
            >
              <div class="info-card tw-shadow-app tw-relative">
                <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">
                  {{ resource.name || resource.data || resource.path }}
                </h4>
                <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px">
                  Uploaded {{ resource['createAt'] || resource['Date of Post'] || resource['dateOfPost'] | moment('calendar') }}
                </h6>
                <p v-if="resource.pillar?.id" class="tw-text-sm tw-m-2 tw-text-app-primary-blue">Category: {{resource.pillar?.goal_group || 'Not set'}}</p>
                <div class="tw-flex tw-items-end tw-justify-between">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    {{ resourceType(resource.file_type) }}
                  </p>
                  <div class="tw-inline-flex tw-items-center">
                    <router-link
                      tag="a"
                      :to="{
                        name: 'ResourceView',
                        query: {
                          doc: `${apiUrl}/${resource.data}`,
                          title: resource.name || resource.data || resource.path,
                        },
                      }"
                      title="view/read"
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </router-link>
                    <button
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                      @click.stop="downloadResource(resource.data, resource.name)"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                          M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                          c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                          C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </ViewMore>
          </div>
        </div>
      </transition>
    </div>

    <div
      slot="bottom-app-view"
      class="px-body tw-py-4"
    >
      <BaseButton
        class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl"
        :text="`SHARE ${ shareToWho || totalSelectedResources + ' resources' }`"
        @click.native.stop="shareResource(selectedResources, $event, selectedMentors, selectedLinkResources)"
      />
    </div>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import _sortBy from 'lodash/sortBy';
import InputGroup from '@/components/InputGroup.vue';
import ContentLoadingTwoGrid from '@/components/ContentLoadingTwoGrid.vue';
import ContentLoadingThreeGrid from '@/components/ContentLoadingThreeGrid.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseCheckBox from '@/components/BaseCheckBox.vue';
import ResourceMixin from '@/mixins/resources';
import { apiPost, apiUrl } from '@/modules/apiHelper';
import { toBoolean, objectIsValid } from '@/modules/dataHelper';

export default {
  components: {
    InputGroup,
    ContentLoadingTwoGrid,
    ContentLoadingThreeGrid,
    BaseCheckBox,
    BaseInput,
  },
  mixins: [ResourceMixin],
  props: ['prevRoute'],
  data() {
    return {
      mentorsResources: {},
      selectedMentors: [],
      loading: {
        myResources: true,
        mentorsResources: true,
        otherResources: true,
      },
      error: {
        myResources: undefined,
        mentorsResources: {},
        otherResources: undefined,
      },
      apiUrl,
    };
  },
  watch: {
    popOverIsOpen() {
      this.selectedMentors = [];
    },
  },
  computed: {
    ...mapGetters([
      'userId',
      'myMentors',
      'popOverIsOpen',
    ]),
    myTotalResources() {
      return this.myResources.length;
    },
    selectedUsers() {
      return this.selectedMentors.length > 0 ? `to ${this.selectedMentors.length}` : '';
    },
    usersAreSelected() {
      return this.selectedMentors.length > 0;
    },
    allUsersAreSelected() {
      return this.selectedMentors.length === this.myMentors.length;
    },
  },
  methods: {
    userIsSelected(id) {
      return this.selectedMentors.indexOf(id) >= 0;
    },
    selectAllUsers(val, checked) {
      // console.log(checked, val);
      if (!checked) {
        this.selectedMentors = [];
      } else {
        this.myMentors.forEach((mentor) => {
          if (this.selectedMentors.indexOf(mentor.mentorid) < 0) {
            this.addUserToShare(mentor.mentorid, checked);
          }
        });
      }
    },
    addUserToShare(userId, checked) {
      // console.log('adding', checked);
      const index = this.selectedMentors.indexOf(userId);
      if (index < 0 && checked) {
        this.selectedMentors.push(userId);
      } else if (!checked) {
        this.selectedMentors.splice(index, 1);
      }
    },
  },
  created() {
    this.$store.commit('isFlatView', false);

    // GET mentors resources
    const requests = [];
    const resourcesError = 'Error in getting resources';

    this.myMentors.forEach((mentor) => {
      const formData = new FormData();
      formData.set('senderid', mentor.mentorid);
      formData.set('menteeid', this.userId);

      requests.push([
        mentor.mentorid,
        apiPost('shared_resource_list_ment', formData, 1)
          .then(async (response) => {
            if (!response) return false;
            if (toBoolean(response.data.error)) {
              this.error.mentorsResources = `${resourcesError} shared by ${mentor.user.name}`;
              this.$toasted.global.appError({ errorMessage: this.error.mentorsResources });
              return false;
            }

            const resourcesRequests = [];

            _sortBy((response.data.shared_resources_ment || []), 'dateOfPost').forEach((resource) => {
              resourcesRequests.push(this.$store.dispatch('getResource', [resource.resourceid]));
            });

            await Promise.all(resourcesRequests).then((resourcesData) => {
              const data = resourcesData.filter(r => objectIsValid(r));
              this.mentorsResources = Object.assign({}, this.mentorsResources, { [mentor.mentorid]: data });
            });

            return true;
          }),
      ]);

      this.mentorsResources = Object.assign({}, { [mentor.mentorid]: [] });
    });

    Promise.all(requests.map(r => r[1]))
      .catch(() => {
        this.error.mentorsResources = `${resourcesError} shared to you`;
        this.$toasted.global.appError({ errorMessage: this.error.mentorsResources });
      })
      .then(() => {
        this.loading.mentorsResources = false;
        return true;
      });
  },
};
</script>

<style>

</style>
