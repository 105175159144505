var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-overflow-hidden tw-w-full"},[_c('transition',{attrs:{"name":"slide-right","mode":"out-in","appear":""}},[_c('swiper',{ref:"ongoing-tasks-slider",staticStyle:{"min-height":"414px"},attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.tasks),function(task,index){return _c('swiper-slide',{key:task.id,class:[
          'swiper-slider--timeline tw-w-auto tw-mb-8',
          { 'swiper-slider--selected tw-z-30': _vm.taskIsSelected(task.id)}
        ]},[_c('div',{class:['tw-inline-block', { 'ml-body': index === 0}]},[_c('div',{staticClass:"tw-mb-3 tw-ml-5",attrs:{"data-swiper-parallax":-20}},[_c('p',{staticClass:"tw-mb-2 tw-text-sm leading-15 tw-font-bold opacity-31"},[_vm._v(" Due "+_vm._s(_vm._f("moment")(_vm._f("toJsDate")(task.end_tym),'Do MMMM'))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(_vm._f("toJsDate")(task.end_tym),'from', 'now'))+") ")]),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-rounded-full tw-bg-black tw-w-3 tw-h-3 tw-shadow-app tw-mr-6 tw-flex-shrink-0"}),_c('div',{staticClass:"tw-flex-shrink-0 opacity-11 tw-border tw-border-black transition-fast slide-swiper__date-timeline-divider"})])]),_c('BaseCard',{class:[
              'task-card task-card--swiper transition-fast tw-flex tw-flex-col tw-justify-between tw-bg-white',
              { 'task-card--selected': _vm.taskIsSelected(task.id)},
            ],nativeOn:{"click":function($event){return _vm.selectTask(task.id)}}},[_c('div',[_c('h4',{staticClass:"task-card__name transition-fast"},[_vm._v(" "+_vm._s(task.task_name)+" ")]),_c('p',{staticClass:"task-card__description tw-text-xs tw-leading-none opacity-78 tw-truncate tw-mx-2 tw-mb-3",domProps:{"innerHTML":_vm._s(_vm.htmlizeLinksInString(task.description))}})]),_c('div',{staticClass:"tw-mb-1 tw-px-3"},[_c('transition',{attrs:{"name":"new","mode":"in-out","appear":""}},[_c('BaseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.taskIsSelected(task.id)),expression:"taskIsSelected(task.id)"}],staticClass:"tw-text-white tw-w-full tw-bg-black tw-block tw-px-6 tw-py-3 tw-mt-4 tw-border-none box-content transition focus:tw-shadow-app-lg focus:tw-bg-gray-900",attrs:{"text":"Mark Completed"},on:{"click":function($event){return _vm.markTaskCompleted(task.id)}}})],1)],1)])],1)])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }