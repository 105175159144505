import Vue from 'vue';
import Vuex from 'vuex';

import User from './User';
import settings from './settings';
import chat from './chat';
import groupchat from './groupchat';
import ui from './ui';
import notifications from './notifications';
import scope from './scope';
import ellyebby from './ellyebby';
import goals from './goals';
import survey from './survey';
import videoMeetings from './video-meetings';
import mentorshipGroups from './mentorship-groups';
import journeys from './journeys';

Vue.use(Vuex);

Vue.config.devtools = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  state: {
  },
  modules: {
    User,
    settings,
    chat,
    groupchat,
    ui,
    notifications,
    scope,
    ellyebby,
    goals,
    survey,
    videoMeetings,
    mentorshipGroups,
    journeys,
  },
});
