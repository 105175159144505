export default {
  name: 'popoverUiMixin',
  methods: {
    async openPopOver(ev) {
      // console.log(ev);
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      this.$store.commit('openPopOver', [
        true,
        ev.clientX + 8,
        ev.clientY + ((ev.target.offsetHeight - ev.offsetY) || ev.clientY + 8), // to try show below the event target
      ]);
    },
  },
};
