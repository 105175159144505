import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { toBoolean, now, isStaleData } from '../../../modules/dataHelper';
import { asyncForEach } from '../../../modules/misc';

const RESOURCE_TYPES = {
  pillarresource: 1,
  pillarlink: 2,
  resource: 3,
  link: 4,
}

export default {
  async getResource(context, [resourceid, resourceType = RESOURCE_TYPES.resource]) {
    const localDb = await apiStore.getItem(`resource/${resourceid}/${resourceType}`) || [0, undefined];

    if (localDb[1] && !isStaleData(localDb[0])) return localDb[1];

    let apiUrl;
    let apiEndpoint;

    switch (Number(resourceType)) {
      case 0:
      case RESOURCE_TYPES.resource:
        apiUrl = 'resource_list';
        apiEndpoint = 1;
        break;
      case RESOURCE_TYPES.link:
        apiUrl = 'resource_link';
        apiEndpoint = 22;
        break;
      case RESOURCE_TYPES.pillarresource:
        apiUrl = 'add_resource_pillar_log';
        apiEndpoint = 23;
        break;
      case RESOURCE_TYPES.pillarlink:
        apiUrl = 'resource_link_pillar';
        apiEndpoint = 22;
        break;
      default:
        apiUrl = 'resource_list';
        apiEndpoint = 1;
        break;
    }

    const formData = new FormData();
    formData.set('resourceid', resourceid);

    const resource = await apiPost(apiUrl, formData, apiEndpoint)
      .then((res) => {
        if (!res) return {};
        if (toBoolean(res.data.error)) return {};

        const resc = res.data[apiUrl][0];

        if (resc?.id) {
          apiStore.setItem(`resource/${resourceid}/${resourceType}`, [now(), resc]);
        }

        return resc || {};
      });


    return resource;
  },

  async shareResources(context, [userId, resources = []]) {
    const menteeid = context.getters.userTypeName === 'mentee' ? context.rootState.User.id : userId;
    const mentorid = context.getters.userTypeName === 'mentor' ? context.rootState.User.id : userId;
    if (!menteeid || !mentorid) return false;

    await asyncForEach(resources, async (resource) => {
      const senderid = context.rootState.User.id;
      const isMentor = context.getters.userTypeName == 'mentor';
      let resourceType;

      if(isMentor) {
        if (resource.title) {
          resourceType = RESOURCE_TYPES.pillarlink;
        } else {
          resourceType = RESOURCE_TYPES.pillarresource;
        }
      } else {
        if (resource.title) {
          resourceType = RESOURCE_TYPES.link;
        } else {
          resourceType = RESOURCE_TYPES.resource;
        }
      }

      const formData = generateFormData({
        senderid,
        mentorid,
        menteeid,
        resourceid: resource.id,
        resource_type: resourceType // if pillarresource, pillarlink, resource or link
      });

      const response = await apiPost('share_resource', formData, 1);

      // If taskId was not sent, send the resource as a messaga also
      if (response) {
        await context.dispatch('sendMessage', {
          userId,
          text: '',
          attachmentId: resource.id,
          attachmentType: 'resourceid',
          resourceType,
        });
      }

      return response;
    });

    return true;
  },

  // TODO: do this
  async getAdminResources(context) {
    // const resourcesError = 'Error in getting resources shared by eMentored';
    const request = context.getters.userTypeName === 'mentor'
      ? 'company_resource_log_mentors'
      : 'company_resource_log_mentees';
    const formData = new FormData();
    formData.set('companyid', this.$store.state.User.companyid);

    apiPost(request, formData, 9)
      .catch((err) => {
        // this.error.otherResources = resourcesError;
        // console.warn(this.error.otherResources, err);
        // this.$toasted.global.appError({
        //   errorMessage: this.error.otherResources,
        // });
      })
      .then((res) => {
        console.log(res);
        // this.loading.otherResources = false;
        // if (!res) return;

        // if (toBoolean(res.data.error)) {
        //   this.error.otherResources = resourcesError;
        //   this.$toasted.global.appError({
        //     errorMessage: this.error.otherResources,
        //   });
        //   return;
        // }

        // this.otherResources = res.data[request] || [];
      });
  },
};
