export default {
  userLogIn(state, userData) {
    state.id = userData.userid;
    state.url = userData.url;
    state.type = Number(userData.type) || undefined;
    state.companyid = Number(userData.companyid) || 1; // ! if id is 0 (zero) for some reason
  },
  updateUser(state, [index, data]) {
    state[index] = data;
  },
  authError(state, err) {
    state.authError = err;
  },
  clearUserSession(state) {
    state.id = undefined;
    state.url = undefined;
    state.name = undefined;
    state.type = undefined;
    state.companyid = undefined;
  },
};
