import { mapGetters } from 'vuex';
import _flatten from 'lodash/flatten';
import _compact from 'lodash/compact';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import form from '@/modules/formHelper';
import { toBoolean, urlFriendly, getReadableFileType } from '@/modules/dataHelper';
import { apiPost, apiStore } from '@/modules/apiHelper';
import { maxFileSize } from '../modules/misc';

const RESOURCE_TYPES = {
  pillarresource: 1,
  pillarlink: 2,
  resource: 3,
  link: 4,
};

export default {
  name: 'ResourceMixin',
  props: ['id'],
  data() {
    return {
      activeTab: 'file',
      shareTo: _compact([this.$route.query.to || this.id]),
      user: {},
      selectedResources: [],
      selectedLinkResources: [],
      myResources: [],
      myLinkResources: [],
      myDocumentsResources: [],
      myImagesResources: [],
      myAudioResources: [],
      myVideoResources: [],
      menteeResourcesCategories: [
        {
          title: 'Links',
          data: this.myLinkResources
        },
        {
          title: 'Documents',
          data: this.myDocumentsResources
        },
        {
          title: 'Images',
          data: this.myImagesResources
        },
        {
          title: 'Audio',
          data: this.myAudioResources
        },
        {
          title: 'Videos',
          data: this.myVideoResources
        }
      ],
      otherResources: [],
      otherLinkResources: [],
      myPillarResources: {},
      myLinksPillarResources: {},
      myDocumentsPillarResources: {},
      myImagesPillarResources: {},
      myAudioPillarResources: {},
      myVideoPillarResources: {},
      mentorResourcesCategories: [
        {
          title: 'Links',
          data: this.myLinksPillarResources
        },
        {
          title: 'Documents',
          data: this.myDocumentsPillarResources
        }, {
          title: 'Images',
          data: this.myImagesPillarResources
        }, {
          title: 'Audio',
          data: this.myAudioPillarResources
        }, {
          title: 'Videos',
          data: this.myVideoPillarResources
        }
      ],
      pillars: [],
      myUploadedPillars: [],
      upload: {
        file: undefined,
        name: undefined,
        link: undefined,
        pillarid: undefined,
        pillaridLink: undefined,
      },
      uploadErrors: {
        file: undefined,
        name: undefined,
        link: undefined,
        pillarid: undefined,
        pillaridLink: undefined,
      },
      uploadResource: true,
      uploadButtonText: 'upload',
      filterValue: '',
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'upload.file': function (val) {
      if (!val) {
        this.upload.file = undefined;
        this.upload.name = undefined;
        this.uploadErrors.file = 'File required';
        this.uploadErrors.name = 'File name required';
        return false;
      }
      this.uploadErrors.file = undefined;
      this.uploadErrors.name = undefined;

      if (val.size > maxFileSize) {
        this.upload.file = undefined;
        this.upload.name = undefined;

        this.$toasted.info('Please upload a file 8MB maximum', {
          duration: 4000,
          position: 'top-right',
        });

        if (this.$refs['resource-file-input']) {
          console.log(this.$refs['resource-file-input']);
          const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
          uploadFileInput.value = null;
        }
        this.clearFileNameInput();

        return false;
      }

      if (!form.isValidFile(val) && val) {
        this.uploadErrors.file = 'Invalid file type';
        this.upload.file = undefined;
        this.upload.name = undefined;
        this.clearFileNameInput();
        return false;
      }
      this.upload.name = val.name.split('.').slice(0, -1).join('');

      this.clearFileNameInput(this.upload.name || '');
      return true;
    },
    // eslint-disable-next-line func-names
    'upload.name': function (val) {
      if (!form.isValidTextInput(val)) {
        this.uploadErrors.name = 'File name required';
        return;
      }

      this.uploadErrors.name = undefined;
    },
    // eslint-disable-next-line func-names
    'upload.link': function (val) {
      if ((val || '').length < 5) {
      // if (!form.isValidUrl(val)) {
        this.uploadErrors.link = 'Valid link required';
        return;
      }

      this.uploadErrors.link = undefined;
    },
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    shareToWho() {
      if (!this.shareTo[0]) {
        return undefined;
      }
      if (this.user.user) {
        return `to ${this.user.user.name}`;
      }
      return undefined;
    },
    totalSelectedResources() {
      return this.selectedResources.length + this.selectedLinkResources.length;
    },
    resourcesAreSelected() {
      const totalSelected = this.selectedResources.length + this.selectedLinkResources.length;
      return totalSelected > 1;
    },
    myTotalResources() {
      return this.myResources.length;
    },
    canUploadResource() {
      if (
        (
          this.activeTab == 'file'
          && form.isValidFile(this.upload.file)
          && form.isValidTextInput(this.upload.name)
          && this.upload.file.size < maxFileSize
        )
        || (
          this.activeTab == 'link'
          && (this.upload.link || '').length > 5
          // && form.isValidUrl(this.upload.link)
          && form.isValidTextInput(this.upload.name)
        )
      ) {
        if (
          (this.isMentor && this.activeTab == 'file' && !this.upload.pillarid)
          || (this.isMentor && this.activeTab == 'link' && !this.upload.pillaridLink)
          ) return false;
        return true;
      }
      return false;
    },
    filteredOtherResources() {
      const searchValue = this.filterValue.toLowerCase();

      return this.otherResources.filter((dataItem) => {
        if ((dataItem?.name || '').includes(searchValue) || (dataItem?.data || '').includes(searchValue)) {
          return dataItem;
        }
      })
    },
    filteredOtherLinkResources() {
      const searchValue = this.filterValue.toLowerCase();

      return this.otherLinkResources.filter((dataItem) => {
        if ((dataItem?.title || '').includes(searchValue) || (dataItem?.link || '').includes(searchValue)) {
          return dataItem;
        }
      })
    }
  },
  methods: {
    clearFileInput() {
      if (this.$refs['resource-file-input']) {
        const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
        uploadFileInput.value = null;
      }
    },
    clearFileNameInput(val = '') {
      if (this.$refs['resource-name']) {
        const uploadNameInput = this.$refs['resource-name'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    clearLinkInput(val = '') {
      if (this.$refs['link-url']) {
        const uploadNameInput = this.$refs['link-url'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    clearLinkNameInput(val = '') {
      if (this.$refs['link-name']) {
        const uploadNameInput = this.$refs['link-name'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    // eslint-disable-next-line consistent-return
    async shareResource(resources = [], ev, to = [], linkResources = []) {
      if (resources.length === 0 && linkResources.length === 0) {
        this.$toasted.info('Select atleast one resource', {
          duration: 4000,
          position: 'top-right',
        });
        return false;
      }

      // * if selected resources are meant for creating a task
      // save to apiStore, then redirect
      if (this.toShareToTask) {
        await apiStore.setItem(`CreateTaskRefResources/${this.id}`, _flatten([resources]));
        await apiStore.setItem(`CreateTaskRefLinkResources/${this.id}`, _flatten([linkResources]));
        this.$router.push({
          name: 'mentorMenteeCreateTask',
          params: {
            id: this.id,
          },
        });

        this.$toasted.info('Resource(s) selected', {
          duration: 2500,
          position: 'top-right',
        });

        await this.$nextTick();

        return true;
      }

      // * if selected resources are meant for sharing to group chat
      if (this.shareTo == 'group-chat') {
        // need to save resource type
        const theResources = _flatten([resources, linkResources]).map((resource) => {
          let resourceType;
  
          if (this.isMentor) {
            if (resource.title) {
              resourceType = RESOURCE_TYPES.pillarlink;
            } else {
              resourceType = RESOURCE_TYPES.pillarresource;
            }
          } else {
            if (resource.title) {
              resourceType = RESOURCE_TYPES.link;
            } else {
              resourceType = RESOURCE_TYPES.resource;
            }
          }

          return {
            ...resource,
            resourceType,
          };
        });
        // set userViewData to be picked by group chat @ onMounted
        await apiStore.setItem('refResource/GroupChat', theResources);

        this.$toasted.info('Resource(s) selected', {
          duration: 2500,
          position: 'top-right',
        });
        
        // redirect to group chat
        this.$router.push({
          name: 'GroupChat'
        });

        return true;
      }

      const usersToShareTo = this.shareTo.length > 0 ? this.shareTo : _compact(to);
      const chatRouteName = this.isMentee ? 'mentorChat' : 'mentorMenteeChat';

      if (usersToShareTo.length === 0) {
        await this.openPopOver(ev);
        await this.$nextTick();
        this.selectResource(resources);
        this.selectLinkResource(linkResources);
        return false;
      }


      this.$toasted.info('Sharing...', {
        duration: 4000,
        position: 'top-right',
      });

      this.$store.commit('openPopOver', [false]);

      // send multiple resources directly to multiple users
      const promises = [];
      usersToShareTo.forEach((userId) => {
        const request = this.$store.dispatch('shareResources', [userId, _flatten([resources, linkResources])]);
        promises.push(request);
      });

      Promise.all(promises)
        .catch((err) => {
          console.warn('shareResources[] err: ', err);
          this.$toasted.global.appError();
          return false;
        })
        .then((res) => {
          if (!res) return false;
          if (usersToShareTo.length === 1) {
            this.$router.push({ name: chatRouteName, params: { id: usersToShareTo[0] } });
          } else {
            this.$toasted.success('Resources sent!', {
              duration: 5000,
              position: 'top-right',
            });

            this.unselectResource();
            this.unselectLinkResource();
            this.selectedMentors = [];
            this.selectedMentees = [];
          }

          return true;
        });
    },
    hasResources(dataName, childDataName = undefined) {
      return childDataName
        ? (this[dataName][childDataName] || []).length > 0
        : this[dataName].length > 0;
    },
    resourceIsSelected(id) {
      return !!this.selectedResources.find(t => t.id === id);
    },
    linkResourceIsSelected(id) {
      return !!this.selectedLinkResources.find(t => t.id === id);
    },
    selectResource(resource) {
      if (Array.isArray(resource)) {
        resource.forEach((resc) => {
          if (!this.resourceIsSelected(resc.id)) {
            this.selectResource(resc);
          }
        });
      } else if (this.resourceIsSelected(resource.id)) {
        this.unselectResource(resource.id);
      } else {
        this.selectedResources.push(resource);
      }
    },
    selectLinkResource(resource) {
      if (Array.isArray(resource)) {
        resource.forEach((resc) => {
          if (!this.linkResourceIsSelected(resc.id)) {
            this.selectLinkResource(resc);
          }
        });
      } else if (this.linkResourceIsSelected(resource.id)) {
        this.unselectLinkResource(resource.id);
      } else {
        this.selectedLinkResources.push(resource);
      }
    },
    unselectResource(id) {
      if (!id) {
        this.selectedResources = [];
        return true;
      }

      const index = this.selectedResources.findIndex(t => t.id === id);
      if (index >= 0) {
        this.selectedResources.splice(index, 1);
      }
      return true;
    },
    unselectLinkResource(id) {
      if (!id) {
        this.selectedLinkResources = [];
        return true;
      }

      const index = this.selectedLinkResources.findIndex(t => t.id === id);
      if (index >= 0) {
        this.selectedLinkResources.splice(index, 1);
      }
      return true;
    },
    fileChange(val) {
      [this.upload.file] = val;
    },
    async resetViewData() {
      // this.$refs['resource-file-input'].value = '';
      await this.$nextTick();
      this.upload.file = undefined;
      this.upload.name = undefined;
      this.upload.link = undefined;
      this.upload.pillarid = undefined;
      this.upload.pillaridLink = undefined;
      await this.$nextTick();
      this.uploadErrors.file = undefined;
      this.uploadErrors.name = undefined;
      this.uploadErrors.link = undefined;
      this.uploadErrors.pillarid = undefined;
      this.uploadErrors.pillaridLink = undefined;
    },
    async doUploadResource() {
      await this.$nextTick();

      if (!this.canUploadResource || !this.uploadResource) {
        return;
      }

      this.uploadButtonText = 'uploading...';
      this.uploadResource = false;

      if (this.activeTab == 'link') {
        const formData = new FormData();
        formData.set('userid', this.userId);
        formData.set('title', this.upload.name);
        formData.set('resource', this.upload.link); // Documentation says param is link, anyway
        if (this.isMentor) {
          formData.set('pillarid', this.upload.pillaridLink);
        }

        const endpointAction = this.isMentor
          ? 41 //tsl_ken_resource_a
          : 18; // tsl_ken_resource

        await apiPost(
          'resource_link',
          formData,
          endpointAction,
          {
            'Content-Type': 'multipart/form-data',
          },
        )
          .catch((err) => {
            console.warn('Error in uploading resource', err);
            this.$toasted.global.appError();
          })
          .then((res) => {
            this.uploadButtonText = 'upload';
            this.uploadResource = true;

            if (!res) return false;
            if (toBoolean(res.data.error)) {
              this.$toasted.global.appError({
                errorMessage: 'Error in uploading resource',
              });
              return false;
            }

            const message = res.data.message || `Success in uploading ${this.upload.name}`;
            this.$toasted.show(message, {
              type: 'success',
              position: 'bottom-right',
              fullWidth: true,
              duration: 5000,
            });

            this.resetViewData();
            this.clearLinkInput();
            this.clearLinkNameInput();
            this.$store.commit('openModal', [false]);
            this.getMyResources();
            return true;
          });

        return;
      }
      
      const formData = new FormData();
      formData.set('name', this.upload.name);
      formData.set('userid', this.userId);
      formData.append('file1', this.upload.file, urlFriendly(this.upload.file.name));
      if (this.isMentor) {
        formData.set('pillarid', this.upload.pillarid);
      }

      const apiAction = this.isMentor ? 'add_resource_pillar' : 'add_resource';

      const uploader = apiPost(
        apiAction,
        formData,
        3, // _custom_
        {
          'Content-Type': 'multipart/form-data',
        },
        (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.uploadButtonText = `uploading... (${percentCompleted}%)`;
        },
      )
        .catch((err) => {
          console.warn('Error in uploading resource', err);
          this.$toasted.global.appError();
        })
        .then((res) => {
          this.uploadButtonText = 'upload';
          this.uploadResource = true;

          if (!res) return false;
          if (toBoolean(res.data.error)) {
            this.$toasted.global.appError({
              errorMessage: 'Error in uploading resource',
            });
            return false;
          }

          const message = res.data.message || `Success in uploading ${this.upload.name}`;
          this.$toasted.show(message, {
            type: 'success',
            position: 'bottom-right',
            fullWidth: true,
            duration: 5000,
          });

          this.resetViewData();
          this.clearFileInput();
          this.clearFileNameInput();
          this.$store.commit('openModal', [false]);
          this.getMyResources();
          return true;
        });
      console.log('uploading...', uploader);
    },
    async openUploadModal() {
      this.$store.commit('openModal', [true]);
    },
    async getMyResources() {
      this.loading.myResources = true;
      const resourcesError = 'Error in getting your resources';
      const fileResourcesApi = this.isMentor ? 'add_resource_pillar_log' : 'user_resources';
      const fileResourcesEndpoint = this.isMentor ? 19 : 1;
      const linkResourcesApi = this.isMentor ? 'resource_link_pillar_user_log' : 'resource_link_user_log';

      const formData = new FormData();
      formData.set('userid', this.userId);

      await Promise.all([
        apiPost(fileResourcesApi, formData, fileResourcesEndpoint), // files resources
        apiPost(linkResourcesApi, formData, 21), // links resources
      ]).catch((err) => {
          this.error.myResources = resourcesError;
          console.warn(this.error.myResources, err);

          this.$toasted.global.appError({
            errorMessage: this.error.myResources,
          });
        })
        .then(([res, resLink]) => {
          this.loading.myResources = false;
          // console.log(res, resLink);
          if (!res || !resLink) return;

          if (toBoolean(res.data.error) || toBoolean(resLink.data.error)) {
            this.error.myResources = resourcesError;
            this.$toasted.global.appError({
              errorMessage: this.error.myResources,
            });
            return;
          }

          const allResources = [
            ...(res.data[fileResourcesApi] || []),
            ...(resLink.data[linkResourcesApi] || [])
          ];

          this.myResources = _reverse(_sortBy(allResources, 'dateOfPost'));
          
          if (this.isMentor) {
            let myLinkResources = {};
            let myImagesResources = {};
            let myAudioResources = {};
            let myVideoResources = {};
            let myDocumentsResources = {};

            const addResourceToCategory = (resource, category, key) => {
              if (!Array.isArray(category[key])) {
                category[key] = [resource];
              } else {
                category[key].push(resource);
              }
            }

            // sort by pillar groups
            allResources.forEach((resource) => {
              const key = `resource/${resource.pillarid}`;

              if (this.resourceIsLink(resource)) {
                addResourceToCategory(resource, myLinkResources, key);
              } else {
                switch (getReadableFileType(resource.file_type)) {
                  case 'svg':
                  case 'jpg':
                  case 'png':
                    addResourceToCategory(resource, myImagesResources, key);
                    break;
                  case 'audio':
                    addResourceToCategory(resource, myAudioResources, key);
                    break;
                  case 'video':
                    addResourceToCategory(resource, myVideoResources, key);
                    break;
                  case 'word':
                  case 'ppt':
                  case 'pdf':
                  case 'excel':
                    addResourceToCategory(resource, myDocumentsResources, key);
                    break;
                  default:
                    addResourceToCategory(resource, myDocumentsResources, key);
                    break;
                }
              }

              if (!Array.isArray(this.myPillarResources[key])) {
                this.myPillarResources[key] = [resource];

                // new key, add pillar to this.myUploadedPillars
                const pillar = this.pillars.find((p) => p.id == resource.pillarid);

                if (pillar) {
                  this.myUploadedPillars.push(pillar);
                // } else {
                  // ? What to do to unkown pillars
                }
              } else {
                this.myPillarResources[key].push(resource);
              }
            });

            // sort by dateOfPost
            Object.entries(myLinkResources).forEach(([key, resources], i) => {
              myLinkResources[key] = _reverse(_sortBy(resources, 'dateofPost'));
            });
            Object.entries(myImagesResources).forEach(([key, resources], i) => {
              myImagesResources[key] = _reverse(_sortBy(resources, 'dateofPost'));
            });
            Object.entries(myAudioResources).forEach(([key, resources], i) => {
              myAudioResources[key] = _reverse(_sortBy(resources, 'dateofPost'));
            });
            Object.entries(myVideoResources).forEach(([key, resources], i) => {
              myVideoResources[key] = _reverse(_sortBy(resources, 'dateofPost'));
            });
            Object.entries(myDocumentsResources).forEach(([key, resources], i) => {
              myDocumentsResources[key] = _reverse(_sortBy(resources, 'dateofPost'));
            });

            this.myLinksPillarResources = myLinkResources;
            this.myImagesPillarResources = myImagesResources;
            this.myAudioPillarResources = myAudioResources;
            this.myVideoPillarResources = myVideoResources;
            this.myDocumentsPillarResources = myDocumentsResources;

            this.mentorResourcesCategories = [{
                title: 'Links',
                data: this.myLinksPillarResources
              },
              {
                title: 'Documents',
                data: this.myDocumentsPillarResources
              }, {
                title: 'Images',
                data: this.myImagesPillarResources
              }, {
                title: 'Audio',
                data: this.myAudioPillarResources
              }, {
                title: 'Videos',
                data: this.myVideoPillarResources
              }
            ];

          } else {
            // sort them
            let myLinkResources = [];
            let myImagesResources = [];
            let myAudioResources = [];
            let myVideoResources = [];
            let myDocumentsResources = [];

            allResources.forEach((resource) => {
              if (this.resourceIsLink(resource)) {
                myLinkResources.push(resource)
                return;
              }

              switch (getReadableFileType(resource.file_type)) {
                case 'svg':
                case 'jpg':
                case 'png':
                  myImagesResources.push(resource);
                  break;
                case 'audio':
                  myAudioResources.push(resource);
                  break;
                case 'video':
                  myVideoResources.push(resource);
                  break;
                case 'word':
                case 'ppt':
                case 'pdf':
                case 'excel':
                  myDocumentsResources.push(resource);
                  break;
                default:
                  myDocumentsResources.push(resource);
                  break;
              }
            });

            // sort them by dateOfPost
            this.myLinkResources = _reverse(_sortBy(myLinkResources, 'dateofPost'));
            this.myImagesResources = _reverse(_sortBy(myImagesResources, 'dateOfPost'));
            this.myAudioResources = _reverse(_sortBy(myAudioResources, 'dateOfPost'));
            this.myVideoResources = _reverse(_sortBy(myVideoResources, 'dateOfPost'));
            this.myDocumentsResources = _reverse(_sortBy(myDocumentsResources, 'dateOfPost'));

            this.menteeResourcesCategories = [{
                title: 'Links',
                data: this.myLinkResources
              },
              {
                title: 'Documents',
                data: this.myDocumentsResources
              },
              {
                title: 'Images',
                data: this.myImagesResources
              },
              {
                title: 'Audio',
                data: this.myAudioResources
              },
              {
                title: 'Videos',
                data: this.myVideoResources
              }
            ];
          }
        });
    },
    async getAdminResources() {
      this.loading.otherResources = true;
      const resourcesError = 'Error in getting resources shared by eMentored';
      const request = this.isMentor // media resources
        ? 'company_resource_log_mentors'
        : 'company_resource_log_mentees';
      //
      const linkRequestEndpoint = this.isMentor
        ? 31
        : 32;
      //

      const formData = new FormData();
      formData.set('companyid', this.$store.state.User.companyid);

      Promise.all([
        apiPost(request, formData, 9), // otherResources (files and media)
        apiPost('share_resource_link_log', formData, linkRequestEndpoint), // link resources for either mentee/mentor
        apiPost('share_resource_link_log', formData, 33), // link resources for both mentee/mentor
        apiPost('share_resource_log', formData, 45),
      ])
        .catch((err) => {
          this.error.otherResources = resourcesError;
          console.warn(this.error.otherResources, err);
          this.$toasted.global.appError({
            errorMessage: this.error.otherResources,
          });
        })
        .then(([res, resUsers, resBoth, resFilesBoth]) => {
          if (!res || !resUsers || !resBoth || !resFilesBoth) {
            this.loading.otherResources = false;
            return;
          }

          if (toBoolean(res.data.error) || toBoolean(resUsers.data.error) || toBoolean(resBoth.data.error) || toBoolean(resFilesBoth.data.error)) {
            this.error.otherResources = resourcesError;
            this.$toasted.global.appError({
              errorMessage: this.error.otherResources,
            });

            this.loading.otherResources = false;
            return;
          }

          const otherResources = [
            ...(res.data[request] || []),
            ...(resFilesBoth.data.share_resource_log || []),
          ];

          const promises = [];
          
          otherResources.forEach((resource) => {
            if (resource.userid == this.userId) {
              promises.push(this.$store.dispatch('getResource', [resource.resourceid]));
            }
          });

          Promise.all(promises).then((resolvedResources) => {
            console.log(resolvedResources);

            const mappedResolvedResources = (resolvedResources || []).map((r) => {
              r.pillar = this.pillars.find((pillar) => r.pillarid == pillar.id) || {}
              return r;
            });
      
            this.otherResources = _reverse(_sortBy(_uniqBy(mappedResolvedResources, 'id'), 'dateOfPost'));

            this.loading.otherResources = false;
          });

          const resUsersDataKey = this.isMentor
            ? 'share_resource_link_mentors_log'
            : 'share_resource_link_mentee_log';
          //

          const otherLinkResources = [
            ...resUsers.data[resUsersDataKey],
            ...resBoth.data.share_resource_link_log
          ].filter((r) => r.userid == this.userId)
            .map((r) => {
              r.pillar = this.pillars.find((pillar) => r.pillarid == pillar.id) || {}
              return r;
            });

          this.otherLinkResources = _reverse(_sortBy(otherLinkResources, 'dateOfPost'))
        });
    },
    resourceIsLink(resource = {}) {
      return !!resource.title || !!resource.link;
    },
    resourceCardData(resource = {}) {
      const fileType = getReadableFileType(resource.resource || resource.path, true);
      const resourceName = ((resource.resource || resource.path).split('/').pop()).split('.')[0];

      return {
        ...resource,
        ...{
          id: resource.resourceid,
          name: resourceName,
          file_type: fileType,
          data: resource.resource || resource.path,
        },
      };
    },
    async deleteResource(resource) {
      // you can only delete resource that belongs to you
      if (Number(resource.userid) !== this.userId) return;
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(`Are you sure you want to delete "${resource.name || resource.title || resource.data}"?`);
      if (!confirm) return;

      const formData = new FormData();
      formData.set('resourceid', resource.id);

      let apiRequest;

      if (this.isMentee) {
        apiRequest = this.resourceIsLink(resource)
          ? apiPost('del_resource_link', formData, 19)
          : apiPost('del_resource', formData, 1);
      } else {
        apiRequest = this.resourceIsLink(resource)
          ? apiPost('resource_link', formData, 36)
          : apiPost('del_add_resource_pillar', formData, 19);
      }

      await apiRequest
        .catch(() => {
          this.$toasted.appError();
          return false;
        })
        .then((res) => {
          if (!res) return;
          if (toBoolean(res.data.error)) {
            this.$toasted.appError({ errorMessage: 'Sorry we can\'t delete resource at the moment' });
          }

          this.$toasted.success('Resource deleted', { duration: 4000 });
          this.getMyResources();
        });
    },
    async getPillars() {
      const pillars = await this.$store.dispatch('getPillarGroups', []);

      if (Array.isArray(pillars)) {
        this.pillars = [...pillars];
      }
    },
  },
  async mounted() {
    // should be false if query is not found
    if (toBoolean(this.$route.query['prompt-upload'])) {
      await this.openUploadModal();
    }

    await this.getPillars() // for mentor
    this.getMyResources();
    this.getAdminResources();

    if (this.shareTo.length > 0) {
      if (this.shareTo != 'group-chat') {
        const user = await this.$store.dispatch('getUserData', this.shareTo[0]) || {};
        this.user = Object.assign({}, user);
      }

      this.$toasted.info('You can send more than one resource', {
        duration: 4000,
        position: 'top-right',
      });
    }
  },
};
