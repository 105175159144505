var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading && _vm.isMobileView)?_c('ContentLoadingTwoGrid',{staticStyle:{"margin-left":"-36px","margin-right":"-36px"}}):(_vm.loading)?_c('ContentLoadingFourGrid',{staticStyle:{"margin-left":"-36px","margin-right":"-36px"}}):(_vm.error)?_c('div',[_c('p',{staticClass:"tw-text-red-400 tw-py-10",domProps:{"innerHTML":_vm._s(_vm.error)}})]):_c('div',[_c('h4',[_vm._v(" Choose 1 or more of the goals below ")]),_vm._l((_vm.goalGroups),function(goalGroup){return [_c('div',{key:goalGroup.id,staticClass:"tw-flex tw-mt-8 tw-items-center tw-relative"},[_c('h3',{staticClass:"tw-text-base lg:tw-text-lg opacity-54 leading-19 lg:leading-24 mr-10 tw-w-3/4 font-title"},[_vm._v(_vm._s(goalGroup.goal_group))]),_c('h6',{directives:[{name:"show",rawName:"v-show",value:(!_vm.goalIsEmpty(goalGroup.id) && _vm.totalSelectedGoals(goalGroup.id) > 0),expression:"!goalIsEmpty(goalGroup.id) && totalSelectedGoals(goalGroup.id) > 0"}],staticClass:"tw-text-xs tw-text-right tw-w-1/4"},[_vm._v(" "+_vm._s(_vm.totalSelectedGoals(goalGroup.id))+" Selected ")])]),(goalGroup.description)?_c('div',{key:goalGroup.id,staticClass:"tw-mb-6 tw-mt-1 opacity-54"},[_vm._v(" "+_vm._s(goalGroup.description)+" ")]):_vm._e(),(!_vm.goalIsEmpty(goalGroup.id))?_c('ViewMore',{key:`${goalGroup.id}-goals`,staticClass:"tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-three tw-flex-wrap tw-mt-4",attrs:{"limit":3,"list":_vm.goalsByGroup(goalGroup.id)},scopedSlots:_vm._u([{key:"default",fn:function({ item: goal }){return [_c('div',{class:[
          'info-card tw-shadow-app tw-relative tw-cursor-pointer',
          {
            'info-card--selected': _vm.goalIsSelected(goal.id)
          },
          {
           'tw-bg-green-100': goal.goalCompleted
          },
        ],on:{"click":function($event){return _vm.selectGoal(goal.id)}}},[_c('h4',{staticClass:"tw-leading-tight tw-font-medium opacity-87 tw-text-xl tw-px-2 tw-py-4 tw-break-words"},[_vm._v(" "+_vm._s(goal.goal)+" ")]),(goal.goalCompleted)?[_c('CompletedGoal',{attrs:{"show-tip":_vm.goalIsSelected(goal.id)}})]:_vm._e(),((goal.description || '').length)?_c('h6',{staticClass:"opacity-54 tw-text-sm leading-19 tw-px-2 tw-pb-4 tw-mt-2 tw-whitespace-pre-wrap tw-break-words",domProps:{"innerHTML":_vm._s(goal.description)}}):_vm._e()],2)]}}],null,true)}):_c('div',{key:`${goalGroup.id}-goals`,staticClass:"tw-py-8"},[_c('p',{staticClass:"opacity-31 tw-text-center"},[_vm._v("Currently there are no goals in "+_vm._s(goalGroup.goal_group))])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }