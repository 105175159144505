<template>
  <div class="tw-flex tw-flex-wrap">
    <template v-for="(badge, index) in badges">
      <div :key="index" class="tw-mr-2 tw-mb-2 tw-cursor-pointer" @click="showGoalBadgeDetails(badge)">
        <UserPhoto :photo="badge.url" :photo-size="[
          'tw-rounded-full',
          { 'tw-w-12 tw-h-12' : listSize === 'normal'},
          { 'tw-w-8 tw-h-8' : listSize === 'tiny'},
          { 'tw-w-24 tw-h-24' : listSize === 'large'},
        ]" class="tw-mb-1" :title="badge.title" />
        <p class="tw-font-bold tw-text-sm" v-if="listSize === 'large'" style="max-width: 96px;">
          {{badge.title}}
        </p>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'UserGoalsBadges',
  props: {
    tiny: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      badges: [], 
    };
  },
  computed: {
    listSize() {
      if (this.tiny) {
        return 'tiny';
      }

      if (this.large) {
        return 'large';
      }

      return 'normal';
    },
  },
  methods: {
    async showGoalBadgeDetails(goalBadge) {
      await this.$nextTick();

      this.$store.commit('openRightSideNav', 'Goal Badge Details');
      await this.$nextTick();

      this.$store.commit('showGoalBadgeDetails', goalBadge);
    }
  },
  async created() {
    this.loading = true;
    await this.$nextTick();

    this.badges = await this.$store.dispatch('getUserGoalsBadges', this.$store.getters.userId);

    this.loading = false;
  },
};
</script>

<style>

</style>
