<template>
<div>
  <input class="input-checkbox tw-hidden" :id="id" v-model="val" :value="value" type="checkbox"/>
  <label class="checkbox tw-inline-flex tw-items-center" :for="id">
    <span class="tw-flex-shrink-0">
      <svg width="12px" height="10px" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </svg>
    </span>
    <span>{{ label }}</span>
  </label>
</div>
</template>

<script>
import { now } from '@/modules/dataHelper';

export default {
  name: 'BaseCheckBox',
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: undefined,
      id: now(),
      doEmit: true,
    };
  },
  watch: {
    val(v) {
      // if it is not boolean, it was not triggered by the checkbox
      if (typeof v === 'boolean') {
        this.$emit('change', this.value, v);
      }
      this.$emit('input', v);
    },
    checked(v) {
      this.doEmit = !!v;
      this.val = v ? this.value : undefined;
    },
  },
  mounted() {
    if (this.checked) {
      this.val = this.value;
    }
  },
};
</script>

<style>

</style>
