import { mapGetters } from 'vuex';

import navUiMixin from './nav';
import popoverUiMixin from './popover';
import resizeUiMixin from './resize';
import vSelectMixin from './v-select';

import resourceUiMixin from './resources';
import taskUiMixin from './tasks';

import matchUiMixin from './match';
import userUiMixin from './user';

import customizationsMixin from './customizations';

export default {
  name: 'uiMixins',
  mixins: [
    navUiMixin,
    popoverUiMixin,
    resizeUiMixin,
    vSelectMixin,
    //
    resourceUiMixin,
    taskUiMixin,
    //
    matchUiMixin,
    userUiMixin,
    customizationsMixin,
  ],
  computed: {
    ...mapGetters([
      // Ui
      'isMobileView',
      'isDesktopView',
      'isDesktopXxlView',
      'isFlatSideNavView',
      'viewWidthIsLargerThan',
      'findViewState',
      'findViewData',
      // User
      'userTypeName',
    ]),
    isMentee() {
      return this.userTypeName === 'mentee';
    },
    isMentor() {
      return this.userTypeName === 'mentor';
    },
  },
};
