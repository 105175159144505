import { apiPost, apiStore, generateFormData } from "../../../modules/apiHelper"
import { isStaleData, now, toBoolean } from "../../../modules/dataHelper";

export default {
  async companyProfessions(context, companyId) {
    const [storeExpiry, storeData] = await apiStore.getItem(`profesions/${companyId}`) || [0, []];

    if ((Array.isArray(storeData) && (storeData || []).length) && !isStaleData(storeExpiry)) return storeData;

    const formData = generateFormData({ companyid: companyId });

    const result = await apiPost('profession', formData, 0)
      .then((res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const professions = res.data.profession || [];

        if (professions.length) {
          apiStore.setItem(`profesions/${companyId}`, [now(), professions]);
        }

        return professions;
      });
    //

    return result;
  },
  async companyRegions(context, companyId) {
    const [storeExpiry, storeData] = await apiStore.getItem(`regions/${companyId}`) || [0, []];

    if ((Array.isArray(storeData) && (storeData || []).length) && !isStaleData(storeExpiry)) return storeData;

    const formData = generateFormData({
      companyid: companyId
    });

    const result = await apiPost('regions', formData, 34)
      .then((res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const regions = res.data.regions || [];

        if (regions.length) {
          apiStore.setItem(`regions/${companyId}`, [now(), regions]);
        }

        return regions;
      });
    //

    return result;
  },
  async companyDivisions(context, companyId) {
    const [storeExpiry, storeData] = await apiStore.getItem(`divisions/${companyId}`) || [0, []];

    if ((Array.isArray(storeData) && (storeData || []).length) && !isStaleData(storeExpiry)) return storeData;

    const formData = generateFormData({
      companyid: companyId
    });

    const result = await apiPost('divisions', formData, 34)
      .then((res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const divisions = res.data.divisions || [];

        if (divisions.length) {
          apiStore.setItem(`divisions/${companyId}`, [now(), divisions]);
        }

        return divisions;
      });
    //

    return result;
  },
  async companyDepartments(context, companyId) {
    const [storeExpiry, storeData] = await apiStore.getItem(`departments/${companyId}`) || [0, []];

    if ((Array.isArray(storeData) && (storeData || []).length) && !isStaleData(storeExpiry)) return storeData;

    const formData = generateFormData({
      companyid: companyId
    });

    const result = await apiPost('departments', formData, 34)
      .then((res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const departments = res.data.departments || [];

        if (departments.length) {
          apiStore.setItem(`departments/${companyId}`, [now(), departments]);
        }

        return departments;
      });
    //

    return result;
  },
  async companySubAreas(context, companyId) {
    const [storeExpiry, storeData] = await apiStore.getItem(`subAreas/${companyId}`) || [0, []];

    if ((Array.isArray(storeData) && (storeData || []).length) && !isStaleData(storeExpiry)) return storeData;

    const formData = generateFormData({
      companyid: companyId
    });

    const result = await apiPost('sub_area', formData, 34)
      .then((res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const subAreas = res.data.sub_area || [];

        if (subAreas.length) {
          apiStore.setItem(`subAreas/${companyId}`, [now(), subAreas]);
        }

        return subAreas;
      });
    //

    return result;
  },
  async companyDeptLevels(context, companyId) {
    const [storeExpiry, storeData] = await apiStore.getItem(`deptLevels/${companyId}`) || [0, []];

    if ((Array.isArray(storeData) && (storeData || []).length) && !isStaleData(storeExpiry)) return storeData;

    const formData = generateFormData({
      companyid: companyId
    });

    const result = await apiPost('dept_level', formData, 34)
      .then((res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const deptLevels = res.data.dept_level || [];

        if (deptLevels.length) {
          apiStore.setItem(`deptLevels/${companyId}`, [now(), deptLevels]);
        }

        return deptLevels;
      });
    //

    return result;
  },
}