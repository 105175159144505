<template>
  <BaseCard
    v-if="size === 'small' || size === 'tiny'"
    :class="[
      'tw-shadow-app tw-rounded-10 tw-rounded-tr-32 tw-p-5',
      {'tw-bg-white': licenseStatus},
      {'tw-bg-red-200': !licenseStatus},
    ]"
  >
    <!-- minute details -->
    <p class="font-title tw-mb-1">{{license.name}}</p>
    <!-- <p class="tw-text-sm opacity-54">{{licenseStatusText}}</p> -->
  </BaseCard>
  <BaseCard
    v-else
    :class="[
      'tw-p-3 tw-border',
      {'tw-border-app-deep-blue': licenseStatus},
      {'tw-border-red-600': !licenseStatus},
    ]"
    @click.native="cardClick($event)"
  >
    <h5 class="tw-text-sm tw-uppercase opacity-31 tw-font-bold tw-mb-2 leading-19">Your current license package</h5>
    <div class="tw-inline-flex tw-justify-between tw-mb-3 tw-items-center">
      <p
        :class="[
          'font-title tw-font-bold tw-text-2xl',
          {'tw-text-app-deep-blue': !selectable}
        ]"
      >{{license.name}}</p>
      <p class="font-title tw-font-bold tw-text-3xl tw-ml-4" v-if="showAmount">ksh.{{license.amount}}</p>
    </div>
    <h5 class="tw-text-sm tw-uppercase opacity-31 tw-font-bold leading-19">Description</h5>
    <p class="tw-text-sm opacity-78 tw-mb-4">{{license.description}}</p>
    <slot name="details">
      <!-- <p class="tw-text-sm" v-if="licenseStartDate && licenseExpiry">
        <span v-if="licenseStartDate">Started on {{licenseStartDate}}<br></span>
        <span v-if="licenseExpiry">|<br></span>
        <span v-if="licenseExpiry">Ends on {{licenseExpiry}}<br></span>
      </p> -->
    </slot>
  </BaseCard>
</template>

<script>
export default {
  name: 'LicenseCard',
  props: {
    license: {
      type: Object,
      required: true,
    },
    licenseStatus: {
      type: Boolean,
      default: true,
    },
    showAmount: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String, // normal|tiny
      default: 'normal',
    },
  },
  methods: {
    cardClick($event) {
      this.$emit('click-card', $event, this.license);
    },
  },
  created() {
    if ((this.size === 'tiny' || this.size === 'small') && this.selectable) {
      console.warn('license card cannot be size:tiny|small and be selectable:true');
    }
  },
};
</script>

<style>

</style>
