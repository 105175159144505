<template>
  <!-- NOTE: DON'T USE AppView COMPONENT, UNLESS <portal> -->
  <transition name="fade" mode="out-in">
    <div v-if="error">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center" v-html="error"></p>
    </div>
    <div v-else-if="loading">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center">Loading...</p>
    </div>
    <div v-else class="tw-mt-8 px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-2/3">
        <div class="tw-text-center tw-max-w-lg tw-mx-auto" v-if="mentorshipGroup">
          
          <p class="tw-text-xl font-title leading-19 opacity-54 tw-mt-6" v-html="mentorshipGroup.group_name"></p>
          <p class="tw-text-base leading-19 opacity-54 tw-mt-6 tw-mb-2" v-html="mentorshipGroup.description"></p>

          <p class="tw-mb-1 tw-text-sm tw-bg-gray-200 tw-py-1 tw-px-1 tw-rounded-10 tw-inline-block">Goal: {{mentorshipGroup?.goal?.goal || ''}}</p>
          <p class="tw-text-sm tw-mt-6 tw-mb-2">
            {{mentors.length}} {{'Mentor' | pluralize(mentors.length, 'Mentors')}},
            {{mentees.length}} {{'Mentee' | pluralize(mentees.length, 'Mentees')}}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'MentorshipGroupProfile',
  props: ['mentorshipGroup', 'groupId'],
  data() {
    return {
      loading: true,
      error: undefined,
      participants: [],
    };
  },
  computed: {
    mentors() {
      return this.participants.filter((p) => Number(p.type) === 3)
    },
    mentees() {
      return this.participants.filter((p) => Number(p.type) === 4)
    },
  },
  methods: {
    async getGroupParticipants() {
      const participants = await this.$store.dispatch('getMentorshipGroupParticipants', this.mentorshipGroup.id);

      if (Array.isArray(participants)) {
        this.participants = participants;
      }
    },
  },
  async created() {
    this.getGroupParticipants()
      .catch((err) => {
        const errorMessage = 'Error in mentorship group details';
        this.error = errorMessage;
        console.warn(errorMessage, err);
        this.$toasted.global.appError({ errorMessage });
      })
      .then(() => {
        this.loading = false;
      });
  },
};
</script>

<style>

</style>
