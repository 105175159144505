<template>
  <AppView padd-bottom>
    <TopNav
      slot="top-app-view"
      title="eMentored"
      logo
      title-class="lg-app:tw-hidden"
      type="menu"
      @input="openSideNav()"
      show-notification-icon
    >
    </TopNav>
    <div class="px-body tw-pb-10 tw-mb-4 tw-pt-8 my-bg-pattern">
      <h3 class="tw-text-center opacity-54 tw-mt-2 tw-text-base leading-19">
        Welcome {{ userName || '' }}
      </h3>
      <h5 class="tw-text-center opacity-54 tw-text-xl leading-24 tw-mt-1 font-title">
        Your tasks
      </h5>
    </div>

    <div class="px-body" v-if="hasGroupTasks">
      <div class="tw-mb-4 tw-bg-app-primary-blue tw-p-4 tw-rounded-10">
        <p class="tw-text-white">
          Go to "My Mentors" page and click on a mentorship group to view its tasks
        </p>
      </div>
    </div>

    <div>
      <transition mode="out-in" name="fade" appear>
        <div v-if="tasksError">
          <p class="tw-text-red-500 tw-py-10 tw-text-center">{{ tasksError }}</p>
        </div>
        <!-- TASKS COMPONENT GOES HERE -->
        <div v-else class="tw-relative tw-overflow-hidden">
          <div v-if="loading">
            <p class="opacity-54 tw-text-center tw-text-gray-700 tw-py-48">getting your tasks...</p>
          </div>
          <div v-else-if="menteeHasTasks && !loading" class="tw-mb-8">
            <!-- TASKS LISTINGS -->
            <!--     -----------------     -->
            <MenteeCurrentTasks :ongoing-tasks="ongoingTasks" />
            <!--     -----------------     -->

            <!-- OVERDUE GOAL TASKS (menteeTasks) -->
            <transition name="new" mode="out-in" appear>
              <div v-if="totalTasksOverdue > 0" class="tw-overflow-x-visible mx-body">
                <div class="tw-flex tw-justify-between">
                  <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Overdue Tasks</h6>
                  <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOverdue }}</h6>
                </div>
                <div class="divider tw-mt-2 tw-border opacity-18"></div>
                <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                  <!-- LIST GRID -->
                  <ViewMore
                    class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-three tw-flex-wrap tw-items-start tw-mt-4"
                    :limit="4"
                    :list="overdueTasks"
                    v-slot="{ item: task }"
                  >
                    <TaskCard
                      :task="task"
                      :task-user-id="menteeId"
                      :full-width-on-large="false"
                      due-class="tw-text-red-500"
                      due-prefix="Due"
                      due-date-ref="end_tym"
                    />
                  </ViewMore>
                </div>
              </div>
            </transition>
            <!-- UPCOMING GOAL TASKS (menteeTasks) -->
            <transition name="new" mode="out-in" appear>
              <div v-if="totalTasksUpcoming > 0" class="tw-overflow-x-visible mx-body">
                <div class="tw-flex tw-justify-between">
                  <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Upcoming Tasks</h6>
                  <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksUpcoming }}</h6>
                </div>
                <div class="divider tw-mt-2 tw-border opacity-18"></div>
                <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                  <!-- LIST GRID -->
                  <ViewMore
                    class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-three tw-flex-wrap tw-items-start tw-mt-4"
                    :limit="3"
                    :list="upcomingTasks"
                    v-slot="{ item: task }"
                  >
                    <TaskCard
                      :task="task"
                      :task-user-id="menteeId"
                      :full-width-on-large="false"
                      due-class="tw-text-blue-400"
                      due-prefix="Starting"
                      due-date-ref="start_tym"
                    />
                  </ViewMore>
                </div>
              </div>
            </transition>
            <!-- TASKS ON REVIEW (menteeTasks) -->
            <transition name="new" mode="out-in" appear>
              <div v-if="totalTasksOnReview > 0" class="tw-overflow-x-visible mx-body">
                <div class="tw-flex tw-justify-between">
                  <h6 class="opacity-54 tw-text-xs">Tasks on Review</h6>
                  <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOnReview }}</h6>
                </div>
                <div class="divider tw-mt-2 tw-border opacity-18"></div>
                <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                  <!-- LIST GRID -->
                  <ViewMore
                    class="tw-flex stacked-grid lg:stacked-grid--stack-three tw-flex-wrap tw-items-stretch tw-mt-4"
                    :limit="3"
                    :list="tasksOnReview"
                    v-slot="{ item: task }"
                  >
                    <TaskCard
                      :task="task"
                      :task-user-id="menteeId"
                      :full-width-on-large="false"
                      :show-on-review-badge="false"
                    />
                  </ViewMore>
                </div>
              </div>
            </transition>
            <!-- COMPLETED GOAL TASKS - REVIEWED (menteeTasks) -->
            <transition name="new" mode="out-in" appear>
              <div v-if="totalTasksCompleted > 0" class="tw-overflow-x-visible mx-body">
                <div class="tw-flex tw-justify-between">
                  <h6 class="opacity-54 tw-text-xs">Completed Tasks</h6>
                  <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksCompleted }}</h6>
                </div>
                <div class="divider tw-mt-2 tw-border opacity-18"></div>
                <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                  <!-- LIST GRID -->
                  <ViewMore
                    class="tw-flex stacked-grid lg:stacked-grid--stack-three tw-flex-wrap tw-items-stretch tw-mt-4"
                    :limit="3"
                    :list="completedTasks"
                    v-slot="{ item: task }"
                  >
                    <TaskCard
                      :task="task"
                      :task-user-id="menteeId"
                      :full-width-on-large="false"
                      :show-comment-button="isMyMentor(task.mentorid)"
                    />
                  </ViewMore>
                </div>
              </div>
            </transition>
          </div>
          <div v-else class="c tw-py-24 tw-text-center">
            <!-- svg for you have no task -->
            <h3 class="font-title tw-text-2xl tw-leading-tight tw-font-bold opacity-54 tw-mb-4">You have no ongoing task</h3>
            <h4 class="tw-text-base tw-leading-tight tw-font-bold opacity-54 tw-mb-10">Schedule your first tasks with your mentors</h4>
            <BaseButton
              class="tw-mx-auto tw-my-4 tw-bg-gray-200"
              text="My Mentors"
              to="/app/my-mentors"
            />
          </div>
        </div>

      </transition>
    </div>
  </AppView>
</template>

<script>
import { mapState } from 'vuex';
import MenteeCurrentTasks from '@/views/_partials/MenteeCurrentTasks.vue';
import tasksListMixin from '@/mixins/taskslist';
import TaskCard from '@/components/TaskCard.vue';

export default {
  name: 'MyTasks',
  components: {
    MenteeCurrentTasks,
    TaskCard,
  },
  mixins: [tasksListMixin],
  data() {
    return {
      tasksError: undefined,
      loading: true,
      // menteeTasks: [],
      tasksName: 'menteeTasks',
    };
  },
  computed: {
    ...mapState({
      userName: state => state.User.name || '',
      menteeTasks: state => state.User.mentee.tasks,
      hasGroupTasks: state => state.User.mentee.hasGroupTasks,
      menteeId: state => state.User.id,
      myMentors: state => state.User.mentee.mentors,
    }),
    menteeHasTasks() {
      return this.menteeTasks.length > 0;
    },
  },
  methods: {
    toMentorsPage() {
      this.$router.push({
        name: 'menteeMentorsView',
      });
    },
    isMyMentor(mentorId) {
      return !!this.myMentors.find((mentor) => mentor?.user?.id == mentorId);
    },
  },
  created() {
    this.$store.commit('isFlatView', false);
    // get mentee tasks
    this.$store.dispatch('getMenteeTasks', true)
      .catch((err) => {
        console.error('Error in getting mentee\'s tasks ', err);
        this.tasksError = 'Error in getting your tasks';
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
</style>
