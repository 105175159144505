var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('ContentLoadingTwoGrid',{staticStyle:{"margin-left":"-36px","margin-right":"-36px"}}):(_vm.error)?_c('div',[_c('p',{staticClass:"tw-text-red-400 tw-py-10",domProps:{"innerHTML":_vm._s(_vm.error)}})]):_c('div',[_c('div',[(_vm.classesAreSelectable)?_c('h3',{class:[
        'tw-text-center -font-title tw-font-bold tw-mb-6',
        { 'tw-text-3xl': !_vm.isMobileView },
      ]},[_vm._v(" You need to go to through our MIP Masterclass for you to continue. "),_c('br'),_vm._v(" Please select a cohort to enroll in. ")]):_c('div',[_c('h3',{staticClass:"tw-text-center font-title tw-font-bold tw-mb-6"},[_vm._v(" Your are signed up for the MIP Session below ")])])]),_vm._l((_vm.classes),function(classData){return [_c('div',{key:classData.id,class:[
        'tw-rounded-32 tw-border-2 tw-p-8 tw-mb-6 tw-mt-6 transition-slow hover:tw-shadow-app',
        { 'tw-border-app-white-87': _vm.classesAreSelectable },
        { 'tw-border-app-deep-blue': !_vm.classesAreSelectable },
      ]},[_c('h4',{staticClass:"tw-text-base tw-uppercase tw-mb-4"},[_vm._v(_vm._s(classData.class))]),_c('h5',{staticClass:"tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54"},[_vm._v("Description")]),_c('h4',{class:['tw-text-base tw-mb-4', { 'tw-w-3/4': !_vm.isMobileView }]},[_vm._v(_vm._s(classData.description))]),_c('div',{staticClass:"tw-flex tw-mb-8"},[_c('div',{staticClass:"tw-inline-block tw-mr-8"},[_c('h5',{staticClass:"tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54"},[_vm._v("Start Date")]),_c('h4',{staticClass:"tw-text-base"},[_vm._v(_vm._s(_vm._f("moment")(_vm._f("toJsDate")(classData.start),'Do, MMM YYYY')))])]),_c('div',{staticClass:"tw-inline-block"},[_c('h5',{staticClass:"tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54"},[_vm._v("End Date")]),_c('h4',{staticClass:"tw-text-base"},[_vm._v(_vm._s(_vm._f("moment")(_vm._f("toJsDate")(classData.end),'Do, MMM YYYY')))])])]),(_vm.classesAreSelectable)?_c('div',{staticClass:"tw-flex tw-items-center"},[_c('BaseButton',{staticClass:"tw-mr-2 tw-py-4 tw-px-8 tw-border-app-deep-blue tw-bg-app-deep-blue tw-text-white hover:tw-shadow-app",attrs:{"text":"enroll","title":`enroll to ${classData.class}`,"disabled":_vm.btnIsDisabled},on:{"click":function($event){return _vm.enrollMentor(classData.id)}}})],1):_vm._e()])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }