<template>
  <AppView>
    <TopNav
      slot="top-app-view"
      title="My Mentors"
      title-class="lg:ml-body lg-app:tw-font-bold"
      center-title
      type="menu"
      @input="openSideNav()"
      show-notification-icon
    />

    <transition name="new" mode="out-in" appear>
      <!-- badges -->
      <div class="tw-mx-auto px-body">
        <div class="tw-bg-gray-100 tw-rounded-10 tw-p-2 tw-mt-8 tw-shadow-lg">
          <p class="tw-text-sm opacity-54 tw-mb-2 tw-text-center">Your collected Badges</p>
          <UserGoalsBadges tiny />
        </div>
      </div>
    </transition>

    <!-- MY MEETINGS -->
    <transition
      mode="out-in"
      name="fade"
      appear
      key="my-meetings-list"
    >
      <div class="px-body tw-py-4 tw-w-full tw-border-b" v-if="meetings.length > 0">
        <div>
          <h5 class="opacity-54 tw-mb-2">Meetings with your mentors / mentorship groups</h5>
        </div>
        
        <div class="tw-relative">
          <swiper
            :options="meetingsSwiperOptions"
            ref="my-meetings-slider"
            :key="meetingsSlideKey"
          >
            <swiper-slide
              v-for="({ participants, ...meeting }, key) in meetings"
              :key="key"
              :class="[
                'tw-shrink-0 tw-w-screen tw-pb-4 tw-h-auto',
              ]"
              style="height: auto;"
            >
              <VideoMeetingCard
                :meeting="meeting"
                :participants="participants"
                class="tw-h-full"
              />
            </swiper-slide>

            <button
              slot="button-prev"
              class="tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-ml-2 tw-left-0 tw-right-auto tw-z-20 tw-bg-app-light-blue"
              v-html="leftNav"
              ref="meetings-slider-button-prev"
              @click.stop="meetingPrevSlide()"
              v-if="meetings.length > 3"
            ></button>

            <button
              slot="button-next"
              class="tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-mr-2 tw-right-0 tw-left-auto tw-z-20 tw-bg-app-light-blue"
              v-html="rightNav"
              ref="meetings-slider-button-next"
              @click.stop="meetingNextSlide()"
              v-if="meetings.length > 3"
            ></button>
          </swiper>
        </div>
      </div>
    </transition>

    <!-- GROUP MENTORSHIPS -->
    <transition
      mode="out-in"
      name="fade"
      appear
      key="group-mentorships-list"
    >
      <div class="px-body tw-py-4 tw-w-full" v-if="groupMentorships.length > 0">
        <template v-for="groupMentorship in groupMentorships">
          <MentorshipGroup
            :mentorship-group="groupMentorship"
            :key="groupMentorship.id"
          />
        </template>
      </div>
    </transition>

    <!-- MY MENTORS -->
    <transition mode="out-in" name="fade" appear>
      <ContentLoadingUserList v-if="loading" class="tw-mt-12" />

      <div v-else-if="hasMentors && !loading">
        <transition-group
          key="mentors"
          tag="div"
          name="new"
          mode="out-in"
          appear
          class="lg:mx-body lg:tw-mt-6 tw-flex tw-flex-wrap"
        >
          <template v-for="({ user }, key) in myMentorsSort">
            <div
              :key="`${user.photo}-${key}`"
              :class="[
                'tw-mt-4 mentor-card',
                {
                  'mentor-card--new tw-text-center tw-shadow-app-lg tw-py-8 lg-app:tw-px-6 mx-body lg-app:tw--mx-6 tw-mb-10 tw-rounded-32 tw-border-2' : !hasUserLastMessage(user.id),
                  'my-bg-pattern tw-border-app-primary-blue tw-bg-black tw-text-app-primary-blue lg:tw-w-full' : !hasUserLastMessage(user.id),
                  'lg:tw-w-1/2' : hasUserLastMessage(user.id),
                },
              ]"
            >
              <div class="mx-body lg:tw-mx-0 tw-inline-flex tw-items-center">
                <UserPhoto
                  :photo="user.photo"
                  :user-name="user"
                  photo-size="tw-w-8 tw-h-8 lg:tw-w-16 lg:tw-h-16"
                />
                <h4 class="opacity-87 tw-tracking-wide tw-ml-2 lg:tw-ml-4 tw-text-xl font-title leading-24 lg:leading-tight">{{user.name}} {{user.last_name}}</h4>
              </div>
              <div></div>
              <transition-group name="fade" tag="div" mode="out-in" appear>
                <template v-if="hasUserLastMessage(user.id)">
                  <div
                    key="head"
                    class="lg:tw-flex lg:tw-flex-row lg:tw-items-start lg-grid"
                  >
                    <div class="lg-grid__col-2/3">
                      <router-link
                        tag="div"
                        :class="[
                          'mr-body lg:tw-mr-0 pl-body lg:tw-pl-0 tw-flex sm:tw-inline-flex tw-items-center bg-black-11  tw-mt-2 tw-cursor-pointer',
                          'tw-py-4 lg:tw-py-2 transition-fast tw-pr-4 tw-rounded-r-full lg:tw-rounded-bl-full lg:tw--ml-4 lg:tw-pl-4 min-h-60 sm:tw-min-w-1/2'
                        ]"
                        :to="{ name: 'mentorChat', params: { id: user.id } }"
                      >
                        <div class="tw-flex-shrink-0 tw-relative">
                          <svg
                            :class="[
                              'tw-h-6 tw-w-6 tw-inline-block opacity-54 tw-fill-current',
                              { 'tw-text-app-blue opacity-87': !userLastMessageIsRead(user.id) && !userLastMessageIsSelf(user.id) },
                            ]"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                            <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
                              c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
                              c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
                              C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
                              c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
                              M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
                              S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
                          </svg>
                          <span
                            v-if="!userLastMessageIsRead(user.id) && !userLastMessageIsSelf(user.id)"
                            class="tw-p-1 tw-rounded-full tw-mt-1 tw-bg-blue-500 tw-absolute tw-top-0 tw-right-0"
                          ></span>
                        </div>
                        <p
                          :class="[
                            'opacity-78 mentor-card__chat tw-text-xs tw-overflow-hidden tw-ml-4',
                            { 'tw-text-app-blue': !userLastMessageIsRead(user.id) && !userLastMessageIsSelf(user.id) },
                          ]"
                        >
                          <span v-if="userLastMessageIsSelf(user.id)" class="tw-italic">You: </span>
                          <span
                            :class="{
                              'tw-italic tw-font-bold': userLastMessageIsResource(user.id)
                            }"
                          >
                            {{ userLastMessageText(user.id) }}
                          </span>
                        </p>
                      </router-link>
                    </div>
                    <div class="tw-mt-4 pl-body lg:tw-pl-16 lg-grid__col-1/3" >
                      <!-- MENTORSHIP ACTIVITY... -->
                      <!-- <ContentLoadingTaskList v-if="true || [].length === 0" /> -->
                    </div>
                  </div>
                  <div key="divider" class="divider tw-mt-4 lg:tw-mt-6 tw-border mx-body lg:tw-mx-0 opacity-18--"></div>
                </template>
                <template v-else>
                  <!-- SAY HI MESSAGE -->
                  <p
                    v-if="!hasUserLastMessage(user.id)"
                    class="tw-text-sm tw-mt-4 tw-mb-8"
                    key="ttl"
                  >
                    NEW MENTOR!
                  </p>
                  <p key="msg" class="tw-max-w-md tw-mx-auto tw-text-lg font-nunito tw-mb-8 tw-px-4">
                    You sent {{ user.name }} a request and {{ user.gender.id | genderize(1) }} has accepted to be your mentor.
                    This is your chance to learn as much and grow!
                  </p>
                  <a
                    key="btn"
                    class="tw-bg-app-primary-blue tw-text-app-light-blue tw-border-none tw-py-3 tw-mb-2 tw-px-8 font-title tw-tracking-widest tw-shadow-app-lg"
                    :href="`${appUrl}/app/mentor/${user.id}/chat`"
                  >
                    Break the ice!
                  </a>
                </template>
              </transition-group>
            </div>
          </template>
        </transition-group>
        <div style="height: 6rem;"></div>
      </div>

      <div v-else class="tw-text-center">
        <!-- [GOODIES] SVG FOR WAITING TO BE MATCHED -->
        <p class="opacity-54 tw-text-center tw-text-gray-700 tw-pt-48 tw-inset-x-1/2">Seems you have no mentors</p>
        <BaseButton
          class="tw-mx-auto tw-my-4 tw-bg-gray-200"
          text="Find A Mentor"
          to="/app/request-mentor"
        />
      </div>
    </transition>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import _sortBy from 'lodash/sortBy';
import ContentLoadingUserList from '@/components/ContentLoadingUserList.vue';
import VideoMeetingCard from '@/components/VideoMeetingCard.vue';
import MatchedListMixin from '@/mixins/matchedlist';
import { toBoolean, now } from '@/modules/dataHelper';
import MentorshipGroup from '@/components/MentorshipGroup.vue';
import UserGoalsBadges from '@/components/UserGoalsBadges.vue';

const appUrl = window.location.origin;

export default {
  name: 'MyMentors',
  components: {
    ContentLoadingUserList,
    VideoMeetingCard,
    MentorshipGroup,
    UserGoalsBadges,
  },
  mixins: [MatchedListMixin],
  data() {
    return {
      loading: true,
      appUrl,
      updatedSlideCounts: 0,
      meetingsSwiperOptions: {
        slidesPerView: 3.25,
        spaceBetween: 20,
        observer: true,
        centeredSlides: false,
        breakpoints: {
          // screenWidth <=767px
          767: {
            slidesPerView: 1.25,
            spaceBetween: 10,
          },
        },
      },
      meetings: [],
      groupMentorships: [],
    };
  },
  watch: {
    // [TODO] toast
    // newMentors(oldValue = [], newValue = []) {
    //   if (oldValue.length < newValue.length) {
    //     // Notification!
    //     this.$toasted.info('New mentor!');
    //   }
    // },
  },
  computed: {
    hasMentors() {
      return this.myMentors.length > 0;
    },
    ...mapGetters([
      'myMentors',
      'userLastMessage',
      'userId',
    ]),
    myMentorsSort() {
      return _sortBy(this.myMentors, ({ user }) => this.hasUserLastMessage(user.id));
    },
    meetingsSlideKey() {
      // eslint-disable-next-line no-unused-expressions
      this.updatedSlideCounts;
      return now();
    },
  },
  methods: {
    meetingPrevSlide() {
      this.$refs['my-meetings-slider'].swiper.slidePrev();
    },
    meetingNextSlide() {
      this.$refs['my-meetings-slider'].swiper.slideNext();
    },
    async getMyMeetings() {
      this.meetings = await this.$store.dispatch('getMyActiveMeetings');
    },
    async getGroupMentorships() {
      const groups = await this.$store.dispatch('getMyMentorshipGroups');

      if (Array.isArray(groups)) {
        this.groupMentorships = groups;
      }
    },
  },
  async created() {
    this.$store.commit('isFlatView', true);

    this.getMyMeetings().then(async () => {
      if (this.$refs['my-meetings-slider']) {
        await this.$refs['my-meetings-slider'].swiper.destroy(false, false);
        await this.$refs['my-meetings-slider'].swiper.init();
      }

      await this.$nextTick();
      this.updatedSlideCounts += 1;
    });

    this.getGroupMentorships();

    await this.$store.dispatch('getMyMentors', true)
      .catch((err) => {
        console.warn('Can\'t get mentors', err);
        this.$toasted.global.appError();
      })
      .then((res) => {
        if (!res) return false;
        if (toBoolean(res.error)) {
          this.$toasted.global.appError({ errorMessage: 'We could not get the list of your mentors' });
          return false;
        }
        return true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>

</style>
