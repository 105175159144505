<template>
  <div
    :class="[
      'app-view',
      { 'app-view--full': isFullAppView }
    ]"
  >
    <div class="tw-fixed tw-top-0 tw-z-10 tw-w-full tw-bg-app-light-blue tw-mx-auto" ref="top-app-view">
      <slot name="top-app-view" />
      <portal-target name="top-app-view" />
    </div>
    <div
      :class="['tw-z-0 tw-relative tw-min-h-screen', bodyClass]"
      :style="{
        paddingTop: `${topViewHeight}px`,
        paddingBottom: paddBottom ? `${bottomViewHeight}px` : 'auto',
      }"
    >
      <slot name="default" :recalculate="calculatePaddings" />
    </div>
    <div
      class="tw-fixed tw-bottom-0 tw-z-10 tw-w-full bg-bottom-app transition-fast box-border"
      ref="bottom-app-view"
      :style="`transform: translateY(${showBottomBar ? '0%' : '100%'}) ${isDesktopXxlView ? 'translateX(-50%)' : ''}`"
    >
      <slot name="bottom-app-view" :recalculate="calculatePaddings" />
      <div :class="{ 'tw-absolute tw-bottom-0 tw-w-full': floatBottomAppView }">
        <transition name="new" mode="out-in" appear>
          <portal-target name="bottom-app-view" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppView',
  props: {
    showBottomBar: {
      type: Boolean,
      default: true,
    },
    floatBottomAppView: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: [Array, String, Object],
      default: '',
    },
    paddBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      topViewHeight: 0,
      bottomViewHeight: 0,
    };
  },
  computed: {
    ...mapState({
      isFullAppView: state => state.ui.isFullAppView,
    }),
  },
  methods: {
    calculatePaddings() {
      if (this.$refs['top-app-view']) {
        this.topViewHeight = this.$refs['top-app-view'] ? this.$refs['top-app-view'].offsetHeight : 0;
      }

      if (this.$refs['bottom-app-view']) {
        this.bottomViewHeight = this.$refs['bottom-app-view'].offsetHeight === 0
          ? this.topViewHeight * 1.35
          : this.$refs['bottom-app-view'].offsetHeight * 1.35;
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    await this.$nextTick();

    this.calculatePaddings();
    // Have to recalculate the heights for Portals' sake
    setTimeout(() => {
      this.calculatePaddings();
    }, 180);
  },
};
</script>

<style>
.bg-bottom-app {
  background: rgba(248,249,255,0);
  background: -moz-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,249,255,0)), color-stop(78%, rgba(248,249,255,0.87)), color-stop(100%, rgba(248,249,255,0.87)));
  background: -webkit-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: -o-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: -ms-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: linear-gradient(to bottom, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f9ff', endColorstr='#f8f9ff', GradientType=0 );
}
</style>
