import { apiUrl } from '../../modules/apiHelper';
import { urlFriendly } from '../../modules/dataHelper';

export default {
  name: 'userUiMixin',
  methods: {
    showUserNotifications() {
      this.$store.commit('openRightSideNav', 'Notifications');
      this.$store.commit('showNotifications');
    },
    userPhoto(url = '') {
      return url.length > 0 ? `${apiUrl}/${urlFriendly(url, true)}` : '';
    },
  },
};
