import { mapState } from 'vuex';
import { capitalizeText } from '../modules/dataHelper';

export default {
  name: 'userApp',
  watch: {
    // if user type changed
    //    when the old user type was valid,
    //    and the new type is also valid,
    // logout
    type(newValue, oldValue) {
      if (newValue !== oldValue && !!oldValue === true && !!newValue === true) {
        console.warn('type change');
        const errorMessage = 'Error in your log in details. Please log in again';
        this.$toasted.global.appError({ errorMessage });
        this.logout(errorMessage);
      }
    },
  },
  computed: {
    ...mapState({
      totalNewMessages: state => state.chat.totalNewMessages,
      type: state => state.User.type,
    }),
  },
  methods: {
    async registerUserStoreModule(userTypeName, storeModule) {
      const store = this.$store;

      if (!(store && store.state.User && store.state.User[userTypeName])) {
        await store.registerModule(['User', userTypeName], storeModule);
        // get necessary user data
        // await store.dispatch('getUserData');
      }
    },
    unregisterUserStoreModule(userStoreName) {
      // eslint-disable-next-line no-underscore-dangle
      const userStoreModule = this.$store._modules.root._children.User;
      // eslint-disable-next-line no-underscore-dangle
      // console.log('STORE NAME', userStoreName, userStoreModule._children[userStoreName]);

      // eslint-disable-next-line no-underscore-dangle
      if (userStoreModule._children[userStoreName]) {
        // clear all user type data
        const mutationFnName = `clear${capitalizeText(userStoreName)}Data`;
        this.$store.commit(mutationFnName);
        this.$store.unregisterModule(['User', userStoreName]);
      }
    },
    async logout(authError = undefined) {
      const userStoreName = this.$store.getters.userTypeName;

      this.$toasted.info('Logging you out..', {
        duration: 4500,
      });
      await this.$store.dispatch(
        'userLogOut',
        authError,
      ).then(async () => {
        if (this.$route.name !== 'login') {
          await this.$router.push({ name: 'login' });
          await this.$nextTick();
          await this.unregisterUserStoreModule(userStoreName);
        }
      });
    },
  },
};
