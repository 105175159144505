var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.size === 'small' || _vm.size === 'tiny')?_c('BaseCard',{class:[
    'tw-shadow-app tw-rounded-10 tw-rounded-tr-32 tw-p-5',
    {'tw-bg-white': _vm.licenseStatus},
    {'tw-bg-red-200': !_vm.licenseStatus},
  ]},[_c('p',{staticClass:"font-title tw-mb-1"},[_vm._v(_vm._s(_vm.license.name))])]):_c('BaseCard',{class:[
    'tw-p-3 tw-border',
    {'tw-border-app-deep-blue': _vm.licenseStatus},
    {'tw-border-red-600': !_vm.licenseStatus},
  ],nativeOn:{"click":function($event){return _vm.cardClick($event)}}},[_c('h5',{staticClass:"tw-text-sm tw-uppercase opacity-31 tw-font-bold tw-mb-2 leading-19"},[_vm._v("Your current license package")]),_c('div',{staticClass:"tw-inline-flex tw-justify-between tw-mb-3 tw-items-center"},[_c('p',{class:[
        'font-title tw-font-bold tw-text-2xl',
        {'tw-text-app-deep-blue': !_vm.selectable}
      ]},[_vm._v(_vm._s(_vm.license.name))]),(_vm.showAmount)?_c('p',{staticClass:"font-title tw-font-bold tw-text-3xl tw-ml-4"},[_vm._v("ksh."+_vm._s(_vm.license.amount))]):_vm._e()]),_c('h5',{staticClass:"tw-text-sm tw-uppercase opacity-31 tw-font-bold leading-19"},[_vm._v("Description")]),_c('p',{staticClass:"tw-text-sm opacity-78 tw-mb-4"},[_vm._v(_vm._s(_vm.license.description))]),_vm._t("details")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }