var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"new","mode":"out-in","appear":""}},[_c('BaseCard',{staticClass:"tw-p-6 tw-pb-8 tw-items-center"},[_c('div',{staticClass:"tw-text-center"},[_c('h5',{staticClass:"tw-text-xs leading-15 tw-font-bold tw-py-2 tw-text-center tw-text-white--"},[_vm._v("Click the circle below to add Profile Photo")]),_c('div',{class:[
          'tw-inline-block tw-w-20 tw-h-20 tw-rounded-full tw-relative tw-cursor-pointer tw-flex-shrink-0 tw-border-2 tw-border-app-primary-blue',
          {'bg-white-11-- bg-black-11' : _vm.loadingPhoto},
        ],on:{"click":_vm.promptUpdatePhoto}},[(_vm.isValidPhoto && !_vm.loadingPhoto)?_c('img',{staticClass:"tw-w-full tw-h-full tw-object-cover tw-rounded-full",attrs:{"src":_vm.form.photo}}):_vm._e(),_c('input',{ref:"photo-input",staticClass:"tw-hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.addUserPhoto}})]),_c('br'),(!_vm.isMobileView)?_c('CameraPhoto',{model:{value:(_vm.form.photoFileFromCamera),callback:function ($$v) {_vm.$set(_vm.form, "photoFileFromCamera", $$v)},expression:"form.photoFileFromCamera"}}):_vm._e()],1),_c('div',{staticClass:"tw-mt-8 tw-max-w-xl tw-w-full tw-mx-auto"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-mb-2"},[_c('h5',{staticClass:"tw-text-xs leading-15 tw-font-bold tw-py-2 tw-text-center tw-text-white--"},[_vm._v("Bio")]),_c('label',{staticClass:"tw-text-xs tw-text-white-- opacity-78"},[_c('span',{class:{
            'tw-text-red-500': !_vm.canSaveBio,
            'tw-text-blue-500': _vm.canSaveBio
          }},[_vm._v(_vm._s(_vm.bioLength))]),_vm._v(" / "+_vm._s(_vm.maxBioLength)+" ")])]),_c('BaseTextarea',{staticClass:"tw-border-b bg-black-11 bg-white-11-- focus:bg-white-11-- hover:bg-white-11-- tw-text-white--",attrs:{"rows":"4","max-height":"45vh"},model:{value:(_vm.form.bio),callback:function ($$v) {_vm.$set(_vm.form, "bio", $$v)},expression:"form.bio"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }