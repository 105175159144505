<template>
  <div class="tw-relative">
    <transition name="fade" mode="out-in">
      <template v-if="loading">
        <div class="tw-flex tw-h-screen tw-w-full tw-justify-center tw-items-center tw-bg-white">
          <p class="opacity-54 tw-italic">
            Please wait...
          </p>
        </div>
      </template>

      <template v-else>
        <div class="tw-h-screen tw-w-full tw-bg-white tw-flex tw-flex-col tw-relative">
          <div class="tw-flex tw-flex-wrap tw-full tw-py-3 tw-items-center mx-body sm:tw-border-none tw-border-b tw-border-gray-800 tw-relative tw-z-20">
            <div class="tw-flex-shrink-0 tw-flex tw-items-center tw-mb-4 tw-bg-white tw-p-3 tw-rounded-32">
              <img
                src="/img/ema-logo.png"
                key="ema-logo"
                alt="eMentoring Africa"
                width="auto"
                class="tw-h-10 tw-w-auto tw-max-w-3xl tw-object-contain tw-block tw-flex-shrink-0 transform-scale-0"
              >
              <div style="height: 40px; width: 1px; transform: rotate(14deg)" class="tw-mx-4 tw-bg-gray-800"></div>
              <img
                class="tw-object-contain tw-h-10 tw-inline-block"
                data-src="/img/usaid-logo.png"
                src="/img/usaid-logo.png"
              >
              <div style="height: 40px; width: 1px; transform: rotate(14deg)" class="tw-mx-4 tw-bg-gray-800"></div>  
              <div>
                <img
                  :src="appLogoUrl"
                  :key="appLogoUrl"
                  alt="eMentored"
                  width="auto"
                  class="tw-h-12 tw-w-auto tw-max-w-3xl tw-object-contain tw-block tw-flex-shrink-0 transform-scale-0"
                >
              </div>
            </div>

            <div class="tw-flex-grow"></div>

            <BaseButton text="Become a Mentor" to="/mentor-signup" class="tw-border-none tw-py-3 tw-px-5 sm:tw-mr-3 tw-mb-2 sm:tw-mb-0 tw-ml-auto sm:tw-ml-0 tw-bg-app-primary-blue tw-text-white" />
            <BaseButton text="Find a Mentor" to="/mentee-signup" class="tw-border-none tw-py-3 tw-px-5 tw-bg-app-primary-blue tw-text-white tw-ml-auto sm:tw-ml-0" />
          </div>
          <div class="tw-flex tw-w-full tw-h-full tw-relative tw-z-20">
            <!-- <div class="tw-hidden md:tw-block md:tw-w-1/6 tw-mt-3 tw-bg-black my-bg-pattern tw-rounded-tr-55 tw-overflow-hidden">
              <img src="/img/KenGen--cropped.jpg" class="tw-w-full tw-h-full tw-object-cover" alt="KenGen Plant" />
            </div> -->
            <img src="/img/Logo Log-In Page.jpeg" class="tw-w-full tw-h-full tw-object-contain sm:tw-top-0 tw-top-10 tw-absolute tw-left-0 tw-z-0" alt="KenGen Plant" />

            <div class="tw-w-full px-body md:tw-px-0 tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-z-10">
              <BaseCard class="tw-p-4 md:tw-px-8 md:tw-py-16 tw-shadow-none md:tw-shadow-app tw-bg-white tw-w-full tw-max-w-md tw-flex-shrink-0 tw-mb-20 auth-card">
                <div>
                  <h1 class="tw-text-4xl tw-text-center">Login</h1>
                  <div class="tw-h-4 tw-block"></div>
                  <form
                    ref="login-form"
                    id="login-form"
                    method="post"
                    action="/login"
                    @submit.prevent="onSubmit"
                  >
                    <div class="tw-block tw-my-4 tw-shadow-md tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-sm" v-if="errors.auth">
                      {{ errors.auth }}
                    </div>
                    <div class="">
                      <InputGroup
                        label="email address"
                        name="email"
                        type="email"
                        placeholder="email"
                        v-model="email"
                        :error="errors.email"
                      />
                    </div>
                    <div class="tw-mb-8">
                      <InputGroup
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="password"
                        v-model="password"
                        :error="errors.password"
                      />
                    </div>
                    <div class="flex items-center justify-between">
                      <BaseButton
                        type="submit"
                        :text="btnText"
                        class="tw-py-3"
                        :disabled="!canSubmit || btnDisabled"
                      />
                      <router-link
                        class="tw-inline-block tw-ml-2 tw-py-2 tw-align-baseline tw-font-bold tw-text-sm tw-text-blue-500
                        my-btn hover:tw-text-blue-800"
                        to="forgot-password"
                      >Forgot Password?</router-link>
                    </div>
                  </form>
                </div>
              </BaseCard>
            </div>
          </div>

          <img src="/img/login KG COLOR@compressed@resized.png" class="tw-w-full tw-h-full tw-object-cover tw-absolute tw-left-0 tw-top-0 tw-z-0" alt="" />
        </div>
      </template>
    </transition>

    <!-- <UserSupport /> -->
    <JivoChat :destroy="destroyJivo" />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import InputGroup from '@/components/InputGroup.vue';
import form from '@/modules/formHelper';
import { apiStore, apiPost } from '@/modules/apiHelper';
import { toBoolean } from '@/modules/dataHelper';
import authMixin from '@/mixins/auth';
import visitorsMixin from '@/mixins/visitors';
import EmentoredAppLogo from '@/components/EmentoredAppLogo.vue';
// import UserSupport from '@/components/UserSupport.vue';
import JivoChat from '@/components/JivoChat.vue';

export default {
  name: 'Login',
  components: {
    InputGroup,
    EmentoredAppLogo,
    // UserSupport,
    JivoChat,
  },

  mixins: [authMixin, visitorsMixin],
  data() {
    return {
      loading: true,
      btnText: 'Log in',
      btnDisabled: false,
      email: undefined,
      password: undefined,
      errors: {
        email: undefined,
        auth: this.$store.state.User.authError,
      },
      destroyJivo: 0,
    };
  },
  computed: {
    canSubmit() {
      return form.isValidEmail(this.email)
        && form.isValidTextInput(this.password, true, form.passwordRegex);
    },
    formData() {
      const formData = new FormData();

      formData.set('email', this.email);
      formData.set('password', this.password);

      return formData;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    email: _debounce(function (val) {
      if (!form.isValidEmail(val)) {
        this.errors.email = 'Invalid email address';
      } else {
        this.errors.email = undefined;
      }
    }, 300),
    // eslint-disable-next-line func-names
    password: _debounce(function (val) {
      if (!form.isValidTextInput(val, true, form.passwordRegex)) {
        this.errors.password = 'Tip: Your password is atleast 8 characters long';
      } else {
        console.log('cleared!');
        this.errors.password = undefined;
        this.errors.password = undefined;
        this.errors.password = undefined;
      }
    }, 150),
  },
  methods: {
    onSubmit() {
      if (!this.canSubmit) {
        this.$store.commit('authError', 'Cannot submit the form. Please check for empty fields');
        // this.$toasted.global.appError({ errorMessage: 'Cannot submit the form. Please check for empty fields', duration: 4000, position: 'top-right' });
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';
      this.$store.commit('authError', undefined);

      return apiPost('login', this.formData)
        .catch((err) => {
          console.warn('login error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnDisabled = false;
          this.btnText = 'Log in';
          if (!res) return false;

          const { data } = res;
          if (toBoolean(data.error)) {
            this.$store.commit('authError', data.message);
            this.$toasted.global.appError({ errorMessage: data.message, duration: 4000, position: 'top-right' });
            return false;
          }

          // unregister all usertType modules
          this.unregisterUserStoreModule('mentor');
          this.unregisterUserStoreModule('mentee');

          if (data.status === 'Verify') {
            // redirect to verify
            this.$router.push({
              name: 'verify-account',
              query: {
                email: this.email,
                s: 'ev',
              },
            });

            return true;
          }

          await this.$store.dispatch('clearUserSession');
          this.$store.commit('authError', false);
          // [TODO] Dynamic license
          //        if licence is an array, show a view for the user to choose the space
          await this.$store.dispatch('userLogIn', data);

          this.$toasted.success(data.message, {
            duration: 4000,
            theme: 'outline',
            position: 'bottom-right',
          });

          await apiStore.removeItem('forgotpasswordsent');
          await this.$nextTick();
          await this.$nextTick();

          if (typeof window.jivo_destroy === 'function') {
            console.log('destroyed from login');
            this.destroyJivo =+ 1;
            window.jivo_destroy()
          }

          /**
           * RELOADING HELPS IN REGISTERING THE PARENT COMPONENT (MENTOR/MENTEE)APP
           * SOMEHOW THE /APP COMPONENT FUNCTION DOES NOT RUN IF NOT RELOADED
           * [TODO] FIX THIS TO JUST USE $router FUNCTIONS
           */
          window.location.reload();

          // await this.$router.push({ name: 'app' })
          //   .catch((err) => {
          //     // TODO: Redirect by old js to an 'Oops' page
          //     console.warn('error in routing', err);
          //   });

          return true;
        });
    },
  },
  async created() {
    await this.unregisterAuthStoreModule();
    // if user is logged in, redirect to app
    const to = sessionStorage.getItem('after-login-redirector') || '/app';
    if (this.$store.getters.isLoggedIn) {
      this.$router.push(to);
      sessionStorage.removeItem('after-login-redirector');
    } else {
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      this.loading = false;
    }
  },

};
</script>

<style>

</style>
