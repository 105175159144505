<template>
  <!-- // * NOTE: DON'T USE AppView COMPONENT, UNLESS <portal> -->
  <transition name="fade" mode="out-in">
    <div v-if="error">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center"> {{ error }} </p>
    </div>

    <div v-else-if="loading">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center">Loading...</p>
    </div>

    <div v-else-if="goals.length" class="tw-mt-8 px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-1/3 lg:tw-order-last">
        <div class="tw-border-app-deep-blue tw-border tw-p-4 tw-rounded-lg">
          <h6 class="tw-text-xs tw-ml-1 tw-mr-2 lg:tw-text-right">Goal</h6>
          <v-select
            class="my-select my-select--huge tw-w-full lg:tw-text-right"
            placeholder=""
            label="goal"
            :value="selectedGoalId"
            @input="setSelectedGoalId"
            :options="goals"
            :reduce="goal => goal.id"
            :clearable="false"
            :filterable="false"
            :searchable="false"
            append-to-body
            :calculate-position="dropdownPosition"
            v-if="goals.length > 1"
          />
          <div v-else>
            <h3 class="font-title leading-30 tw-text-xl tw-text-black tw-w-full lg:tw-text-right tw-px-1"> {{ goals[0].goal }} </h3>
            <div class="tw-border tw-border-black opacity-54 tw-mt-1"></div>
          </div>
        </div>
        <!-- SELECTED GOAL INFO -->
        <transition name="fade" mode="out-in">
          <div class="tw-mt-4 tw-mb-8 tw-flex tw-flex-wrap" v-show="selectedGoalId">
            <div class="tw-w-1/2">
              <h4 class="leading-14 font-14 tw-text-gray-700 tw-mb-1">{{ totalSelectedGoalTasks }} {{'task' | pluralize(totalSelectedGoalTasks, 'tasks') }}</h4>
              <h4 v-show="!!totalTasksCompleted" class="leading-14 font-14 tw-text-gray-700 tw-mb-1">{{ totalTasksCompleted }} {{'task' | pluralize(totalTasksCompleted, 'tasks') }} completed</h4>
              <h4 v-show="!!totalTasksOverdue" class="leading-14 font-14 tw-text-red-700">{{ totalTasksOverdue }} {{'task' | pluralize(totalTasksOverdue, 'tasks') }} overdue</h4>
            </div>
            <div class="tw-w-1/2 tw-flex tw-flex-col tw-items-end">
              <BaseButton
                v-if="false"
                text="More info"
                class="tw-text-right"
                icon
                @click="showSelectedGoalDetails()"
              >
                <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M36,72c-9.5,0-18.7-3.8-25.4-10.6c-14-14-14-36.9,0-50.9C17.3,3.8,26.5,0,36,0s18.7,3.8,25.4,10.6c14,14,14,36.9,0,50.9
                    C54.7,68.2,45.5,72,36,72z M36,3.6c-8.6,0-16.7,3.4-22.9,9.5C0.5,25.7,0.5,46.3,13.1,59c6.1,5.9,14.2,9.4,22.9,9.4
                    s16.7-3.4,22.9-9.5l0,0c12.6-12.6,12.6-33.1,0-45.9C52.7,7,44.6,3.6,36,3.6z M38.7,22.5c-0.7,0.7-1.4,0.9-2.3,0.9
                    c-0.9,0-1.8-0.4-2.3-0.9c-0.7-0.7-0.9-1.4-0.9-2.3s0.4-1.8,0.9-2.3c0.7-0.7,1.4-0.9,2.3-0.9c0.9,0,1.6,0.4,2.3,0.9
                    c0.7,0.7,0.9,1.4,0.9,2.3S39.4,22,38.7,22.5z M33.8,53.3V29c0-0.4,0.4-0.7,0.7-0.7h3.6c0.4,0,0.7,0.4,0.7,0.7v24.5
                    c0,0.4-0.4,0.7-0.7,0.7h-3.6C34.2,54,33.8,53.6,33.8,53.3z"/>
                </svg>
              </BaseButton>
              <div v-else>
                <p class="font-title tw-text-2xl leading-28 tw-text-right tw-font-extrabold">{{ totalTasksCompleted | percent(totalSelectedGoalTasks) }}</p>
                <p class="font-title tw-text-2xl leading-17 tw-text-right tw-font-medium">complete</p>
              </div>
            </div>
            <div class="tw-w-full tw-text-right">
              <BaseButton
                text="Create task"
                class="tw-inline-block tw-my-6"
                icon
                v-show="selectedGoalTasks.length > 0"
                :to="{ name: 'mentorMenteeCreateTask', query: { goal: selectedGoalId } }"
              >
                <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                    c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
                </svg>
              </BaseButton>
            </div>
            <div class="tw-w-full tw-mt-8 tw-hidden lg:tw-block">
              <p class="tw-text-right tw-text-sm leading-17 opacity-31 tw-w-full" v-html="selectedGoal.description">
              </p>
              <!-- <BaseButton
                text="Comment"
                class="tw-text-center tw-text-white tw-bg-black tw-block tw-w-full tw-mt-8"
                icon
              >
                <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
                    c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
                    c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
                    C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
                    c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
                    M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
                    S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
                </svg>
              </BaseButton> -->
            </div>
          </div>
        </transition>
      </div>

      <div class="lg-grid__col-2/3 lg:tw-pr-8">
        <div class="tw-p-2 tw-border tw-border-app-primary-blue tw-rounded-10 tw-mb-4" v-if="canMarkMatchComplete">
        <!-- <div class="tw-p-2 tw-border tw-border-app-primary-blue tw-rounded-10 tw-mb-4" v-if="true"> -->
          <p class="tw-mb-2 tw-text-sm tw-text-app-primary-blue">
            {{mentee?.user?.name || 'Mentee'}} has completed all your tasks. <br />
            You can mark your session as complete. <br />
            <span class="tw-italic tw-text-xs tw-font-bold">You can still continue your mentorship if you would like so</span>
          </p>
          <BaseButton
            :text="loadingEndingMatch ? 'Working...' : 'Mark Complete'"
            class="tw-inline-block tw-bg-app-primary-blue tw-text-white tw-py-3 tw-border-0 tw-shadow-app"
            @click="endMatchSession"
            :disabled="loadingEndingMatch"
          />
        </div>

        <div v-if="selectedGoalTasks.length === 0" class="px-body tw-py-16 tw-text-center">
          <!-- svg for has no task -->
          <h3 class="font-title tw-text-2xl tw-leading-tight tw-font-bold opacity-54 tw-mb-4">Mentee has no task in "{{ selectedGoal.goal }}"</h3>
          <h4 class="tw-text-base tw-leading-tight tw-font-bold opacity-54 tw-mb-8">Create his first task</h4>
          <BaseButton
            text="Create Task"
            class="tw-my-4 tw-py-3 tw-bg-gray-200"
            icon
            :to="{ name: 'mentorMenteeCreateTask', query: { goal: selectedGoalId } }"
          >
            <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
            </svg>
          </BaseButton>
        </div>
        <div v-else>

          <!-- TASKS ON REVIEW GOAL TASK (selectedGoalTasks) -->
          <transition name="new" mode="out-in" appear>
            <TasksOnReview v-if="totalTasksOnReview > 0" :tasks="selectedGoalTasks" />
          </transition>

          <!-- ONGOING GOAL TASK (selectedGoalTasks) -->
          <transition name="new" mode="out-in" appear>
            <div v-if="totalTasksOngoing > 0" class="tw-overflow-x-visible">
              <div class="tw-flex tw-justify-between">
                <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Ongoing Tasks</h6>
                <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOngoing }}</h6>
              </div>
              <div class="divider tw-mt-2 tw-border opacity-18"></div>
              <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                  <!-- date-class="tw-text-white opacity-78" -->
                <!-- LIST GRID -->
                <ViewMore
                  :limit="1"
                  :list="ongoingTasks"
                  v-slot="{ item: task }"
                  more-btn-text="More Ongoing Tasks"
                >
                  <TaskCard
                    :task="task"
                    :task-user-id="menteeId"
                    :full-width-on-large="true"
                    :show-task-details-on-click="true"
                    :show-mentor="false"
                    due-class="tw-text-green-500 tw-bg-blue-100 tw-inline-block tw-rounded-full tw-px-2 tw-py-1"
                    due-prefix="Due"
                    due-date-ref="end_tym"
                    date-class="tw-text-white-- opacity-78"
                    class="lg:tw-pt-2 tw-mb-4 tw-border tw-border-app-deep-blue tw-bg-app-deep-blue-- tw-text-white-- tw-shadow-app"
                  />
                </ViewMore>
              </div>
            </div>
          </transition>

          <!-- OVERDUE GOAL TASKS (selectedGoalTasks) -->
          <transition name="new" mode="out-in" appear>
            <div v-if="totalTasksOverdue > 0" class="tw-overflow-x-visible tw-mt-10">
              <div class="tw-flex tw-justify-between">
                <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Overdue Tasks</h6>
                <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOverdue }}</h6>
              </div>
              <div class="divider tw-mt-2 tw-border opacity-18"></div>
              <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                <!-- LIST GRID -->
                <ViewMore
                  class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                  :limit="4"
                  :list="overdueTasks"
                  v-slot="{ item: task }"
                >
                  <TaskCard
                    :task="task"
                    :task-user-id="menteeId"
                    :full-width-on-large="false"
                    :show-mentor="false"
                    due-class="tw-text-red-500"
                    due-prefix="Due"
                    due-date-ref="end_tym"
                  />
                </ViewMore>
              </div>
            </div>
          </transition>

          <!-- UPCOMING GOAL TASKS (selectedGoalTasks) -->
          <transition name="new" mode="out-in" appear>
            <div v-if="totalTasksUpcoming > 0" class="tw-overflow-x-visible">
              <div class="tw-flex tw-justify-between">
                <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Upcoming Tasks</h6>
                <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksUpcoming }}</h6>
              </div>
              <div class="divider tw-mt-2 tw-border opacity-18"></div>
              <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                <!-- LIST GRID -->
                <ViewMore
                  class="tw-flex stacked-grid stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                  :limit="2"
                  :list="upcomingTasks"
                  v-slot="{ item: task }"
                >
                  <TaskCard
                    :task="task"
                    :task-user-id="menteeId"
                    :full-width-on-large="false"
                    :show-mentor="false"
                    due-class="tw-text-blue-400"
                    due-prefix="Starting"
                    due-date-ref="start_tym"
                  />
                </ViewMore>
              </div>
            </div>
          </transition>

          <!-- COMPLETED GOAL TASKS (selectedGoalTasks) -->
          <transition name="new" mode="out-in" appear>
            <div v-if="totalTasksCompleted > 0" class="tw-overflow-x-visible">
              <div class="tw-flex tw-justify-between">
                <h6 class="opacity-54 tw-text-xs">Completed Tasks</h6>
                <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksCompleted }}</h6>
              </div>
              <div class="divider tw-mt-2 tw-border opacity-18"></div>
              <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                <!-- LIST GRID -->
                <ViewMore
                  class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4"
                  :limit="4"
                  :list="completedTasks"
                  v-slot="{ item: task }"
                >
                  <TaskCard
                    :task="task"
                    :task-user-id="menteeId"
                    :full-width-on-large="false"
                    :show-mentor="false"
                    due-class="tw-text-blue-400"
                  />
                </ViewMore>
              </div>
            </div>
          </transition>

        </div>
      </div>
    </div>

    <!-- <div v-else class="px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-2/3">
        <h3 class="opacity-78 tw-text-sm tw-text-center tw-px-1 tw-py-12">
          {{ (mentee.user || {}).name || 'Mentee' }} has not chosen {{ ((mentee.user || {}).gender || {}).id || '' | genderize(3) }} goals yet
        </h3>
      </div>
    </div> -->

  </transition>
</template>

<script>
import { mapMutations } from 'vuex';
import TasksOnReview from '@/views/_partials/TasksOnReview.vue';
import TaskCard from '@/components/TaskCard.vue';
import tasksListMixin from '@/mixins/taskslist';

const minimumTasksToComplete = 3;

export default {
  components: {
    TasksOnReview,
    TaskCard,
  },
  mixins: [tasksListMixin],
  data() {
    return {
      menteeId: this.$route.params.id,
      loading: true,
      error: undefined,
      loadingEndingMatch: false,
      goals: [[]],
      tasks: [],
      tasksName: 'selectedGoalTasks',
    };
  },
  props: ['id', 'mentee'],
  computed: {
    selectedGoal() {
      return this.goals.find(g => g.id === this.selectedGoalId);
    },
    selectedGoalId() {
      return this.findViewState(`menteeViewSelectedGoal/${this.id}`) || (this.goals[0] || {}).id;
    },
    selectedGoalTasks() {
      if (!this.selectedGoalId) {
        this.$toasted.info('Select a goal to view its task', {
          duration: 5000,
        });

        return [];
      }

      return this.tasks.filter(t => t.subgoalid === this.selectedGoalId);
    },
    totalSelectedGoalTasks() {
      if (!this.selectedGoalId) {
        return undefined;
      }
      return this.totalTasks;
    },
    canMarkMatchComplete() {
      return this.totalSelectedGoalTasks == this.totalTasksCompleted
        && this.totalTasksCompleted >= minimumTasksToComplete
        && this.totalSelectedGoalTasks > 0
    }
  },
  methods: {
    ...mapMutations([
      'updateUserViewStates',
    ]),
    setSelectedGoalId(val) {
      this.updateUserViewStates({
        [`menteeViewSelectedGoal/${this.id}`]: val,
      });
    },
    showSelectedGoalDetails() {
      console.log('more info...');
    },
    async endMatchSession() {
      this.loadingEndingMatch = true;
      const response = await this.$store.dispatch('endMatchSession', {
        menteeid: this.menteeId,
        goalid: this.selectedGoalId,
      });

      if (response) {
        this.$toasted.info('Please fill this short survey', { duration: 4000 });
        this.$router.push(`/app/surveys/matchended-survey?goalid=${this.selectedGoalId}`);
        
        this.$store.commit('removeMentee', this.menteeId);
        await this.$store.dispatch('getMyData');

        this.loadingEndingMatch = false;

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      } else {
        this.$toasted.global.appError();
      }
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('getMatchGoal', this.menteeId),
      this.$store.dispatch('tasksList', [this.$store.state.User.id, this.menteeId]),
    ])
      .catch((err) => {
        console.warn('Error in getting mentee ', this.$attrs, ': ', err);
        this.$toasted.global.appError();
        this.error = 'Mentee tasks could not be found!';
      })
      .then((res) => {
        // console.log(res);
        if (!res[0] || !res[1]) return false;

        this.goals = [res[0] || {}];
        this.tasks = res[1] || [];

        this.loading = false;
        this.error = undefined;
        return true;
      });

    this.$emit('view-mounted');
  },
};
</script>

<style>

</style>
