<template>
  <div class="tw-relative tw-w-full tw-py-1">
    <div :class="['tw-flex tw-items-start', {'tw-justify-end': textIsSelf}]">
      <div v-if="!textIsSelf" class="tw-min-w-8 tw-ml-8">
        <!-- userphoto -->
        <UserPhoto
          :photo="user.photo"
          :user-name="user"
          photo-size="tw-h-8 tw-w-8"
          slot="title-left"
          class="tw-inline-block tw-mx-2 tw-bg-gray-300"
          :title="user.name"
        />
      </div>
      <!-- v-if="isResourceRefOnly" -->
      <div
        v-if="isResourceRefOnly"
        :class="[
          'chat-bubble chat-bubble--resource tw-overflow-visible box-border lg:tw-rounded-55 tw-fill-current',
          { 'chat-bubble--right lg:tw-mr-2 tw-rounded-l-55 tw-rounded-r-none' : textIsSelf },
          { 'chat-bubble--left tw-rounded-r-55 tw-rounded-l-55 tw-border tw-border-app-primary-blue' : !textIsSelf }
        ]"
      >
        <div :class="[
          'tw-py-2',
          {'tw-pl-12 pr-body lg:tw-pr-10 lg:tw-mr-2': textIsSelf},
          {'tw-pr-12 pl-body lg:tw-pl-10 lg:tw-ml-2': !textIsSelf},
          ]"
        >
          <!-- ADD FILE PREVIEW COMPONENT HERE -->
          <div class="tw-pr-4">
            <p class="tw-text-xs opacity-31 tw-uppercase">RESOURCE</p>
            <a
              v-if="isLinkResourceRef"
              class="tw-underline tw-font-bold tw-text-sm leading-17 tw-mb-2 tw-break-all tw-text-app-primary-blue"
              :href="resource.resource"
              target="_blank"
            >
              {{ resource.title || resource.name || resource.data }}
            </a>
            <h4 v-else class="tw-font-bold tw-text-sm leading-17 tw-mb-2 opacity-54 tw-break-all">
              {{ resource.name || resource.data }}
            </h4>
            <div class="tw-flex tw-justify-between tw-items-center opacity-54">
              <button
                @click.prevent="downloadResource(resource.data, resource.name)"
                class="tw-p-1 opacity-54"
                v-if="!isLinkResourceRef"
              >
                <svg class="tw-w-4 tw-h-4 transform-scale-0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                    M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                    c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                    C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                </svg>
              </button>
              <div v-if="isLinkResourceRef"></div>
              <p v-if="isLinkResourceRef" class="opacity-31 tw-uppercase tw-text-xs leading-14">
                LINK
              </p>
              <p v-else class="opacity-31 tw-uppercase tw-text-xs leading-14">
                {{ resourceType(resource.file_type) }}
              </p>
            </div>
            <div class="tw-flex tw-items-center">
            <span
              :class="[
                'opacity-54 tw-mx-2 tw-my-1 tw-text-xs leading-14 tw-font-light tw-block tw-flex-grow',
                { 'tw-text-right' : textIsSelf },
                { 'tw-text-left' : !textIsSelf },
              ]"
            >{{ message.tym | toJsDate(true) | moment('ddd Do hh:mm A') }}</span>
            <!-- READ/UNREAD TICKS -->
            <span
              v-if="textIsSelf"
              :class="[
              'tw-fill-current tw-my-1 tw-flex-shrink-0',
              { 'tw-text-green-400': read},
              { 'tw-text-gray-500': !read},
              ]"
            >
              <svg class="tw-h-3 tw-w-3 transform-scale-0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <g>
                  <path d="M34.6,58.7c-0.8,0-1.6-0.4-2.2-1l-16.7-18c-1.2-1.3-1.1-3.4,0-4.7c1.2-1.3,3.1-1.2,4.3,0l14.5,15.6l32.1-36.4
                    c1.1-1.3,3.1-1.4,4.3-0.2c1.2,1.2,1.3,3.3,0.2,4.7L36.9,57.7C36.3,58.3,35.5,58.7,34.6,58.7L34.6,58.7z"/>
                  <path d="M35.2,40.9l19.1-21.7c1-1.1,1.2-2.9,0.4-4.2c-1.1-1.8-3.4-1.9-4.7-0.4L30.5,36.7c-1.1,1.2-1.1,3.1,0.2,4.3l0,0
                    C31.9,42.3,34,42.2,35.2,40.9z"/>
                  <path d="M22.1,52.8L5.8,35.1c-1-1.1-2.4-1.4-3.8-1c-2.1,0.8-2.6,3.3-1.2,4.8L17.4,57c1.2,1.4,3.4,1.4,4.6,0l0,0
                    C23.2,55.9,23.2,54,22.1,52.8z"/>
                </g>
              </svg>
            </span>
          </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="tw-text-xs opacity-78 tw-ml-4 tw-text-app-primary-blue tw-mb-0.5" v-if="!textIsSelf">
          {{user.name}} {{user.last_name}}
        </p>
        <div
          :class="[
            'chat-bubble tw-overflow-visible tw-fill-current',
            { 'chat-bubble--right mr-body-- tw-mr-4' : textIsSelf },
            { 'tw-rounded-tl-32' : isAReply && textIsSelf },
            { 'tw-rounded-tr-32' : isAReply && !textIsSelf },
            { 'chat-bubble--left tw-ml-4 tw-bg-app-primary-blue tw-text-app-light-blue' : !textIsSelf },
          ]"
        >
          <div v-if="isAReply" class="tw-overflow-auto">
            <div
              :class="[
                'tw-mt-2 tw-py-2 bg-black-11',
                { 'tw-rounded-l-32 tw-pl-8 tw-pr-2 tw-ml-2' : textIsSelf },
                { 'tw-rounded-r-32 tw-pr-8 tw-pl-2 tw-mr-2' : !textIsSelf },
              ]"
            >
              <p class="tw-text-xs opacity-31 tw-uppercase">{{userRepliedTo.name}}</p>
              <h4 class="tw-font-bold tw-text-sm leading-17 tw-mb-2 opacity-54" v-html="htmlizeLinksInString(messageRepliedTo.chat_message)">
              </h4>
            </div>
          </div>
          <div class="tw-overflow-auto tw-relative">
            <p class="tw-text-sm opacity-87 leading-17 tw-mt-2 tw-mx-2 tw-break-words tw-whitespace-pre-wrap" v-html="htmlizeLinksInString(message.chat_message || '...')">
            </p>
            <div class="tw-flex tw-flex-col">
              <!-- READ/UNREAD STATS -->
              <!-- <span
                :class="[
                  'tw-mx-2 tw-mt-2 tw-text-xs leading-14 tw-font-light',
                  { 'tw-text-right opacity-31' : textIsSelf },
                  { 'tw-text-left opacity-54' : !textIsSelf },
                ]"
              >
                20 read, 10 unread
              </span> -->
              <span
                :class="[
                  'tw-mx-2 tw-my-2 tw-text-xs leading-14 tw-font-light',
                  { 'tw-text-right opacity-31' : textIsSelf },
                  { 'tw-text-left opacity-54' : !textIsSelf },
                ]"
              >{{ message.tym | toJsDate | moment('ddd Do hh:mm A') }}</span>
            </div>
          </div>

          <!-- chat bubble ticks -->
          <svg
            v-if="textIsSelf"
            class="tw-absolute tw-top-0 tw-right-0 tw-w-2 tw--mr-2 transform-scale-0"
            style="height: 10px;enable-background:new 0 0 8 10.2;"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 8 10.2" xml:space="preserve">
            <path fill="#000" fill-opacity="0.08" d="M0,0c2.7,0,5.4,0,6.4,0c2.2,0,1.7,2.6,1,3.4C6.8,4.1,1.1,9.2,0,10.2L0,0z"/>
          </svg>
          <svg
            v-else-if="!textIsSelf"
            class="tw-absolute tw-top-0 tw-left-0 tw-w-2 tw--ml-2 transform-scale-0"
            style="height: 10px;enable-background:new 0 0 8 10.2;"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 8 10.2" xml:space="preserve">
            <path fill="var(--primary-blue)" d="M8,10.2c-1.1-1-6.8-6.1-7.4-6.9C-0.1,2.6-0.6,0,1.6,0c1,0,3.7,0,6.4,0L8,10.2z"/>
          </svg>
        </div>
      </div>
      <!-- <div v-if="!textIsSelf && !isResourceRef" class="tw-min-w-8 tw-ml-2"> -->
      <div v-if="!textIsSelf" class="tw-min-w-8 tw-ml-2">
        <!-- reply button -->
        <BaseButton
          text=""
          icon
          style="line-height: 0; font-size: 0;"
          class="tw-bg-gray-200 tw-rounded-full tw-py-3 tw-text-app-primary-blue tw-border-0 hover:tw-bg-gray-600 hover:tw-text-white transition-fast"
          @click="replyToThisMessage()"
        >
          <svg slot="icon" class="tw-w-4 tw-h-4 tw-mx-3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M70.9,0.9c-0.8-0.8-2.2-1-3.3-0.6l-65.8,25c-1.2,0.6-2,1.8-1.8,3c0,1.2,0.8,2.2,2,2.6l28.8,10l10.4,29.1
              c0.4,1.2,1.6,2,2.8,2l0,0c1.4,0,2.6-1,3-2.2L71.9,4.1C72.2,2.9,71.7,1.7,70.9,0.9z M44.1,66.1l-9.8-27c-0.4-0.8-1-1.6-1.8-1.8
              L5.7,28.1L67.1,4.9L44.1,66.1z M39.6,32.3c-0.8-0.8-0.8-2,0-2.8l10-10c0.8-0.8,2-0.8,2.8,0s0.8,2,0,2.8l-10,10
              c-0.4,0.4-1,0.6-1.4,0.6C40.7,33,40.1,32.5,39.6,32.3z"/>
          </svg>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toBoolean, htmlizeLinksInString } from '@/modules/dataHelper';
import UserPhoto from '@/components/UserPhoto.vue';

const RESOURCE_TYPES = {
  pillarresource: 1,
  pillarlink: 2,
  resource: 3,
  link: 4,
};

export default {
  name: 'MentorshipGroupChatBubbleText',
  components: {
    UserPhoto,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    status: {
      type: [String, Boolean],
      required: true,
    },
    senderId: {
      type: [String, Number],
      required: true,
    },
    // CAN EITHER BE TASKID OR RESOURCE...
    attachment: {
      type: [String, Number],
      required: false,
    },
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      resource: {},
      user: {},
      messageRepliedTo: {},
      userRepliedTo: {},
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    textIsSelf() {
      return this.senderId === this.userId || Number(this.senderId) === Number(this.userId);
    },
    read() {
      return toBoolean(this.status);
    },
    isResourceRef() {
      return this.message.resourceid.length > 0 && this.message.resourceid !== '0';
    },
    isLinkResourceRef() {
      // eslint-disable-next-line eqeqeq
      return this.isResourceRef && (this.message.resource_type == RESOURCE_TYPES.pillarlink || this.message.resource_type == RESOURCE_TYPES.link);
    },
    isResourceRefOnly() {
      return this.isResourceRef && !!this.message.chat_message === false;
    },
    isAReply() {
      return !!this.message.chatrefid && this.message.chatrefid !== '0';
    },
    isTaskRef() {
      return (this.message.taskid || '').length > 0 && this.message.taskid !== '0';
    },
  },
  methods: {
    replyToThisMessage() {
      this.$emit('reply', this.message, this.user);
    },
    markTextRead() {
      this.$emit('text-read');
    },
    htmlizeLinksInString(str) {
      return htmlizeLinksInString(str);
    },
  },
  async created() {
    if (!this.textIsSelf) {
      this.user = await this.$store.dispatch('getUserData', this.message.senderid);
    }

    if (this.isAReply) {
      // get the message replied to
      this.messageRepliedTo = this.$store.getters.mentorshipGroupChatMessage(this.groupId, this.message.chatrefid);
      this.userRepliedTo = await this.$store.dispatch('getUserData', this.messageRepliedTo?.senderid);
    }

    // get attachment
    if (this.isResourceRef) {
      this.resource = await this.$store.dispatch('getResource', [this.message.resourceid, this.message.resource_type]);
      // console.log(this.resource);
      this.$emit('update');
    }
  },
};
</script>

<style>
</style>
